import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

// mock
import './fake-db/index.js'

// axios
import axios from './axios.js'

window.axios = axios
Vue.prototype.$http = axios

import VueApollo from 'vue-apollo'
Vue.use(VueApollo)

import vueApolloNetwork from './network/apollo/vueApolloNetwork'

const { apolloProvider } = vueApolloNetwork

// ACL
import acl from './acl/acl'

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// i18n
import i18n from './i18n/i18n'

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Vuex Store
import store from './store/store'

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

// composition api
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  acl,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app')

window.storage = {}
window.useFake = false
