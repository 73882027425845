import vue from 'vue'
import { updateByUserLocalStorage } from '@/services/localStorage/byUser'

const state = {
  // 自己的自選欄位
  // key: typeName, value: 自選欄位
  customFieldState: {},
}

const getters = {
  getCustomField: (state) => {
    return state.customFieldState
  },
}

const mutations = {
  setCustomField(state, customField) {
    state.customFieldState = customField
  },
  setCustomFieldByType(state, { typeName, value }) {
    vue.set(state.customFieldState, typeName, value)
  },
}

const actions = {
  setCustomFieldAndLocalStorageByType(
    { commit, rootGetters },
    { typeName, value }
  ) {
    commit('setCustomFieldByType', { typeName, value })
    const meId = rootGetters['me/meId']
    updateByUserLocalStorage({
      userId: meId,
      typeKey: typeName,
      value,
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
