import { ApolloClient } from 'apollo-boost'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, concat, split } from 'apollo-link'

import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import store from '@/store/store.js'

import VueApollo from 'vue-apollo'

const getHeaders = () => {
  const headers = {}
  return headers
}

window.REQUEST_DOMAIN = `api.${window.location.host}`

const httpLink = createHttpLink({
  // Local test remove uri / url 字串增加_u=user / _b=broker
  // uri: process.env.NODE_ENV == 'development'?'https://' + 'api.otsonet-broker.com' + '/gql/query_test_b' : 'https://' + window.REQUEST_DOMAIN + '/gql/query',
  uri:
    process.env.NODE_ENV === 'development'
      ? '/local-proxy-api/gql/query'
      : `https://${window.REQUEST_DOMAIN}/gql/query`,
  credentials: 'include',
})

// Create the subscripjion websocket link / url 字串增加_u=user / _b=broker
// const wsLink = new WebSocketLink({
//   // uri: process.env.NODE_ENV == 'development'?'wss://' + 'api.otsonet-broker.com' + '/gql/subscription_test_b' : 'wss://' + window.REQUEST_DOMAIN + '/gql/subscription',
//   uri: process.env.NODE_ENV === 'development'? `wss://api.otsonet-broker.com/gql/subscription_test_b` : `wss://${window.REQUEST_DOMAIN}/gql/subscription`,
// });

const wsUri =
  process.env.NODE_ENV === 'development'
    ? 'wss://api.otsonet-broker.com/gql/subscription_test_b'
    : `wss://${window.REQUEST_DOMAIN}/gql/subscription`

const wsClient = new SubscriptionClient(wsUri, {
  reconnect: true,
})

const wsLink = new WebSocketLink(wsClient)

wsClient.onConnected(() => console.log('websocket connected!!'))
wsClient.onDisconnected(() => console.log('websocket disconnected!!'))
wsClient.onReconnected(() => {
  console.log('websocket reconnected!!')
  store.commit('notification/setBrokerAuditNotify', true)
})

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
  wsLink.subscriptionClient.maxConnectTimeGenerator.max

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: getHeaders(),
  }) //request拦截器

  return forward(operation)
})

const apolloClient = new ApolloClient({
  // 你需要在这里使用绝对路径
  link: concat(middlewareLink, link),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    // apollo options applied to all queries in components
    $query: {
      fetchPolicy: 'network-only',
    },
  },
})

window.apolloClient = apolloClient

export default {
  apolloClient,
  apolloProvider,
}
