const defaultState = () => {
  return {
    editUserId: '',
    isEditedByBroker: false,
    isOpenPopupEditKyc: false,
  }
}

const state = defaultState()

const getters = {}

const actions = {}

const mutations = {
  setEditUserId(state, { userId }) {
    state.editUserId = userId
  },
  setIsEditedByBroker(state, { isEditedByBroker }) {
    state.isEditedByBroker = isEditedByBroker
  },
  setIsOpenPopupEditKyc(state, { isOpenPopupEditKyc }) {
    state.isOpenPopupEditKyc = isOpenPopupEditKyc
  },
  clearKyc(state) {
    // eslint-disable-next-line no-unused-vars
    state = defaultState()
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
