const state = {
  clickAppTime: 0,
}

const getters = {}

const actions = {}

const mutations = {
  updateClickAppTime(state, timestamp) {
    state.clickAppTime = timestamp
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
