import sortBy from 'lodash/sortBy'

const ROOT_KEY_BY_USER = 'byUser'
const ROOT_KEY_TIME_BY_USER = 'timeByUser'

const MAX_USER_SOLVE = 5

// 目前不支持多帳戶登入 因此共用 byUser
// 如果支持分頁不同帳號登入 則應該每次更新都要重取 localstorage

/**
 * { _userId: { xxx } }
 */
let byUser = {}

let timeByUser = {}

const setByUserLocalStorage = () => {
  const targetStringfy = JSON.stringify(byUser)
  localStorage.setItem(ROOT_KEY_BY_USER, targetStringfy)
}

const setTimeByUserLocalStorage = () => {
  const targetStringfy = JSON.stringify(timeByUser)
  localStorage.setItem(ROOT_KEY_TIME_BY_USER, targetStringfy)
}

/**
 * 將 updateTimeMap 製作成與 referMap 擁有且只有對應的 key，代表 updateTimeMap 多餘的 key 將會被捨棄，
 * 並且若缺少對應 referMap 的 key 時將會新增，並以當下時間做為新的更新時間。
 * @param {Object} referMap 被參考的 key-value 物件
 * @param {Object} updateTimeMap 紀錄對應參考資料更新時間的物件
 * @returns {Object} 會返回將 updateTimeMap 重新製作確保正確的 key-value(updateTime) 物件。
 */
const makeCorrectUpdateTimeReferMap = (referMap, updateTimeMap) => {
  /**
   * 進入此方法時的 timestamp
   */
  const updateTimeNow = +new Date()
  const correctUpdateMap = {}
  Object.keys(referMap).forEach((key) => {
    const updateTimeByKey = updateTimeMap[key]
    // timestamp 為數字，因此用 undefined 判斷為空
    // (雖然使用邏輯上不會有 timestamp 為 0 的更新時間)
    if (updateTimeByKey === undefined) {
      correctUpdateMap[key] = updateTimeNow
    } else {
      correctUpdateMap[key] = updateTimeByKey
    }
  })
  return correctUpdateMap
}

/**
 * 將 service 主要的 map 與 對應 updateTime map，調整成在上限數字內，
 * 若超過則會保留較新的資料。
 *
 * private. only use in this service.
 * it use variable in this service.
 */
const setTargetMapInLimit = () => {
  const userIds = Object.keys(byUser)
  if (userIds.length <= MAX_USER_SOLVE) {
    return
  }

  const mixUserIdAndUpdateTime = userIds.map((userId) => {
    return {
      userId,
      updateTime: timeByUser[userId],
      value: byUser[userId],
    }
  })

  const sortMixUserIdAndUpdateTime = sortBy(
    mixUserIdAndUpdateTime,
    'updateTime'
  )
  const lengthOutOfLimit = userIds.length - MAX_USER_SOLVE

  const targetsInLimit = sortMixUserIdAndUpdateTime.slice(lengthOutOfLimit)
  const newByUser = {}
  const newTimeByUser = {}

  targetsInLimit.forEach((target) => {
    newByUser[target.userId] = target.value
    newTimeByUser[target.userId] = target.updateTime
  })

  byUser = newByUser
  timeByUser = newTimeByUser
}
/**
 * private. only use in this service.
 * it use variable in this service.
 */
const initLocalStorageService = () => {
  const byUserLocalStorage = localStorage.getItem(ROOT_KEY_BY_USER)
  const timeByUserLocalStorage = localStorage.getItem(ROOT_KEY_TIME_BY_USER)

  if (!byUserLocalStorage) {
    // 放入預設值到 localstorage
    setByUserLocalStorage()
  } else {
    byUser = JSON.parse(byUserLocalStorage)
  }

  if (!timeByUserLocalStorage) {
    // 放入預設值到 localstorage
    setTimeByUserLocalStorage()
  } else {
    const originalTimeByUser = JSON.parse(timeByUserLocalStorage)
    // local storage 都要確保資料正確，因為使用者可在瀏覽器上自行更改
    timeByUser = makeCorrectUpdateTimeReferMap(byUser, originalTimeByUser)
    setTimeByUserLocalStorage()
  }

  setTargetMapInLimit()
}

const addNewUserDefaultValueToByUser = (userId) => {
  byUser[userId] = {}
  // 這裡必須要先給一次更新時間，因為有新增 user 時，要計算現存的 user 是否超過範圍，若有則需要依照更新時間決定誰刪除
  timeByUser[userId] = +new Date()
  setTargetMapInLimit()
}

/**
 * perfomance: 使用 deep copy, 效能考量建議此 function 作為第一次獲取資料，自行另外存一份作為後續修改並同步，效能會較佳。
 */
const getByUserLocalStorageByUserId = (userId) => {
  if (!byUser[userId]) {
    addNewUserDefaultValueToByUser(userId)
  }
  return JSON.parse(JSON.stringify(byUser[userId]))
}

const updateByUserLocalStorage = ({ userId, typeKey, value }) => {
  if (!byUser[userId]) {
    addNewUserDefaultValueToByUser()
  }
  byUser[userId][typeKey] = value
  timeByUser[userId] = +new Date()

  setByUserLocalStorage()
  setTimeByUserLocalStorage()
}

// 初始化 第一次先載入 local storage
initLocalStorageService()

export { getByUserLocalStorageByUserId, updateByUserLocalStorage }
