const BYTE_NUMS_TEN_MB = 10 * 1024 * 1024

export default {
  /**
   * 沒有使用者名稱時預設帶入
   * 但 broker 當前不使用預設名稱
   */
  DEFAULT_USER_NAME: 'user',
  //
  /**
   * 預設 ID 欄位，不選所屬代理等等的預設
   * 原因是後端為 BigInt 只是前端轉字串使用
   */
  DEFAULT_ID: '0',

  DEFAULT_IMAGE_MAX_SIZE: BYTE_NUMS_TEN_MB,
  // 所有使用者
  user: {
    /**
     * 對應到 me 的 roles 角色文字
     */
    userRoleEnum: {
      BROKER: 'broker',
      AGENT: 'agent',
      USER: 'user',
    },
  },
  kyc: {
    /**
     * value: data in server response
     */
    kycGenderDataValueEnum: {
      MALE: 0,
      FEMALE: 1,
    },
    /**
     * enum by server
     */
    kycStatusEnum: {
      UNSUBMITTED: 'UNSUBMITTED',
      SUBMITTED: 'SUBMITTED',
      PASSED: 'PASSED',
      FAILED: 'FAILED',
    },
  },
  setting: {
    traderAccountLimit: {
      min: 1,
      max: 100,
    },
  },
}
