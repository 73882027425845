import mock from "@/fake-db/mock.js"
  const data = {
    mt4: {
        "mt4manapi": {
          "server": "demo.fixserver.vip",
          "login": 100,
          "password": "Otso4321!"
        },
        "segments": {
          "start": 160200001,
          "end": 160300000,
          "next": 160200014
        },
        "timezone": 2,
        "character_encoding": "GB18030"
      },
    gmt: [{city : "(GMT-11:00) Midway Island",
    gmt: -11},
    {city : "(GMT-11:00) Samoa",
    gmt: -11},
    {city : "(GMT-10:00) Hawaii",
    gmt: -10},
    {city : "(GMT-09:00) Alaska",
    gmt: -9},
    {city : "(GMT-08:00) Pacific Time (US & Canada)",
    gmt: -8},
    {city : "(GMT-08:00) Tijuana",
    gmt: -8},
    {city : "(GMT-07:00) Arizona",
    gmt: -7},
    {city : "(GMT-07:00) Mountain Time (US & Canada)",
    gmt: -7},
    {city : "(GMT-07:00) Chihuahua",
    gmt: -7},
    {city : "(GMT-07:00) Mazatlan",
    gmt: -7},
    {city : "(GMT-06:00) Mexico City",
    gmt: -6},
    {city : "(GMT-06:00) Monterrey",
    gmt: -6},
    {city : "(GMT-06:00) Saskatchewan",
    gmt: -6},
    {city : "(GMT-06:00) Central Time (US & Canada)",
    gmt: -6},
    {city : "(GMT-05:00) Eastern Time (US & Canada)",
    gmt: -5},
    {city : "(GMT-05:00) Indiana (East)",
    gmt: -5},
    {city : "(GMT-05:00) Bogota",
    gmt: -5},
    {city : "(GMT-05:00) Lima",
    gmt: -5},
    {city : "(GMT-04:00) Atlantic Time (Canada)",
    gmt: -4},
    {city : "(GMT-04:00) La Paz",
    gmt: -4 },
    {city : "(GMT-04:00) Santiago",
    gmt: -4},
    {city : "(GMT-03:00) Buenos Aires",
    gmt: -3},
    {city : "(GMT-03:00) Greenland",
    gmt: -3},
    {city : "(GMT-02:00) Stanley",
    gmt: -2},
    {city : "(GMT-01:00) Azores",
    gmt: -1},
    {city : "(GMT-01:00) Cape Verde Is.",
    gmt: -1},
    {city : "(GMT) Casablanca",
    gmt: 0},
    {city : "(GMT) Dublin",
    gmt: 0},
    {city : "(GMT) Lisbon",
    gmt: 0},
    {city : "(GMT) London",
    gmt: 0},
    {city : "(GMT) Monrovia",
    gmt: 0},
    {city : "(GMT+01:00) Amsterdam",
    gmt: 1},
    {city : "(GMT+01:00) Belgrade",
    gmt: 1},
    {city : "(GMT+01:00) Berlin",
    gmt: 1},
    {city : "(GMT+01:00) Bratislava",
    gmt: 1},
    {city : "(GMT+01:00) Brussels",
    gmt: 1},
    {city : "(GMT+01:00) Budapest",
    gmt: 1},
    {city : "(GMT+01:00) Copenhagen",
    gmt: 1},
    {city : "(GMT+01:00) Ljubljana",
    gmt: 1},
    {city : "(GMT+01:00) Madrid",
    gmt: 1},
    {city : "(GMT+01:00) Paris",
    gmt: 1},
    {city : "(GMT+01:00) Prague",
    gmt: 1},
    {city : "(GMT+01:00) Rome",
    gmt: 1},
    {city : "(GMT+01:00) Sarajevo",
    gmt: 1},
    {city : "(GMT+01:00) Skopje",
    gmt: 1},
    {city : "(GMT+01:00) Stockholm",
    gmt: 1},
    {city : "(GMT+01:00) Vienna",
    gmt: 1},
    {city : "(GMT+01:00) Warsaw",
    gmt: 1},
    {city : "(GMT+01:00) Zagreb",
    gmt: 1},
    {city : "(GMT+02:00) Athens",
    gmt: 2},
    {city : "(GMT+02:00) Bucharest",
    gmt: 2},
    {city : "(GMT+02:00) Cairo",
    gmt: 2},
    {city : "(GMT+02:00) Harare",
    gmt: 2},
    {city : "(GMT+02:00) Helsinki",
    gmt: 2},
    {city : "(GMT+02:00) Istanbul",
    gmt: 2},
    {city : "(GMT+02:00) Jerusalem",
    gmt: 2},
    {city : "(GMT+02:00) Kyiv",
    gmt: 2},
    {city : "(GMT+02:00) Minsk",
    gmt: 2},
    {city : "(GMT+02:00) Riga",
    gmt: 2},
    {city : "(GMT+02:00) Sofia",
    gmt: 2},
    {city : "(GMT+02:00) Tallinn",
    gmt: 2},
    {city : "(GMT+02:00) Vilnius",
    gmt: 2},
    {city : "(GMT+03:00) Baghdad",
    gmt: 3},
    {city : "(GMT+03:00) Kuwait",
    gmt: 3},
    {city : "(GMT+03:00) Nairobi",
    gmt: 3},
    {city : "(GMT+03:00) Riyadh",
    gmt: 3},
    {city : "(GMT+03:00) Moscow",
    gmt: 3},
    {city : "(GMT+04:00) Baku",
    gmt: 4},
    {city : "(GMT+04:00) Volgograd",
    gmt: 4},
    {city : "(GMT+04:00) Muscat",
    gmt: 4},
    {city : "(GMT+04:00) Tbilisi",
    gmt: 4},
    {city : "(GMT+04:00) Yerevan",
    gmt: 4},
    {city : "(GMT+05:00) Karachi",
    gmt: 5},
    {city : "(GMT+05:00) Tashkent",
    gmt: 5},
    {city : "(GMT+06:00) Ekaterinburg",
    gmt: 6},
    {city : "(GMT+06:00) Almaty",
    gmt: 6},
    {city : "(GMT+06:00) Dhaka",
    gmt: 6},
    {city : "(GMT+07:00) Novosibirsk",
    gmt: 7},
    {city : "(GMT+07:00) Bangkok",
    gmt: 7},
    {city : "(GMT+07:00) Jakarta",
    gmt: 7},
    {city : "(GMT+08:00) Krasnoyarsk",
    gmt: 8},
    {city : "(GMT+08:00) Chongqing",
    gmt: 8},
    {city : "(GMT+08:00) Hong Kong",
    gmt: 8},
    {city : "(GMT+08:00) Kuala Lumpur",
    gmt: 8},
    {city : "(GMT+08:00) Perth",
    gmt: 8},
    {city : "(GMT+08:00) Singapore",
    gmt: 8},
    {city : "(GMT+08:00) Taipei",
    gmt: 8},
    {city : "(GMT+08:00) Ulaan Bataar",
    gmt: 8},
    {city : "(GMT+08:00) Urumqi",
    gmt: 8},
    {city : "(GMT+09:00) Irkutsk",
    gmt: 9},
    {city : "(GMT+09:00) Seoul",
    gmt: 9},
    {city : "(GMT+09:00) Tokyo",
    gmt: 9},
    {city : "(GMT+10:00) Yakutsk",
    gmt: 10},
    {city : "(GMT+10:00) Brisbane",
    gmt: 10},
    {city : "(GMT+10:00) Canberra",
    gmt: 10},
    {city : "(GMT+10:00) Guam",
    gmt: 10},
    {city : "(GMT+10:00) Hobart",
    gmt: 10},
    {city : "(GMT+10:00) Melbourne",
    gmt: 10},
    {city : "(GMT+10:00) Port Moresby",
    gmt: 10},
    {city : "(GMT+10:00) Sydney",
    gmt: 10},
    {city : "(GMT+11:00) Vladivostok",
    gmt: 11},
    {city : "(GMT+12:00) Magadan",
    gmt: 12},
    {city : "(GMT+12:00) Auckland",
    gmt: 12},
    {city : "(GMT+12:00) Fiji",
    gmt: 12}]
  }

mock.onGet("/api/data/mt4").reply(() => {
  return [200, data.mt4];
});
mock.onGet("/api/data/gmt").reply(() => {
    return [200, data.gmt];
  });