import mock from "@/fake-db/mock.js"
const data = {
  //持倉查看
  funds: [{
    "id": "291374891",
    "signal_id": "291374891",
    "name": "我是反指標",
    "description": "EA交易,28种主流货币对,杠杆1：100；预计平均年化收益率15%-20%,预计最大回撤不超过50%；要求采用比例跟随,跟随比例最大不超过1.5*跟随资金/交易员资金；考虑到跟随佣金,建议跟随金额不低于1000刀",
    "status": "",
    "enabled_at": "",
    "is_subscribe": true,
    "followed_at": "",
    "subscribe_at": "",
    "mulitiple": "",
    "reverse": "",
    "is_follow": true,
    "is_trade": true,
    "score": 22,
    "total_investment": "928.00",
    "profit": -80,
    "dropdown": "98",
    "rating": 1.8,
    "equity": 210.22,
    "subscribers": 92,
    "series": [{
      name: '7 day',
      data: [100, 60, -52, 40, -29, 18, 5]
    }],
    "past7": 2211,
    "thisMonth": 18589,
    "lastMonth": 7683,
  },
  {
    "id": "291374892",
    "signal_id": "291374892",
    "name": "想跟就跟",
    "description": "這是一個好訊號源",
    "status": "follow",
    "enabled_at": "",
    "is_subscribe": false,
    "followed_at": "",
    "subscribe_at": "",
    "mulitiple": "",
    "reverse": "",
    "is_follow": false,
    "is_trade": false,
    "total_investment": "8,928.00",
    "profit": "-30",
    "dropdown": "-18",
    "rating": "3.8",
    "equity": "89.13",
    "series": [{
      name: '30day',
      data: [128, 40, 36, 52, 38, 60, 55]
    }],
    "past7": 6589,
    "thisMonth": 86589,
    "lastMonth": 73683,
  },
  {
    "id": "291374893",
    "signal_id": "291374893",
    "name": "一飛沖天訊號",
    "description": "這是一個好訊號源",
    "status": "",
    "enabled_at": "",
    "is_subscribe": "",
    "followed_at": "",
    "subscribe_at": "",
    "mulitiple": "",
    "reverse": "",
    "is_follow": false,
    "is_trade": false,
    "total_investment": "128,928.00",
    "profit": "220",
    "dropdown": "38",
    "rating": "9.0",
    "equity": "170.32",
    "series": [{
      name: '30day',
      data: [8, 10, 16, 20, 27, 38, 55]
    }],
    "past7": 2211,
    "thisMonth": 18589,
    "lastMonth": 7683,
  },
  {
    "id": "291374894",
    "signal_id": "291374894",
    "name": "年化報酬500%",
    "description": "這是一個好訊號源",
    "status": "follow",
    "enabled_at": "",
    "is_subscribe": "",
    "followed_at": "",
    "subscribe_at": "",
    "mulitiple": "",
    "reverse": "",
    "total_investment": "--",
    "is_follow": true,
    "is_trade": null,
    "profit": "7",
    "dropdown": "8",
    "rating": "6.8",
    "equity": "170.32",
    "series": [{
      name: '30day',
      data: [8, 20, 36, 52, 38, 22, 9]
    }],
    "past7": 2211,
    "thisMonth": 18589,
    "lastMonth": 7683,
  },
],
walletAllocationRecord: [
  { 
    "number": 11,
    "id": "1048910489111",
    "type": "Withdrawal",
    "target": "",
    "detailBt": false,
    "amount": 150.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Failed',
    //status 處理中 成功 失敗
  },
  { 
    "number": 10,
    "id": "1048910489110",
    "type": "Withdrawal",
    "target": "",
    "detailBt": false,
    "amount": 150.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Pending',
    //status 處理中 成功 失敗
  },
  { 
    "number": 9,
    "id": "1048910489109",
    "type": "Withdrawal",
    "target": "",
    "detailBt": false,
    "amount": 150.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Passed',
  },
  
  {
    "number": 8,
    "id": "1048910489108",
    "type": "To",
    "target": "MO",
    "detailBt": true,
    "ticket": "1048910489104",
    "amount": 100.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": '',
  },
  {
    "number": 7,
    "id": "1048910489107",
    "type": "To",
    "target": "至PAUL",
    "detailBt": true,
    "ticket": "1048910489107",
    "amount": 100.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": '',
  },
  { 
    "number": 6,
    "id": "1048910489106",
    "type": "Withdrawal",
    "target": "",
    "detailBt": false,
    "amount": 150.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Pending',
  },
  { 
    "number": 5,
    "id": "1048910489105",
    "type": "From",
    "target": "MO",
    "detailBt": true,
    "amount": 200.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": '',
  },
  {
    "number": 4,
    "id": "1048910489104",
    "type": "From",
    "target": "PAUL",
    "detailBt": true,
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": '',
  },
  {
    "number": 3,
    "id": "1048910489103",
    "type": "Deposit",
    "target": "",
    "detailBt": false,
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Passed',//電匯
  },
  {
    "number": 2,
    "id": "1048910489102",
    "type": "Deposit",
    "target": "",
    "detailBt": false,
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Failed',
  },
    {
    "number": 1,
    "id": "1048910489101",
    "type": "Deposit",
    "target": "",
    "detailBt": false,
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Pending',
  },
  ],
tradeAccountFundRecord: [
  {
    "no": 6,
    "id": "1048910489104",
    "type": "PnL",
    "ticket": "1048910489104",
    "amount": 100.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "大家發大財",
    "target": "BBB",
  },
  {
    "no": 5,
    "id": "1048910489105",
    "type": "Commission",
    "ticket": "1048910489105",
    "amount": 100.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "一月",
    "target": "BBB",
  },
  { 
    "no": 4,
    "id": "1048910489106",
    "type": "To",
    "amount": 150.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "至Bob",
    "target": "BBB",
  },
  { 
    "no": 3,
    "id": "1048910489107",
    "type": "From",
    "amount": 200.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "至Bob",
    "target": "BBB",
  },
  {
    "no": 2,
    "id": "1048910489108",
    "type": "ProfitShare",
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "一月",
    "target": "BBB",
  },
  {
    "no": 1,
    "id": "1048910489108",
    "type": "To",
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "至Bob",
    "target": "BBB",
  },
],
deposit: {
    "wallet_requests_total": 5,
    "wallet_requests": [
      {
        "id": "517887787249172553",
        "user": {
          "id": "123235245",
          "name": "eric",
          "email": "eric.jhou@otsogroup.com",
          "kyc": {
            "phone": "+8860911111111"
          }
        },
        "payment_type": "TELEGRAPHIC",
        "status": "REJECTED",
        "created_at": "2020-06-19T06:12:53Z",
        "deposit_account": "22222",
        "amount": 200,
        "user_comment": "",
        "comment": "找不到資料",
      },
      {
        "id": "517887610081771593",
        "user": {
          "id": "123235245",
          "name": "eric",
          "email": "eric.jhou@otsogroup.com",
          "kyc": {
            "phone": "+8860911111111"
          }
        },
        "payment_type": "TELEGRAPHIC",
        "status": "WITHDRAWN",
        "created_at": "2020-06-19T06:12:31Z",
        "deposit_account": "11111",
        "amount": 110,
        "user_comment": ""
      },
      {
        "id": "517755560414675017",
        "user": {
          "id": "123235245",
          "name": "eric",
          "email": "eric.jhou@otsogroup.com",
          "kyc": {
            "phone": "+8860911111111"
          }
        },
        "payment_type": "TELEGRAPHIC",
        "status": "APPLYING",
        "created_at": "2020-06-19T01:37:25Z",
        "deposit_account": "500",
        "amount": 100,
        "user_comment": ""
      },
      {
        "id": "516550079788613720",
        "user": {
          "id": "123235245",
          "name": "eric",
          "email": "eric.jhou@otsogroup.com",
          "kyc": {
            "phone": "+8860911111111"
          }
        },
        "payment_type": "TELEGRAPHIC",
        "status": "APPROVED",
        "created_at": "2020-06-17T07:45:59Z",
        "deposit_account": "02324",
        "amount": 1111,
        "user_comment": ""
      },
      {
        "id": "516541932772524120",
        "user": {
          "id": "123235245",
          "name": "eric",
          "email": "eric.jhou@otsogroup.com",
          "kyc": {
            "phone": "+8860911111111"
          }
        },
        "payment_type": "TELEGRAPHIC",
        "status": "APPLYING",
        "created_at": "2020-06-17T07:29:01Z",
        "deposit_account": "11111",
        "amount": 111,
        "user_comment": ""
      }
    ]
},
withdrawal : {
  "wallet_requests_total": 7,
  "wallet_requests": [
    {
      "id": "521200922026573897",
      "user": {
        "name": "eric",
        "email": "eric.jhou@otsogroup.com",
        "kyc": {
          "phone": "+8860911111111"
        }
      },
      "status": "APPLYING",
      "created_at": "2020-06-24T01:15:15Z",
      "withdrawal_account": {
        "name": "中信",
        "payment_account": "48394834",
        "address": "南京東路",
        "payee_name": "eric",
        "payee_address": "哈哈",
        "swift_code": "FFDRGDFG"
      },
      "amount": 100,
      "user_comment": ""
    },
    {
      "id": "517887484881797193",
      "user": {
        "name": "eric",
        "email": "eric.jhou@otsogroup.com",
        "kyc": {
          "phone": "+8860911111111"
        }
      },
      "status": "APPLYING",
      "created_at": "2020-06-19T06:12:15Z",
      "withdrawal_account": {
        "name": "中信",
        "payment_account": "48394834",
        "address": "南京東路",
        "payee_name": "eric",
        "payee_address": "哈哈",
        "swift_code": "FFDRGDFG"
      },
      "amount": 110,
      "user_comment": ""
    },
    {
      "id": "517877145016467529",
      "user": {
        "name": "eric",
        "email": "eric.jhou@otsogroup.com",
        "kyc": {
          "phone": "+8860911111111"
        }
      },
      "status": "APPLYING",
      "created_at": "2020-06-19T05:50:43Z",
      "withdrawal_account": {
        "name": "中信",
        "payment_account": "48394834",
        "address": "南京東路",
        "payee_name": "eric",
        "payee_address": "哈哈",
        "swift_code": "FFDRGDFG"
      },
      "amount": 100,
      "user_comment": ""
    },
    {
      "id": "516550003435503704",
      "user": {
        "name": "eric",
        "email": "eric.jhou@otsogroup.com",
        "kyc": {
          "phone": "+8860911111111"
        }
      },
      "status": "APPLYING",
      "created_at": "2020-06-17T07:45:50Z",
      "withdrawal_account": {
        "name": "中信",
        "payment_account": "48394834",
        "address": "南京東路",
        "payee_name": "eric",
        "payee_address": "哈哈",
        "swift_code": "FFDRGDFG"
      },
      "amount": 100,
      "user_comment": ""
    },
    {
      "id": "516541341610541144",
      "user": {
        "name": "eric",
        "email": "eric.jhou@otsogroup.com",
        "kyc": {
          "phone": "+8860911111111"
        }
      },
      "status": "APPLYING",
      "created_at": "2020-06-17T07:27:47Z",
      "withdrawal_account": {
        "name": "中信",
        "payment_account": "48394834",
        "address": "南京東路",
        "payee_name": "eric",
        "payee_address": "哈哈",
        "swift_code": "FFDRGDFG"
      },
      "amount": 100,
      "user_comment": ""
    },
    {
      "id": "516534875445002328",
      "user": {
        "name": "eric",
        "email": "eric.jhou@otsogroup.com",
        "kyc": {
          "phone": "+8860911111111"
        }
      },
      "status": "APPLYING",
      "created_at": "2020-06-17T07:14:19Z",
      "withdrawal_account": {
        "name": "中信",
        "payment_account": "48394834",
        "address": "南京東路",
        "payee_name": "eric",
        "payee_address": "哈哈",
        "swift_code": "FFDRGDFG"
      },
      "amount": 111,
      "user_comment": ""
    },
    {
      "id": "516534400104530008",
      "user": {
        "name": "eric",
        "email": "eric.jhou@otsogroup.com",
        "kyc": {
          "phone": "+8860911111111"
        }
      },
      "status": "APPLYING",
      "created_at": "2020-06-17T07:13:20Z",
      "withdrawal_account": {
        "name": "中信",
        "payment_account": "48394834",
        "address": "南京東路",
        "payee_name": "eric",
        "payee_address": "哈哈",
        "swift_code": "FFDRGDFG"
      },
      "amount": 1000,
      "user_comment": ""
    }
  ]
},
}
mock.onGet("/api/record/historyRecord").reply(() => {
  return [200, data.historyRecord];
});
mock.onGet("/api/record/funds").reply(() => {
  return [200, data.funds];
});

mock.onGet("/api/record/walletAllocationRecord").reply(() => {
  return [200, data.walletAllocationRecord];
});

mock.onGet("/api/record/tradeAccountFundRecord").reply(() => {
  return [200, data.tradeAccountFundRecord];
});

mock.onGet("/api/record/deposit").reply(() => {
  return [200, data.deposit];
});


mock.onGet("/api/record/withdrawal").reply(() => {
  return [200, data.withdrawal];
});
