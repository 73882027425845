/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

const pageBrokerGroup = {
  groups: true,
}

const pageBrokerTrader = {
  groups: true,
  traders: true,
}

const pageBrokerTraderDetail = {
  groups: true,
  trader: {},
}

const pageBrokerUser = {
  users: true,
}

const pageBrokerUserDetail = {
  user: {},
  user_logs: {},
}

const pageBrokerKycs = {
  kycs: true,
}

const pageBrokerDeposit = {
  by_broker: true,
}

const pageBrokerWithdraw = {
  by_broker: true,
}

const pageBrokerWithdrawDetail = {
  wallet_request: {},
}

const pageBrokerMarket = {
  markets: true,
}

const pageBrokerMarketLevel = {
  market: true,
  by_broker: true,
}

const pageBrokerAgent = {
  agents: true,
  markets: true,
}

const pageBrokerAgentDetail = {
  agent: {},
}

const pageBrokerSystem = {
  by_broker: true,
}

const pageBrokerPlatform = {
  company: true,
  payments: true,
}

const pageUserDashboard = {
  me: true,
}

const pageUserTrader = {
  me: true,
}

const pageUserProfile = {
  me: true,
}

const pageNavBarProfile = {
  me: true,
}
// PAGE STATUS
const pageStatus = {
  brokerGroup: pageBrokerGroup,
  brokerTrader: pageBrokerTrader,
  brokerTraderDetail: pageBrokerTraderDetail,
  brokerUser: pageBrokerUser,
  brokerUserDetail: pageBrokerUserDetail,
  brokerKyc: pageBrokerKycs,
  brokerDeposit: pageBrokerDeposit,
  brokerWithdraw: pageBrokerWithdraw,
  brokerWithdrawDetail: pageBrokerWithdrawDetail,
  brokerMarket: pageBrokerMarket,
  brokerMarketLevel: pageBrokerMarketLevel,
  brokerAgent: pageBrokerAgent,
  brokerAgentDetail: pageBrokerAgentDetail,
  userDashboard: pageUserDashboard,
  userTrader: pageUserTrader,
  userProfile: pageUserProfile,
  navBarProfile: pageNavBarProfile,
  brokerSystem: pageBrokerSystem,
  brokerPlatform: pageBrokerPlatform,
}

const sideBarNotifyStatus = {
  AuditKyc: 0,
  AuditWithdraw: 0,
  AuditDeposit: 0,
}

// *From Auth - Data will be received from auth provider
const userDefaults = {
  id: 0, // From Auth
  name: 'david wang', // From Auth
  roles: [],
  email: 'davie.wang@otsogroup.com',
  about:
    'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
  photoURL: require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
  status: 'online',
  KYC: true,
  KYCing: false,
  gender: 'Male',
  birthday: '1977-12-02',
  mobile: '0914719722',
  address: '台北市松山區南京東路四段197號9樓',
}

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  var mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser: userDefaults,
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  is_touch_device: is_touch_device(),
  mainLayoutType: themeConfig.mainLayoutType || 'vertical',
  navbarSearchAndPinList: navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: 'default',
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList['pages'].data.filter(
    (page) => page.is_bookmarked
  ),
  theme: themeConfig.theme || 'light',
  themePrimaryColor: colors.primary,
  BreadthumbSecond: '33331',
  pageGqlControl: pageStatus,
  roleLoading: true,
  sideBarNotify: sideBarNotifyStatus,
  serverList: [],
  currentServer: {},
  isEnableServerChange: false,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
}

export default state
