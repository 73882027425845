import config from '@/config'

const {
  user: { userRoleEnum },
  DEFAULT_USER_NAME,
} = config

const state = {
  meState: {},
  referrer: '',
}

const getters = {
  meId(state) {
    return state.meState.id
  },
  isMeHavingBrokerRole: (state) => {
    if (!state.meState.roles) return false
    const INDEX_NOT_FOUND = -1
    return state.meState.roles.indexOf(userRoleEnum.BROKER) !== INDEX_NOT_FOUND
  },
  isMeHavingAgentRole: (state) => {
    if (!state.meState.roles) return false
    const INDEX_NOT_FOUND = -1
    return state.meState.roles.indexOf(userRoleEnum.AGENT) !== INDEX_NOT_FOUND
  },
  /**
   * 使用者角色，若有多個照優先順序決定主要角色
   */
  meMainRole: (state, getters) => {
    // 依照優先次序
    if (getters.isMeHavingBrokerRole) {
      return userRoleEnum.BROKER
    } else if (getters.isMeHavingAgentRole) {
      return userRoleEnum.AGENT
    }
    return userRoleEnum.USER
  },
  meNameDisplay: (state, getters) => {
    switch (getters.meMainRole) {
      case userRoleEnum.BROKER: {
        // PM 說 Broker 一定有名字
        return state.meState.name
      }
      case userRoleEnum.AGENT:
      case userRoleEnum.USER:
      default: {
        const userNameFromKyc = state.meState.kyc && state.meState.kyc.name
        if (!userNameFromKyc) {
          return DEFAULT_USER_NAME
        }
        return userNameFromKyc
      }
    }
  },
}

const actions = {}

const mutations = {
  updateMe(state, { me }) {
    state.meState = { ...state.meState, ...me }
  },
  updateReferrer(state, { referrer }) {
    state.referrer = referrer
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
