import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'

Vue.use(AclInstaller)

// issue: 原先預設是 'user', 為了短期應付需求先改為預設 broker，之後重構權限時要移除
let initialRole = 'broker'

// const userInfo = JSON.parse(localStorage.getItem('userInfo'))
// if (userInfo && userInfo.userRole) initialRole = userInfo.userRole

export default new AclCreate({
  initial: initialRole,
  notfound: '/pages/not-authorized',
  router,
  acceptLocalRules: true,
  globalRules: {
    broker: new AclRule('broker').generate(),
    agent: new AclRule('agent').generate(),
    user: new AclRule('user').or('agent').generate(),
    public: new AclRule('user').or('agent').or('broker').generate(),
  },
})
