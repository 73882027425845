// 可同單字一次觀看多個語系翻譯的格式，效能差一些但也只有初次載入會運行，有需要可使用
// const b = {
// 	Broker: {
// 		'zh-tw': 'Broker',
// 		'en-us': 'Broker'
// 	}
// }
// const a = (language) => {
// 	const languageModel = {}
// 	Object.keys(b).forEach(wordTranslate => {
// 		languageModel[wordTranslate] = b[wordTranslate][language]
// 	})
// 	return languageModel
// }

//// en-us, zh-tw, zh-cn, vi-vn, ja-jp, ko-kr, co-fr
// en, cn

export default {
  'en-us': {
    Broker: 'Broker',
    Provider: 'Provider',
    Trader: 'Trader',
    Signal: 'Signal',
    YES: 'Yes',
    NO: 'No',
    //Menu Word
    Review: 'View',
    AgentApplication: 'Agent Application',
    AgentManage: 'Agent Management',
    Client: 'Client',
    FollowerManage: 'Followers Management',
    FollowerList: 'Followers List',
    Order: 'Orders',
    OrderHistory: 'Order History',
    AccountDetail: 'Account Detail',
    AccountHistory: 'Account History',
    CreatAccount: 'Create Account',
    FundHistory: 'Funding History',
    ReadInfo: 'Check Info',
    // SIDE MENU
    Dashboard: 'Dashboard',
    Setting: 'Preferences',
    UserManagement: 'User Management',
    Audit: 'Needs Approval',
    MarketGroup: 'Market Group',
    Management: 'Management',
    Report: 'Report',

    // Word
    More: 'Detail',
    LoginSetting: 'Login Setting',
    LoginDevice: 'Login Device',
    LoginDate: 'Login Date',
    Position: 'Location',
    BrokerSetting: 'Broker Preferences',
    SystemSetting: 'System Preferences',
    SettingDes1: 'To reinforce',
    SettingDes2:
      'a safe and secure environment for trading, if you have any questions or concerns about your account, then please contact us using the contact details provided.',
    FundManage: 'Fund Management',
    User: 'User',
    UserManage: 'User Management',
    UserAccount: 'User Account',
    // Search placeholder
    PlaceholderUser: 'User Name、Email',
    PlaceholderTrader: 'Trader Name, User Name',
    PlaceholderAgent: 'Agent Name',
    PlaceholderOrderQuery: 'Account, Order, Ticker',
    NotYetBindUser: 'Not linked Users',
    UserName: 'User Name',
    AgentName: 'Agent name', //等同使用者名稱
    KYC: 'KYC Application',
    PerLot: 'Per Lot',
    PerDeal: 'Per Order',
    //Login
    Login: 'Login',
    LoginMt: 'MT Login',
    LastLogin: 'Last Login',
    LoginNormal: 'UserName',
    LoginMtText: 'MT Account and Password',
    TextBuildAccount: 'To get a verification code, please enter your e-mail.',
    Password: 'Password',
    YourEmail: 'Your Email',
    ForgotPassword: 'Forgot password？',
    TextForgotPassword:
      'To reset your password, please enter the email address associated with your CRM account, we will send you a verification code to that email.',
    AccountManage: 'Account Management',
    OrderQuery: 'Check Orders',
    OrderStatus: 'Order Status',
    //Button
    Send: 'Send',
    Confirm: 'Confirm',
    AlreadyConfirm: 'Confirmed',
    Done: 'Finished',
    Delete: 'Remove',
    EditName: 'Edit',
    EditNickName: 'Edit Nick Name',
    AddMarket: 'Create New Market',
    AddLevel: 'Create New Level',
    EditLevel: 'Change Tier',
    DeleteLevel: 'Remove Level',
    DeleteLevelDes: 'Please confirm you want to remove the level.',
    DeleteLevelDes2: 'Failed to remove level due to remaining users.',
    DuplicateLevel: 'Copy Last Settings',
    DeleteLoginDevice: 'Remove Login Device',

    DeleteMarket: 'Market Removed',
    CommissionSetting: 'Commission Settings',
    AddMarketDes: 'Create new market, set a new level and commission.',
    DeleteMarketDes: 'Please confirm you want to remove the market.',
    CanNotDeleteMarket: 'Failed to remove market due to remaining users.',
    DeleteConfirm: 'Please confirm you want to remove it.',
    ConfirmWithdraw: 'Please confirm the withdrawal.',
    Upload: 'Upload',
    Demo: 'Demo',
    //NOTIFY
    PWDone: 'Your password has been changed.',
    DepositDone: 'Deposit Requested',
    DepositOk: 'Deposit Successful',
    WithdrawalDone: 'Withdraw Successful',
    ChangeGroupDone: 'Group Change Successful',
    ChangeLeverageDone: 'Leverage Change Successful',
    DisableAccount: 'Suspend Account',
    EnableAccount: 'Recover Account',
    EnableAccountDes:
      'Are you sure you want to recover the account? This will allow access to <br>.',
    DisableAccountDes:
      'Are you sure you want to suspend the account? This will block it from <br><br>.',
    ChangeParentDes: 'Adjust Higher Tier',
    enableDemo: 'Enable Demo',
    cancelIB: 'Diable IB',
    ChangeClientToIB: 'Transfer Client to Following IB',
    ActionFailed: 'Action Failed',
    ThrottleLimit: 'Too many attempts, please try again later.',
    CopyWordSuccess: 'Copied',
    CopyWordFailed: 'Copy Incompleted',

    IPDes: 'IP address，e.g. aaa.com / 111.222.33.44',
    //註冊
    RDone: 'Registration Completed',
    RText:
      'To protect your rights please fill out this form correctly and completely.',
    NickName: 'Nick Name', //User register name
    RRealName: 'Real Name', // KYC name
    Name: 'Name', //Kyc name
    RPersonalInfo: 'Personal Information',
    RBankInfo: 'Bank Information',
    RRestInfo: 'Residential Information ',
    RIDCode: 'ID#',
    RIDDoc: 'Proof of Identification',
    RAddressDoc: 'Proof of Residential Address',
    RBankDoc: 'Proof of Bank Account',
    RIDP: 'Copy of Front ID',
    RIDN: 'Copy of Back ID',
    RBankDoc1: 'Banking Details-1',
    RBankDoc2: 'Banking Details-2',
    UserDetail: 'User Detail',
    UserInformation: 'User Information',

    //SETTING
    TraderAreaTimezone: 'Trader Time Zone',
    LanguageCode: 'Language',
    Increase: 'Ascending',
    Decreasing: 'Descending',
    CompanyName: 'Company Name',
    CompanyWebsite: 'Company Website',
    BusinessHours: 'Business Hours',
    WorkingDes:
      'Please contact us if you need any further information, we are available at:',
    SUN: 'Sunday',
    MON: 'Monday',
    TUE: 'Tuesday',
    WED: 'Wednesday',
    THU: 'Thursday',
    FRI: 'Friday',
    SAT: 'Saturday',
    OS: 'Operating System',
    RIDText: 'Please provide your ID.',
    RAddressText:
      'We accepts proofs of residency that includes your name and address, you may use current utlity bills, bank statement or rental agreement, etc.',
    RBankText:
      'Please provide your personal information documents to support your bank information, such as passbook.',
    RGender: 'Gender',
    RBirthday: 'Date of Birth',
    I18GenderMale: 'Male',
    I18GenderFemale: 'Female',
    RGender3: 'Other',
    RGender4: 'Prefer not to state.',
    RRegion: 'Country',
    RNationCode: 'Country Code',
    RSelect: 'Select',
    RCity: 'City',
    RProvince: 'State',
    RArea: 'District',
    RPhone: 'Phone#',
    RAddress: 'Address',
    Remail: 'Email',
    RPassword: 'Password',
    ROPassword: 'Old Password',
    RNPassword: 'New Password',
    RMTPassword: 'MT Account Password',
    RMTPasswordLimited:
      'Password must be limited to 15 characters and contain both numbers and letters/special characters.',
    I18NewRMTPasswordLimited:
      'Password must be limited to 15 characters and contain both numbers and letters/special characters.',
    RPassword1: 'At least 8 character and contain either letters or numbers.',
    RPasswordConfirm: 'Confirm Password',
    RPasswordConfirm1: 'Password Missmatch',
    RPasswordReset: 'Reset Password',
    CreateAccount: 'Create Account',
    AccountName: 'Account Name', //Trader account name
    AccountPW: 'Password',
    AccountPWA: 'Retype Password',
    MTLogin: 'MT Login',
    OnlyNumber: 'Numbers Only',
    OnlyNumberNoZero: 'None Zero Digits',
    NumberArea: 'Segment Preference',
    Range: 'Range',
    Minnum: 'Must be greater than minimum segment.',
    Numrange: 'Must be bewteen minimum and maximum segment.',
    MinValue: 'Minimum',
    MaxValue: 'Maximum',
    NextAccountNum: 'Next Account#',
    NumberNow: 'Current#',
    MTPW: 'MT Password',
    //後端回傳Errors
    NoLogged: 'Not Logged In',
    AlreadyLogged: 'Logged In',
    AuthServiceError: 'Authentiation Error',
    EmailIsAlreadyUsed: 'Email is already used for another account.',
    EmailDoesNotExist: 'Email does not exist.',
    WrongEmailOrPassword: 'Wrong email or password.',
    CertificationExpired: 'Certificatoin Expired',
    ErrorFailAccount:
      'Account creation failed, please contact your account manager.',
    'Limit exceeded': 'Repeated requests, please try again later.',
    'broker-trader-enable': 'Account Activated',
    'broker-sync-config': 'Information Sync',
    'broker-sync-trader': 'Account Sync',
    'broker-trader-setgroup': 'Trader Group',
    'broker-trader-leverage': 'Trader Leverage',
    CommissionIsGreaterThanThePreviousLevel:
      'Commission cannot exceeds the previous level.',
    CommissionIsLessThanThePreviousLevel:
      'Commission cannot be less than next level.',
    ManagerAPIConnFailed: 'Manager API connection failed.',
    BadRequest: 'Request error...',
    ImageDisabled: 'Clear Image',
    Inside: 'Internal',
    Outside: 'External',
    BrowserIcon: 'Browser',
    //Errors
    ErrorMail: 'Invaild Email Address',
    ErrorPW:
      'At least 8 character and contain both numbers and letters/special characters：+ - @ _ = * $.',
    ErrorVerify: 'Incorrect Verificatoin Code',
    ErrorName:
      'Invalid name (limited to 64 characters, allowed in upper/lower case, number, space and special characters：- _ . ·，).',
    ErrorCorrect: 'Invalid Format',
    ErrorLogin: 'Only numbers should be allowed, limited to 32 characters.',
    ErrorPhone:
      'Must be bewteen 7 and 15 characters and only numbers should be allowed.',
    ErrorID: 'Please enter ID # appears on your ID.',
    ErrorCountryCode: 'Please Select Country',
    ErrorEmpty: 'Cannot be null or empty.',
    ErrorAccount: 'Invalid account format, must contain at leat 8 characters.',
    MemberTern: 'Terms & Conditions',
    PrivacyPolicy: 'Privacy Policy',
    TextKnowing:
      'I confirm having read, understood and accepted the privacy policy.',
    TextKnowing2:
      'Trading CFDs carries considerable risk of capital loss. CFDs are complex instruments and come with high risk of losing money rapidly due to leverage. Terms and conditions apply.',
    VerifyPassText:
      'Request accepted, please check in your personal information.',
    SecurityBlock: 'Trade Disabled',
    GroupBlock: 'Group Suspended',
    ErrorReason: 'Error Report',
    Or: 'Or',
    None: 'None',
    BackLogin: 'Back to Login',
    VerifyCode: 'Verify Code',
    Cancel: 'Cancel',
    ReSend: 'Resubmit',
    VerifyReceived: 'Please check your email for your verification code.',
    SendVerify: 'Verify Email',
    EmailAlready:
      'Email already in use. Please login with the account or create a new account.',
    EmailNotExist: 'Email not exist, please try another one.',
    Required: 'Required Field',
    RequiredSwift: 'Invalid SWIFT code',
    RequireIban: 'Invalid IBAN code',
    //Bank
    ReceviedBankInfo: 'Payee Bank Information',
    GBankName: 'Bank Name',
    GBankAddress: 'Bank Address',
    GBankAccount: 'Bank Account',
    GManName: 'Recipient',
    GManAccount: 'Recipient Account',
    GManAddress: 'Recipient Address',
    GBankCode: 'Bank Code',
    PaymentCurrency: 'Currency',
    PaymentChannel: 'Payment Method',
    PaymenLimit: 'A $1000 minimum deposit is needed.',
    ChoosePayment: 'Choose Payment',
    TradeCurrency: 'Currency',
    Mer_no: 'Merchant#',
    InputMer_no: 'Enter Merchant#',
    Telegraphic: 'Telegraphic',
    SettlementByDay:
      'Daily settlement; Sync Orders Daily at GMT +0 00:00; Settle at GMT +0 01:00 on Following Day',
    SettlementByWeek:
      'Weekly settlement; Sync Orders Daily at GMT +0 00:00; Settle at GMT +0 01:00 Every Sunday',
    SettlementByMonth:
      'Monthly settlement; Sync Orders Daily at GMT +0 00:00; Settle at GMT +0 01:00 on the 1st of Following Month',
    //註冊
    ErrMsg2: 'Please try again later.',
    //Signal
    STotalInvest: 'Investment',
    Balance: 'Balance',
    SEquity: 'Equity',
    Save: 'Save',
    Show: 'Show',
    Num: 'Record',
    AddAccountDirect: 'Add Account',
    BindMT4Account: 'Link MT4',
    SAvailableFunds: 'Avaiable Funds',
    WithdrawCost: 'Withdrew',
    DepositCost: 'Deposited',
    WaitWithdrawCost: 'Pending Withdrawal',
    ApplicationWithdraw: 'Withdraw',
    SFPnL: 'Floating P/L',
    SMargin: 'Margin',
    MarginUsed: 'Margin Used',
    MarginCanUse: 'Margin Available',
    MarginRatio: 'Margin Percentage',
    SIncome: 'Income',
    SRetreat: 'Retreat',
    SAddPt: 'Add Pt',
    Leverage: 'Leverage',
    AccountNum: 'User Counts',
    AgentNum: 'Agent Number',
    Sercurity: 'Order Type',
    MarginCall: 'Margin Call',
    MarginStopOut: 'Stop Out',
    TitleTrade: 'Trade Type',
    SCredit: 'Credit',
    Sscore: 'Score',
    Default: 'Default',
    SetDefault: 'Set as default group.',
    LeverageDefault: 'Default Leverage',
    ChangeLeverage: 'Modify Leverage',
    All: 'All',
    PnLTotal: 'Total P/L',
    ChooseGroup: 'Choose Group',
    ChangeGroup: 'Change Group',
    //Order
    OnHold: 'Pending',
    OrderClose: 'Cover',
    OrderOpen: 'Open Interest',
    OrderCloseProcess: 'Processing',
    NoUnprocess: 'No Pending Approval',
    EmptyMsg1: 'Available Information',
    EmptyInfo: 'No Information Available',
    Empty: 'Empty',
    UnitPoint: 'Unit Point',
    Add: 'Add',
    Edit: 'Modify',
    PopUpCloneDes:
      'Create an account and you will be able to trade multiple products and manage your funds',
    CopyPromoteLink: 'Referral Link',
    readOnlypassword: 'Show Password Only',
    BalanceReport: 'Balance Report',
    UserDistribution: 'User Distribution',
    SelectPlaceHolderChoseAccount: 'Choose Account',
    SelectAgent: 'Select',
    AdvanceSearch: 'Advanced Search',
    FunctionSearch: 'Search Tools',
    FunctionSearchTrader:
      'Enter trader account. Use a comma to seperate two or more accounts.',
    FunctionSearchUser:
      'Enter user account. Use a comma to seperate two or more accounts.',
    FunctionCommission: 'Enter Commission No.',
    Search: 'Search',

    ChoisePayment: 'Choose Payment',
    PaymentMethod: 'Payment Method',
    PaymentSetting: 'Payment Preference',
    PaymentColegantDes:
      'We will direct you to another page when choosing Colegant.',
    PaymentDes1: 'We will direct you to another page when choosing',
    PaymentDes2: '.',
    LastFiveNum: 'Last Five Digits of Account#',
    //No Data
    // REPORT
    Subscribe: 'Subscribe',
    UnSubscribe: 'Unsubscribe',
    UnSubscribeDes: 'You are now unsubsribed.',
    TradeNoAccess: 'No Access to Trading',
    UserNoAccess: 'No Access',
    TSymbol: 'Ticker',
    TOrderNo: 'Order#',
    TType: 'Order Type',
    TSummary: 'Summary',
    TLots: 'Lots',
    TTrade: 'Volume',
    TPrice: 'Price',
    TSecurity: 'Order Type',
    TOpenDate: 'Open Date',
    TOpenArea: 'Open Position Date',
    TCloseArea: 'Close Position Date',
    OpenPrice: 'Open Price ',
    TCloseDate: 'Close Date',
    CurrentPrice: 'Current Price',
    ClosePrice: 'Close Price',
    OrderSyncTime: 'Last Order Sync',
    TStopLoss: 'Stop Loss',
    TStopWin: 'Stop Gain',
    ProfitLoss: 'Net Profit',
    Storage: 'Interest',
    Fees: 'Fees',
    TProfit: 'Profit',
    TradeLoss: 'Loss',
    GMTWord: 'Current Time Zone',
    //Record
    BackPoint: 'Rebate',
    SettingPC: 'Rebate Setting',
    Commission: 'Commission',
    Commission2: 'Commission Rebate',
    CommissionReport: 'Commission Report',
    CommissionOrder: 'Commission#',
    MarketNone: 'No Current Market',
    CommissionSend: 'Commission',
    SettlementTime: 'Settled Time',
    IntervalTime: 'Time',
    CommissionType: 'Commission Type',
    CommissionRange: 'Commission',
    NormalCommission: 'Normal Commission',
    SameCommission: 'Equal Commission',
    OverCommission: 'Superior Commission',

    //Application
    Application: 'Application',
    ApplicationMan: 'Applicant',
    ApplicationName: "Applicant's Name",
    ApplicationAccount: 'Applicant Account',
    ApplicationEmail: 'Applicant Email',
    ApplicationOrder: 'Request#',
    ApplicationType: 'Type',
    ApplicationPhone: "Applicant's Number",

    BuyQuty: 'Buy Qty',
    SellQuty: 'Sell Qty',
    PnL: 'Net P/L',
    PnLArea: 'P/L Range',
    ProfitShare: 'Profit Share',
    FundOut: 'Withdraw',
    FundIn: 'Add Fund',
    SelectMarkets: 'Select Market',
    SelectLevel: 'Select Tier',
    SetLevel: 'Modify Tier',

    SelectLevelDes: 'Level changing is only allowed in same market.',
    SelectCharacter: 'Category',
    SelectAccountStatus: 'Account Status',
    TotalCommission: 'Commission',
    CommissionAccounting: 'Calculate Commission',
    getCommission: 'Recieve Commission',
    SelectAgentDes: 'Check Commission-Paid Agents',
    CreateAccounRecord: '1W New Accounts',
    LastSettlementCommission: 'Last Paid Commission',
    TraderVolum7: '1W Volume',
    Day7: '1W',
    PnL7: '1W P/L',
    HotSymbol: 'Trending Now',
    Credit: 'Credit',
    TotalWithdrawal: 'Total Withdrawal',
    Withdrawal: 'Withdrawal',
    WithdrawalCost: 'Available for Withdrawal',
    Deposit: 'Deposit',
    TotalDeposit: 'Total Deposit',
    WithdrawalMin: 'Minimum Withdrawal Amount',
    Costmin: 'Amount Cannot be Less than',
    DepositNum: 'Deposit History',
    WithdrawalNum: 'Withdrawal History',
    //交易組別
    AllGroup: 'All Groups',
    MTGroup: 'Group',
    DefaultGroup: 'Default Group',
    MTGroupSetting: 'Group Settings',
    GroupIn: 'Assigned Group',
    Enabled: 'Enabled',
    Disable: 'Suspended',
    IsIBANSWIFT: 'Do you need SWIFT/IBAN code?',
    //資金配置紀錄
    FundArrange: 'Asset Arrangement',
    SelectCostRange: 'Balance Range',
    SelectCostRangeDesMax: 'Enter Maximum Amount, then Sort by High to Low',
    SelectCostRangeDesMin: 'Enter Minimum Amount, then Sort by Low to High',
    CostRange: 'Amount',
    Arragne: 'Distribution',
    ArragneTo: 'Distribute to',
    EnterFundCost: 'Enter Amount',
    FunArrangeRecord: 'Distribution History',
    FundArrangeMin: 'Minimum Amount',
    TradeHistory: 'History',
    ChoseFrom: 'Choose From',
    Wallet: 'Wallet',
    Type: 'Method',
    Time: 'Time',
    StartTime: 'Start Time',
    EndTime: 'End Time',
    Start: 'Start',
    End: 'End',
    Cost: 'Amount',
    AccountRecord: 'Tranfer History',
    Function: 'Functions',
    Summary: 'Summary',
    KYCStatus: 'KYC Verification',
    Action: 'Options',
    Comment: 'Notes',
    Account: 'Account',

    //市場
    MarketCommission: 'Market Commission',
    MarketCommissionSetting: 'Commission Settings',
    Market: 'Market',
    EditMarket: 'Edit Market',
    Agent: 'Agents',
    AgentList: 'Agents Management',
    IsAgent: 'Agent',
    NotAgent: 'Not Agent',
    MarketLevel: 'Market Level',
    MarketName: 'Name',
    MarketCost: 'Market Transactions',
    Level: 'Tier',
    Detail: 'Details',
    ChannelName: 'Channel',

    //User List
    TraderName: 'Trader Name',
    AccountStatus: 'Account Status',
    Register: 'Register',
    RegisterEnabled: 'Enabled',
    RegisterDisabled: 'Disabled',
    RegisterDate: 'Registration Date',
    AuditDate: 'Request Date',
    ApplicationDate: 'Request Date',
    ApplicationDateInterval: 'Request Date',
    HandleDate: 'Process Date',
    HandleDateInterval: 'Process Date',
    CompletedDate: 'Completion Date',
    ChoseGMTTime: 'Change Your Time Zone',
    DepositOrder: 'Deposit Account#',
    Completed: 'Completed',
    WithdrawalApplication: 'Withdrawal Request',
    StopAccountDes:
      'Your account has been Suspended. Please contact your administrator.',
    WithdrawalOrder: 'Withdrawal Account#',
    AllCharacters: 'All Categories',
    Characters: 'Category',
    Status: 'Status',
    ThisWeek: '1W History ',
    ThisMonth: '1M History',
    //交易數據
    TradeStatus: 'Status',
    TradeData: 'Data',
    TradeEva: 'Evaluation',
    TDPL: 'Net P/L',
    TDLots: 'Total Lots',
    TDInterest: 'Total Interest',
    TDHandingFee: 'Total Fee',
    //NOTIFY
    NewApplication: 'New Request',
    DepositSuccess: 'Deposit Success',
    Approved: 'Approved',
    WithdrawnSuccess: 'Withdraw Success',
    Reject: 'Denied',
    Reject2: 'Denied',
    RejectApplication: 'Denied Request',
    Void: 'Invalid',
    Check: 'Verified',
    Grant: 'Distributed',
    RejectReason: 'Reason for Denial',
    Notify: 'Notifications',
    ViewAllNotify: 'Clear All Notifications',
    Clean: 'Clear',
    EnabledAgent: 'Activate Agent',
    ChangeParent: 'Adjust Leading',
    // ORGANIZATION
    Organization: 'Organization',
    People: 'Users',
    OrganizationPeople: 'Users',
    TotalUser: 'Total Users',
    OrganizationStructure: 'Structure',
    BrokerAgent: 'Agents of Broker',
    BrokerUser: 'Customers of Broker',
    AgentAgent: 'Agents of Agent',
    Agentuser: 'Customers of Agent',
    DirectUser: 'Direct Customers',
    NormalAgent: 'Subordinate Agent',
    OverAgent: 'Superior Agent',
    SameAgent: 'Equal Agent',
    Referrer: 'Referrer',
    OrganizationDes:
      'Sort by market level, non-agent will be place at the bottom.',
    //--
    SameBonus: 'Equal Bonus',
    OverBonus: 'Superior Bonus',
    //UNIT
    UnitMan: 'Users',
    UnitStuff: 'Units',
    SyncConfig: 'Sync ',
    SyncOrderhistory: 'Sync History',
    TraderLeverage: 'Edit Leverage',
    TraderSetGroup: 'Edit Trader Group',
    WalletRequestDepositApprove: 'Deposited',
    WalletRequestWithdrawWithdraw: 'Withdrew',
    WalletRequestDepositReject: 'Deposit Failed',
    WalletRequestWithdrawReject: 'Withdraw Failed',
    UserEnable: 'Account Recoverd',
    UserDisable: 'Account Suspended',
    UserPasswordUpdate: 'Password Updated',
    AgentAdd: 'You are now an agent.',
    AgentRemove: 'You have been removed from an agent.',

    BrokerNotFound: 'Broker Not Found ',
    WrongManagerAPI: 'Incorrect Manager API',
    MT4Error: 'MT Error',
    ServiceError: 'An error occurred. Please try again later.',
    TraderIsNotExists: 'Could not find your account.',
    //交易帳號相關
    TraderAccount: 'Account',
    TraderAccountManage: 'Account Management',
    SyncTrader: 'Sync Account',
    TraderEnable: 'Trading Account Recoverd',
    TraderDisable: 'Trading Account Suspended',
    TraderAccountAdd: 'Create Trading Account',

    TraderCreateFailed: 'Failed to create trading account.',
    TraderAccountAddText:
      'Create an account and you will be able to trade multiple products and manage your funds',
    TraderAccountNone: 'No Trader Account',
    TraderCreate: 'Create Trading Account',

    TraderBind: 'Link Trading Account',
    TraderBindFailed: 'Link Failed',
    TraderDetail: 'Account Details',
    TraderNotFound: 'Could not find your account.',
    ModifyAccountPassword: 'Reset Password',
    // Personal Setting
    About: 'About',
    Personal: 'User',
    PersonSetting: 'Preference',
    CompanySetting: 'Company Settings',
    BankSetting: 'Bank Preference',
    WorkingDateSetting: 'Business Hour',
    UserOpenAccountSetting: 'User Account Settings',
    AuditSetting: 'Approval Settings',
    AgentRuleSetting: 'Agent Regulation',
    Logout: 'Logout',

    // ChangeTradePW
    TraderPWModify: 'Change password for current trader account.',
    TraderPWMain: 'Change password for main account.',
    TraderPWRead: 'Read Password Only',
    UserNotFound: 'User Not Found',
    TransferFailed: 'Transfer Failed',
    Loading: 'Loading',
    WrongPassword: 'Incorrect Password',
    SomethingWrong: 'Something Went Wrong!',
    TryLatter: 'Please try again later.',

    Success: 'Success',
    AddDone: 'Successfully Added',
    AddFailed: 'Failed to Add',
    SaveDone: 'Saved',
    SaveFailed: 'Unable to Save',
    Failed: 'Failed',
    AsyncSend: 'Request Sent',
    ProcessRequest: 'Request Processing',
    //Broker audit
    AuditKyc: 'KYC Verification',
    AuditWithdraw: 'Withdrawal',
    AuditDeposit: 'Deposit',
    AuditReject: 'Request Denied',
    AuditKycReject: 'KYC Denied',
    AuditPass: 'Are you sure you want to approve the request？',
    AuditRejectRes: 'Please deliver a feedback to help them fix the problem.',
    AuditAllow: 'Approved',
    NextStep: 'Next Step',
    PrevStep: 'Previous Step',
    LastUpdate: 'Last Update',
    Least7Day: 'Last 7-day',
    Allow: 'Verified',
    NotAllow: 'Not Verified',
    AuditKycAllow: 'KYC has been verified.',
    Retry: 'Please Retry',
    Reason: 'Reason',
    Download: 'Download',
    To: 'To',
    // Broker-使用者管理
    ParentAccount: 'Superior',
    NoParent: 'None',
    Direct: 'Direct Relative',
    Direct2: 'Whether if it is direct relative.',
    // Button
    Operation: 'Options',
    OperationDone: 'Operation is Finished ',
    //KYC return
    KYCUNSUBMITTEDTitle: 'Incomplete KYC',
    KYCUNSUBMITTED: 'Unsubmitted',
    KYCSUBMITTEDTitle: 'Verifying',
    KYCAllowTitle: 'Verified',

    KYCAllow: 'KYC Approved',
    KYCReject: 'KYC Denied',
    KYCUNSUBMITTEDDes:
      'Once you have been verified, you will be able to manage your account and fund your account.',
    KYCSUBMITTEDDes:
      'Once you have been verified, you will be able to create a new trading account.',
    KYCFAILEDDes:
      'Your request has been declined, please update your application and submit it again.',
    KYCUNSUBMITTEDButtonText: 'KYC Verification',
    KYCSUBMITTEDButtonText: 'KYC Verifing...',
    KYCFAILEDButtonText: 'Request Again',
    KycStatusSubmitted: 'Pending Approval',
    KycStatusPassed: 'KYC has been verified.',
    KycStatusFailed: 'KYC has been denied.',

    IDCardFrontIsARequiredField: 'ID picture size should be',
    IDCardFrontExceeds600kB: 'ID picture size should be less than 600KB.',
    IDCardFBackIsARequiredField: 'ID picture size should be',
    IDCardBackExceeds600kB: 'ID picture size should be less than 600KB.',
    AddressFileIsARequiredField: 'Proof of residential address should be',
    AddressFileExceeds600kB:
      'Proof of residential address should be less than 600KB.',
    BankFrontFileIsARequiredField: 'Document of bank account should be',
    BankFrontFileExceeds600kB:
      'Document of bank account should be less than 600KB.',
    BankBackFileIsARequiredField: 'Document of bank account should be',
    BankBackFileExceeds600kB:
      'Document of bank account should be less than 600KB.',
    // 20201027 END

    ApplicationFailed: 'Request Failed',
    ParentAgent: 'Superior',
    YesReadInfo: 'Yes, please check the information.',
    MarketIn: 'Assigned Market',
    IdCardNumber: 'ID#',
    ReceviedBankName: 'Recipient Bank',
    ReceviedBankAddress: 'Recipient Bank Address',
    ChangeMarket: 'Modify Market',
    ChangeMarketErrorMsg1:
      'Not allowed to modify the market due to multiple tiers in the market.',
    // 20201028 END
    ReadTraderAccount: 'Trader Accounts',
    // 20201116 END
    UserInfo: 'User Information',
    AddrInfo: 'Address',
    BankInfo: 'Bank Information',
    MarketGroupMap: 'Market and Groups',
    IncludeGroup: 'Groups Included',
    SetDefault1: 'Set as Default',
    LevelName: 'Tier Name',
    // 20201119 END
    OrderAdvanceSearchMsg1:
      'There are different services depending on the servers.',
    // 20201123 END
    BindOtherAccount: 'Link to Other Account',
    Server: 'Server',
    // 20201124 END
    Broker_Deposit_Applying: 'New Request',
    User_Deposit_Applying: 'Payment Processing',
    Broker_Deposit_Reviewing: 'Paid',
    User_Deposit_Reviewing: 'Processing',
    Broker_Deposit_rejected: 'Denied',
    User_Deposit_rejected: 'Request Failed',
    Broker_Deposit_Approved: 'Funded',
    User_Deposit_Approved: 'Funded',

    Broker_Withdraw_Applying: 'New Request',
    User_Withdraw_Applying: 'Pending Approval',
    Broker_Withdraw_Approved: 'Approved',
    User_Withdraw_Approved: 'Approved',
    Broker_Withdraw_rejected: 'Denied',
    User_Withdraw_rejected: 'Request Failed',
    Broker_Withdraw_Withdraw: 'Withdrew',
    User_Withdraw_Withdraw: 'Withdrew',
    // 20201127 END
    DepositIn: 'Net Deposit',
    AgentInfo: 'Agent Information',
    // 20201201 END
    DepositSetting: 'Deposit Preference',
    WithdrawalSetting: 'Withdrawal Preference',
    DepostieWallet: 'E-wallet',
    // 20201207 END
    ApplyAmount: 'Request Amount',
    PaymentDetail: 'Payment Details',
    DesignationRate: 'Rate',
    PaymentAmount: 'Amount Paid',
    OrderNumber: 'Order#',
    ApplyerEmail: 'Applicant Email',
    CallApiErrorMsg: 'Please reload or try again later.',
    Deleted: 'Removed',
    DeleteEWallet: 'Remove Wallet',
    DeleteEWalletMsg: 'Are you sure you want to remove wallet？',
    WalletName: 'Wallet Name',
    EWalletEnableMsg: 'Please choose at least one wallet.',
    MinAmount: 'Minimum Amount',
    MaxAmount: 'Maximum Amount',
    Channel: 'Methods',
    DeleteChannelMsg: 'Are you sure you want to remove payment method？',
    CurrencyEnableMsg: 'Please choose at least one currency.',
    DeleteChannel: 'Remove Method',
    Warning: 'Alerts',
    WalletAddress: 'Wallet Address',
    EnterWalletAddressMsg: 'Enter address for your wallet',
    EnterWalletNameMsg: 'Enter a name for your wallet',
    DeleteCurrencyMsg: 'Are you sure you want to remove currency？',
    DeleteCurrency: 'Remove Currency',
    EnterChannelNameMsg: 'Enter Channal',
    EnterAmountRangeMsg: 'Please enter a valid range.',
    EnterCurrencyNameMsg: 'Please enter a valid 3-charachter currency code.',
    EnterRateMsg: 'Up to 6 decimal places.',
    EnterRateMsg8: 'Up to 8 decimal places.',
    MinAmountEqualUSDMsg: 'Minimum Amount in USD',
    MaxAmountEqualUSDMsg: 'Maximum Amount in USD',
    EnterCurrencyMsg: 'Enter Currency',
    EnterRateMsg2: 'Enter Exchange Rate',
    Created: 'Created',
    Edited: 'Edited',
    EnterTokenMsg: 'Enter Token',
    TransferInfo: 'Transfer Information',
    BankAccountName: 'Name of Account Holder',
    Rate: 'Exchange Rate',
    TransferInAmount: 'Amount Converted',
    MinAmountMsg: 'Minimum Amount',
    MaxAmountMsg: 'Maximum Amount',
    TransferOutAmount: 'Amount Converted',
    NoEnableCurrencyAmount:
      'No available currency, please contact your administrator.',
    AccountInfo: 'Account Information',
    ReceiveMan: 'Recipient',
    ApplySent: 'Request Sent',
    // 20201214 END
    WrongDataMsg: 'Correct the invalid field entries.',
    EnterBankNameMsg: 'Enter Bank Name',
    EnterBankCodeMsg: 'Enter Bank Code',
    EnterBankAccountNameMsg: 'Enter Name of Account Holder',
    EnterBankAccountMsg: 'Enter Account#',
    EnterBankAddressMsg: 'Enter Bank Address',
    EnterRateMsg3: 'Exchange rate should be greater than 0.',
    // 20201215 END
    IDCardFrontExceeds: 'ID picture size should be less than 10MB.',
    IDCardBackExceeds: 'ID picture size should be less than 10MB.',
    AddressFileExceeds:
      'Proof of residential address should be less than 10MB.',
    BankFrontFileExceeds: 'Document of bank account should be less than 10MB.',
    BankBackFileExceeds: 'Document of bank account should be less than 10MB.',
    // 20201216 END
    UserIsDisabled: 'Account Not Activated',
    // 20201222 END
    YourEWalletAddr: 'Your E-wallet',
    Copy: 'Copy',
    UserEWalletAddr: 'Clients E-wallet',
    // 20201228 END
    AllMarket: 'All Markets',
    // 20201229 END
    ActivePeople: 'Active Accounts',
    TotalPeople: 'Total Accounts',
    PlatformFilter: 'Select Device',
    Mobile: 'Mobile Device',
    Tablet: 'Tablet',
    Desktop: 'PC',
    AccountAndName: 'Account Name、Real Name',
    ShowAllDataMsg: 'Show All',
    // 20201230 END
    Lots: 'Lots',
    // 20210127 END
    MemberTernDesc: `{brokerName} is happy to provide you with information, content, tools, products and services. These terms and conditions also include important disclosures and information about certain products and services. Your use of the {brokerName} website is subject to these terms and conditions. These terms and conditions are a binding agreement between you and {brokerName}. Your login and use of this website requires you to agree to these terms and conditions and other legal notices and statements posted on this website. Your use of the {brokerName} website will be governed by the version of the terms and conditions in effect on the {brokerName} website that day.

			{brokerName} can modify these terms and conditions at any time without prior notice. Your continued login and use of this website requires you to accept these terms and conditions. {brokerName} will not notify you of any changes or modifications individually.

			These terms and conditions are any other agreements between you and {brokerName} (including any customer or account agreements and restrict your use of the information, content, tools, products and services provided on the {brokerName} website and through the {brokerName} website Any other agreements). Unless you have a written agreement with {brokerName}, the {brokerName} website is for your personal and non-commercial use only.
			The information on this website is not intended to be distributed or used by any person who violates local laws or regulations in his country or jurisdiction. The services or investments described on the {brokerName} website will not be provided to persons who provide this service or invest in violation of local laws or regulations in their country of residence.

			Visitors to this website are responsible for confirming the terms of local laws or regulations that they are bound to and comply with. The content on the {brokerName} website should not be regarded as a solicitation to purchase any product or service or an offer to sell any product or service to a person who is in such an offer, solicitation, purchase or sale in accordance with the jurisdiction People in jurisdictions where the law is illegal.
		`,
    PrivacyPolicyDesc: `{brokerName} respects personal privacy and values the relationship with customers. {brokerName} provides customers with privacy protection on personal data, and has won the support and respect of customers; according to strict security measures, all personal data collected by {brokerName} will be protected. This privacy protection policy explains how {brokerName} collects personal data, and how it is used and disclosed. And explain in detail the rights that customers have. This policy applies to customers who are about to become AIA Forex, customers who have become {brokerName}, and customers who are applying to become {brokerName}.

			1. According to the Anti-Money Laundering and Counter-Terrorism Financing Act 2006, {brokerName} is responsible for collecting information related to customer identity and verifying the true identity of the customer. {brokerName} will perform customer identification and verification procedures in accordance with the New Zealand Privacy Act 1993.

			2. When a customer submits an application form to open a {brokerName} real or simulated account, the {brokerName} company will provide the customer's personal information. When applying to open a {brokerName} real or simulated account, you confirm and agree to the relevant policy terms and prove that the consent you provided to {brokerName} is voluntary. Personal information is all information about customers, which can identify customers or confirm their true identity. When personal information is collected by a third party (not the customer himself), {brokerName} will take reasonable steps to ensure that the customer knows the information has been collected and collected.

			3. {brokerName} keeps all transaction and activity records of client accounts, including (but not limited to) closing information of client accounts. {brokerName} will collect customer information from available public sources (such as the company register). {brokerName} will hold clients’ personal data strictly in accordance with the principles of the New Zealand Privacy Act 1993. Customers can request access to the customer information held by {brokerName} at any time. {brokerName} can record phone conversations between customers and {brokerName} staff. This recording can be used to resolve any disputes between the customer and {brokerName}, which is the legal right of {brokerName} and meets the requirements of regulatory agencies and other government organizations. When customers fill out online applications or other types of forms, or operate customer accounts or transactions through the {brokerName} website, {brokerName} can also collect and hold information about customers.

			4. {brokerName} may collect sensitive information about customers. If the complaint resolution provided by {brokerName} fails to satisfy the customer, the customer can complain to the privacy officer who will further investigate your complaint.

			5. Customers can send an email to request the personal information held by {brokerName}. {brokerName} generally processes customer requests within 14 days. If the customer's request is complex, in this case it may take more time, {brokerName} will inform the customer that additional time is needed. There is no cost involved in requesting the client’s personal information, but {brokerName} may charge the client a reasonable fee for processing the client’s request. In some cases, the law allows {brokerName} to deny customer access. If {brokerName} has reason to suspect that a serious illegal activity or misconduct is being or may be engaged in and involves the function or operation of {brokerName}, if the request is likely to harm any legitimate interests, the request may be rejected. In this case, {brokerName} will provide the customer with the reason for rejection (unless it is deemed inappropriate or unreasonable to provide the customer with the reason), other regulatory matters, and provide the existing complaint mechanism. If feasible, {brokerName} may take reasonable steps and provide a request if it meets the needs of {brokerName} and the customer.

			6. {brokerName} requires customers to immediately notify {brokerName} in writing of any changes in the customer profile held by {brokerName}. This will ensure that the customer profile held by {brokerName} is kept up to date and allows {brokerName} to notify customers of the ongoing status of the customer’s account, the customer’s margin responsibilities and the customer’s trading activities. Customers can request {brokerName} to change customer personal information held by {brokerName} that the customer believes to be inaccurate. Regarding the requirements for modifying or correcting the information or requesting the privacy policy and practical information of {brokerName}, if {brokerName} and the customer have a disagreement on the accuracy of the information, the customer can request {brokerName} to attach a statement to the information, indicating the customer The information is considered inaccurate or incomplete. {brokerName} will send a written notice to the client to refuse to correct the personal information, list the reasons for the refusal (unless it is deemed inappropriate to provide the reason for the refusal), provide a complaint mechanism for the refusal, and other related matters.

			7. If {brokerName} accepts the information as inaccurate, out of date, incomplete or irrelevant or misleading, {brokerName} will take all reasonable steps to correct the personal information to ensure that it is accurate, current, complete, relevant and Not misleading.

			8. {brokerName} will take all reasonable steps to avoid improper use, loss, unauthorized use, interference, alteration or disclosure of the customer's personal information.
		`,
    Dot: '.',
    // 20210217
    LoginDone1: 'Fully integrated transaction management system, including',
    LoginDone2: 'Multi-account visualized management',
    LoginDone3: 'Instant deposit and withdrawal',
    LoginDone4: 'Clear and concise fund record and transaction statements',
    LoginDone5:
      'Trading FX, metals, and CFDs is risky. We encourage you carefully consider your investment objectives. ',
    EWalletType: 'Types of e-Wallet',
    AcceptEWalletType: 'Types of acceptable e-wallets by the broker',
    EWalletSubmitMsg: `Please confirm the information you have filled in is correct before hitting the
			“Application” button. The broker will not be responsible for any losses associate with
			the incorrect inputs. `,
    // 20210719
    CryptocurrencyType: 'Type of cryptocurrency',
    CryptocurrencyTypeMsg: 'Cryptocurrency can only contain 3-10 characters',
    ProtocolsLabel:
      'Available Protocols (separate different protocols with semicolon ”;”)',
    ProtocolsMsg:
      'Each protocol name can only contain 3-10 characters including numbers',
    FormatMsg: 'Please confirm the format is correct',
    Decimal2Msg: 'Allow 2 decimal places',
    Protocol: 'Blockchain Protocol',
    // 20210720

    //20210817
    I18CreateUserAccountPrompt:
      'Account created successfully. Please email account information to the user.',
    I18CreateUserAccount: 'Create Account',
    // 20210819
    I18EnterEmailPleace: 'Email',
    I18AssignedAgent: 'Assigned Agent',
    I18SelectMarket: 'Select Market',
    I18SelectAgent: 'Select Agent',
    I18SelectNoAgent: 'No Agent',
    I18AgentChange: 'Modify Agent',
    I18SelectAgentTier: 'Select Tier',
    I18PromptTierCouldBeDifferent:
      'Default tier could be different with the tier in the original market.',
    I18ErrorGroupNotOpenInMarket:
      'The account {{TraderAccount.id}} of {{User.name}} was assigned to Group {{Group.name}} which is not available in the {{market.name}}.',
    I18PromptAgentChangeMarketOnlyWithLevels:
      'Agents are only allowed to change to market with multi-tiers.',
    I18NotHaveAgent: 'No agent.',
    // 20210924
    I18ClearCache: 'Clear cache',
    I18PromptClearCacheAfterUpdateManager:
      'To see your changes after updating the manager permissions on MT4/MT5, you may need to refresh by clearing your cache.',

    // 20211006
    I18FurtherInformation: 'Further information',
    I18LimitHalfwidthText: 'Limited to {limit} characters',
    I18ExceedTextLimit: 'Exceed the input limit',
    I18UpdateTimeLatest: 'Update Time',
    I18TraderAccountLimit: 'Maximum Trading Account',
    I18TraderAccountLimitExclude: 'Exclude existing accounts',
    I18TraderAccontLimitRange: 'Set a limit from {min} - {max}',

    // 20211122
    I18CustomField: 'Table on Demand',
    I18SelectAll: 'Select All',

    I18StorageNew: 'Interest',
    // 20211130
    I18OrderPosition: 'Position',
    I18OrderHistory: 'History',
    I18SetupPrice: 'Setup Price',
    I18OrderDoneTime: 'Done Time',
    I18OrderDoneTimeOrRange: 'Done Time',
    I18TradeTime: 'Time',
    I18FinalPrice: 'Price',

    // 20211209
    I18BankCodeEmptyCheck: 'SWIFT/IBAN code is unfilled, still save?',
    // 20211221
    I18DepositComfirmed: 'Deposit comfirmed',
    I18BrokerDepositDoubleCheckText:
      'Please confirm the deposit {currencyName} {amount} to {userName}?',
    I18BrokerDepositDepositTempText:
      'Please complete and approve the withdrawal application in the CRM system and make sure the amount first before actually transferring money to the client.',
    I18UserKycIsRequiredBeforeDeposit: 'Please complete user KYC first',
    I18WithdrawalRequestComfirmed: 'Withdrawal request comfirmed',
    I18AssetArrangementComfirmed: 'Asset arrangement completed',
    I18BrokerAssetArrangementToTraderDoubleCheckText:
      'Please confirm the asset arrangement {currencyName} {amount} to {traderId}?',
    I18BrokerWithdrawalRequestDoubleCheckText:
      'Please confirm the withdrawal {currencyName} {amount} for {userName}?',
    I18DepositByBroker: 'Deposit by broker',
    I18BrokerAssetArrangementToUserAccountDoubleCheckText:
      'Please confirm the user account {currencyName} {amount} to {accountId}?',
    I18AssetAmount: 'Amount',
    I18RequestByUserSelf: 'Request by client',
    I18RequestByBroker: 'Request by broker',
    I18TraderInformation: 'Trader Account Info.',
    I18ViewTraderInfoPermission:
      'Permission for agent to see trader account information',
    I18nUploadImageCorrupted: 'The uploaded image file is corrupted',
  },
  'zh-tw': {
    Broker: 'Broker',
    Provider: 'Provider',
    Trader: 'Trader',
    Signal: 'Signal',
    YES: '是',
    NO: '否',
    //Menu Word
    Review: '查看',
    AgentApplication: '代理申请',
    AgentManage: '代理商管理',
    Client: '客戶',
    FollowerManage: '客戶管理',
    FollowerList: '客戶列表',
    Order: '交易訂單',
    OrderHistory: '歷史交易',
    AccountDetail: '帳號細節',
    AccountHistory: '帳號紀錄',
    CreatAccount: '建立帳號',
    FundHistory: '資金紀錄',
    ReadInfo: '查看資料',
    // SIDE MENU
    Dashboard: '儀表板',
    Setting: '設置',
    UserManagement: '人員管理',
    Audit: '審核',
    MarketGroup: '組別與市場',
    Management: '管理',
    Report: '報表',

    // Word
    More: '查看細節',
    LoginSetting: '登入管理',
    LoginDevice: '登入裝置',
    LoginDate: '登入時間',
    Position: '位置',
    BrokerSetting: '平台設置',
    SystemSetting: '系統設置',
    SettingDes1: '為了加强在',
    SettingDes2:
      '交易的安全性，我們會提供關於您帳號的安全保護。若有帳號被盜用之疑慮，請聯繫',
    FundManage: '資金管理',
    User: '使用者',
    UserManage: '使用者管理',
    UserAccount: '使用者帳號',
    // Search placeholder
    PlaceholderUser: '使用者帳號名稱、Email',
    PlaceholderTrader: '帳號名稱, 使用者名稱',
    PlaceholderAgent: '使用者帳號名稱',
    PlaceholderOrderQuery: '交易帳號 訂單號 品種',
    NotYetBindUser: '尚未綁定',
    UserName: '使用者名稱',
    AgentName: '代理商名稱', //等同使用者名稱
    KYC: 'KYC資料申請',
    PerLot: '每手',
    PerDeal: '每處理',
    //Login
    Login: '登入',
    LoginMt: 'MT登入',
    LastLogin: '最近登入',
    LoginNormal: '歡迎使用，請輸入您的帳號',
    LoginMtText: '請輸入您的MT帳號與密碼登入',
    TextBuildAccount:
      '請先輸入您的Email帳號，收取認證信件，為保障權益請確實填寫.',
    Password: '您的密碼',
    YourEmail: '您的電子信箱',
    ForgotPassword: '忘記密碼？',
    TextForgotPassword:
      '請輸入您在CRM註冊的Email，我們將會重新寄送認證信件，讓您重設密碼。',
    AccountManage: '帳號管理',
    OrderQuery: '訂單查詢',
    OrderStatus: '訂單狀態',

    //Button
    Send: '送出',
    Confirm: '確定',
    AlreadyConfirm: '已確認',
    Done: '完成',
    Delete: '刪除',
    EditName: '編輯名稱',
    EditNickName: '編輯暱稱',
    AddMarket: '新增市場',
    AddLevel: '新增層級',
    EditLevel: '變更層級',
    DeleteLevel: '刪除層級',
    DeleteLevelDes: '確定要刪除層級？',
    DeleteLevelDes2: '還有使用者無法刪除層級。',
    DuplicateLevel: '複製上一級佣金設置',
    DeleteLoginDevice: '刪除登入裝置',

    DeleteMarket: '刪除市場',
    CommissionSetting: '佣金設置',
    AddMarketDes: '新增市場，並可以在此市場做不同的層級與佣金管理。',
    DeleteMarketDes: '確定要刪除市場？',
    CanNotDeleteMarket: '還有使用者無法刪除市場。',
    DeleteConfirm: '確定要刪除？',
    ConfirmWithdraw: '確定要出金？',
    Upload: '上傳',
    Demo: '模擬倉',
    //NOTIFY
    PWDone: '密碼變更完成',
    DepositDone: '入金已送出',
    DepositOk: '入金完成',
    WithdrawalDone: '出金已完成',
    ChangeGroupDone: '變更組別完成',
    ChangeLeverageDone: '變更交易槓桿完成',
    DisableAccount: '帳號停用',
    EnableAccount: '帳號啟用',
    EnableAccountDes: '啟用帳號，可使用相關功能<br>確定要啟用？',
    DisableAccountDes: '停用此帳號，部份功能將被限制<br>確定要停用？',
    ChangeParentDes: '變更上級',
    enableDemo: '啟用模擬倉',
    cancelIB: '停用代理權限',
    ChangeClientToIB: '請將客戶轉移到以下代理',
    ActionFailed: '操作失敗',
    ThrottleLimit: '操作次數過多，請稍候重試',
    CopyWordSuccess: '文字複製成功',
    CopyWordFailed: '文字複製失敗',

    IPDes: '域名或IP，例：aaa.com / 111.222.33.44',
    //註冊
    RDone: '完成註冊',
    RText: '為保護權益，請詳細填寫資料.',
    NickName: '暱稱', //User register name
    RRealName: '真實姓名', // KYC name
    Name: '姓名', //Kyc name
    RPersonalInfo: '身份資料',
    RBankInfo: '銀行資料',
    RRestInfo: '居住資料',
    RIDCode: '身份證字號',
    RIDDoc: '身分證明文件',
    RAddressDoc: '地址證明文件',
    RBankDoc: '銀行證明文件',
    RIDP: '證件正面',
    RIDN: '證件反面',
    RBankDoc1: '銀行文件-1',
    RBankDoc2: '銀行文件-2',
    UserDetail: '使用者細節',
    UserInformation: '個人資料',

    //SETTING
    TraderAreaTimezone: '交易平台時區',
    LanguageCode: '語系編碼',
    Increase: '遞增',
    Decreasing: '遞減',
    CompanyName: '公司名稱',
    CompanyWebsite: '公司網址',
    BusinessHours: '工作時間',
    WorkingDes: '為方便客戶的使用，我們提供以下的工作時間',
    SUN: '星期日',
    MON: '星期一',
    TUE: '星期二',
    WED: '星期三',
    THU: '星期四',
    FRI: '星期五',
    SAT: '星期六',
    OS: '作業系統',
    RIDText: '請提供身分證件資料。',
    RAddressText:
      '請提供能夠證明個人居所資料的相關文件，如：電信 水電帳單...等含有個人資料與住所資料的頁面。',
    RBankText:
      '請提供與身分證件相符合的銀行資料，如：存摺...等含有個人資料的文件。',
    RGender: '性別',
    RBirthday: '生日',
    I18GenderMale: '先生',
    I18GenderFemale: '女士',
    RGender3: '非二元性別',
    RGender4: '不透露',
    RRegion: '國家/地區',
    RNationCode: '請選擇國家/地區',
    RSelect: '請選擇',
    RCity: '城市',
    RProvince: '洲/省',
    RArea: '區',
    RPhone: '聯絡電話',
    RAddress: '地址',
    Remail: '電子信箱',
    RPassword: '密碼',
    ROPassword: '原密碼',
    RNPassword: '新密碼',
    RMTPassword: 'MT交易原帳號密碼',
    RMTPasswordLimited: '包含英文與數字或符號,且不超過於15個字元',
    I18NewRMTPasswordLimited: '限8-15字元，必須包含英文與數字。',
    RPassword1: '英文或數字,且不少於8個字元',
    RPasswordConfirm: '確認密碼',
    RPasswordConfirm1: '您的密碼不一致',
    RPasswordReset: '變更密碼',
    CreateAccount: '建立帳號',
    AccountName: '帳號名稱', //Trader account name
    AccountPW: '請輸入密碼',
    AccountPWA: '請再次輸入密碼',
    MTLogin: 'MT 帳號',
    OnlyNumber: '僅限數字',
    OnlyNumberNoZero: '僅限數字且不可為零',
    NumberArea: '號段設置',
    Range: '範圍',
    Minnum: '不可比最小號段小',
    Numrange: '請介於號段最小值與最大值內',
    MinValue: '最小值',
    MaxValue: '最大值',
    NextAccountNum: '下一個開戶號',
    NumberNow: '目前號段',
    MTPW: 'MT 密碼',
    //後端回傳Errors
    NoLogged: '尚未登入',
    AlreadyLogged: '帳號已經登入',
    AuthServiceError: '認證服務錯誤',
    EmailIsAlreadyUsed: '此Email已經有人使用',
    EmailDoesNotExist: 'Email不存在',
    WrongEmailOrPassword: '帳號或密碼輸入錯誤',
    CertificationExpired: '驗證過期',
    ErrorFailAccount: '申請帳號失敗，请聯繫您的經紀商進行處理',
    'Limit exceeded': '嘗試次數過多，請稍後嘗試。',
    'broker-trader-enable': '交易帳號啟用',
    'broker-sync-config': '資料同步',
    'broker-sync-trader': '交易帳號同步',
    'broker-trader-setgroup': '交易組別',
    'broker-trader-leverage': '交易槓桿',
    CommissionIsGreaterThanThePreviousLevel: '佣金設置不可大於上一級',
    CommissionIsLessThanThePreviousLevel: '佣金設置不可小於下一級',
    ManagerAPIConnFailed: 'Manager API 連結失敗',
    BadRequest: '出了點問題...',
    ImageDisabled: '清除圖片',
    Inside: '內部',
    Outside: '外部',
    BrowserIcon: '視窗頁籤',
    //Errors
    ErrorMail: '請輸入正確的Email格式',
    ErrorPW: '密碼不少於8個字元，英文字母或數字，可含特殊字符：+ - @ _ = * $',
    ErrorVerify: '請輸入正確的驗證碼',
    ErrorName:
      '請輸入正確格式(可包含英文大小寫、數字、空格、特殊字符：- _ . ·，最多不超過64個字)',
    ErrorCorrect: '請輸入正確格式',
    ErrorLogin: '只能輸入數字，最多不超過32個字元',
    ErrorPhone: '只能輸入數字，最少7字元，最多不超過15個字元',
    ErrorID: '請輸入與您提供的證件相符合的ID號碼',
    ErrorCountryCode: '請選擇國家與地區',
    ErrorEmpty: '欄位不可為空',
    ErrorAccount: '請輸入正確的帳號格式，最少8字元',
    MemberTern: '會員條款',
    PrivacyPolicy: '隱私保障政策',
    TextKnowing: '已經了解並接受',
    TextKnowing2: '外匯價差合約交易涉及高風險 條款與條例適用',
    VerifyPassText: '審核通過，請直接更新到我的個人資料。',
    SecurityBlock: '目前無法交易',
    GroupBlock: '組別目前停用中',
    ErrorReason: '失敗原因',
    Or: '及',
    None: '無',
    BackLogin: '回到登入',
    VerifyCode: '驗證碼確認',
    Cancel: '取消',
    ReSend: '重新傳送',
    VerifyReceived: '請至您的信箱收取驗證碼',
    SendVerify: '寄送認證信',
    EmailAlready: '您的Email已存在，請直接使用登入或重新申請另外一個Email。',
    EmailNotExist: '您的Email不存在，請再嘗試別的Email。',
    Required: '必填欄位',
    RequiredSwift: '請輸入正確的SWIFT code格式',
    RequireIban: '請輸入正確的IBAN code格式',
    //Bank
    ReceviedBankInfo: '收款銀行資料',
    GBankName: '銀行名稱',
    GBankAddress: '銀行地址',
    GBankAccount: '銀行帳號',
    GManName: '收款人名稱',
    GManAccount: '收款帳號',
    GManAddress: '收款人地址',
    GBankCode: '銀行代碼',
    PaymentCurrency: '支付幣別',
    PaymentChannel: '支付通道',
    PaymenLimit: '入金金額最小不可小於$1,000元',
    ChoosePayment: '選擇通道',
    TradeCurrency: '交易幣別',
    Mer_no: '商戶號',
    InputMer_no: '請輸入商戶號',
    Telegraphic: '電匯',
    SettlementByDay:
      '日結算，當日GMT +0 00:00同步訂單，隔日GMT +0 01:00結算訂單。',
    SettlementByWeek:
      '週結算，每日GMT +0 00:00同步訂單，週日GMT +0 01:00結算訂單。',
    SettlementByMonth:
      '月結算，每日GMT +0 00:00同步訂單，隔月第一日GMT +0 01:00結算訂單。',
    //註冊
    ErrMsg2: '請稍後嘗試',
    //Signal
    STotalInvest: '投資資金',
    Balance: '餘額',
    SEquity: '淨值',
    Save: '儲存',
    Show: '顯示',
    Num: '筆',
    AddAccountDirect: '新增交易帳號',
    BindMT4Account: '綁定MT4',
    SAvailableFunds: '可用資金',
    WithdrawCost: '已出金金額',
    DepositCost: '已入金金額',
    WaitWithdrawCost: '待出金金額',
    ApplicationWithdraw: '申請出金金額',
    SFPnL: '浮動盈虧',
    SMargin: '保證金',
    MarginUsed: '已用保證金',
    MarginCanUse: '可用保證金',
    MarginRatio: '保證金比例',
    SIncome: '收益',
    SRetreat: '回撤',
    SAddPt: '加點',
    Leverage: '交易槓桿',
    AccountNum: '帳號數量',
    AgentNum: '代理數量',
    Sercurity: '交易類型',
    MarginCall: '警示線',
    MarginStopOut: '強平線',
    TitleTrade: '交易類型 / 品種',
    SCredit: '信用額度',
    Sscore: '評分',
    Default: '預設',
    SetDefault: '設為預設組別',
    LeverageDefault: '預設槓桿',
    ChangeLeverage: '調整槓桿',
    All: '全部',
    PnLTotal: '總盈虧',
    ChooseGroup: '選擇組別',
    ChangeGroup: '變更組別',
    //Order
    OnHold: '掛單',
    OrderClose: '平倉',
    OrderOpen: '未平倉',
    OrderCloseProcess: '處理中',
    NoUnprocess: '沒有待處理項目！',
    EmptyMsg1: '目前資料',
    EmptyInfo: '目前沒有資料',
    Empty: '空',
    UnitPoint: '點',
    Add: '新增',
    Edit: '修改',
    PopUpCloneDes: '複製一個帳號，並可以在此帳號做不同的交易與資金配置',
    CopyPromoteLink: '複製推廣連結',
    readOnlypassword: '只讀密碼',
    BalanceReport: '出入金報表',
    UserDistribution: '使用者分布',
    SelectPlaceHolderChoseAccount: '選擇帳號',
    SelectAgent: '選擇代理',
    AdvanceSearch: '進階搜尋',
    FunctionSearch: '功能搜尋',
    FunctionSearchTrader: '請輸入交易帳號，若有多個請使用逗號分開。',
    FunctionSearchUser: '請輸入使用者帳號，若有多個請使用逗號分開。',
    FunctionCommission: '請輸入佣金單號',
    Search: '搜尋',

    ChoisePayment: '請選擇支付方式',
    PaymentMethod: '支付方式',
    PaymentSetting: '支付設置',
    PaymentColegantDes: '選擇Colegant支付將會導至付款頁面，確認請按下申請。',
    PaymentDes1: '選擇',
    PaymentDes2: '支付將會導至付款頁面。',
    LastFiveNum: '帳號末五碼',
    //No Data
    // REPORT
    Subscribe: '訂閱',
    UnSubscribe: '取消訂閱',
    UnSubscribeDes: '您的訂閱已經取消。',
    TradeNoAccess: '帳號無法交易',
    UserNoAccess: '無法使用',
    TSymbol: '品種',
    TOrderNo: '訂單號',
    TType: '類型',
    TSummary: '摘要',
    TLots: '手數',
    TTrade: '交易量',
    TPrice: '交易價格',
    TSecurity: '交易種類',
    TOpenDate: '開倉時間',
    TOpenArea: '開倉時間或區間',
    TCloseArea: '平倉時間或區間',
    OpenPrice: '開倉價',
    TCloseDate: '平倉時間',
    CurrentPrice: '市場價',
    ClosePrice: '平倉價',
    OrderSyncTime: '在倉單同步時間',
    TStopLoss: '止損',
    TStopWin: '止盈',
    ProfitLoss: '損益',
    Storage: '利息',
    Fees: '手續費',
    TProfit: '獲利',
    TradeLoss: '虧損',
    GMTWord: '時間為本機GMT時間',
    //Record
    BackPoint: '返點',
    SettingPC: '返點返佣統一設置',
    Commission: '佣金',
    Commission2: '返佣',
    CommissionReport: '佣金報表',
    CommissionOrder: '佣金單號',
    MarketNone: '目前沒有市場',
    CommissionSend: '佣金發放',
    SettlementTime: '結算時間',
    IntervalTime: '結算時間或區間',
    CommissionType: '佣金類型',
    CommissionRange: '佣金範圍',
    NormalCommission: '一般佣金',
    SameCommission: '平級獎勵',
    OverCommission: '越級獎勵',

    //Application
    Application: '申請',
    ApplicationMan: '申請人',
    ApplicationName: '申請人名稱',
    ApplicationAccount: '申請人帳號',
    ApplicationEmail: '申請人電子信箱',
    ApplicationOrder: '申請單號',
    ApplicationType: '申請類型',
    ApplicationPhone: '申請人電話',

    BuyQuty: '買入量',
    SellQuty: '賣出量',
    PnL: '盈虧',
    PnLArea: '盈虧範圍',
    ProfitShare: '利潤分成',
    FundOut: '轉出',
    FundIn: '轉入',
    SelectMarkets: '選擇市場',
    SelectLevel: '請選擇層級',
    SetLevel: '修改層級',

    SelectLevelDes: '僅限同市場變更層級，請先篩選市場。',
    SelectCharacter: '功能角色篩選',
    SelectAccountStatus: '篩選帳號狀態',
    TotalCommission: '總佣金',
    CommissionAccounting: '佣金核算',
    getCommission: '收取佣金',
    SelectAgentDes: '查詢收取佣金的代理商',
    CreateAccounRecord: '七日內開戶紀錄',
    LastSettlementCommission: '上次結算佣金',
    TraderVolum7: '七日內交易量',
    Day7: '七日內',
    PnL7: '七日內盈虧',
    HotSymbol: '熱門交易商品',
    Credit: '信用',
    TotalWithdrawal: '總出金',
    Withdrawal: '出金',
    WithdrawalCost: '可出金金額',
    Deposit: '入金',
    TotalDeposit: '總入金',
    WithdrawalMin: '出金最小金額',
    Costmin: '金額不可低於',
    DepositNum: '入金筆數',
    WithdrawalNum: '出金筆數',
    //交易組別
    AllGroup: '全部組別',
    MTGroup: '交易組別',
    DefaultGroup: '預設交易組別',
    MTGroupSetting: '交易組別設置',
    GroupIn: '所屬組別',
    Enabled: '啟用',
    Disable: '停用',
    IsIBANSWIFT: '是否填寫SWIFT / IBAN code',
    //資金配置紀錄
    FundArrange: '資金配置',
    SelectCostRange: '篩選餘額範圍',
    SelectCostRangeDesMax: '只輸入最大值，則以最大值往下搜尋',
    SelectCostRangeDesMin: '只輸入最小值，則以最小值往上搜尋',
    CostRange: '金額範圍',
    Arragne: '配置',
    ArragneTo: '配置到',
    EnterFundCost: '請輸入配置金額',
    FunArrangeRecord: '資金變動紀錄',
    FundArrangeMin: '配置金額不可低於',
    TradeHistory: '歷史交易紀錄',
    ChoseFrom: '選擇從',
    Wallet: '主錢包',
    Type: '方式',
    Time: '時間',
    StartTime: '開始時間',
    EndTime: '結束時間',
    Start: '開始',
    End: '結束',
    Cost: '金額',
    AccountRecord: '出入金紀錄',
    Function: '功能',
    Summary: '摘要',
    KYCStatus: 'KYC認證',
    Action: '動作',
    Comment: '備註',
    Account: '帳號',

    //市場
    MarketCommission: '市場佣金',
    MarketCommissionSetting: '市場佣金設置',
    Market: '市場',
    EditMarket: '編輯市場',
    Agent: '代理商',
    AgentList: '代理商管理',
    IsAgent: '是否代理商',
    NotAgent: '非代理',
    MarketLevel: '市場層級',
    MarketName: '市場名稱',
    MarketCost: '市場出入金',
    Level: '層級',
    Detail: '詳細',
    ChannelName: '通道名稱',

    //User List
    TraderName: '帳號名稱',
    AccountStatus: '帳號狀態',
    Register: '註冊',
    RegisterEnabled: '開放註冊',
    RegisterDisabled: '不可註冊',
    RegisterDate: '註冊時間',
    AuditDate: '送審時間',
    ApplicationDate: '申請時間',
    ApplicationDateInterval: '申請時間或區間',
    HandleDate: '處理時間',
    HandleDateInterval: '處理時間或區間',
    CompletedDate: '完成時間',
    ChoseGMTTime: '選擇GMT時間',
    DepositOrder: '入金申請號',
    Completed: '完成',
    WithdrawalApplication: '出金申請',
    StopAccountDes: '帳號停用中請聯絡您的服務提供商',
    WithdrawalOrder: '出金申請號',
    AllCharacters: '全部角色',
    Characters: '角色',
    Status: '狀態',
    ThisWeek: '7日內交易',
    ThisMonth: '30日內交易',
    //交易數據
    TradeStatus: '交易近況',
    TradeData: '交易數據',
    TradeEva: '交易評估',
    TDPL: '总盈亏',
    TDLots: '總手數',
    TDInterest: '總利息',
    TDHandingFee: '總手續費',
    //NOTIFY
    NewApplication: '新申請',
    DepositSuccess: '入金成功',
    Approved: '已批准',
    WithdrawnSuccess: '出金成功',
    Reject: '已駁回',
    Reject2: '駁回',
    RejectApplication: '駁回申請',
    Void: '作廢',
    Check: '已查核',
    Grant: '已發放',
    RejectReason: '駁回原因',
    Notify: '通知',
    ViewAllNotify: '通知清除',
    Clean: '清除',
    EnabledAgent: '啟用代理權限',
    ChangeParent: '變更上級',
    // ORGANIZATION
    Organization: '組織',
    People: '人數',
    OrganizationPeople: '組織人數',
    TotalUser: '用戶總人數',
    OrganizationStructure: '組織結構',
    BrokerAgent: '平台商代理',
    BrokerUser: '平台商直客',
    AgentAgent: '代理商代理',
    Agentuser: '代理商直客',
    DirectUser: '直客',
    NormalAgent: '代理',
    OverAgent: '越級代理',
    SameAgent: '平級代理',
    Referrer: '推薦人',
    OrganizationDes: '以市場層級排列，若非代理位於最下方。',
    //--
    SameBonus: '平級獎勵',
    OverBonus: '越級獎勵',
    //UNIT
    UnitMan: '人',
    UnitStuff: '個',
    SyncConfig: '資訊同步',
    SyncOrderhistory: '歷史訂單同步',
    TraderLeverage: '槓桿調整',
    TraderSetGroup: '組別變更',
    WalletRequestDepositApprove: '入金通過',
    WalletRequestWithdrawWithdraw: '已出金',
    WalletRequestDepositReject: '入金不通過',
    WalletRequestWithdrawReject: '出金失敗',
    UserEnable: '您的帳號已被啟用',
    UserDisable: '您的帳號已被停用',
    UserPasswordUpdate: '您的密碼已被更改',
    AgentAdd: '您已成為代理',
    AgentRemove: '已經移除代理權限',

    BrokerNotFound: 'Broker not found',
    WrongManagerAPI: 'Wrong manger API',
    MT4Error: 'MT error',
    ServiceError: '服務發生問題，請稍候重試。',
    TraderIsNotExists: '交易帳號不存在',
    //交易帳號相關
    TraderAccount: '交易帳號',
    TraderAccountManage: '交易帳號管理',
    SyncTrader: '交易帳號同步',
    TraderEnable: '交易帳號啟用',
    TraderDisable: '交易帳號停用',
    TraderAccountAdd: '新增交易帳號',

    TraderCreateFailed: '新增交易帳號失敗',
    TraderAccountAddText:
      '新增交易帳號，並可以在此帳號做不同的交易與資金配置。',
    TraderAccountNone: '目前沒有交易帳號',
    TraderCreate: '新增交易帳號',

    TraderBind: '交易帳號綁定',
    TraderBindFailed: '交易帳號綁定失敗',
    TraderDetail: '交易帳號細節',
    TraderNotFound: '交易帳號不存在',
    ModifyAccountPassword: '修改此帳號密碼',
    // Personal Setting
    About: '關於',
    Personal: '個人',
    PersonSetting: '個人設置',
    CompanySetting: '公司設置',
    BankSetting: '銀行設置',
    WorkingDateSetting: '作業時間設置',
    UserOpenAccountSetting: '使用者開戶設置',
    AuditSetting: '審核設置',
    AgentRuleSetting: '代理商規則設置',
    Logout: '登出',

    // ChangeTradePW
    TraderPWModify: '修改此交易帳號密碼',
    TraderPWMain: '主密碼變更',
    TraderPWRead: '只讀密碼變更',
    UserNotFound: '使用者不存在',
    TransferFailed: '資金配置失敗',
    Loading: '請稍候 ...',
    WrongPassword: '密碼錯誤',
    SomethingWrong: '發生了點問題...',
    TryLatter: '請稍候重試',

    Success: '成功',
    AddDone: '新增成功',
    AddFailed: '新增失敗',
    SaveDone: '儲存成功',
    SaveFailed: '儲存失敗',
    Failed: '失敗',
    AsyncSend: '已送出請求',
    ProcessRequest: '您的申請已經提交，將由專人幫您處理。',
    //Broker audit
    AuditKyc: 'KYC審核',
    AuditWithdraw: '出金審核',
    AuditDeposit: '入金申請',
    AuditReject: '審核不通過',
    AuditKycReject: '沒有通過KYC審核',
    AuditPass: '確認審核通過？',
    AuditRejectRes: '請填寫不通過原因，幫助使用者改善更新資料。',
    AuditAllow: '審核通過',
    NextStep: '下一步',
    PrevStep: '上一步',
    LastUpdate: '最近更新',
    Least7Day: '最近七日',
    Allow: '通過',
    NotAllow: '不通過',
    AuditKycAllow: '通過KYC審核',
    Retry: '請重新操作',
    Reason: '原因',
    Download: '下載',
    To: '至',
    // Broker-使用者管理
    ParentAccount: '上級',
    NoParent: '無',
    Direct: '直屬',
    Direct2: '是否直屬',
    // Button
    Operation: '操作',
    OperationDone: '操作完成',
    //KYC return
    KYCUNSUBMITTEDTitle: '尚未完成KYC',
    KYCUNSUBMITTED: '未送審',
    KYCSUBMITTEDTitle: '審核中',
    KYCAllowTitle: '審核通過',

    KYCAllow: 'KYC審核已通過',
    KYCReject: 'KYC審核未通過',
    KYCUNSUBMITTEDDes: '通過KYC認證資料後即可使用帳號管理功能，可出金入金。',
    KYCSUBMITTEDDes: '通過KYC認證資料後即可新增您的交易帳號。',
    KYCFAILEDDes:
      '您的申請被駁回，可能是資料有誤或者沒填寫清楚，請重新提交資料申請。',
    KYCUNSUBMITTEDButtonText: 'KYC資料認證',
    KYCSUBMITTEDButtonText: 'KYC審核中...',
    KYCFAILEDButtonText: 'KYC再次申請',
    KycStatusSubmitted: '待審核',
    KycStatusPassed: '審核通過',
    KycStatusFailed: '審核不通過',

    IDCardFrontIsARequiredField: '身分證圖片為必須',
    IDCardFrontExceeds600kB: '身分證圖片檔案大小必須小於600kb',
    IDCardFBackIsARequiredField: '身分證圖片為必須',
    IDCardBackExceeds600kB: '身分證圖片檔案大小必須小於600kb',
    AddressFileIsARequiredField: '地址證明文件為必須',
    AddressFileExceeds600kB: '地址證明文件檔案大小必須小於600kb',
    BankFrontFileIsARequiredField: '銀行證明文件為必須',
    BankFrontFileExceeds600kB: '銀行證明文件檔案大小必須小於600kb',
    BankBackFileIsARequiredField: '銀行證明文件為必須',
    BankBackFileExceeds600kB: '銀行證明文件檔案大小必須小於600kb',
    // 20201027 END

    ApplicationFailed: '申請失敗',
    ParentAgent: '所屬上級',
    YesReadInfo: '是，查看資料',
    MarketIn: '所屬市場',
    IdCardNumber: '身分證字號',
    ReceviedBankName: '收款銀行名稱',
    ReceviedBankAddress: '收款銀行地址',
    ChangeMarket: '所屬市場變更',
    ChangeMarketErrorMsg1: '有上下級使用者無法變更市場',
    // 20201028 END
    ReadTraderAccount: '查看交易帳號',
    // 20201116 END
    UserInfo: '身分資訊',
    AddrInfo: '住址資訊',
    BankInfo: '銀行資訊',
    MarketGroupMap: '市場與組別圖',
    IncludeGroup: '包含組別',
    SetDefault1: '設定預設',
    LevelName: '層級名稱',
    // 20201119 END
    OrderAdvanceSearchMsg1: '依據服務器提供的服務內容會有差異',
    // 20201123 END
    BindOtherAccount: '綁定其他帳號',
    Server: '服務器',
    // 20201124 END
    Broker_Deposit_Applying: '新申請',
    User_Deposit_Applying: '支付中',
    Broker_Deposit_Reviewing: '已支付',
    User_Deposit_Reviewing: '審核中',
    Broker_Deposit_rejected: '駁回',
    User_Deposit_rejected: '申請失敗',
    Broker_Deposit_Approved: '入金成功',
    User_Deposit_Approved: '入金成功',

    Broker_Withdraw_Applying: '新申请',
    User_Withdraw_Applying: '待審核',
    Broker_Withdraw_Approved: '已審核',
    User_Withdraw_Approved: '審核中',
    Broker_Withdraw_rejected: '作廢',
    User_Withdraw_rejected: '申請失敗',
    Broker_Withdraw_Withdraw: '已出金',
    User_Withdraw_Withdraw: '已出金',
    // 20201127 END
    DepositIn: '淨入金',
    AgentInfo: '代理資訊',
    // 20201201 END
    DepositSetting: '入金設置',
    WithdrawalSetting: '出金設置',
    DepostieWallet: '電子錢包',
    // 20201207 END
    ApplyAmount: '申請金額',
    PaymentDetail: '支付細節',
    DesignationRate: '指定匯率',
    PaymentAmount: '支付金額',
    OrderNumber: '單號',
    ApplyerEmail: '申請人電郵',
    CallApiErrorMsg: '請重新或稍後操作',
    Deleted: '已刪除',
    DeleteEWallet: '刪除電子錢包',
    DeleteEWalletMsg: '確定要刪除電子錢包？',
    WalletName: '錢包名稱',
    EWalletEnableMsg: '請至少啟用一種錢包',
    MinAmount: '最小金額',
    MaxAmount: '最大金額',
    Channel: '通道',
    DeleteChannelMsg: '確定要刪除支付通道？',
    CurrencyEnableMsg: '請至少啟用一種交易幣別',
    DeleteChannel: '刪除支付通道',
    Warning: '提醒',
    WalletAddress: '錢包地址',
    EnterWalletAddressMsg: '請輸入錢包地址',
    EnterWalletNameMsg: '請輸入錢包名稱',
    DeleteCurrencyMsg: '確定要刪除交易幣別？',
    DeleteCurrency: '刪除交易幣別',
    EnterChannelNameMsg: '請輸入通道名稱',
    EnterAmountRangeMsg: '請輸入正確金額範圍',
    EnterCurrencyNameMsg: '請輸入正確幣別代碼(3個大寫英文)',
    EnterRateMsg: '支持至小數位後6位',
    EnterRateMsg8: '支持至小數位後8位',
    MinAmountEqualUSDMsg: '最小金額約等同USD',
    MaxAmountEqualUSDMsg: '最大金額約等同USD',
    EnterCurrencyMsg: '請輸入幣別',
    EnterRateMsg2: '請輸入匯率',
    Created: '已新增',
    Edited: '已修改',
    EnterTokenMsg: '請輸入Token',
    TransferInfo: '匯款資訊',
    BankAccountName: '銀行戶名',
    Rate: '匯率',
    TransferInAmount: '兌換後入金',
    MinAmountMsg: '金額不可小於',
    MaxAmountMsg: '金額不可高於',
    TransferOutAmount: '兌換後出金',
    NoEnableCurrencyAmount: '沒有幣種可使用，請聯絡您的經紀商。',
    AccountInfo: '帳號資訊',
    ReceiveMan: '收款人',
    ApplySent: '申請已送出',
    // 20201214 END
    WrongDataMsg: '請輸入正確資料',
    EnterBankNameMsg: '請輸入銀行名稱',
    EnterBankCodeMsg: '請輸入銀行代碼',
    EnterBankAccountNameMsg: '請輸入銀行戶名',
    EnterBankAccountMsg: '請輸入銀行帳號',
    EnterBankAddressMsg: '請輸入銀行地址',
    EnterRateMsg3: '匯率需大於0',
    // 20201215 END
    IDCardFrontExceeds: '身分證圖片檔案大小必須小於10MB',
    IDCardBackExceeds: '身分證圖片檔案大小必須小於10MB',
    AddressFileExceeds: '地址證明文件檔案大小必須小於10MB',
    BankFrontFileExceeds: '銀行證明文件檔案大小必須小於10MB',
    BankBackFileExceeds: '銀行證明文件檔案大小必須小於10MB',
    // 20201216 END
    UserIsDisabled: '帳號未啟用',
    // 20201222 END
    YourEWalletAddr: '您的電子錢包地址',
    Copy: '複製',
    UserEWalletAddr: '客戶電子錢包地址',
    // 20201228 END
    AllMarket: '全部市場',
    // 20201229 END
    ActivePeople: '活躍人數',
    TotalPeople: '總人數',
    PlatformFilter: '裝置篩選',
    Mobile: '行動裝置',
    Tablet: '平板',
    Desktop: '桌機',
    AccountAndName: '帳號、真實姓名',
    ShowAllDataMsg: '目前資料顯示全部',
    // 20201230 END
    Lots: '手',
    // 20210127 END
    MemberTernDesc: `{brokerName} 很高興可以為您提供資訊、內容、工具、產品及服務。此條款及條件亦包括有關若干產品及服務的重要披露及資料。您在使用 {brokerName} 網站時須受此條款及條件的約束。此條款及條件是您與 {brokerName} 之間具有約束力的協議。您登入及使用本網站需要您同意此條款及條件及載於本網站的其他法律通知及聲明。您使用 {brokerName} 網站將受 {brokerName} 網站當日生效的條款及條件版本所約束。

		{brokerName} 可隨時修改此條款及條件而無須事先作出通知。您持續登錄及使用本網站需要您接納此條款及條件。 {brokerName} 將不會個別地就任何更改或修改通知您。

		此條款及條件是您與 {brokerName} 之間任何其他協議（包括任何客戶或賬戶協議及約束您使用 {brokerName} 網站上所提供及通過 {brokerName} 網站提供的資訊、內容、工具、產品及服務的任何其他協議）之外的條款及條件。除非您與 {brokerName} 有書面協議，否則 {brokerName} 網站僅供您個人及非商業性使用。
		本網站上的資料不擬分發或由下述人士使用：在其國家或司法管轄區內分發或使用違反當地法律或規例的任何人士。 {brokerName} 網站上所述的服務或投資將不會提供給下述人士：在其居住國家內提供此服務或投資違反當地法律或規例的人士。

		本網站的訪客有責任確認其受約束且遵守的當地法律或規例的條款。{brokerName} 網站上的內容不應被視為一項對下述人士作出購買任何產品或服務的招攬或出售任何產品或服務的要約∶處於這類要約、招攬、購買或出售根據該司法管轄區法律屬非法的司法權區之內的人士。
		`,
    PrivacyPolicyDesc: `{brokerName} 尊重個人隱私，並且重視與客戶的關係。{brokerName} 為客戶提供關於個人資料方面的隱私保障，並且取得了客戶的支持及尊重；根據嚴格的安全性措施，{brokerName} 所收集的所有個人資料都將受到保障。本私隱保障政策會說明 {brokerName} 如何收集個人資料，及如何使用及披露這些資料。並詳細解釋客戶所擁有的權利。此政策適用於即將成為友邦外彙的客戶、已經成為 {brokerName} 的客戶及正在申請成為 {brokerName} 的客戶。

			1.  根據《2006 年反洗黑錢及反恐怖主義融資法》（Anti-Money Laundering and Counter-Terrorism Financing Act 2006），{brokerName} 有責任收集客戶身份相關的資料信息及核實客戶的真實身份。{brokerName} 將會根據新西蘭隱私法（Privacy Act 1993）進行客戶身份識別及核實程序。

			2. 客戶提交申請表開立 {brokerName} 真實或模擬帳戶時，將會向 {brokerName} 公司提供客戶個人資料。申請開立 {brokerName} 真實或模擬帳戶時，您確認並同意相關政策條款，並證明您所提供給 {brokerName} 的同意是出於自願的。個人資料是有關客戶的所有資料，能夠識別客戶或確認客戶的真實身份。當個人資料是由第三方（非客戶本人）收集時，{brokerName} 將會採取合理步驟去保證客戶知曉其資料已收集及收集的情況。

			3. {brokerName} 保留了客戶帳戶的所有交易及活動紀錄，包括（但不限於）客戶帳戶的平倉資料。{brokerName} 會從可得的公共資料來源（例如公司登記冊）收集客戶資料。{brokerName} 將會嚴格地根據 1993 年新西蘭隱私法的原則持有客戶的個人資料。客戶可以在任何時間要求查閱 {brokerName} 所持有的客戶資料。{brokerName} 可以將客戶與 {brokerName} 職員之間的電話對話錄音。該錄音資料可以用來解決客戶與 {brokerName} 之間的任何糾紛，這屬於 {brokerName} 的法定權利，並符合監管機構及其他政府組織的要求。當客戶填寫網上申請或其他類型的表格，或通過 {brokerName} 網站操作客戶帳戶或交易時，{brokerName} 也可收集及持有關於客戶的資料。

			4. {brokerName} 可能收集有關客戶的敏感資料，若 {brokerName} 所提供的投訴解決方案未能令客戶滿意，客戶可向隱私專員投訴，其將進一步調查您的投訴。

			5. 客戶可以發送郵件去索取 {brokerName} 持有的個人資料。{brokerName} 一般於 14 天內處理客戶要求。如果客戶的要求是複雜的，在這種情況下可能需要更多的時間，{brokerName} 將會告知客戶需要額外的時間。請求索取客戶本人的資料是不涉及任何費用，但 {brokerName} 可能會向客戶收取處理客戶請求合理的費用。在某些情況下，法律允許 {brokerName} 拒絕客戶的查閱。如果 {brokerName} 有理由懷疑嚴重的非法活動或不當行為正在或可能被從事，並涉及到 {brokerName} 的職能或運作，如果該索取很可能會損害任何合法利益的話，該索取可能會被拒絕。這種情況下，{brokerName} 將會向客戶提供拒絕的原因（除非向客戶提供原因被視為不合適或不合理）、其他監管事項，並提供現有的投訴機制。如可行的話，{brokerName} 可能會採取合理的步驟並在符合 {brokerName} 和客戶需要下提供索取。

			6. {brokerName} 要求客戶在 {brokerName} 持有的客戶個人資料有任何轉變時立即以書面通知 {brokerName}。這將確保 {brokerName} 所持有的客戶個人資料保持最新，及使 {brokerName} 得以就客戶帳戶的持續狀況、客戶的保證金責任及客戶的交易活動向客戶作出通知。客戶可隨時要求 {brokerName} 更改 {brokerName} 所持有而客戶認為是不準確的客戶個人資料。有關修改資料或更正資料或索取有關 {brokerName} 隱私保障政策及實務資料的要求，若 {brokerName} 與客戶就資料的準確性出現分歧，客戶可要求 {brokerName} 對該資料附加聲明，註明客戶認為該資料為不準確或不完整。{brokerName} 將向客戶發出書面通知拒絕更正個人資料、列明拒絕的原因（除非提供拒絕的原因被視為不合適）、提供有關該拒絕的投訴機制，以及其他相關事宜。

			7. 如果 {brokerName} 接納該資料為不準確、過期、不完整或不相關或具誤導性，{brokerName} 將採取一切合理步驟以更正個人資料，以確保它是準確、最新、完整的、相關的和非誤導。

			8. {brokerName} 將採取所有合理步驟以免客戶的個人資料遭到不當使用、遺失、未經授權使用、干擾、更改或披露。
		`,
    Dot: '。',
    // 20210217
    LoginDone1: '我們提供優質便利的交易管理平台，具備多元強大功能：',
    LoginDone2: '多帳號圖像式管理',
    LoginDone3: '出入金申請快速方便',
    LoginDone4: '資金紀錄、交易報表一目瞭然',
    LoginDone5: '投資一定有風險，請謹慎小心。',
    EWalletType: '電子錢包類型',
    AcceptEWalletType: '經紀商所接受電子錢包類型',
    EWalletSubmitMsg:
      '請確認所填資訊正確再送出，若輸入錯誤需自行承擔，經紀商無賠償責任。',
    // 20210217
    CryptocurrencyType: '數字貨幣幣別',
    CryptocurrencyTypeMsg: '幣別限輸入英文3~10字元',
    ProtocolsLabel: '使用協定(可填多項協定，請用“;”區隔)',
    ProtocolsMsg: '各項協定限輸入英文及數字3~10字元',
    FormatMsg: '請確認資料格式',
    Decimal2Msg: '支持至小數位後2位',
    Protocol: '協定',
    // 20210720

    //20210817
    I18CreateUserAccountPrompt:
      '帳號新增完成。請發送信件告知使用者帳號相關資訊。',
    I18CreateUserAccount: '新增使用者',
    // 20210819
    I18EnterEmailPleace: '請輸入電子信箱',
    I18AssignedAgent: '所屬代理',
    I18SelectMarket: '請選擇所屬市場',
    I18SelectAgent: '請選擇所屬代理',
    I18SelectNoAgent: '不選擇代理',
    I18AgentChange: '所屬代理變更',
    I18SelectAgentTier: '設定代理層級',
    I18PromptTierCouldBeDifferent: '注意: 預設帶入之層級可能跟原市場有差異',
    I18ErrorGroupNotOpenInMarket:
      '{{User.name}}的交易帳號： {{TraderAccount.id}}的所屬組別{{Group.name}}在目的市場 {{market.name}}未開啟。',
    I18PromptAgentChangeMarketOnlyWithLevels:
      '代理變更市場，選項僅限有層級之市場',
    I18NotHaveAgent: '無代理',
    // 20210924
    I18ClearCache: '更新連接',
    I18PromptClearCacheAfterUpdateManager:
      'MT4/MT5系統中manager變更管理組別後，需進行數據連接更新。',
    // 20211006
    I18FurtherInformation: '其他資訊',
    I18LimitHalfwidthText: '上限 {limit} 個半形字元',
    I18ExceedTextLimit: '已超過字數上限',
    I18UpdateTimeLatest: '最新更新時間',
    I18TraderAccountLimit: '單一使用者開設交易帳戶數量上限',
    I18TraderAccountLimitExclude: '已開設綁定完成者不在此限',
    I18TraderAccontLimitRange: '數量限制為 {min} - {max} 之間',

    // 20211122
    I18CustomField: '自選欄位',
    I18SelectAll: '全選',

    I18StorageNew: '庫存費',

    // 20211130
    I18OrderPosition: '持倉',
    I18OrderHistory: '歷史訂單',
    I18SetupPrice: '設置價格',
    I18OrderDoneTime: '到期時間',
    I18OrderDoneTimeOrRange: '到期時間或區間',
    I18TradeTime: '交易時間',
    I18FinalPrice: '成交價',
    // 20211209
    I18BankCodeEmptyCheck: 'SWIFT/IBAN code未填寫，是否確認送出?',
    // 20211221
    I18DepositComfirmed: '入金確認',
    I18BrokerDepositDoubleCheckText:
      '確定幫 {userName} 入金 {currencyName} {amount}?',
    I18BrokerDepositDepositTempText:
      '主動為客戶出金仍需通過「審核」，提醒您每次出金前先至CRM系統「申請」並確認客戶所需出金金額足夠。',
    I18UserKycIsRequiredBeforeDeposit: '使用者須完成KYC才能進行出入金',
    I18WithdrawalRequestComfirmed: '出金申請確認',
    I18AssetArrangementComfirmed: '資金配置確認',
    I18BrokerAssetArrangementToTraderDoubleCheckText:
      '確定配置 {currencyName} {amount} 給交易帳號 {traderId}?',
    I18BrokerWithdrawalRequestDoubleCheckText:
      '確定申請出金 {currencyName} {amount} 給 {userName}?',
    I18DepositByBroker: 'Broker入金',
    I18BrokerAssetArrangementToUserAccountDoubleCheckText:
      '確定配置 {currencyName} {amount} 給使用者帳號 {accountId}?',

    I18AssetAmount: '配置金額',
    I18RequestByUserSelf: '自行申請',
    I18RequestByBroker: 'broker 申請',
    I18TraderInformation: '交易帳號資訊',
    I18ViewTraderInfoPermission: '瀏覽交易帳號資訊功能',
    I18nUploadImageCorrupted: '上傳圖片檔案毀損',
  },
  'zh-cn': {
    Broker: 'Broker',
    Provider: 'Provider',
    Trader: 'Trader',
    Signal: 'Signal',
    YES: '是',
    NO: '否',
    //Menu Word
    AgentApplication: '代理申请',
    AgentManage: '代理商管理',
    Client: '客户',
    FollowerManage: '客户管理',
    FollowerList: '客户列表',
    Order: '交易订单',
    OrderHistory: '历史交易',
    AccountDetail: '账号细节',
    AccountHistory: '账号记录',
    CreatAccount: '建立账号',
    FundHistory: '资金记录',
    ReadInfo: '查看资料',
    Review: '查看',
    // SIDE MENU
    Dashboard: '仪表板',
    Setting: '设置',
    UserManagement: '人员管理',
    Audit: '审核',
    MarketGroup: '组别与市场',
    Management: '管理',
    Report: '报表',

    // Word
    More: '查看细节',
    LoginSetting: '登入管理',
    LoginDevice: '登入设备',
    LoginDate: '登入时间',
    Position: '位置',
    BrokerSetting: '平台设置',
    SystemSetting: '系统设置',
    SettingDes1: '为了加强在',
    SettingDes2:
      '交易的安全性，我们提供关于账户的安全保护。如有账号被盜用疑虑信息有泄漏，请联系',
    FundManage: '资金管理',
    User: '使用者',
    UserManage: '使用者管理',
    UserAccount: '使用者账号',
    // Search placeholder
    PlaceholderUser: '使用者账号名称、Email',
    PlaceholderTrader: '账号名称, 使用者名称',
    PlaceholderAgent: '使用者账号名称',
    PlaceholderOrderQuery: '交易账号 订单号 品种',
    NotYetBindUser: '尚未绑定',
    UserName: '使用者名称',
    AgentName: '代理商名称', //等同使用者名称
    KYC: 'KYC资料申请',
    PerLot: '每手',
    PerDeal: '每处理',
    //Login
    Login: '登入',
    LoginMt: 'MT登入',
    LastLogin: '最近登入',
    LoginNormal: '欢迎使用，请输入您的帐号',
    LoginMtText: '请输入您的MT账号与密码登入',
    TextBuildAccount:
      '请先输入您的Email帐号，收取认证信件，为保障权益请确实填写.',
    Password: '您的密码',
    YourEmail: '您的电子邮件',
    ForgotPassword: '忘记密码？',
    TextForgotPassword:
      '请输入您在CRM注册的Email，我们将会重新寄送认证信件，让您重设密码。',
    AccountManage: '账号管理',
    OrderQuery: '订单查询',
    OrderStatus: '订单状态',
    //Button
    Send: '送出',
    Confirm: '确定',
    AlreadyConfirm: '已确认',
    Done: '完成',
    Delete: '删除',
    EditName: '编辑名称',
    EditNickName: '编辑昵称',
    AddMarket: '新增市场',
    AddLevel: '新增层级',
    EditLevel: '变更层级',
    DeleteLevel: '删除层级',
    DeleteLevelDes: '确定要删除层级？',
    DeleteLevelDes2: '还有使用者无法删除层级。',
    DuplicateLevel: '复制上一级佣金设置',
    DeleteLoginDevice: '删除登入装置',

    DeleteMarket: '删除市场',
    CommissionSetting: '佣金设置',
    AddMarketDes: '新增市场，并可以在此市场做不同的层级与佣金管理。',
    DeleteMarketDes: '确定要删除市场？',
    CanNotDeleteMarket: '还有使用者无法删除市场。',
    DeleteConfirm: '确定要删除？',
    ConfirmWithdraw: '确定要出金？',
    Upload: '上传',
    Demo: '模拟仓',
    //NOTIFY
    PWDone: '密码变更完成',
    DepositDone: '入金已送出',
    AddDone: '新增成功',
    DepositOk: '入金完成',
    WithdrawalDone: '出金已完成',
    ChangeGroupDone: '变更组别完成',
    ChangeLeverageDone: '变更交易杠杆完成',
    DisableAccount: '账号停用',
    EnableAccount: '账号启用',
    EnableAccountDes: '启用账号，可使用相关功能<br>确定要启用？',
    DisableAccountDes: '停用此账号，部份功能将被限制<br>确定要停用？',
    ChangeParentDes: '变更上级',
    enableDemo: '启用模拟仓',
    cancelIB: '停用代理权限',
    ChangeClientToIB: '请将客户转移到以下代理',
    ActionFailed: '操作失败',
    ThrottleLimit: '操作次数过多，请稍候重试',
    CopyWordSuccess: '文字复制成功',
    CopyWordFailed: '文字复制失败',

    IPDes: '域名或IP，例：aaa.com / 111.222.33.44',
    //注册
    RDone: '完成注册',
    RText: '为保护权益，请详细填写资料.',
    NickName: '昵称', //User register name
    RRealName: '真实姓名', // KYC name
    Name: '姓名', //Kyc name
    RPersonalInfo: '身份资料',
    RBankInfo: '银行资料',
    RRestInfo: '居住资料',
    RIDCode: '身份证字号',
    RIDDoc: '身分证明文件',
    RAddressDoc: '地址证明文件',
    RBankDoc: '银行证明文件',
    RIDP: '证件正面',
    RIDN: '证件反面',
    RBankDoc1: '银行文件-1',
    RBankDoc2: '银行文件-2',
    UserDetail: '使用者细节',
    UserInformation: '个人资料',

    //SETTING
    TraderAreaTimezone: '交易平台时区',
    LanguageCode: '语系编码',
    Increase: '递增',
    Decreasing: '递减',
    CompanyName: '公司名称',
    CompanyWebsite: '公司网址',
    BusinessHours: '工作时间',
    WorkingDes: '为方便客户的使用，我们提供以下的工作时间',
    SUN: '星期日',
    MON: '星期一',
    TUE: '星期二',
    WED: '星期三',
    THU: '星期四',
    FRI: '星期五',
    SAT: '星期六',
    OS: '操作系统',
    RIDText: '请提供身分证件数据。',
    RAddressText:
      '请提供能够证明个人居所数据的相关文件，如：电信 水电账单...等含有个人资料与住所数据的页面。',
    RBankText:
      '请提供与身分证件相符合的银行数据，如：存折...等含有个人资料的文件。',
    RGender: '性别',
    RBirthday: '生日',
    I18GenderMale: '先生',
    I18GenderFemale: '女士',
    RGender3: '非二元性别',
    RGender4: '不透露',
    RRegion: '国家／地区',
    RNationCode: '请选择国家 / 地区',
    RSelect: '请选择',
    RCity: '城市',
    RProvince: '洲/省',
    RArea: '区',
    RPhone: '联络电话',
    RAddress: '地址',
    Remail: '电子邮件',
    RPassword: '密码',
    ROPassword: '原密码',
    RNPassword: '新密码',
    RMTPassword: 'MT交易原账号密码',
    RMTPasswordLimited: '包含英文与数字或符号,且不超过于15个字符',
    I18NewRMTPasswordLimited: '限8-15字符，必須包含英文与数字。',
    RPassword1: '英文或数字,且不少于8个字符',
    RPasswordConfirm: '确认密码',
    RPasswordConfirm1: '您的密码不一致',
    RPasswordReset: '变更密码',
    CreateAccount: '建立帐号',
    AccountName: '账号名称', //Trader account name
    AccountPW: '请输入密码',
    AccountPWA: '请再次输入密码',
    MTLogin: 'MT 账号',
    OnlyNumber: '仅限数字',
    OnlyNumberNoZero: '仅限数字且不可为零',
    NumberArea: '号段设置',
    Range: '范围',
    Minnum: '不可比最小号段小',
    Numrange: '请介于号段最小值与最大值内',
    MinValue: '最小值',
    MaxValue: '最大值',
    NextAccountNum: '下一个开户号',
    NumberNow: '目前号段',
    MTPW: 'MT 密码',
    //后端回传Errors
    NoLogged: '尚未登入',
    AlreadyLogged: '账号已经登入',
    AuthServiceError: '认证服务错误',
    EmailIsAlreadyUsed: '此Email已经有人使用',
    EmailDoesNotExist: 'Email不存在',
    WrongEmailOrPassword: '帐号或密码输入错误',
    CertificationExpired: '验证过期',
    ErrorFailAccount: '申请帐号失败，请联系您的经纪商进行处理',
    'Limit exceeded': '尝试次数过多，请稍后尝试。',
    'broker-trader-enable': '交易账号启用',
    'broker-sync-config': '数据同步',
    'broker-sync-trader': '交易账号同步',
    'broker-trader-setgroup': '交易组别',
    'broker-trader-leverage': '交易杠杆',
    CommissionIsGreaterThanThePreviousLevel: '佣金设置不可大于上一级 ',
    CommissionIsLessThanThePreviousLevel: '佣金设置不可小于下一级',
    ManagerAPIConnFailed: 'Manager API 连结失败',
    BadRequest: '出了点问题...',
    ImageDisabled: '清除图片',
    Inside: '内部',
    Outside: '外部',
    BrowserIcon: '窗口页签',
    //Errors
    ErrorMail: '请输入正确的Email格式',
    ErrorPW: '密码不少于8个字符，英文字母或数字，可含特殊字符：+ - @ _ = * $',
    ErrorVerify: '请输入正确的验证码',
    ErrorName:
      '请输入正确格式(可包含英文大小写、数字、空格、特殊字符：- _ .·，最多不超过64个字)',
    ErrorCorrect: '请输入正确格式',
    ErrorLogin: '只能输入数字，最多不超过32个码',
    ErrorPhone: '只能输入数字，最少7码，最多不超过15个码',
    ErrorID: '请输入与您提供的证件相符合的ID号码',
    ErrorCountryCode: '请选择国家与地区',
    ErrorEmpty: '字段不可为空',
    ErrorAccount: '请输入正确格式，最少8码',
    MemberTern: '会员条款',
    PrivacyPolicy: '隐私保障政策',
    TextKnowing: '已阅读并同意',
    TextKnowing2: '外汇差价合约交易涉及高风险 条款与条例适用',
    VerifyPassText: '审核通过，请直接更新到我的个人资料。',
    SecurityBlock: '目前无法交易',
    GroupBlock: '组别目前停用中',
    ErrorReason: '失败原因',
    Or: '及',
    None: '无',
    BackLogin: '回到登入',
    VerifyCode: '验证码确认',
    Cancel: '取消',
    ReSend: '重新传送',
    VerifyReceived: '请至您的信箱收取验证码',
    SendVerify: '寄送认证信',
    EmailAlready: '您的Email已存在，请直接使用登入或重新申请另外一个Email。',
    EmailNotExist: '您的Email不存在，请再尝试别的Email。',
    Required: '必填字段',
    RequiredSwift: '请输入正确的SWIFT code格式',
    RequireIban: '请输入正确的IBAN code格式',
    //Bank
    ReceviedBankInfo: '收款银行资料',
    GBankName: '银行名称',
    GBankAddress: '银行地址',
    GBankAccount: '银行账号',
    GManName: '收款人名称',
    GManAccount: '收款账号',
    GManAddress: '收款人地址',
    GBankCode: '银行代码',
    PaymentCurrency: '支付币别',
    PaymentChannel: '支付通道',
    PaymenLimit: '入金金额最小不可小於$1,000元',
    ChoosePayment: '选择通道',
    TradeCurrency: '交易币别',
    Mer_no: '商户号',
    InputMer_no: '请输入商户号',
    Telegraphic: '电汇',
    SettlementByDay:
      '日结算，当日GMT +0 00:00同步订单，隔日GMT +0 01:00结算订单。',
    SettlementByWeek:
      '周结算，每日GMT +0 00:00同步订单，周日GMT +0 01:00结算订单。',
    SettlementByMonth:
      '月结算，每日GMT +0 00:00同步订单，隔月第一日GMT +0 01:00结算订单。',
    //注册
    ErrMsg2: '请稍后尝试',
    //Signal
    STotalInvest: '投资资金',
    Balance: '余额',
    SEquity: '净值',
    Save: '储存',
    Show: '显示',
    Num: '笔',
    AddAccountDirect: '新增交易账号',
    BindMT4Account: '绑定MT4',
    SAvailableFunds: '可用资金',
    WithdrawCost: '已出金金额',
    DepositCost: '已入金金额',
    WaitWithdrawCost: '待出金金额',
    ApplicationWithdraw: '申请出金金额',
    SFPnL: '浮动盈亏',
    SMargin: '保证金',
    MarginUsed: '已用保证金',
    MarginCanUse: '可用保证金',
    MarginRatio: '保证金比例',
    SIncome: '收益',
    SRetreat: '回撤',
    SAddPt: '加点',
    Leverage: '交易杠杆',
    AccountNum: '账号数量',
    AgentNum: '代理数量',
    Sercurity: '交易类型',
    MarginCall: '警示线',
    MarginStopOut: '强平线',
    TitleTrade: '交易类型 / 品种',
    SCredit: '信用额度',
    Sscore: '评分',
    Default: '预设',
    SetDefault: '设为预设组别',
    LeverageDefault: '预设杠杆',
    ChangeLeverage: '调整杠杆',
    All: '全部',
    PnLTotal: '总盈亏',
    ChooseGroup: '选择组别',
    ChangeGroup: '变更组别',
    //Order
    OnHold: '挂单',
    OrderClose: '平仓',
    OrderOpen: '未平仓',
    OrderCloseProcess: '处理中',
    NoUnprocess: '没有待处理项目！',
    EmptyMsg1: '目前资料',
    EmptyInfo: '目前没有资料',
    Empty: '空',
    UnitPoint: '点',
    Add: '新增',
    Edit: '修改',
    PopUpCloneDes: '复制一个账号，并可以在此账号做不同的交易与资金配置',
    CopyPromoteLink: '复制推广连结',
    readOnlypassword: '只读密码',
    BalanceReport: '出入金报表',
    UserDistribution: '使用者分布',
    SelectPlaceHolderChoseAccount: '选择账号',
    SelectAgent: '选择代理',
    AdvanceSearch: '进阶搜寻',
    FunctionSearch: '功能搜寻',
    FunctionSearchTrader: '请输入交易账号，若有多个请使用逗号分开。',
    FunctionSearchUser: '请输入使用者账号，若有多个请使用逗号分开。',
    FunctionCommission: '请输入佣金单号',
    Search: '搜寻',

    ChoisePayment: '请选择支付方式',
    PaymentMethod: '支付方式',
    PaymentColegantDes: '选择Colegant支付将会导至付款页面，确认请按下申请。',
    PaymentDes1: '选择',
    PaymentDes2: '支付将会导至付款页面。',
    LastFiveNum: '账号末五码',
    //No Data
    // REPORT
    Subscribe: '订阅',
    UnSubscribe: '取消订阅',
    UnSubscribeDes: '您的订阅已经取消。',
    TradeNoAccess: '账号无法交易',
    UserNoAccess: '无法使用',
    TSymbol: '品种',
    TOrderNo: '订单号',
    TType: '类型',
    TSummary: '摘要',
    TLots: '手数',
    TTrade: '交易量',
    TPrice: '交易价格',
    TSecurity: '交易种类',
    TOpenDate: '开仓时间',
    TOpenArea: '开仓时间或区间',
    TCloseArea: '平仓时间或区间',
    OpenPrice: '开仓价',
    TCloseDate: '平仓时间',
    CurrentPrice: '市场价',
    ClosePrice: '平仓价',
    OrderSyncTime: '在仓单同步时间',
    TStopLoss: '止损',
    TStopWin: '止盈',
    ProfitLoss: '损益',
    Storage: '利息',
    Fees: '手续费',
    TProfit: '获利',
    TradeLoss: '亏损',
    GMTWord: '时间为本机GMT时间',
    //Record
    BackPoint: '返点',
    SettingPC: '返点返佣统一设置',
    Commission: '佣金',
    Commission2: '返佣',
    CommissionReport: '佣金报表',
    CommissionOrder: '佣金单号',
    MarketNone: '目前没有市场',
    CommissionSend: '佣金发放',
    SettlementTime: '结算时间',
    IntervalTime: '结算时间或区间',
    CommissionType: '佣金类型',
    CommissionRange: '佣金范围',
    NormalCommission: '一般佣金',
    SameCommission: '平级奖励',
    OverCommission: '越级奖励',

    //Application
    Application: '申请',
    ApplicationMan: '申请人',
    ApplicationName: '申请人名称',
    ApplicationAccount: '申请人账号',
    ApplicationEmail: '申请人电子信箱',
    ApplicationOrder: '申请单号',
    ApplicationType: '申请类型',
    ApplicationPhone: '申请人电话',

    BuyQuty: '买入量',
    SellQuty: '卖出量',
    PnL: '盈亏',
    PnLArea: '盈亏范围',
    ProfitShare: '利润分成',
    FundOut: '转出',
    FundIn: '转入',
    SelectMarkets: '选择市场',
    SelectLevel: '请选择层级',
    SetLevel: '修改层级',

    SelectLevelDes: '仅限同市场变更层级，请先筛选市场。',
    SelectCharacter: '功能角色筛选',
    SelectAccountStatus: '筛选账号状态',
    TotalCommission: '总佣金',
    CommissionAccounting: '佣金核算',
    getCommission: '收取佣金',
    SelectAgentDes: '查询收取佣金的代理商',
    CreateAccounRecord: '七日内开户纪录',
    LastSettlementCommission: '上次结算佣金',
    TraderVolum7: '七日内交易量',
    Day7: '七日内',
    PnL7: '七日内盈亏',
    HotSymbol: '热门交易商品',
    Credit: '信用',
    TotalWithdrawal: '总出金',
    Withdrawal: '出金',
    WithdrawalCost: '可出金金额',
    Deposit: '入金',
    TotalDeposit: '总入金',
    WithdrawalMin: '出金最小金额',
    Costmin: '金额不可低于',
    DepositNum: '入金笔数',
    WithdrawalNum: '出金笔数',
    //交易组别
    AllGroup: '全部组别',
    MTGroup: '交易组别',
    DefaultGroup: '默认交易组别',
    MTGroupSetting: '交易组别设置',
    GroupIn: '所属交易组别',
    Enabled: '启用',
    Disable: '停用',
    IsIBANSWIFT: '是否填写SWIFT / IBAN code',
    //资金配置纪录
    FundArrange: '资金配置',
    SelectCostRange: '筛选余额范围',
    SelectCostRangeDesMax: '只输入最大值，则以最大值往下搜寻',
    SelectCostRangeDesMin: '只输入最小值，则以最小值往上搜寻',
    CostRange: '金额范围',
    Arragne: '配置',
    ArragneTo: '配置到',
    EnterFundCost: '请输入配置金额',
    FunArrangeRecord: '资金变动纪录',
    FundArrangeMin: '配置金额不可低于',
    TradeHistory: '历史交易纪录',
    ChoseFrom: '选择从',
    Wallet: '主钱包',
    Type: '方式',
    Time: '时间',
    StartTime: '开始时间',
    EndTime: '结束时间',
    Start: '开始',
    End: '结束',
    Cost: '金额',
    AccountRecord: '出入金纪录',
    Function: '功能',
    Summary: '摘要',
    KYCStatus: 'KYC认证',
    Action: '动作',
    Comment: '备注',
    Account: '账号',

    //市场
    MarketCommission: '市场佣金',
    MarketCommissionSetting: '市场佣金设置',
    Market: '市场',
    EditMarket: '编辑市场',
    Agent: '代理商',
    AgentList: '代理商管理',
    IsAgent: '是否代理商',
    NotAgent: '非代理',
    MarketLevel: '市场层级',
    MarketName: '市场名称',
    MarketCost: '市场出入金',
    Level: '层级',
    Detail: '详细',
    ChannelName: '通道名称',

    //User List
    TraderName: '账号名称',
    AccountStatus: '账号状态',
    Register: '注册',
    RegisterEnabled: '开放注册',
    RegisterDisabled: '不可注册',
    RegisterDate: '注册时间',
    AuditDate: '送审时间',
    ApplicationDate: '申请时间',
    ApplicationDateInterval: '申请时间或区间',
    HandleDate: '处理时间',
    HandleDateInterval: '处理时间或区间',
    CompletedDate: '完成时间',
    ChoseGMTTime: '选择GMT时间',
    DepositOrder: '入金申请号',
    Completed: '完成',
    WithdrawalApplication: '出金申请',
    StopAccountDes: '账号停用中请联络您的服务提供商',
    WithdrawalOrder: '出金申请号',
    AllCharacters: '全部角色',
    Characters: '角色',
    Status: '状态',
    ThisWeek: '7日内交易',
    ThisMonth: '30日内交易',
    //交易数据
    TradeStatus: '交易近况',
    TradeData: '交易数据',
    TradeEva: '交易评估',
    TDPL: '总盈亏',
    TDLots: '总手数',
    TDInterest: '总利息',
    TDHandingFee: '总手续费',
    //NOTIFY
    NewApplication: '新申请',
    DepositSuccess: '入金成功',
    Approved: '已批准',
    WithdrawnSuccess: '出金成功',
    Reject: '已驳回',
    Reject2: '驳回',
    RejectApplication: '驳回申请',
    Void: '作废',
    Check: '已查核',
    Grant: '已发放',
    RejectReason: '驳回原因',
    Notify: '通知',
    ViewAllNotify: '通知清除',
    Clean: '清除',
    EnabledAgent: '启用代理权限',
    ChangeParent: '变更上级',
    // ORGANIZATION
    Organization: '组织',
    People: '人数',
    OrganizationPeople: '组织人数',
    TotalUser: '用户总人数',
    OrganizationStructure: '组织结构',
    BrokerAgent: '平台商代理',
    BrokerUser: '平台商直客',
    AgentAgent: '代理商代理',
    Agentuser: '代理商直客',
    DirectUser: '直客',
    NormalAgent: '代理',
    OverAgent: '越级代理',
    SameAgent: '平级代理',
    Referrer: '推荐人',
    OrganizationDes: '以市场层级排列，若非代理位于最下方。',
    //--
    SameBonus: '平级奖励',
    OverBonus: '越级奖励',
    //UNIT
    UnitMan: '人',
    UnitStuff: '个',
    SyncConfig: '信息同步',
    SyncOrderhistory: '历史订单同步',
    TraderLeverage: '杠杆调整',
    TraderSetGroup: '组别变更',
    WalletRequestDepositApprove: '入金通过',
    WalletRequestWithdrawWithdraw: '已出金',
    WalletRequestDepositReject: '入金不通过',
    WalletRequestWithdrawReject: '出金失败',
    UserEnable: '您的账号已被启用',
    UserDisable: '您的账号已被停用',
    UserPasswordUpdate: '您的密码已被更改',
    AgentAdd: '您已成为代理',
    AgentRemove: '已经移除代理权限',

    BrokerNotFound: 'Broker not found',
    WrongManagerAPI: 'Wrong manger API',
    MT4Error: 'MT error',
    ServiceError: '服务发生问题，请稍候重试。',
    TraderIsNotExists: '交易账号不存在',
    //交易账号相关
    TraderAccount: '交易账号',
    TraderAccountManage: '交易账号管理',
    SyncTrader: '交易账号同步',
    TraderEnable: '交易账号启用',
    TraderDisable: '交易账号停用',
    TraderAccountAdd: '新增交易账号',
    TraderCreateFailed: '新增交易账号失败',
    TraderAccountAddText:
      '新增交易账号，并可以在此账号做不同的交易与资金配置。',
    TraderAccountNone: '目前没有交易账号',
    TraderCreate: '新增交易账号',
    TraderBind: '交易账号绑定',
    TraderBindFailed: '交易账号绑定失败',
    TraderDetail: '交易账号细节',
    TraderNotFound: '交易账号不存在',
    ModifyAccountPassword: '修改此账号密码',
    // Personal Setting
    About: '关于',
    Personal: '个人',
    PersonSetting: '个人设置',
    CompanySetting: '公司设置',
    BankSetting: '银行设置',
    WorkingDateSetting: '作业时间设置',
    UserOpenAccountSetting: '使用者开户设置',
    PaymentSetting: '支付设置',
    AuditSetting: '审核设置',
    AgentRuleSetting: '代理商规则设置',
    Logout: '注销',

    // ChangeTradePW
    TraderPWModify: '修改此交易账号密码',
    TraderPWMain: '主密码变更',
    TraderPWRead: '只读密码变更',
    UserNotFound: '使用者不存在',
    TransferFailed: '资金配置失败',
    Loading: '请稍候 ...',
    WrongPassword: '密码错误',
    SomethingWrong: '发生了点问题...',
    TryLatter: '请稍候重试',

    Success: '成功',
    AddFailed: '新增失败',
    SaveDone: '储存成功',
    SaveFailed: '储存失败',
    Failed: '失败',
    AsyncSend: '已送出请求',
    ProcessRequest: '您的申请已经提交，将由专人帮您处理。',
    //Broker audit
    AuditKyc: 'KYC审核',
    AuditWithdraw: '出金审核',
    AuditDeposit: '入金申请',
    AuditReject: '审核不通过',
    AuditKycReject: '没有通过KYC审核',
    AuditPass: '确认审核通过？',
    AuditRejectRes: '请填写不通过原因，帮助用户改善更新数据。',
    AuditAllow: '审核通过',
    NextStep: '下一步',
    PrevStep: '上一步',
    LastUpdate: '最近更新',
    Least7Day: '最近七日',
    Allow: '通过',
    NotAllow: '不通过',
    AuditKycAllow: '通过KYC审核',
    Retry: '请重新操作',
    Reason: '原因',
    Download: '下载',
    To: '至',
    // Broker-使用者管理
    ParentAccount: '上级',
    NoParent: '无',
    Direct: '直属',
    Direct2: '是否直属',
    // Button
    Operation: '操作',
    OperationDone: '操作完成',
    //KYC return
    KYCUNSUBMITTEDTitle: '尚未完成KYC',
    KYCUNSUBMITTED: '未送审',
    KYCSUBMITTEDTitle: '审核中',
    KYCAllowTitle: '审核通过',

    KYCAllow: 'KYC审核已通过',
    KYCReject: 'KYC审核未通过',
    KYCUNSUBMITTEDDes: '通过KYC认证数据后即可使用账号管理功能，可出金入金。',
    KYCSUBMITTEDDes: '通过KYC认证数据后即可新增您的交易账号。',
    KYCFAILEDDes:
      '您的申请被驳回，可能是数据有误或者没填写清楚，请重新提交数据申请。',
    KYCUNSUBMITTEDButtonText: 'KYC资料认证',
    KYCSUBMITTEDButtonText: 'KYC审核中...',
    KYCFAILEDButtonText: 'KYC再次申请',
    KycStatusSubmitted: '待审核',
    KycStatusPassed: '审核通过',
    KycStatusFailed: '审核不通过',

    IDCardFrontIsARequiredField: '身分证图片为必须',
    IDCardFrontExceeds600kB: '身分证图片档案大小必须小于600kb',
    IDCardFBackIsARequiredField: '身分证图片为必须',
    IDCardBackExceeds600kB: '身分证图片档案大小必须小于600kb',
    AddressFileIsARequiredField: '地址证明文件为必须',
    AddressFileExceeds600kB: '地址证明文件档案大小必须小于600kb',
    BankFrontFileIsARequiredField: '银行证明文件为必须',
    BankFrontFileExceeds600kB: '银行证明文件档案大小必须小于600kb',
    BankBackFileIsARequiredField: '银行证明文件为必须',
    BankBackFileExceeds600kB: '银行证明文件档案大小必须小于600kb',
    // 20201027 END

    ApplicationFailed: '申请失败',
    ParentAgent: '所属上级',
    YesReadInfo: '是，查看资料',
    MarketIn: '所属市场',
    IdCardNumber: '身份证号码',
    ReceviedBankName: '收款银行名称',
    ReceviedBankAddress: '收款银行地址',
    ChangeMarket: '所属市场变更',
    ChangeMarketErrorMsg1: '有上下级使用者无法变更市场',
    // 20201028 END
    ReadTraderAccount: '查看交易帐号',
    // 20201116 END
    UserInfo: '身分资讯',
    AddrInfo: '住址资讯',
    BankInfo: '银行资讯',
    MarketGroupMap: '市场与组别图',
    IncludeGroup: '包含组别',
    SetDefault1: '设定预设',
    LevelName: '层级名称',
    // 20201119 END
    OrderAdvanceSearchMsg1: '依据服务器提供的服务内容会有差异',
    // 20201123 END
    BindOtherAccount: '绑定其他帐号',
    Server: '服务器',
    // 20201124 END
    Broker_Deposit_Applying: '新申请',
    User_Deposit_Applying: '支付中',
    Broker_Deposit_Reviewing: '已支付',
    User_Deposit_Reviewing: '审核中',
    Broker_Deposit_rejected: '驳回',
    User_Deposit_rejected: '申请失败',
    Broker_Deposit_Approved: '入金成功',
    User_Deposit_Approved: '入金成功',

    Broker_Withdraw_Applying: '新申请',
    User_Withdraw_Applying: '待审核',
    Broker_Withdraw_Approved: '已审核',
    User_Withdraw_Approved: '审核中',
    Broker_Withdraw_rejected: '作废',
    User_Withdraw_rejected: '申请失败',
    Broker_Withdraw_Withdraw: '已出金',
    User_Withdraw_Withdraw: '已出金',
    // 20201127 END
    DepositIn: '净入金',
    AgentInfo: '代理资讯',
    // 20201201 END
    DepositSetting: '入金设置',
    WithdrawalSetting: '出金设置',
    DepostieWallet: '电子钱包',
    // 20201207 END
    ApplyAmount: '申请金额',
    PaymentDetail: '支付细节',
    DesignationRate: '指定汇率',
    PaymentAmount: '支付金额',
    OrderNumber: '单号',
    ApplyerEmail: '申请人电邮',
    CallApiErrorMsg: '请重新或稍后操作',
    Deleted: '已删除',
    DeleteEWallet: '删除电子钱包',
    DeleteEWalletMsg: '确定要删除电子钱包？ ',
    WalletName: '钱包名称',
    EWalletEnableMsg: '请至少启用一种钱包',
    MinAmount: '最小金额',
    MaxAmount: '最大金额',
    Channel: '通道',
    DeleteChannelMsg: '确定要删除支付通道？ ',
    CurrencyEnableMsg: '请至少启用一种交易币别',
    DeleteChannel: '删除支付通道',
    Warning: '提醒',
    WalletAddress: '钱包地址',
    EnterWalletAddressMsg: '请输入钱包地址',
    EnterWalletNameMsg: '请输入钱包名称',
    DeleteCurrencyMsg: '确定要删除交易币别？ ',
    DeleteCurrency: '删除交易币别',
    EnterChannelNameMsg: '请输入通道名称',
    EnterAmountRangeMsg: '请输入正确金额范围',
    EnterCurrencyNameMsg: '请输入正确币别代码(3个大写英文)',
    EnterRateMsg: '支持至小数位后6位',
    EnterRateMsg8: '支持至小数位后8位',
    MinAmountEqualUSDMsg: '最小金额约等同USD',
    MaxAmountEqualUSDMsg: '最大金额约等同USD',
    EnterCurrencyMsg: '请输入币别',
    EnterRateMsg2: '请输入汇率',
    Created: '已新增',
    Edited: '已修改',
    EnterTokenMsg: '请输入Token',
    TransferInfo: '汇款资讯',
    BankAccountName: '银行户名',
    Rate: '汇率',
    TransferInAmount: '兑换后入金',
    MinAmountMsg: '金额不可小于',
    MaxAmountMsg: '金额不可高于',
    TransferOutAmount: '兑换后出金',
    NoEnableCurrencyAmount: '没有币种可使用，请联络您的经纪商。 ',
    AccountInfo: '帐号资讯',
    ReceiveMan: '收款人',
    ApplySent: '申请已送出',
    // 20201214 END
    WrongDataMsg: '请输入正确资料',
    EnterBankNameMsg: '请输入银行名称',
    EnterBankCodeMsg: '请输入银行代码',
    EnterBankAccountNameMsg: '请输入银行户名',
    EnterBankAccountMsg: '请输入银行帐号',
    EnterBankAddressMsg: '请输入银行地址',
    EnterRateMsg3: '汇率需大于0',
    // 20201215 END
    IDCardFrontExceeds: '身分证图片档案大小必须小于10MB',
    IDCardBackExceeds: '身分证图片档案大小必须小于10MB',
    AddressFileExceeds: '地址证明文件档案大小必须小于10MB',
    BankFrontFileExceeds: '银行证明文件档案大小必须小于10MB',
    BankBackFileExceeds: '银行证明文件档案大小必须小于10MB',
    // 20201216 END
    UserIsDisabled: '帐号未启用',
    // 20201222 END
    YourEWalletAddr: '您的电子钱包地址',
    Copy: '复制',
    UserEWalletAddr: '客户电子钱包地址',
    // 20201228 END
    AllMarket: '全部市场',
    // 20201229 END
    ActivePeople: '活跃人数',
    TotalPeople: '总人数',
    PlatformFilter: '装置筛选',
    Mobile: '行动装置',
    Tablet: '平板',
    Desktop: '桌机',
    AccountAndName: '帐号、真实姓名',
    ShowAllDataMsg: '目前资料显示全部',
    // 20201230 END
    Lots: '手',
    // 20210127 END
    MemberTernDesc: `{brokerName} 很高兴可以为您提供资讯、内容、工具、产品及服务。此条款及条件亦包括有关若干产品及服务的重要披露及资料。您在使用 {brokerName} 网站时须受此条款及条件的约束。 此条款及条件是您与 {brokerName} 之间具有约束力的协议。您登入及使用本网站需要您同意此条款及条件及载于本网站的其他法律通知及声明。您使用 {brokerName} 网站将受 {brokerName} 网站当日生效的条款及条件版本所约束。

		{brokerName} 可随时修改此条款及条件而无须事先作出通知。您持续登录及使用本网站需要您接纳此条款及条件。{brokerName} 将不会个别地就任何更改或修改通知您。

		此条款及条件是您与 {brokerName} 之间任何其他协议（包括任何客户或账户协议及约束您使用 {brokerName} 网站上所提供及通过 {brokerName} 网站提供的资讯、内容、工具、产品及服务的任何其他协议）之外的条款及条件。除非您与 {brokerName} 有书面协议，否则 {brokerName} 网站仅供您个人及非商业性使用。
		本网站上的资料不拟分发或由下述人士使用：在其国家或司法管辖区内分发或使用违反当地法律或规例的任何人士。{brokerName} 网站上所述的服务或投资将不会提供给下述人士：在其居住国家内提供此服务或投资违反当地法律或规例的人士。

		本网站的访客有责任确认其受约束且遵守的当地法律或规例的条款。{brokerName} 网站上的内容不应被视为一项对下述人士作出购买任何产品或服务的招揽或出售任何产品或服务的要约：处于这类要约、招揽、购买或出售根据该司法管辖区法律属非法的司法权区之内的人士。
		`,
    PrivacyPolicyDesc: `{brokerName} 尊重个人隐私，并且重视与客户的关系。{brokerName} 为客户提供关于个人资料方面的隐私保障，并且取得了客户的支持及尊重；根据严格的安全性措施，{brokerName}所收集的所有个人资料都将受到保障。本私隐保障政策会说明 {brokerName} 如何收集个人资料，及如何使用及披露这些资料。并详细解释客户所拥有的权利。此政策适用于即将成为友邦外汇的客户、已经成为 {brokerName} 的客户及正在申请成为 {brokerName} 的客户。

			1. 根据《2006 年反洗黑钱及反恐怖主义融资法》（Anti-Money Laundering and Counter-Terrorism Financing Act 2006），{brokerName} 有责任收集客户身份相关的资料信息及核实客户的真实身份。{brokerName} 将会根据新西兰隐私法（Privacy Act 1993）进行客户身份识别及核实程序。

			2. 客户提交申请表开立 {brokerName} 真实或模拟账户时，将会向 {brokerName} 公司提供客户个人资料。申请开立 {brokerName} 真实或模拟账户时，您确认并同意相关政策条款，并证明您所提供给 {brokerName} 的同意是出于自愿的。个人资料是有关客户的所有资料，能够识别客户或确认客户的真实身份。当个人资料是由第三方（非客户本人）收集时，{brokerName} 将会采取合理步骤去保证客户知晓其资料已收集及收集的情况。

			3. {brokerName} 保留了客户账户的所有交易及活动纪录，包括（但不限于）客户账户的平仓资料。{brokerName} 会从可得的公共资料来源（例如公司登记册）收集客户资料。{brokerName} 将会严格地根据 1993 年新西兰隐私法的原则持有客户的个人资料。客户可以在任何时间要求查阅 {brokerName} 所持有的客户资料。{brokerName} 可以将客户与 {brokerName} 职员之间的电话对话录音。该录音资料可以用来解决客户与 {brokerName} 之间的任何纠纷，这属于 {brokerName} 的法定权利，并符合监管机构及其他政府组织的要求。当客户填写网上申请或其他类型的表格，或通过 {brokerName} 网站操作客户账户或交易时，{brokerName} 也可收集及持有关于客户的资料。

			4. {brokerName} 可能收集有关客户的敏感资料，若 {brokerName} 所提供的投诉解决方案未能令客户满意，客户可向隐私专员投诉，其将进一步调查您的投诉。

			5. 客户可以发送邮件去索取 {brokerName} 持有的个人资料。{brokerName} 一般于 14 天内处理客户要求。如果客户的要求是复杂的，在这种情况下可能需要更多的时间，{brokerName} 将会告知客户需要额外的时间。请求索取客户本人的资料是不涉及任何费用，但 {brokerName} 可能会向客户收取处理客户请求合理的费用。在某些情况下，法律允许 {brokerName} 拒绝客户的查阅。如果 {brokerName} 有理由怀疑严重的非法活动或不当行为正在或可能被从事，并涉及到 {brokerName} 的职能或运作，如果该索取很可能会损害任何合法利益的话，该索取可能会被拒绝。这种情况下，{brokerName} 将会向客户提供拒绝的原因（除非向客户提供原因被视为不合适或不合理）、其他监管事项，并提供现有的投诉机制。如可行的话，{brokerName} 可能会采取合理的步骤并在符合 {brokerName} 和客户需要下提供索取。

			6. {brokerName} 要求客户在 {brokerName} 持有的客户个人资料有任何转变时立即以书面通知 {brokerName}。这将确保 {brokerName} 所持有的客户个人资料保持最新，及使 {brokerName} 得以就客户账户的持续状况、客户的保证金责任及客户的交易活动向客户作出通知。客户可随时要求 {brokerName} 更改 {brokerName} 所持有而客户认为是不准确的客户个人资料。有关修改资料或更正资料或索取有关 {brokerName} 隐私保障政策及实务资料的要求，若 {brokerName} 与客户就资料的准确性出现分歧，客户可要求 {brokerName} 对该资料附加声明，注明客户认为该资料为不准确或不完整。{brokerName} 将向客户发出书面通知拒绝更正个人资料、列明拒绝的原因（除非提供拒绝的原因被视为不合适）、提供有关该拒绝的投诉机制，以及其他相关事宜。

			7. 如果 {brokerName} 接纳该资料为不准确、过期、不完整或不相关或具误导性，{brokerName} 将采取一切合理步骤以更正个人资料，以确保它是准确、最新、完整的、相关的和非误导。</li>

			8. {brokerName} 将采取所有合理步骤以免客户的个人资料遭到不当使用、遗失、未经授权使用、干扰、更改或披露。
		`,
    Dot: '。',
    // 20210217
    LoginDone1: '我们提供优质便利的交易管理平台，具备多元强大功能：',
    LoginDone2: '多账号图像式管理',
    LoginDone3: '出入金申请快速方便',
    LoginDone4: '资金纪录、交易报表一目了然',
    LoginDone5: '投资一定有风险，请谨慎小心。',
    EWalletType: '电子钱包类型',
    AcceptEWalletType: '经纪商所接受电子钱包类型',
    EWalletSubmitMsg:
      '请确认所填信息正确再送出，若输入错误需自行承担，经纪商无赔偿责任。',
    // 20210719
    CryptocurrencyType: '数字货币币别',
    CryptocurrencyTypeMsg: '币别限输入英文3~10字符',
    ProtocolsLabel: '使用协议(可填多项协议，请用“;”隔开)',
    ProtocolsMsg: '各项协议限输入英文及数字3~10字符',
    FormatMsg: '请确认数据格式',
    Decimal2Msg: '支持至小数位后2位',
    Protocol: '协定',
    // 20210720

    //20210817
    I18CreateUserAccountPrompt:
      '账号新增完成。请发送信件告知使用者账号相关信息。',
    I18CreateUserAccount: '新增使用者',
    // 20210819
    I18EnterEmailPleace: '请输入電子信箱',
    I18AssignedAgent: '所属代理',

    I18SelectMarket: '请选择所属市场',
    I18SelectAgent: '请选择所属代理',
    I18SelectNoAgent: '不选择代理',
    I18AgentChange: '所属代理变更',
    I18SelectAgentTier: '设定代理层级',
    I18PromptTierCouldBeDifferent: '注意: 预设带入之层级可能跟原市场有差异',
    I18ErrorGroupNotOpenInMarket:
      '{{User.name}}的交易账号： {{TraderAccount.id}}的所属组别{{Group.name}}在目的市场 {{market.name}}未开启。',
    I18PromptAgentChangeMarketOnlyWithLevels:
      '代理变更市场，选项仅限有层级之市场',
    I18NotHaveAgent: '无代理',
    // 20210924
    I18ClearCache: '更新连接',
    I18PromptClearCacheAfterUpdateManager:
      'MT4/MT5系统中manager变更管理组别后，需进行数据连接更新。',

    // 20211006
    I18FurtherInformation: '其他信息',
    I18LimitHalfwidthText: '上限 {limit} 个半角字符',
    I18ExceedTextLimit: '已超过字数上限',
    I18UpdateTimeLatest: '最新更新时间',

    I18TraderAccountLimit: '单一用户开设交易帐户数量上限',
    I18TraderAccountLimitExclude: '已开设绑定完成者不在此限',
    I18TraderAccontLimitRange: '数量限制为 {min} - {max} 之间',

    // 20211122
    I18CustomField: '自选字段',
    I18SelectAll: '全选',

    I18StorageNew: '库存费',
    // 20211130
    I18OrderPosition: '持仓',
    I18OrderHistory: '历史订单',
    I18SetupPrice: '设置价格',
    I18OrderDoneTime: '到期时间',
    I18OrderDoneTimeOrRange: '到期时间或区间',
    I18TradeTime: '交易时间',
    I18FinalPrice: '成交价',
    // 20211209
    I18BankCodeEmptyCheck: 'SWIFT/IBAN code未填写，是否确认送出?',

    // 20211221
    I18DepositComfirmed: '入金确认',
    I18BrokerDepositDoubleCheckText:
      '确定帮 {userName} 入金 {currencyName} {amount}?',
    I18BrokerDepositDepositTempText:
      '主动为客户出金仍需通过「审核」，提醒您每次出金前先至CRM系统「申请」并确认客户所需出金金额足够',
    I18UserKycIsRequiredBeforeDeposit: '使用者须完成KYC才能进行出入金',
    I18WithdrawalRequestComfirmed: '出金申请确认',
    I18AssetArrangementComfirmed: '资金配置确认',
    I18BrokerAssetArrangementToTraderDoubleCheckText:
      '确定配置 {currencyName} {amount} 给交易账号 {traderId}?',
    I18BrokerWithdrawalRequestDoubleCheckText:
      '确定申请出金 {currencyName} {amount} 给 {userName}?',
    I18DepositByBroker: 'Broker入金',
    I18BrokerAssetArrangementToUserAccountDoubleCheckText:
      '确定配置 {currencyName} {amount} 給使用者账号 {accountId}?',
    I18AssetAmount: '配置金额',

    I18RequestByUserSelf: '自行申请',
    I18RequestByBroker: 'broker 申请',
    I18TraderInformation: '交易帐户信息',
    I18ViewTraderInfoPermission: '浏览交易帐户信息功能',
    I18nUploadImageCorrupted: '上传图片档案毁损',
  },
  'vi-vn': {
    Broker: 'Người môi giới', //Người môi giới
    Provider: 'Nhà cung cấp', //Nhà cung cấp
    Trader: 'TNhà giao dịch', // Nhà giao dịch
    Signal: 'Tín hiệu', //Tín hiệu
    YES: 'Có', // Có
    NO: 'Không', //Không
    //Menu Word
    AgentApplication: 'Đơn xin đại lý',
    Client: 'Khách hàng', //Khách hàng
    FollowerManage: 'Quản lý khách hàng', //Quản lý khách hàng
    FollowerList: 'Danh sách người theo dõi', //Danh sách người theo dõi
    Order: 'Đặt lệnh', //Đặt lệnh
    OrderHistory: 'Lịch sử giao dịch', //Lịch sử giao dịch
    AccountDetail: 'Chi tiết tài khoản', //Chi tiết tài khoản
    AccountHistory: 'Lịch sử tài khoản', //Lịch sử tài khoản
    CreatAccount: 'Tạo tài khoản', //Tạo tài khoản
    FundHistory: 'Lịch sử quỹ', //Lịch sử quỹ
    ReadInfo: 'Kiểm tra thông tin', //Kiểm tra thông tin
    Review: 'Kiểm tra', //Kiểm tra
    // SIDE MENU
    Dashboard: 'Bảng điều khiển', //Bảng điều khiển
    Setting: 'Cài đặt', //Cài đặt
    UserManagement: 'Quản lý người dùng', //Quản lý người dùng
    Audit: 'Phê duyệt',
    MarketGroup: 'Nhóm thị trường', //Nhóm thị trường
    Management: 'Quản lý', //Quản lý
    Report: 'Báo cáo', //Báo cáo

    // Word
    More: 'Kiểm tra chi tiết', //Kiểm tra chi tiết
    LoginSetting: 'Quản lý đăng nhập', //Quản lý đăng nhập
    LoginDevice: 'Thiết bị đăng nhập', //Thiết bị đăng nhập
    LoginDate: 'Thời gian đăng nhập', //Thời gian đăng nhập
    Position: 'Vị trí', //Vị trí
    BrokerSetting: 'Thiết lập người môi giới', //Thiết lập người môi giới
    SystemSetting: 'Cài đặt hệ thống', //Cài đặt hệ thống
    SettingDes1: 'Để tăng cường', //Để tăng cường
    SettingDes2:
      'Tính an toàn của giao dịch, chúng tôi cung cấp bảo vệ an toàn trên tài khoản. Nếu như nghi ngờ thông tin bị rò rỉ, vui lòng liên hệ', //Tính an toàn của giao dịch, chúng tôi cung cấp bảo vệ an toàn trên tài khoản. Nếu như nghi ngờ thông tin bị rò rỉ, vui lòng liên hệ
    FundManage: 'Quản lý quỹ', //Quản lý quỹ
    User: 'Người dùng', //Người dùng
    UserManage: 'Quản lý người dùng', //Quản lý người dùng
    UserAccount: 'Tài khoản người dùng', //Tài khoản người dùng
    // Search placeholder
    PlaceholderUser: 'Tên tài khoản người dùng, Email', //Tên tài khoản người dùng, Email
    PlaceholderTrader: 'Tên tài khoản, Tên người dùng', //Tên tài khoản
    PlaceholderAgent: 'Tên tài khoản người dùng', //Tên tài khoản người dùng
    PlaceholderOrderQuery: 'Nhập mã MT/đơn hàng/Type', //Tài khoản giao dịch Mã đơn Loại sản phẩm
    NotYetBindUser: 'Chưa liên kết', //Chưa liên kết
    UserName: 'Tên người dùng', //Tên người dùng
    AgentName: 'Tên đại lý', //等同使用者名称 Tên đại lý , Tên người dùng tương đương
    KYC: 'Đơn tài liệu KYC', //Đơn tài liệu KYC
    PerLot: 'Mỗi lot', //Mỗi lot
    PerDeal: 'Mỗi lần xử lý', //Mỗi lần xử lý
    //Login
    Login: 'Đăng nhập', //Đăng nhập
    LoginMt: 'Đăng nhập MT', //Đăng nhập MT
    LastLogin: 'Lần đăng nhập gần đây', //Lần đăng nhập gần đây
    LoginNormal: 'Chào mừng sử dụng, vui lòng đăng nhập tài khoản của bạn', //Chào mừng sử dụng, vui lòng đăng nhập tài khoản của bạn
    LoginMtText: 'Vui lòng nhập tài khoản MT và mật khẩu đăng nhập', //Vui lòng nhập tài khoản MT và mật khẩu đăng nhập
    TextBuildAccount:
      'Vui lòng nhập tài khoản Email của bạn, để nhận được thư xác thực, vui lòng điền vào để bảo vệ quyền lợi của bạn.', //Vui lòng nhập tài khoản Email của bạn, để nhận được thư xác thực, vui lòng điền vào để bảo vệ quyền lợi của bạn
    Password: 'Mật khẩu của bạn', //Mật khẩu của bạn
    YourEmail: 'Email của bạn', //Email của bạn
    ForgotPassword: 'Quên mật khẩu ?', //Quên mật khẩu ?
    TextForgotPassword:
      'Vui lòng nhập Email đăng ký trên CRM, chúng tôi sẽ gửi lại thư xác thực để bạn đặt lại mật khẩu của mình', //Vui lòng nhập Email đăng ký trên CRM, chúng tôi sẽ gửi lại thư xác thực để bạn đặt lại mật khẩu của mình
    AccountManage: 'Quản lý tài khoản', //Quản lý tài khoản
    OrderQuery: 'Theo dõi đơn hàng', //Theo dõi đơn hàng
    OrderStatus: 'Trạng thái đơn hàng', //Trạng thái đơn hàng
    // LoginDone1: 'Chúng tôi cung cấp cho bạn dịch vụ tài liệu chất lượng tốt nhất và nhiều chức năng mạnh mẽ. ',
    // LoginDone2: 'Đăng ký các nguồn tín hiệu và tài liệu chất lượng cao',
    // LoginDone3: 'Rút tiền và gửi tiền nhanh chóng, thuận tiện',
    // LoginDone4: 'Danh mục quản lý nhiều tín hiệu',
    // LoginDone5: 'Đầu tư phải mạo hiểm, hãy cẩn thận. ',
    //Button
    Send: 'Gửi đi',
    Confirm: 'Xác nhận', //Xác nhận
    AlreadyConfirm: 'Đã xác nhận', //Đã xác nhận
    Done: 'Đã xong', //Đã xong
    Delete: 'Xóa bỏ', //Xóa bỏ
    EditName: 'Chỉnh sửa tên', //Chỉnh sửa tên
    EditNickName: 'Chỉnh sửa biệt danh', //Chỉnh sửa biệt danh
    AddMarket: 'Thêm thị trường', //Thêm thị trường
    AddLevel: 'Thêm Level', //Thêm Level
    EditLevel: 'Điều chỉnh Level', //Điều chỉnh Level
    DeleteLevel: 'Xóa Level', //Xóa Level
    DeleteLevelDes: 'Xác nhận xóa Level ？', //Xác nhận xóa Level ?
    DeleteLevelDes2: 'Vẫn còn tài khoản đang hoạt động không thể xóa Level ', //Vẫn còn tài khoản đang hoạt động không thể xóa Level
    DuplicateLevel: 'Sao chép cài đặt phí hoa hồng ở cấp trước', //Sao chép cài đặt phí hoa hồng ở cấp trước
    DeleteLoginDevice: 'Xóa thiết bị đăng nhập', //Xóa thiết bị đăng nhập

    DeleteMarket: 'Xóa thị trường', //Xóa thị trường
    CommissionSetting: 'Thiết lập phí hoa hồng', //Thiết lập phí hoa hồng
    AddMarketDes:
      'Thêm thị trường,  và bạn có thể ở thị trường này thực hiện các cấp và quản lý phí hoa hồng khác nhau', //Thêm thị trường,  và bạn có thể ở thị trường này thực hiện các cấp và quản lý phí hoa hồng khác nhau
    DeleteMarketDes: 'Xác nhận xóa thị trường ?', //Xác nhận xóa thị trường ?
    CanNotDeleteMarket:
      'Vẫn còn tài khoản đang hoạt động không thể xóa thị trường', //Vẫn còn tài khoản đang hoạt động không thể xóa thị trường
    DeleteConfirm: 'Xác nhận muốn xóa bỏ ?', //Xác nhận muốn xóa bỏ ?
    ConfirmWithdraw: 'Xác nhận muốn rút tiền ?', //Xác nhận muốn rút tiền ?
    Upload: 'Tải lên', //Tải lên
    Demo: 'Demo', //Demo
    //NOTIFY
    PWDone: 'Đã hoàn tất thay đổi mật khẩu', //Đã hoàn tất thay đổi mật khẩu
    DepositDone: 'Tiền nạp đã được gửi đi', //Tiền nạp đã được gửi đi
    AddDone: 'Thêm thành công', //Thêm thành công
    DepositOk: 'Nạp tiền thành công', //Nạp tiền thành công
    WithdrawalDone: 'Rút tiền thành công', //Rút tiền thành công
    ChangeGroupDone: 'Thay đổi Group thành công', //Thay đổi Group thành công
    ChangeLeverageDone: 'Thay đổi đòn bẩy giao dịch thành công', //Thay đổi đòn bẩy giao dịch thành công
    DisableAccount: 'Tài khoản vô hiệu hóa', //Tài khoản vô hiệu hóa
    EnableAccount: 'Kích hoạt tài khoản', //Kích hoạt tài khoản
    EnableAccountDes:
      'Kích hoạt tài khoản, bạn có thể sử dụng các chức năng liên quan <br> Xác nhận muốn kích hoạt ?', //Kích hoạt tài khoản, bạn có thể sử dụng các chức năng liên quan <br> Xác nhận muốn kích hoạt ?
    DisableAccountDes:
      'Hủy kích hoạt tài khoản, một số chức năng sẽ bị hạn chế <br> Xác nhận muốn vô hiệu hóa ?', //Hủy kích hoạt tài khoản, một số chức năng sẽ bị hạn chế <br> Xác nhận muốn vô hiệu hóa ?
    ChangeParentDes: 'Thay đổi cấp trên', //Thay đổi cấp trên
    enableDemo: 'Kích hoạt Demo', //Kích hoạt Demo
    cancelIB: 'Hủy bỏ quyền hạn IB', //Hủy bỏ quyền hạn IB
    ChangeClientToIB: 'Vui lòng chuyển khách hàng cho IB sau', //Vui lòng chuyển khách hàng cho IB sau
    ActionFailed: 'Thao tác thất bại', //Thao tác thất bại
    ThrottleLimit: 'Quá nhiều thao tác, vui lòng thử lại sau', //Quá nhiều thao tác, vui lòng thử lại sau
    CopyWordSuccess: 'Văn bản được sao chép thành công', //Văn bản được sao chép thành công
    CopyWordFailed: 'Văn bản sao chép thất bại', //Văn bản sao chép thất bại

    IPDes: 'Tên miền hoặc IP：aaa.com / 111.222.33.44', //Tên miền hoặc IP
    //注册 Đăng ký
    RDone: 'Đăng ký thành công', //Đăng ký thành công
    RText: 'Để bảo vệ quyền lợi, vui lòng điền thông tin chi tiết.', //Để bảo vệ quyền lợi, vui lòng điền thông tin chi tiết
    NickName: 'User register name Biệt danh', //User register name Biệt danh
    RRealName: 'Họ và tên', // Họ và tên
    Name: 'Kyc name Tên', //Kyc name Tên
    RPersonalInfo: 'Thông tin cá nhân',
    RBankInfo: 'Thông tin ngân hàng',
    RRestInfo: 'Thông tin nơi cư trú',
    RIDCode: 'Số chứng minh thư', //Số chứng minh thư
    RIDDoc: 'Tên chứng minh thư', //Tên chứng minh thư
    RAddressDoc: 'Địa chỉ', //Địa chỉ
    RBankDoc: 'Tên Ngân hàng', //Tên Ngân hàng
    RIDP: 'Mặt trước chứng minh thư', //Mặt trước chứng minh thư
    RIDN: 'Mặt sau chứng minh thư', //Mặt sau chứng minh thư
    RBankDoc1: 'Thông tin ngân hàng-1', //Thông tin ngân hàng-1
    RBankDoc2: 'Thông tin ngân hàng-2', //Thông tin ngân hàng-2
    UserDetail: 'Chi tiết người dùng', //Chi tiết người dùng
    UserInformation: 'Thông tin cá nhân', //Thông tin cá nhân

    //SETTING
    TraderAreaTimezone: 'Múi giờ sàn giao dịch', //Múi giờ sàn giao dịch
    LanguageCode: 'Mã hóa ngôn ngữ', //Mã hóa ngôn ngữ
    Increase: 'Tăng', //Tăng
    Decreasing: 'Giảm', //Giảm
    CompanyName: 'Tên công ty', //Tên công ty
    CompanyWebsite: 'Website công ty', //Website công ty
    BusinessHours: 'Thời gian làm việc', //Thời gian làm việc
    WorkingDes:
      'Để thuận tiện cho khách hàng, chúng tôi cung cấp giờ làm việc như sau', //Để thuận tiện cho khách hàng, chúng tôi cung cấp giờ làm việc như sau
    SUN: 'Chủ nhật',
    MON: 'Thứ hai',
    TUE: 'Thứ ba',
    WED: 'Thứ tư',
    THU: 'Thứ năm',
    FRI: 'Thứ sáu',
    SAT: 'Thứ bảy',
    OS: 'Hệ thống thao tác', //Hệ thống thao tác
    RIDText: 'Vui lòng cung cấp số chứng minh thư。', //Vui lòng cung cấp số chứng minh thư
    RAddressText:
      'Vui lòng cung cấp các dữ liệu liên quan có thể chứng minh dữ liệu cư trú cá nhân, chẳng hạn như hóa đơn viễn thông, điện nước, v.v. các trang chứa thông tin cá nhân và dữ liệu cư trú', //Vui lòng cung cấp các dữ liệu liên quan có thể chứng minh dữ liệu cư trú cá nhân, chẳng hạn như hóa đơn viễn thông, điện nước, v.v. các trang chứa thông tin cá nhân và dữ liệu cư trú
    RBankText:
      'Vui lòng cung cấp thông tin ngân hàng khớp với chứng minh thư của bạn, chẳng hạn như: sổ tiết kiệm ... và các tài liệu khác có chứa thông tin cá nhân', //Vui lòng cung cấp thông tin ngân hàng khớp với chứng minh thư của bạn, chẳng hạn như: sổ tiết kiệm ... và các tài liệu khác có chứa thông tin cá nhân
    RGender: 'Giới tính', //Giới tính
    RBirthday: 'Ngày sinh', //Ngày sinh
    I18GenderMale: 'Ông', //Ông
    I18GenderFemale: 'Bà', //Bà
    RGender3: 'Phi nhị giới', //Phi nhị giới
    RGender4: 'Không tiết lộ', //Không tiết lộ
    RRegion: 'Quốc gia / Khu vực', //Quốc gia / Khu vực
    RNationCode: 'Vui lòng chọn quốc gia / Khu vực', //Vui lòng chọn quốc gia / Khu vực
    RSelect: 'Vui lòng chọn', //Vui lòng chọn
    RCity: 'Thành phố', //Thành phố
    RProvince: 'Tỉnh /Thành phố', //Tỉnh /Thành phố
    RArea: 'Khu vực', //Khu vực
    RPhone: 'Số điện thoại liên lạc', //Số điện thoại liên lạc
    RAddress: 'Địa chỉ', //Địa chỉ
    Remail: 'Email', //Email
    RPassword: 'Mật khẩu', //Mật khẩu
    ROPassword: 'Mật khẩu cũ', //Mật khẩu cũ
    RNPassword: 'Mật khẩu mới', //Mật khẩu mới
    RMTPassword: 'Mật khẩu giao dịch tài khoản MT', //Mật khẩu giao dịch tài khoản MT
    RMTPasswordLimited:
      'Bao gồm chữ, số (có hoặc không ký hiệu), và không quá 15 ký tự', //Bao gồm chữ tiếng Anh và số hoặc ký hiệu, và không quá 15 ký tự )
    I18NewRMTPasswordLimited:
      'Bao gồm chữ, số (có hoặc không ký hiệu), và không quá 8-15 ký tự',
    RPassword1: 'Chữ tiếng Anh hoặc số, và không ít hơn 8 ký tự', //Chữ tiếng Anh hoặc số, và không ít hơn 8 ký tự
    RPasswordConfirm: 'Xác nhận mật khẩu', //Xác nhận mật khẩu
    RPasswordConfirm1: 'Mật khẩu của bạn không khớp', //Mật khẩu của bạn không khớp
    RPasswordReset: 'Đổi mật khẩu', //Đổi mật khẩu
    CreateAccount: 'Tạo tài khoản', //Tạo tài khoản
    AccountName: 'Trader account name Tên tài khoản', //Trader account name Tên tài khoản
    AccountPW: 'Mật khẩu', //Mật khẩu
    AccountPWA: 'Nhập lại mật khẩu', //Nhập lại mật khẩu
    MTLogin: 'Tài khoản MT', //Tài khoản MT
    OnlyNumber: 'Chỉ bao gồm số', //Chỉ bao gồm số
    OnlyNumberNoZero: 'Chỉ bao gồm số khác 0', //Chỉ bao gồm số khác 0
    NumberArea: 'Thiết lập số khu vực', //Thiết lập số khu vực
    Range: 'Phạm vi', //Phạm vi
    Minnum: 'Không nhỏ hơn đoạn số nhỏ nhất', //Không nhỏ hơn đoạn số nhỏ nhất
    Numrange: 'Vui lòng ở trong phạm vi số tối thiểu và tối đa', //Vui lòng ở trong phạm vi số tối thiểu và tối đa
    MinValue: 'Tối thiểu', //Tối thiểu
    MaxValue: 'Tối đa', //Tối đa
    NextAccountNum: 'Số tài khoản kế tiếp', //Số tài khoản kế tiếp
    NumberNow: 'Số hiện tại', //Số hiện tại
    MTPW: 'Mật khẩu MT', //Mật khẩu MT
    //后端回传Errors Lỗi
    NoLogged: 'Không đăng nhập được', //Không đăng nhập được
    AlreadyLogged: 'Tài khoản đã được đăng nhập', //Tài khoản đã được đăng nhập
    AuthServiceError: 'Lỗi dịch vụ xác thực', //Lỗi dịch vụ xác thực
    EmailIsAlreadyUsed: 'Email này đã có người sử dụng', //Email này đã có người sử dụng
    EmailDoesNotExist: 'Email không tồn tại', //Email không tồn tại
    WrongEmailOrPassword: 'Nhập sai tài khoản hoặc mật khẩu', //Nhập sai tài khoản hoặc mật khẩu
    CertificationExpired: 'Xác minh đã hết hạn', //Xác minh đã hết hạn
    ErrorFailAccount:
      'Đăng ký tài khoản không thành công, vui lòng liên hệ với nhà môi giới của bạn để xử lý', //Đăng ký tài khoản không thành công, vui lòng liên hệ với nhà môi giới của bạn để xử lý
    'Limit exceeded': 'Quá nhiều lần thử, vui lòng thử lại sau', //Quá nhiều lần thử, vui lòng thử lại sau
    'broker-trader-enable': 'Kích hoạt tài khoản giao dịch', //Kích hoạt tài khoản giao dịch
    'broker-sync-config': 'Đồng bộ hóa dữ liệu', //Đồng bộ hóa dữ liệu
    'broker-sync-trader': 'Đồng bộ hóa tài khoản giao dịch', //Đồng bộ hóa tài khoản giao dịch
    'broker-trader-setgroup': 'Group giao dịch', //Group giao dịch
    'broker-trader-leverage': 'Đòn bẩy giao dịch', //Đòn bẩy giao dịch
    CommissionIsGreaterThanThePreviousLevel:
      'Thiết lập phí hoa hồng không được lớn hơn mức trước đó', //Thiết lập phí hoa hồng không được lớn hơn mức trước đó
    CommissionIsLessThanThePreviousLevel:
      'Thiết lập phí hoa hồng không được nhỏ hơn mức tiếp theo', //Thiết lập phí hoa hồng không được nhỏ hơn mức tiếp theo
    ManagerAPIConnFailed: 'Liên kết quản lý API thất bại', //Liên kết quản lý API thất bại
    BadRequest: 'Xảy ra chút sự cố …', //Xảy ra chút sự cố …
    ImageDisabled: 'Xóa hình ảnh', //Xóa hình ảnh
    Inside: 'Bên trong', //Bên trong
    Outside: 'Bên ngoài', //Bên ngoài
    BrowserIcon: 'Bookmarks window', //Bookmarks window
    //Errors
    ErrorMail: 'Vui lòng nhập đúng định dạng email', //Vui lòng nhập đúng định dạng email
    ErrorPW:
      'Mật khẩu không được ít hơn 8 ký tự, chữ tiếng Anh hoặc số, có thể chứa các ký tự đặc biệt: + - @ _ = * $ ',
    ErrorVerify: 'Vui lòng nhập chính xác mã xác minh', //Vui lòng nhập chính xác mã xác minh
    ErrorName:
      'Vui lòng nhập đúng định dạng (có thể bao gồm chữ tiếng Anh viết hoa, viết thường, số, dấu cách, ký tự đặc biệt: -_. ·, Không quá 64 ký tự)', //Vui lòng nhập đúng định dạng (có thể bao gồm chữ tiếng Anh viết hoa, viết thường, số, dấu cách, ký tự đặc biệt: -_. ·, Không quá 64 ký tự)
    ErrorCorrect: 'Vui lòng nhập đúng định dạng', //Vui lòng nhập đúng định dạng
    ErrorLogin: 'Chỉ nhập số, không quá 32 số)', //Chỉ nhập số, không quá 32 số)
    ErrorPhone: 'Chỉ nhập số, ít nhất 7 số, không quá 15 số', //Chỉ nhập số, ít nhất 7 số, không quá 15 số
    ErrorID: 'Vui lòng nhập số ID khớp với ID bạn đã cung cấp', //Vui lòng nhập số ID khớp với ID bạn đã cung cấp
    ErrorCountryCode: 'Vui lòng chọn quốc gia và khu vực', //Vui lòng chọn quốc gia và khu vực
    ErrorEmpty: 'Không được để trống', //Không được để trống
    ErrorAccount: 'Vui lòng nhập đúng định dạng, ít nhất 8 số', //Vui lòng nhập đúng định dạng, ít nhất 8 số
    MemberTern: 'Điều khoản thành viên', //Điều khoản thành viên
    PrivacyPolicy: 'Chính sách bảo mật', //Chính sách bảo mật
    TextKnowing: 'Đã đọc và đồng ý', //Đã đọc và đồng ý
    TextKnowing2:
      'Các giao dịch CFD ngoại hối có rủi ro cao, các điều khoản và quy định được áp dụng.', //Các giao dịch CFD ngoại hối có rủi ro cao, các điều khoản và quy định được áp dụng
    VerifyPassText:
      'Xem xét thông qua, vui lòng cập nhật trực tiếp vào hồ sơ của tôi',
    SecurityBlock: 'Hiện không thể giao dịch', //Hiện không thể giao dịch。'//Xem xét thông qua, vui lòng cập nhật trực tiếp vào hồ sơ của tôi
    GroupBlock: 'Group hiện tại đã bị vô hiệu hóa', //Group hiện tại đã bị vô hiệu hóa
    ErrorReason: 'Nguyên nhân thất bại', //Nguyên nhân thất bại
    Or: 'Hoặc', //Hoặc
    None: 'Không có', //Không có
    BackLogin: 'Trở về đăng nhập', //Trở về đăng nhập
    VerifyCode: 'Mã xác minh', //Mã xác minh
    Cancel: 'Hủy', //Hủy
    ReSend: 'Gửi lại', //Gửi lại
    VerifyReceived: 'Vui lòng vào hộp thư của bạn để nhận mã xác minh', //Vui lòng vào hộp thư của bạn để nhận mã xác minh
    SendVerify: 'Gửi thư xác minh', //Gửi thư xác minh
    EmailAlready:
      'Email của bạn đã tồn tại, vui lòng đăng nhập trực tiếp hoặc đăng ký lại email khác。', //Email của bạn đã tồn tại, vui lòng đăng nhập trực tiếp hoặc đăng ký lại email khác
    EmailNotExist: 'Email của bạn không tồn tại, vui lòng nhập email khác', //Email của bạn không tồn tại, vui lòng nhập email khác
    Required: 'Phần bắt buộc', //Phần bắt buộc
    RequiredSwift: 'Vui lòng nhập đúng định dạng mã SWIFT', //Vui lòng nhập đúng định dạng mã SWIFT
    RequireIban: 'Vui lòng nhập đúng định dạng mã IBAN', //Vui lòng nhập đúng định dạng mã IBAN
    //Bank
    ReceviedBankInfo: 'Thông tin ngân hàng nhận', //Thông tin ngân hàng nhận
    GBankName: 'Tên ngân hàng', //Tên ngân hàng
    GBankAddress: 'Địa chỉ ngân hàng', //Địa chỉ ngân hàng
    GBankAccount: 'Tài khoản ngân hàng', //Tài khoản ngân hàng
    GManName: 'Tên người nhận', //Tên người nhận
    GManAccount: 'Tài khoản nhận', //Tài khoản nhận
    GManAddress: 'Địa chỉ nhận ', //Địa chỉ nhận
    GBankCode: 'Mã ngân hàng', //Mã ngân hàng
    PaymentCurrency: 'Đơn vị tiền tệ thanh toán', //Đơn vị tiền tệ thanh toán
    PaymentChannel: 'Kênh thanh toán', //Kênh thanh toán
    PaymenLimit: 'Số tiền nạp tối thiểu không được dưới 1.000 đô la', //Số tiền nạp tối thiểu không được dưới 1.000 đô la
    ChoosePayment: 'Chọn kênh thanh toán', //Chọn kênh thanh toán
    TradeCurrency: 'Tiền tệ giao dịch', //Tiền tệ giao dịch
    Mer_no: 'Số doanh nghiệp', //Số doanh nghiệp
    InputMer_no: 'Vui lòng nhập số doanh nghiệp', //Vui lòng nhập số doanh nghiệp
    Telegraphic: 'Điện báo', //Điện báo
    SettlementByDay:
      'Thanh toán hàng ngày, GMT +0 00:00 đồng bộ hóa đơn hang trong cùng ngày, GMT +0 01:00 ngày hôm sau giải quyết đơn hàng', //Thanh toán hàng ngày, GMT +0 00:00 đồng bộ hóa đơn hang trong cùng ngày, GMT +0 01:00 ngày hôm sau giải quyết đơn hàng
    SettlementByWeek:
      'Thanh toán hàng tuần, GMT +0 00:00 hàng ngày đồng bộ hóa đơn hàng, GMT +0 01:00 Chủ nhật giải quyết đơn hàng 。', //Thanh toán hàng tuần, GMT +0 00:00 hàng ngày đồng bộ hóa đơn hàng, GMT +0 01:00 Chủ nhật giải quyết đơn hàng
    SettlementByMonth:
      'Thanh toán hàng tháng, GMT +0 00:00 hàng ngày đồng bộ hóa đơn hàng, GMT +0 01:00 vào ngày đầu tiên của tháng tiếp theo giải quyết đơn hàng。', //Thanh toán hàng tháng, GMT +0 00:00 hàng ngày đồng bộ hóa đơn hàng, GMT +0 01:00 vào ngày đầu tiên của tháng tiếp theo giải quyết đơn hàng
    //注册 Đăng ký
    ErrMsg2: 'Vui lòng thử lại sau', //Vui lòng thử lại sau
    //Signal Tín hiệu
    STotalInvest: 'Số tiền đầu tư', //Số tiền đầu tư
    Balance: 'Số dư', //Tiền còn lại
    SEquity: 'Giá trị ròng', //Giá trị ròng
    Save: 'Lưu', //Lưu
    Show: 'Hiển thị', //Hiển thị
    Num: 'dòng', //Số lượng
    AddAccountDirect: 'Thêm tài khoản giao dịch mới', //Thêm tài khoản giao dịch mới
    BindMT5Account: 'Liên kết MT5', //Liên kết MT5
    SAvailableFunds: 'Số tiền có thể sử dụng', //Số tiền có thể sử dụng
    WithdrawCost: 'Số tiền đã rút', //Số tiền đã rút
    DepositCost: 'Số tiền đã nạp', //Số tiền đã nạp
    WaitWithdrawCost: 'Chờ rút tiền', //Chờ rút tiền
    ApplicationWithdraw: 'Đơn xin rút tiền', //Đơn xin rút tiền
    SFPnL: 'Lãi lỗ thả nổi', //Lãi lỗ thả nổi
    SMargin: 'Ký quỹ', //Lãi lỗ thả nổi
    MarginUsed: 'Ký quỹ đã sử dụng', //Ký quỹ đã sử dụng
    MarginCanUse: 'Ký quỹ khả dụng', //Ký quỹ khả dụng
    MarginRatio: 'Tỷ lệ ký quỹ', //Tỷ lệ ký quỹ
    SIncome: 'Lợi nhuận thu về', //Lợi nhuận thu về
    SRetreat: 'Rút', //Rút
    SAddPt: 'Thêm điểm', //Thêm điểm
    Leverage: 'Đòn bẩy giao dịch', //Đòn bẩy giao dịch
    AccountNum: 'Số lượng tài khoản', //Số lượng tài khoản
    AgentNum: 'Số lượng đại lý', // Số lượng đại lý
    Sercurity: 'Loại giao dịch', //Loại giao dịch
    MarginCall: 'Dòng cảnh báo', // Dòng cảnh báo
    MarginStopOut: 'Dừng ký quỹ', // Dừng ký quỹ
    TitleTrade: 'Loại giao dịch / Sản phẩm', //Loại giao dịch / Sản phẩm
    SCredit: 'Hạn ngạch tín dụng', //Hạn ngạch tín dụng
    Sscore: 'Điểm', //Điểm
    Default: 'Mặc định', //Mặc định
    SetDefault: 'Đặt làm Group mặc định', //Đặt làm Group mặc định
    LeverageDefault: 'Đòn bẩy mặc định', // Đòn bẩy mặc định
    ChangeLeverage: 'Điều chỉnh đòn bẩy', // Điều chỉnh đòn bẩy
    All: 'Toàn bộ', // Toàn bộ
    PnLTotal: 'Tổng lãi lỗ', // Tổng lãi lỗ
    ChooseGroup: 'Chọn Group', // Chọn Group
    ChangeGroup: 'Thay đổi Group', //Thay đổi Group
    //Order
    OnHold: 'Treo đơn', //Treo đơn
    OrderClose: 'Giá đóng lệnh', // Giá đóng lệnh
    OrderOpen: 'Giá mở lệnh', // Giá mở lệnh
    OrderCloseProcess: 'Trong quá trình xử lý', //Trong quá trình xử lý
    NoUnprocess: 'Không có đơn hàng đang chờ xử lý', //Không có đơn hàng đang chờ xử lý
    EmptyMsg1: 'Dữ liệu hiện tại', //Dữ liệu hiện tại
    EmptyInfo: 'Không có dữ liệu hiện tại trong thời điểm này', //Không có dữ liệu hiện tại trong thời điểm này
    Empty: 'Trống', //Trống
    UnitPoint: 'Điểm', //Điểm
    Add: 'Thêm', //Thêm
    Edit: ' Chỉnh sửa', //Chỉnh sửa
    PopUpCloneDes:
      'Sao chép một tài khoản và bạn có thể thực hiện các giao dịch khác nhau và phân bổ tiền trong tài khoản này', // Sao chép một tài khoản và bạn có thể thực hiện các giao dịch khác nhau và phân bổ tiền trong tài khoản này
    CopyPromoteLink: 'Sao chép link giới thiệu', //Sao chép liên kết quảng cáo
    readOnlypassword: 'Mật khẩu chỉ đọc', //Mật khẩu chỉ đọc
    BalanceReport: 'Báo cáo nạp và rút tiền', //Báo cáo gửi và rút tiền
    UserDistribution: 'Phân bố người dùng', //Phân bố người dùng
    SelectPlaceHolderChoseAccount: 'Chọn tài khoản', //Chọn tài khoản
    SelectAgent: 'Chọn đại lý', // Chọn đại lý
    AdvanceSearch: 'Tìm kiếm nâng cao', //Tìm kiếm nâng cao
    FunctionSearch: 'Chức năng tìm kiếm', //Chức năng tìm kiếm
    FunctionSearchTrader:
      'Vui lòng nhập tài khoản giao dịch, nếu có nhiều hơn một tài khoản, vui lòng sử dụng dấu phẩy để phân tách。', //Vui lòng nhập tài khoản giao dịch, nếu có nhiều hơn một tài khoản, vui lòng sử dụng dấu phẩy để phân tách
    FunctionSearchUser:
      'Vui lòng nhập tài khoản người dùng, nếu có nhiều hơn một tài khoản, vui lòng sử dụng dấu phẩy để phân tách', //Vui lòng nhập tài khoản người dùng, nếu có nhiều hơn một tài khoản, vui lòng sử dụng dấu phẩy để phân tách
    FunctionCommission: 'Vui lòng nhập mã số đơn hoa hồng', // Vui lòng nhập mã số đơn hoa hồng
    Search: 'Tìm kiếm', //Tìm kiếm

    ChoisePayment: 'Vui lòng chọn phương thức thanh toán', // Vui lòng chọn phương thức thanh toán
    PaymentMethod: 'Phương thức thanh toán', //Phương thức thanh toán
    PaymentColegantDes:
      'Chọn Colegant để thanh toán và bạn sẽ được dẫn đến trang thanh toán, vui lòng bấm vào Apply để xác nhận。', //Chọn Colegant để thanh toán và bạn sẽ được dẫn đến trang thanh toán, vui lòng bấm vào Apply để xác nhận
    PaymentDes1: 'Chọn',
    PaymentDes2:
      'để thanh toán và bạn sẽ được dẫn đến trang thanh toán, vui lòng bấm vào Apply để xác nhận。',
    LastFiveNum: '5 số cuối tài khoản', // 5 số cuối tài khoản
    //No Data
    // REPORT
    Subscribe: 'Đăng ký', // Đăng ký
    UnSubscribe: 'Hủy đăng ký', // Hủy đăng ký
    UnSubscribeDes: ' Đăng ký của bạn đã được hủy bỏ。', // Đăng ký của bạn đã được hủy bỏ
    TradeNoAccess: 'Tài khoản không thể giao dịch', //Tài khoản không thể giao dịch
    UserNoAccess: 'Không thể dử dụng', //Không thể dử dụng
    TSymbol: 'Loại sản phẩm', // Loại sản phẩm
    TOrderNo: 'Mã số đơn hàng', //Mã số đơn hàng
    TType: 'Phân loại', //Loại
    TSummary: 'Tóm lược', //Tóm lược
    TLots: 'Lot', // Lot
    TTrade: 'Tổng số Lot', //Lượng giao dịch
    TPrice: 'Giá giao dịch', // Giá giao dịch
    TSecurity: 'Loại giao dịch', // Loại giao dịch
    TOpenDate: 'Thời gian mở lệnh', //Thời gian mở lệnh
    TOpenArea: 'Thời gian khu vực hoặc thời gian mở cửa', //Thời gian khu vực hoặc thời gian mở cửa
    TCloseArea: 'Thời gian khu vực hoặc thời gian đóng cửa', //Thời gian khu vực hoặc thời gian đóng cửa
    OpenPrice: 'Giá mở cửa', //Giá mở cửa
    TCloseDate: 'Thời gian đóng lệnh', //Giá đóng cửa
    CurrentPrice: 'Giá thị trường', // Giá thị trường
    ClosePrice: 'Giá đóng cửa', //Giá đóng cửa
    OrderSyncTime: 'Đồng bộ hóa thời gian đơn hàng', //Đồng bộ hóa thời gian đơn hàng
    TStopLoss: 'Dừng lỗ', //Dừng lỗ
    TStopWin: 'Chốt lời', //Chốt lời
    ProfitLoss: 'Lợi nhuận và thua lỗ', //Lợi nhuận và thua lỗ
    Storage: 'Phí qua đêm', //Lãi
    Fees: 'Phí giao dịch', //Phí giao dịch
    TProfit: 'Lợi nhuận', // Lợi nhuận
    TradeLoss: 'Lỗ', // Lỗ
    GMTWord: 'Giờ là giờ GMT gốc', // Giờ là giờ GMT gốc
    //Record
    BackPoint: 'Phí hoa hồng', //Phí hoa hồng
    SettingPC: 'Thiết lập thống nhất về phí hoa hồng', //Thiết lập thống nhất về phí hoa hồng
    Commission: 'Phí hoa hồng', //Phí hoa hồng
    Commission2: 'Phí hoa hồng', // Phí hoa hồng
    CommissionReport: 'Báo cáo phí hoa hồng', // Báo cáo phí hoa hồng
    CommissionOrder: 'Lệnh hoa hồng', //Lệnh hoa hồng
    MarketNone: 'Hiện tại không có thị trường', //Hiện tại không có thị trường
    CommissionSend: 'Uỷ ban',
    SettlementTime: 'Thời gian kết toán', //Thời gian kết toán
    IntervalTime: 'Thời gian khu vực và thời gian kết toán', //Thời gian khu vực và thời gian kết toán
    CommissionType: 'Loại hoa hồng', // Loại hoa hồng
    CommissionRange: 'Phạm vi hoa hồng', //Phạm vi hoa hồng
    NormalCommission: 'Hoa hồng căn bản', // Hoa hồng căn bản
    SameCommission: 'Hoa hồng cùng cấp', // Hoa hồng ngang hàng
    OverCommission: 'Hoa hồng vượt mức', // Hoa hồng vượt mức

    //Application
    Application: 'Đơn', // Đơn
    ApplicationMan: 'Người nộp đơn', //Người nộp đơn
    ApplicationName: 'Tên người nộp đơn', // Tên người nộp đơn
    ApplicationAccount: 'Tài khoản người nộp đơn', //Tài khoản người nộp đơn
    ApplicationEmail: 'Email người nộp đơn', // Email người nộp đơn
    ApplicationOrder: 'Mã đơn', //Mã đơn
    ApplicationType: 'Loại đơn', //Loại đơn
    ApplicationPhone: 'Số điện thoại người nộp đơn', //Số điện thoại người nộp đơn

    BuyQuty: 'Buy', //Lượng mua vào
    SellQuty: 'Sell',
    PnL: 'Lãi lỗ',
    PnLArea: 'Phạm vi lãi lỗ',
    ProfitShare: ' Chia sẻ lợi nhuận',
    FundOut: 'Chuyển ra',
    FundIn: 'Chuyển vào',
    SelectMarkets: 'Chọn đại lý',
    SelectLevel: 'Chọn Level',
    SetLevel: 'Chỉnh sửa Level',

    SelectLevelDes:
      'Chỉ thay đổi Level trong cùng một thị trường, vui lòng chọn thị trường trước。',
    SelectCharacter: 'Sàng lọc vai trò chức năng',
    SelectAccountStatus: 'Lọc trạng thái tài khoản',
    TotalCommission: 'Tổng phí hoa hồng',
    CommissionAccounting: 'Kế toán hoa hồng',
    getCommission: 'Hoa hồng nhận được',
    SelectAgentDes: 'Đại lý truy vấn thu nhận phí hoa hồng',
    CreateAccounRecord: 'Lịch sử mở tài khoản trong vòng 7 ngày',
    LastSettlementCommission: 'Kết toán phí hoa hồng lần trước',
    TraderVolum7: 'Lượng giao dịch trong vòng 7 ngày',
    Day7: 'Trong  7 ngày',
    PnL7: 'Lãi lỗ trong 7 ngày',
    HotSymbol: 'các sản phẩm giao dịch phổ biến',
    Credit: 'Tín dụng',
    TotalWithdrawal: 'Tổng rút ra',
    Withdrawal: 'Rút tiền',
    WithdrawalCost: 'Số tiền có thể rút',
    Deposit: 'Lịch sử Nạp tiền',
    TotalDeposit: ' Tổng nạp',
    WithdrawalMin: 'Số tiền rút tổi thiểu',
    Costmin: 'Số tiền không được thấp hơn',
    DepositNum: 'Số lần nạp tiền',
    WithdrawalNum: 'Số lần rút tiền',
    //交易组别 Group giao dịch
    AllGroup: 'Tất cả group',
    MTGroup: 'Group giao dịch',
    DefaultGroup: 'Group giao dịch mặc định',
    MTGroupSetting: 'Thiết lập Group giao dịch',
    GroupIn: 'Thuộc Group giao dịch',
    Enabled: 'Kích hoạt',
    Disable: 'Ngừng sử dụng',
    IsIBANSWIFT: 'Có điền mã SWIFT / mã IBAN không ?',
    //资金配置纪录
    FundArrange: 'Phân bổ vốn',
    SelectCostRange: 'Sàng lọc phạm vi số tiền',
    SelectCostRangeDesMax:
      'Chỉ nhập giá trị lớn nhất, sau đó tìm kiếm phía dưới với giá trị lớn nhất',
    SelectCostRangeDesMin:
      'Chỉ nhập giá trị nhỏ nhất, sau đó tìm kiếm phía trên với giá trị nhỏ nhất',
    CostRange: 'Phạm vi số tiền',
    Arragne: 'Phân bố',
    ArragneTo: 'Phân bổ tới',
    EnterFundCost: 'Vui lòng nhập số tiền phân bổ',
    FunArrangeRecord: 'Lịch sử biến động vốn',
    FundArrangeMin: 'Phân bổ vốn không được thấp hơn',
    TradeHistory: 'Lịch sử giao dịch',
    ChoseFrom: 'Chọn từ',
    Wallet: 'Ví tiền',
    Type: 'Cách thức',
    Time: 'Thời gian',
    StartTime: 'Thời gian bắt đầu',
    EndTime: 'Thời gian kết thúc',
    Start: 'Bắt đầu',
    End: 'Kết thúc',
    Cost: 'Số tiền',
    AccountRecord: 'Lịch sử nạp rút tiền',
    Function: 'Chức năng',
    Summary: 'Tóm lược',
    KYCStatus: 'Chứng nhận KYC',
    Action: 'Hoạt động',
    Comment: 'Bình luận',
    Account: 'Tài khoản',

    //市场 Thị trường
    MarketCommission: 'Thị trường hoa hồng',
    MarketCommissionSetting: 'Thiết lập thị trường hoa hồng',
    Market: 'Thị trường',
    EditMarket: 'Chỉnh sửa thị trường',
    Agent: 'Đại lý',
    AgentList: 'Quản lý đại lý',
    IsAgent: 'Có phải là đại lý không?',
    NotAgent: 'Không phải đại lý',
    MarketLevel: 'Level thị trường',
    MarketName: 'Tên thị trường',
    MarketCost: 'Nạp rút trên thị trường',
    Level: 'Cấp độ',
    Detail: 'Chi tiết',
    ChannelName: 'Tên kênh',

    //User List
    TraderName: 'Tên tài khoản',
    AccountStatus: 'Trạng thái tài khoản',
    Register: 'Đăng ký',
    RegisterEnabled: 'Đăng ký được kích hoạt',
    RegisterDisabled: 'Không thể đăng ký',
    RegisterDate: 'Thời gian đăng ký',
    AuditDate: 'Thời gian xem xét',
    ApplicationDate: 'Thời gian nộp đơn',
    ApplicationDateInterval: 'Thời gian khu vực và thời gian nộp đơn',
    HandleDate: 'Thời gian xử lý',
    HandleDateInterval: 'Thời gian khu vực và thời gian xử lý',
    CompletedDate: 'Thời gian hoàn thành',
    ChoseGMTTime: 'Chọn thời gian GMT',
    DepositOrder: 'Số đơn nạp tiền',
    Completed: 'Thành công',
    WithdrawalApplication: 'Rút tiền',
    StopAccountDes:
      'Vui lòng liên hệ với nhà cung cấp dịch vụ của bạn trong quá trình ngừng sử dụng tài khoản',
    WithdrawalOrder: 'Số đơn rút tiền',
    AllCharacters: 'Tất cả vai trò',
    Characters: 'Vai trò',
    Status: 'Trạng thái',
    ThisWeek: 'Giao dịch trong 7 ngày',
    ThisMonth: 'Giao dịch trong 30 ngày',
    //交易数据 Số liệu giao dịch
    TradeStatus: 'Trạng thái giao dịch gần đây',
    TradeData: 'Số liệu giao dịch',
    TradeEva: 'Đánh giá giao dịch',
    TDPL: 'Tổng lãi lỗ',
    TDLots: 'Tổng số lot',
    TDInterest: 'Tổng lãi',
    TDHandingFee: 'Tổng phí dịch vụ',
    //NOTIFY Thông báo
    NewApplication: 'Đơn mới',
    DepositSuccess: 'Nạp tiền thành công',
    Approved: 'Đã được phê duyệt',
    WithdrawnSuccess: 'Rút tiền thành công',
    Reject: 'Đã từ chối',
    Reject2: 'Từ chối',
    RejectApplication: 'Từ chối đơn',
    Void: 'Trống',
    Check: 'Đã kiểm tra',
    Grant: 'Đã cấp',
    RejectReason: 'Nguyên nhân từ chối',
    Notify: 'Thông báo',
    ViewAllNotify: 'Xóa thông báo',
    Clean: 'Xóa',
    EnabledAgent: 'Kích hoạt ủy quyền',
    ChangeParent: 'Thay đổi cấp trên',
    // ORGANIZATION Tổ chức
    Organization: 'Tổ chức',
    People: 'Số người',
    OrganizationPeople: 'Số người của tổ chức',
    TotalUser: 'Tổng số người dùng',
    OrganizationStructure: 'Kết cấu tổ chức',
    BrokerAgent: 'Đại lý nền tảng',
    BrokerUser: 'Người dùng nền tảng',
    AgentAgent: 'Đại lý của các đại lý',
    Agentuser: 'Người dùng đại lý',
    DirectUser: 'Người dùng trực tiếp',
    NormalAgent: 'Đại lý',
    OverAgent: 'Đại lý cấp cao',
    SameAgent: 'Đại lý cùng cấp',
    Referrer: 'Người giới thiệu',
    OrganizationDes:
      'Sắp xếp theo cấp độ thị trường, nếu không phải đại lý để dưới cùng',
    //--
    SameBonus: 'Thưởng cùng cấp',
    OverBonus: 'Thưởng cấp cao ',
    //UNIT Đơn vị
    UnitMan: 'Người',
    UnitStuff: 'Đơn vị',
    SyncConfig: 'Đồng bộ tin nhắn',
    SyncOrderhistory: 'Đồng bộ lịch sử đơn hàng',
    TraderLeverage: 'Điều chỉnh đòn bẩy',
    TraderSetGroup: 'Thay đổi nhóm',
    WalletRequestDepositApprove: 'Thông báo nạp tiền',
    WalletRequestWithdrawWithdraw: 'Đã rút tiền',
    WalletRequestDepositReject: 'Nạp tiền không thông qua',
    WalletRequestWithdrawReject: 'Rút tiền thất bại',
    UserEnable: 'Tài khoản của bạn đã được kích hoạt',
    UserDisable: 'Tài khoản của bạn đã bị vô hiệu hóa',
    UserPasswordUpdate: 'Mật khẩu của bạn đã  được thay đổi',
    AgentAdd: 'Bạn đã trở thành đại lý',
    AgentRemove: 'Ủy quyền đã bị xóa',

    BrokerNotFound: 'Không tìm thấy người môi giới',
    WrongManagerAPI: 'Sai thông tin API',
    MT4Error: 'Lỗi MT',
    ServiceError: 'Đã xảy ra sự cố với dịch vụ, vui lòng thử lại sau',
    TraderIsNotExists: 'Tài khoản giao dịch không tồn tại',
    //交易账号相关Liên quan tới tài khoản giao dịch
    TraderAccount: 'Tài khoản giao dịch',
    TraderAccountManage: 'Quản lý tài khoản giao dịch',
    SyncTrader: 'Đồng bộ tài khoản giao dịch',
    TraderEnable: 'Kích hoạt tài khoản giao dịch',
    TraderDisable: 'Vô hiệu hóa tài khoản giao dịch',
    TraderAccountAdd: 'Thêm tài khoản giao dịch',
    TraderCreateFailed: 'Thêm tài khoản giao dịch thất bại',
    TraderAccountAddText:
      'Thêm tài khoản giao dịch,Và bạn có thể thực hiện các giao dịch khác nhau và phân bổ vốn trong tài khoản này',
    TraderAccountNone: 'Hiện tại không có tài khoản giao dịch',
    TraderCreate: 'Thêm tài khoản giao dịch',
    TraderBind: 'Liên kết tài khoản giao dịch',
    TraderBindFailed: 'Liên kết tài khoản giao dịch thất bại',
    TraderDetail: 'Chi tiết tài khoản giao dịch',
    TraderNotFound: 'Tài khoản giao dịch không tồn tại',
    ModifyAccountPassword: 'Sửa đổi mật khẩu tài khoản này',
    // Personal Setting Thiết lập cá nhân
    About: 'Thông tin khác',
    Personal: 'Cá nhân',
    PersonSetting: 'Thiết lập cá nhân',
    CompanySetting: 'Thiết lập công ty',
    BankSetting: 'Thiết lập ngân hàng',
    WorkingDateSetting: 'Thiết lập thời gian công việc',
    UserOpenAccountSetting: 'Thiết lập mở tài khoản người dùng',
    PaymentSetting: 'Cài đặt thanh toán',
    AuditSetting: 'Kiểm tra cài đặt',
    AgentRuleSetting: 'Thiết lập quy tắc đại lý',
    Logout: 'Đăng xuất',

    // ChangeTradePW
    TraderPWModify: 'Sửa đổi mật khẩu của tài khoản giao dịch này',
    TraderPWMain: 'Thay đổi mật khẩu chính',
    TraderPWRead: 'Thay đổi mật khẩu chỉ đọc',
    UserNotFound: 'Người dùng không tồn tại',
    TransferFailed: 'Phân bổ vốn thất bại',
    Loading: 'Loading …',
    WrongPassword: 'Sai mật khẩu',
    SomethingWrong: 'Xảy ra chút vấn đề …',
    TryLatter: 'Vui lòng thử lại sau',

    Success: 'Thành công',
    AddFailed: 'Thêm thất bại',
    SaveDone: 'Lưu thành công',
    SaveFailed: 'Lưu thất bạiLưu thất bại',
    Failed: 'Thất bại',
    AsyncSend: 'Đã gửi đơn yêu cầu',
    ProcessRequest: 'Đơn của bạn đã được gửi và sẽ được chuyên gia xử lý',
    //Broker audit
    AuditKyc: 'Kiểm tra KYC',
    AuditWithdraw: 'Xem xét rút tiền',
    AuditDeposit: 'Nạp tiền',
    AuditReject: 'Xem xét không thông qua',
    AuditKycReject: 'Kiểm tra KYC không được thông qua',
    AuditPass: 'Xác nhận rằng bài đánh giá đã thông qua?',
    AuditRejectRes:
      'Vui lòng điền lý do không thành công để giúp người dùng cải thiện và cập nhật dữ liệu',
    AuditAllow: 'Xem xét thông qua',
    NextStep: 'Bước tiếp theo',
    PrevStep: 'Bước trước đó',
    LastUpdate: 'Cập nhật gần đây',
    Least7Day: '7 ngày gần đây',
    Allow: 'Thông qua',
    NotAllow: 'Không thông qua',
    AuditKycAllow: 'Thông qua kiểm tra KYC',
    Retry: 'Vui lòng thử lại',
    Reason: 'Nguyên nhân',
    Download: 'Tải xuống',
    To: 'Đến',
    // Broker-使用者管理 Quản lý người dùng
    KYCFAILEDDes:
      'Đơn đăng ký của bạn đã bị từ chối, có thể do dữ liệu không chính xác hoặc không được điền rõ ràng, vui lòng gửi lại đơn đăng ký dữ liệu',
    KYCUNSUBMITTEDButtonText: 'Xác thực dữ liệu KYC',
    KYCSUBMITTEDButtonText: 'Trong quá trình kiểm tra KYC ...',
    KYCFAILEDButtonText: 'KYC đăng ký lại',
    KycStatusSubmitted: 'Đợi xem xét',
    KycStatusPassed: 'Xem xét thông qua',
    KycStatusFailed: 'Xem xét không thông qua',

    IDCardFrontIsARequiredField: 'Ảnh chứng minh thư mặt trước là bắt buộc',
    IDCardFrontExceeds600kB:
      'Kích thước tệp của mặt trước chứng minh thư phải nhỏ hơn 600kb',
    IDCardFBackIsARequiredField: 'Ảnh chứng minh thư mặt sau là bắt buộc',
    IDCardBackExceeds600kB:
      'Kích thước tệp của mặt sau chứng minh thư phải nhỏ hơn 600kb',
    AddressFileIsARequiredField: 'Tài liệu xác minh địa chỉ là bắt buộc',
    AddressFileExceeds600kB:
      'Kích thước tệp của tài liệu xác minh địa chỉ phải nhỏ hơn 600kb',
    BankFrontFileIsARequiredField:
      'Tài liệu xác minh mặt trước thẻ ngân hàng là bắt buộc',
    BankFrontFileExceeds600kB:
      'Kích thước tệp của tài liệu xác minh mặt trước thẻ ngân hàng phải nhỏ hơn 600kb',
    BankBackFileIsARequiredField:
      'Tài liệu xác minh mặt sau thẻ ngân hàng là bắt buộc',
    BankBackFileExceeds600kB:
      'Kích thước tệp của tài liệu xác minh mặt sau thẻ ngân hàng phải nhỏ hơn 600kb',
    // 20201027 END

    ApplicationFailed: 'Yêu cầu thất bại',
    ParentAgent: 'Thuộc cấp trên',
    YesReadInfo: 'Phải, kiểm tra dữ liệu',
    MarketIn: 'Thuộc thị trường',
    IdCardNumber: 'Số chứng minh thư',
    ReceviedBankName: 'Tên ngân hàng nhận tiền',
    ReceviedBankAddress: 'Địa chỉ ngân hàng nhận tiền',
    ChangeMarket: 'Thay đổi thị trường',
    ChangeMarketErrorMsg1:
      'Có những người dùng cấp trên dưới không thể thay đổi thị trường',
    // 20201028 END
    ReadTraderAccount: 'Kiểm tra tài khoản giao dịch',
    // 20201116 END
    UserInfo: 'Thông tin người dùng',
    AddrInfo: 'Thông tin địa chỉ',
    BankInfo: 'Thông tin ngân hàng',
    MarketGroupMap: 'Biểu đồ thị trường và nhóm',
    IncludeGroup: 'Bao gồm Group',
    SetDefault1: 'Thiết lập mặc định',
    LevelName: 'Tên Level',
    // 20201119 END
    OrderAdvanceSearchMsg1:
      'Sẽ có sự khác biệt tùy thuộc vào nội dung dịch vụ mà máy chủ cung cấp',
    // 20201123 END
    BindOtherAccount: 'Liên kết tài khoản khác',
    Server: 'Thiết bị phục vụ',
    // 20201124 END
    Broker_Deposit_Applying: 'Đơn mới',
    User_Deposit_Applying: 'Trong quá trình thanh toán',
    Broker_Deposit_Reviewing: 'Đã thanh toán',
    User_Deposit_Reviewing: 'Trong quá trình xem xét',
    Broker_Deposit_rejected: 'Từ chối',
    User_Deposit_rejected: 'Yêu cầu thất bại',
    Broker_Deposit_Approved: 'Nạp tiền thành công',
    User_Deposit_Approved: 'Nạp tiền thành công',

    Broker_Withdraw_Applying: 'Đơn mới',
    User_Withdraw_Applying: 'Đợi xem xét',
    Broker_Withdraw_Approved: 'Đã xem xét',
    User_Withdraw_Approved: 'Trong quá trình xem xét',
    Broker_Withdraw_rejected: 'Trống',
    User_Withdraw_rejected: 'Yêu cầu thất bại',
    Broker_Withdraw_Withdraw: 'Đã rút tiền',
    User_Withdraw_Withdraw: 'Đã rút tiền',
    // 20201127 END
    DepositIn: 'Tiền gửi ròng',
    AgentInfo: 'Thông tin đại lý',
    // 20201201 END
    DepositSetting: 'Thiết lập nạp tiền',
    WithdrawalSetting: 'Thiết lập rút tiền',
    DepostieWallet: 'Ví điện tử',
    // 20201207 END
    ApplyAmount: 'Yêu cầu số tiền',
    PaymentDetail: 'Chi tiết thanh toán',
    DesignationRate: 'Chỉ định tỷ giá',
    PaymentAmount: 'Thanh toán số tiền',
    OrderNumber: 'Số đơn',
    ApplyerEmail: 'Email người nộp đơn',
    CallApiErrorMsg: 'Vui lòng thử lại hoặc thao tác sau',
    Deleted: 'Đã xóa',
    DeleteEWallet: 'Xóa ví điện tử',
    DeleteEWalletMsg: 'Chắc chắn muốn xóa ví điện tử ?',
    WalletName: 'Tên ví',
    EWalletEnableMsg: 'Vui lòng kích hoạt ít nhất một ví',
    MinAmount: 'Số tiền tối thiểu',
    MaxAmount: 'Số tiền tối đa',
    Channel: 'Kênh',
    DeleteChannelMsg: 'Xác nhận muốn xóa kênh thanh toán ?',
    CurrencyEnableMsg: 'Vui lòng kích hoạt ít nhất một loại tiền tệ giao dịch',
    DeleteChannel: 'Xóa kênh thanh toán',
    Warning: 'Nhắc nhở',
    WalletAddress: 'Địa chỉ ví',
    EnterWalletAddressMsg: 'Vui lòng nhập địa chỉ ví',
    EnterWalletNameMsg: 'Vui lòng nhập tên ví',
    DeleteCurrencyMsg: 'Chắc chắn muốn xóa đơn vị tiền tệ giao dịch ?',
    DeleteCurrency: 'Xóa đơn vị tiền tệ giao dịch',
    EnterChannelNameMsg: 'Vui lòng nhập tên kênh',
    EnterAmountRangeMsg: 'Vui lòng nhập chính xác phạm vi số tiền',
    EnterCurrencyNameMsg:
      'Vui lòng nhập chính xác mã tiền tệ (3 chữ hoa tiếng Anh)',
    EnterRateMsg: 'Hỗ trợ tới 6 chữ số thập phân',
    EnterRateMsg8: 'Hỗ trợ tới 8 chữ số thập phân',
    MinAmountEqualUSDMsg: 'Số tiền tổi thiểu tương đương với USD',
    MaxAmountEqualUSDMsg: 'Số tiền tối đa tương đương với USD',
    EnterCurrencyMsg: 'Vui lòng nhập đơn vị tiền tệ',
    EnterRateMsg2: 'Vui lòng nhập tỷ giá',
    Created: 'Đã tạo mới',
    Edited: 'Đã sửa đổi',
    EnterTokenMsg: 'Vui lòng nhập Token',
    TransferInfo: 'Thông tin tỷ giá',
    BankAccountName: 'Tên tài khoản ngân hàng',
    Rate: 'Tỷ giá',
    TransferInAmount: 'Nạp tiền sau khi chuyển đổi',
    MinAmountMsg: 'Số tiền không thể nhỏ hơn',
    MaxAmountMsg: 'Số tiền không thể cao hơn',
    TransferOutAmount: 'Rút tiền sau khi chuyển đổi',
    NoEnableCurrencyAmount:
      'Không có loại tiền tệ có thể sử dùng, vui lòng liên hệ với nhà môi giới của bạn',
    AccountInfo: 'Thông tin tài khoản',
    ReceiveMan: 'Người nhận tiền',
    ApplySent: 'Đơn đã gửi đi',
    // 20201214 END
    WrongDataMsg: 'Vui lòng nhập dữ liệu chính xác',
    EnterBankNameMsg: 'Vui lòng nhập tên ngân hàng',
    EnterBankCodeMsg: 'Vui lòng nhập mã ngân hàng',
    EnterBankAccountNameMsg: 'Vui lòng nhập tên tài khoản ngân hàng của bạn',
    EnterBankAccountMsg: 'Vui lòng nhập tài khoản ngân hàng',
    EnterBankAddressMsg: 'Vui lòng nhập địa chỉ ngân hàng',
    EnterRateMsg3: 'Tỷ giá hối đoái phải lớn hơn 0',
    // 20201215 END
    IDCardFrontExceeds:
      'Kích thước tệp ảnh mặt trước chứng minh thư phải nhỏ hơn 10MB',
    IDCardBackExceeds:
      'Kích thước tệp mặt sau chứng minh thư phải nhỏ hơn 10MB',
    AddressFileExceeds:
      'Kích thước tệp tài liệu xác minh địa chỉ phải nhỏ hơn 10MB',
    BankFrontFileExceeds:
      'Kích thước tệp tài liệu xác minh mặt trước thẻ ngân hàng phải nhỏ hơn 10MB',
    BankBackFileExceeds:
      'Kích thước tệp tài liệu xác minh mặt sau thẻ ngân hàng phải nhỏ hơn 10MB',
    // 20201216 END
    UserIsDisabled: 'Tài khoản chưa kích hoạt',
    // 20201222 END
    YourEWalletAddr: 'Địa chỉ ví điện tử của bạn',
    Copy: 'Sao chép',
    UserEWalletAddr: 'Địa chỉ ví điện tử của khách hàng',
    // 20201228 END
    AllMarket: 'Toàn bộ thị trường',
    // 20201229 END
    ActivePeople: 'Số người hoạt động',
    TotalPeople: 'Tổng số người',
    PlatformFilter: 'Sàng lọc thiết bị',
    Mobile: 'Thiết bị di động',
    Tablet: 'Máy tính bảng',
    Desktop: 'Máy tính để bàn',
    AccountAndName: 'Tài khoản, họ tên thật',
    ShowAllDataMsg: 'Hiển thị toàn bộ tài liệu hiện tại',
    MemberTernDesc: `{brokerName} Rất hân hạnh khi được cung cấp thông tin công cụ, sản phẩm và dịch vụ cho bạn. Các điều khoản và điều kiện này cũng bao gồm các tiết lộ và thông tin quan trọng về các sản phẩm và dịch vụ nhất định. Bạn cần phải đồng ý là đã đọc, hiểu và đồng ý tất cả các điều khoản và điều kiện cũng như các thông báo và tuyên bố pháp lý khác được đăng trên trang web này.

		Các điều khoản và điều kiện này có thể được sửa đổi bất cứ lúc nào mà không cần thông báo trước.Bạn sẽ không được thông báo về bất kỳ thay đổi hoặc sửa đổi nào khác.

		Các điều khoản và điều kiện này bổ sung cho các thỏa thuận khác (bao gồm mọi thỏa thuận khách hàng ,tài khoản hoặc bất kỳ thỏa thuận nào khác hạn chế việc bạn sử dụng thông tin, nội dung, công cụ, sản phẩm và dịch vụ được cung cấp thông qua trang web). Trừ khi bạn có thỏa thuận bằng văn bản, trang web này chỉ dành cho mục đích sử dụng cá nhân và phi thương mại. Thông tin trên trang web này không dành cho những đối tượng sau: Bất kỳ ai đã vi phạm luật hoặc quy định địa phương ở quốc gia khác hoặc nơi sinh sống của họ. Các dịch vụ hoặc khoản đầu tư được mô tả trên trang web sẽ không cung cấp cho những người sau: những người cung cấp dịch vụ, đầu tư vi phạm luật hoặc quy định địa phương tại quốc gia cư trú của họ.

		Khách truy cập trang web này có trách nhiệm xác nhận đang tuân thủ các điều khoản hoặc quy định, luật pháp địa phương .Nội dung trên trang web không được coi là lời chào mời mua bất kỳ sản phẩm hoặc dịch vụ nào hoặc lời đề nghị bán bất kỳ sản phẩm hoặc dịch vụ nào cho những người sau: đề nghị, gạ gẫm mua bán bất hợp pháp theo luật của nước sở tại.
	`,
    PrivacyPolicyDesc: `{brokerName} Tôn trọng quyền riêng tư cá nhân và coi trọng mối quan hệ với khách hàng. Áp dụng các biện pháp bảo mật nghiêm ngặt tất cả dữ liệu cá nhân khách hàng và đảm bảo những quyền lợi mà khách hàng có được. Chính sách này áp dụng cho khách hàng sắp trở thành khách hàng, khách hàng hiện tại và tương lai.

		1. Theo “Đạo luật tài trợ chống rửa tiền và chống khủng bố năm 2006” (Anti-Money Wash and Counter-Terrorism Finance 2006), chúng tôi có trách nhiệm thu thập thông tin và xác minh danh tính thực sự của khách hàng. Các thủ tục xác minh và nhận dạng khách hàng sẽ được thực hiện theo Đạo luật Bảo mật của New Zealand (Privacy Act 1993).

		2. Khi khách hàng nộp đơn đăng ký mở tài khoản thực hoặc tài khoản Demo, quý khách cần cung cấp thông tin cá nhân, xác nhận và đồng ý với các điều khoản chính sách có liên quan và chứng minh rằng bạn hoàn toàn tự nguyện. Khi thông tin cá nhân được thu thập bởi bên thứ ba (không phải chính khách hàng), các bước xác minh sẽ được thực hiện để đảm bảo rằng khách hàng nhận thức được thông tin của bản thân đang được cung cấp.

		3. Tất cả hồ sơ giao dịch và hoạt động của tài khoản khách hàng đều được lưu giữ, bao gồm (nhưng không giới hạn) thông tin đóng tài khoản khách hàng. Dữ liệu khách hàng sẽ được thu thập từ các nguồn dữ liệu công khai có sẵn (chẳng hạn như sổ đăng ký công ty). Dữ liệu cá nhân của khách hàng sẽ được lưu giữ nghiêm ngặt theo các nguyên tắc của Đạo luật Quyền riêng tư năm 1993 của New Zealand. Khách hàng có thể yêu cầu quyền truy cập thông tin bất cứ lúc nào. Và có thể ghi âm lại cuộc nói chuyện điện thoại giữa khách hàng và nhân viên. Các tài liệu ghi âm có thể được sử dụng để giải quyết mọi tranh chấp giữa khách hàng và thuộc quyền hợp pháp của các cơ quan quản lý và các tổ chức chính phủ khác. Khi khách hàng điền vào đơn đăng ký trực tuyến hoặc các loại biểu mẫu khác, thông qua trang web bộ phận tiếp nhận thông tin cũng có thể thu thập và nắm giữ thông tin về khách hàng.

		4. Nếu liên quan đến thông tin nhạy cảm, việc giải quyết khiếu nại không hợp lý, quý khách hàng có thể khiếu nại với Ủy viên bảo mật đề xem xét về khiếu nại của bạn.

		5. Khách hàng có thể gửi email để yêu cầu cung cấp thông tin cá nhân mà họ nắm giữ. Yêu cầu của khách hàng thường được xử lý trong vòng 14 ngày. Nếu yêu cầu của khách hàng tương đối phức tạp, trong trường hợp này có thể cần thêm thời gian và khách hàng sẽ nhận được thông báo bằng văn bản. Việc yêu cầu cung cấp thông tin cá nhân sẽ không tính phí nhưng khách hàng có thể bị tính một khoản phí hợp lý để xử lý yêu cầu của khách hàng. Trong một số trường hợp, luật pháp cho phép khách hàng bị từ chối truy cập. Nếu có lý do để nghi ngờ rằng một hoạt động bất hợp pháp nghiêm trọng hoặc hành vi sai trái đang được thực hiện hoặc có thể được thực hiện và các chức năng hoặc hoạt động có liên quan, nếu yêu cầu có thể gây tổn hại đến bất kỳ lợi ích hợp pháp nào, yêu cầu có thể bị từ chối. Trong trường hợp này, khách hàng sẽ được cung cấp lý do từ chối (trừ khi lý do được cho là không phù hợp hoặc không hợp lý), các vấn đề quy định khác và cơ chế khiếu nại hiện có sẽ được cung cấp. Nếu khả thi, nó có thể thực hiện các bước hợp lý và đưa ra yêu cầu bồi thường nếu nó đáp ứng nhu cầu của khách hàng.

		6. hách hàng phải thông báo ngay bằng văn bản khi có bất kỳ thay đổi nào về thông tin cá nhân của mình. Điều này sẽ đảm bảo rằng thông tin cá nhân của khách hàng được cập nhật và nhận được thông báo về tình trạng của tài khoản. Khách hàng cần ký quỹ cho các hoạt động giao dịch của mình. Và có thể yêu cầu thay đổi thông tin cá nhân mà khách hàng cho là không chính xác bất cứ lúc nào . Nếu có bất đồng với khách hàng về tính chính xác của thông tin liên quan đến việc sửa đổi hoặc chỉnh sửa thông tin hoặc yêu cầu chính sách bảo vệ quyền riêng tư có liên quan và thông tin thực tế, khách hàng có thể yêu cầu tuyên bố về thông tin, nêu rõ rằng khách hàng xem xét thông tin không chính xác hoặc không đầy đủ. Khách hàng sẽ được thông báo bằng văn bản về việc từ chối chỉnh sửa thông tin cá nhân, nêu rõ lý do từ chối (trừ khi việc cung cấp lý do từ chối được cho là không phù hợp), cung cấp cơ chế khiếu nại cho việc từ chối và các vấn đề liên quan khác.

		7. Nếu thông tin được xác nhận là không chính xác, lỗi thời, không đầy đủ hoặc không liên quan hoặc gây hiểu nhầm, tất cả các bước hợp lý sẽ được thực hiện để chỉnh sửa thông tin cá nhân để đảm bảo rằng thông tin đó chính xác, cập nhật, đầy đủ, phù hợp và không gây hiểu nhầm.

		8. Tất cả các bước hợp lý sẽ được thực hiện để tránh sử dụng không đúng cách, mất mát, sử dụng trái phép, can thiệp, thay đổi hoặc tiết lộ dữ liệu cá nhân của khách hàng.
	`,
    // 20201230 END
    Dot: '.',
    // 20210217
    LoginDone1: 'Fully integrated transaction management system, including',
    LoginDone2: 'Multi-account visualized management',
    LoginDone3: 'Instant deposit and withdrawal',
    LoginDone4: 'Clear and concise fund record and transaction statements',
    LoginDone5:
      'Trading FX, metals, and CFDs is risky. We encourage you carefully consider your investment objectives. ',
    EWalletType: 'Types of e-Wallet',
    AcceptEWalletType: 'Types of acceptable e-wallets by the broker',
    EWalletSubmitMsg: `Please confirm the information you have filled in is correct before hitting the
			“Application” button. The broker will not be responsible for any losses associate with
			the incorrect inputs. `,
    // 20210719
    CryptocurrencyType: 'Type of cryptocurrency',
    CryptocurrencyTypeMsg: 'Cryptocurrency can only contain 3-10 characters',
    ProtocolsLabel:
      'Available Protocols (separate different protocols with semicolon ”;”)',
    ProtocolsMsg:
      'Each protocol name can only contain 3-10 characters including numbers',
    FormatMsg: 'Please confirm the format is correct',
    Decimal2Msg: 'Allow 2 decimal places',
    Protocol: 'Blockchain Protocol',
    // 20210720

    //20210817
    I18CreateUserAccountPrompt:
      'Account created successfully. Please email account information to the user.',
    I18CreateUserAccount: 'Create Account',
    // 20210819
    I18EnterEmailPleace: 'Email',
    I18AssignedAgent: 'Assigned Agent',
    I18SelectMarket: 'Select Market',
    I18SelectAgent: 'Select Agent',
    I18SelectNoAgent: 'No Agent',
    I18AgentChange: 'Modify Agent',
    I18SelectAgentTier: 'Select Tier',
    I18PromptTierCouldBeDifferent:
      'Default tier could be different with the tier in the original market.',
    I18ErrorGroupNotOpenInMarket:
      'The account {{TraderAccount.id}} of {{User.name}} was assigned to Group {{Group.name}} which is not available in the {{market.name}}.',
    I18PromptAgentChangeMarketOnlyWithLevels:
      'Agents are only allowed to change to market with multi-tiers.',
    I18NotHaveAgent: 'No agent.',
    // 20210924
    I18ClearCache: 'Clear cache',
    I18PromptClearCacheAfterUpdateManager:
      'To see your changes after updating the manager permissions on MT4/MT5, you may need to refresh by clearing your cache.',

    // 20211006
    I18FurtherInformation: 'Further information',
    I18LimitHalfwidthText: 'Limited to {limit} characters',
    I18ExceedTextLimit: 'Exceed the input limit',
    I18UpdateTimeLatest: 'Update Time',

    I18TraderAccountLimit: 'Maximum Trading Account',
    I18TraderAccountLimitExclude: 'Exclude existing accounts',
    I18TraderAccontLimitRange: 'Set a limit from {min} - {max}',

    // 20211122
    I18CustomField: 'Table on Demand',
    I18SelectAll: 'Select All',

    I18StorageNew: 'Phí qua đêm',

    // 20211130
    I18OrderPosition: 'Position',
    I18OrderHistory: 'History',
    I18SetupPrice: 'Setup Price',
    I18OrderDoneTime: 'Done Time',
    I18OrderDoneTimeOrRange: 'Done Time',
    I18TradeTime: 'Time',
    I18FinalPrice: 'Price',
    // 20211209
    I18BankCodeEmptyCheck: 'SWIFT/IBAN code is unfilled, still save?',
    // 20211221
    I18DepositComfirmed: 'Deposit comfirmed',
    I18BrokerDepositDoubleCheckText:
      'Please confirm the deposit {currencyName} {amount} to {userName}?',
    I18BrokerDepositDepositTempText:
      'Please complete and approve the withdrawal application in the CRM system and make sure the amount first before actually transferring money to the client.',
    I18UserKycIsRequiredBeforeDeposit: 'Please complete user KYC first',
    I18WithdrawalRequestComfirmed: 'Withdrawal request comfirmed',
    I18AssetArrangementComfirmed: 'Asset arrangement completed',
    I18BrokerAssetArrangementToTraderDoubleCheckText:
      'Please confirm the asset arrangement {currencyName} {amount} to {traderId}?',
    I18BrokerWithdrawalRequestDoubleCheckText:
      'Please confirm the withdrawal {currencyName} {amount} for {userName}?',
    I18DepositByBroker: 'Deposit by broker',
    I18BrokerAssetArrangementToUserAccountDoubleCheckText:
      'Please confirm the user account {currencyName} {amount} to {accountId}?',
    I18AssetAmount: 'Amount',
    I18RequestByUserSelf: 'Request by client',
    I18RequestByBroker: 'Request by broker',
    I18TraderInformation: 'Trader Account Info.',
    I18ViewTraderInfoPermission:
      'Permission for agent to see trader account information',
    I18nUploadImageCorrupted: 'The uploaded image file is corrupted',
  },

  // 日文
  'ja-jp': {
    Broker: 'Broker',
    Provider: 'Provider',
    Trader: 'Trader',
    Signal: 'Signal',
    YES: 'はい',
    NO: 'いええ',
    //Menu Word
    Review: 'レビュー',
    AgentApplication: '代理の申請',
    AgentManage: '代理の管理',
    Client: 'お客様',
    FollowerManage: 'お客様の管理',
    FollowerList: 'お客様のリスト',
    Order: 'オーダー',
    OrderHistory: '過去の取引記録',
    AccountDetail: 'アカウント情報',
    AccountHistory: 'アカウント記録',
    CreatAccount: 'クリエートアカウント',
    FundHistory: '資本金記録',
    ReadInfo: 'レビューインフォメーション',
    // SIDE MENU
    Dashboard: 'ダッシュボード',
    Setting: '設定',
    UserManagement: 'ユーザーの管理',
    Audit: '審査',
    MarketGroup: 'マーケットグループ',
    Management: '管理',
    Report: 'レポート',

    // Word
    More: 'もっと',
    LoginSetting: 'ログインの設定',
    LoginDevice: 'ログインの設備',
    LoginDate: 'ログインの時間',
    Position: '位置',
    BrokerSetting: 'プラットフォーム設定',
    SystemSetting: 'システム設定',
    SettingDes1: '強化した',
    SettingDes2:
      'トレードのセキュリティ、お客様のアカウントに安全性を提供します．情報の漏洩が懸念される場合はご連絡ください。',
    FundManage: '資本金の管理',
    User: 'ユーザー',
    UserManage: 'ユーザーの管理',
    UserAccount: 'ユーザーアカウント',
    // Search placeholder
    PlaceholderUser: 'ユーザーアカウント名称、メール',
    PlaceholderTrader: 'アカウント名称, ユーザー名',
    PlaceholderAgent: 'ユーザーアカウント名称',
    PlaceholderOrderQuery: '取引アカウント オーダー番号 品種',
    NotYetBindUser: '未接続',
    UserName: 'ユーザー名',
    AgentName: '代理名', //等同使用者名稱
    KYC: 'KYC資料資料申請',
    PerLot: '毎ロット',
    PerDeal: '每ディール',
    //Login
    Login: 'ログイン',
    LoginMt: 'MTログイン',
    LastLogin: '最近ログイン',
    LoginNormal: 'ようこそ、アカウントをご入力ください',
    LoginMtText: 'MTアカウントとパスワードをご入力ください',
    TextBuildAccount:
      '先にメールをご記入ください．検証コードを取得し、お客様の保護のため、正確にご記入してください.',
    Password: 'あなたのパスワード',
    YourEmail: 'あなたのメール',
    ForgotPassword: 'パスワードをお忘れですか？',
    TextForgotPassword:
      'CRMで登録したメールをご記入ください。パスワード再設定のため、検証コードを改めて送りいたします。',
    AccountManage: 'アカウントの管理',
    OrderQuery: 'オーダー検索',
    OrderStatus: 'オーダー状態',
    // LoginDone1: '最高のフォローアップサービスと豊富で強力な機能を提供します。',
    // LoginDone2: 'クオリティ高いフィードの購読とフォローアップオーダー',
    // LoginDone3: '出金入金便利スピーディ',
    // LoginDone4: 'マルチシグナル管理ポートフォリオ',
    // LoginDone5: '投資にはリスクが伴いますので、ご注意ください。',

    //Button
    Send: '送る',
    Confirm: '決定する',
    AlreadyConfirm: '確認しました',
    Done: '完成',
    Delete: '削除',
    EditName: '編集お名前',
    EditNickName: '編集ニックネーム',
    AddMarket: '新しいマーケティング',
    AddLevel: '新しいレベル',
    EditLevel: 'レベル変更',
    DeleteLevel: 'レベル削除',
    DeleteLevelDes: 'レベルのご削除をよろしいでしょうか？',
    DeleteLevelDes2:
      'ユーザーが存在しております、レベルを削除することができません。',
    DuplicateLevel: 'アッパーレベルの手数料の設置をコピーします',
    DeleteLoginDevice: 'ログインデバイスの削除',

    DeleteMarket: 'マーケティング削除',
    CommissionSetting: '手数料の設定',
    AddMarketDes:
      '新しマーケティングには、それぞれのレベルと手数料の管理ができます。',
    DeleteMarketDes: 'マーケティングの削除をよろしでしょうか？',
    CanNotDeleteMarket:
      'ユーザーが存在しております、マーケティングを削除することができません。',
    DeleteConfirm: '削除をしますか？',
    ConfirmWithdraw: '出金しますか？',
    Upload: 'アップロード',
    Demo: 'デモ倉庫',
    //NOTIFY
    PWDone: 'パスワードの変更を完成しました',
    DepositDone: '入金を送りました',
    DepositOk: '入金完了',
    WithdrawalDone: '出金完了',
    ChangeGroupDone: 'グループ変更完成',
    ChangeLeverageDone: 'トレードレバー変更完成',
    DisableAccount: 'アカウントの無効化',
    EnableAccount: 'アカウントの有効化',
    EnableAccountDes: 'カウントの有効化、関連する機能<br>を有効にしますか？',
    DisableAccountDes:
      'アカウントの無効化のため、部分機能が制限されます。<br>を無効にしますか？',
    ChangeParentDes: '上位の変更',
    enableDemo: 'デモ倉庫の有効化',
    cancelIB: '代理の権限を無効します',
    ChangeClientToIB: 'お客様を以下の代理に移してください',
    ActionFailed: '操作失敗',
    ThrottleLimit:
      '操作が回数多すぎるため、しばらく待ってからお試ししてください',
    CopyWordSuccess: '文字コピー成功',
    CopyWordFailed: '文字コピー失敗',

    IPDes: '域名或IP，例：aaa.com / 111.222.33.44',
    //註冊
    RDone: '登録完成',
    RText: 'お客様の権利保護のため、ご丁寧にご記入ください。',
    NickName: 'ニックネーム', //User register name
    RRealName: 'お名前', // KYC name
    Name: '苗字', //Kyc name
    RPersonalInfo: 'お客様情報',
    RBankInfo: '銀行情報',
    RRestInfo: '住所情報',
    RIDCode: '身分証明書番号',
    RIDDoc: '身分証明書書類',
    RAddressDoc: '住所の証明書類',
    RBankDoc: '銀行の証明書類',
    RIDP: '身分証明書の表',
    RIDN: '身分証明書の裏',
    RBankDoc1: '銀行ドキュメント-1',
    RBankDoc2: '銀行ドキュメント-2',
    UserDetail: 'ユーザーディテール',
    UserInformation: '個人情報',

    //SETTING
    TraderAreaTimezone: 'トレーダーエリアのタイムゾーン',
    LanguageCode: '言語コード',
    Increase: '昇順',
    Decreasing: '降順',
    CompanyName: '会社名',
    CompanyWebsite: '会社ウェブサイト',
    BusinessHours: '営業時間',
    WorkingDes: '営業時間を下記にご覧ください',
    SUN: '日曜日',
    MON: '月曜日',
    TUE: '火曜日',
    WED: '水曜日',
    THU: '木曜日',
    FRI: '金曜日',
    SAT: '土曜日',
    OS: 'オペレーティングシステム',
    RIDText: '身分証明書の資料をご提供ください。',
    RAddressText:
      '個人の居住地情報を証明する関連書類をご提出してください。例えば：電気代 水道代　電話代など請求書...個人情報と住所がある書類のページ。',
    RBankText:
      '身分証明書と同じな銀行資料をご提供ください。例えば：通帳など...個人情報がある書類。',
    RGender: '性別',
    RBirthday: '誕生日',
    I18GenderMale: '男性',
    I18GenderFemale: '女性',
    RGender3: 'その他',
    RGender4: '回答しない',
    RRegion: '国/地域',
    RNationCode: '国/地域を選んでください',
    RSelect: '選んでください',
    RCity: '市/県',
    RProvince: '',
    RArea: '区',
    RPhone: '電話',
    RAddress: '住所',
    Remail: 'メールアドレス',
    RPassword: 'パスワード',
    ROPassword: '元のパスワード',
    RNPassword: '新しパスワード',
    RMTPassword: 'MTトレード元のアカウントとパスワード',
    RMTPasswordLimited: '英語と数字または記号を含み、15キャラクター以下',
    I18NewRMTPasswordLimited: '英語と数字または記号を含み、8-15キャラクター',
    RPassword1: '英語または数字で、8キャラクター以上',
    RPasswordConfirm: 'パスワードの確認',
    RPasswordConfirm1: 'あなたのパスワードが一致ではありません',
    RPasswordReset: 'パスワード変更',
    CreateAccount: 'クリエートアカウント',
    AccountName: 'アカウント名称', //Trader account name
    AccountPW: 'パスワード',
    AccountPWA: 'パスワードもう一度ご入力ください',
    MTLogin: 'MT アカウント',
    OnlyNumber: '数字のみ',
    OnlyNumberNoZero: '数字のみでゼロにすることはできません',
    NumberArea: 'ナンバーゾーン',
    Range: '範囲',
    Minnum: '一番小さいナンバーゾーンより小さくすることができません',
    Numrange: 'ナンバーゾーンの最小値と最大値の範囲内',
    MinValue: '最小値',
    MaxValue: '最大値',
    NextAccountNum: '次のアカウント番号',
    NumberNow: '現在のナンバーゾーン',
    MTPW: 'MT パスワード',
    //後端回傳Errors
    NoLogged: 'まだログインしていません',
    AlreadyLogged: 'ログインしています',
    AuthServiceError: '検証サービスエラー',
    EmailIsAlreadyUsed: 'このメールアドレス既に存在します',
    EmailDoesNotExist: 'このメールアドレスを存在してません',
    WrongEmailOrPassword: 'アカウントやパスワードの入力ミス',
    CertificationExpired: '検証コード期限切れ',
    ErrorFailAccount: 'アカウントの申請に失敗、ブローカーにご連絡ください',
    'Limit exceeded': 'お試し回数が多すぎで，後ほどお試しください。',
    'broker-trader-enable': 'トレードアカウントの有効化',
    'broker-sync-config': 'データの同期化',
    'broker-sync-trader': 'トレードアカウントの同期化',
    'broker-trader-setgroup': 'トレードグループ',
    'broker-trader-leverage': 'トレードレバー',
    CommissionIsGreaterThanThePreviousLevel:
      '手数料の設定はアッパーレベルより高いことができません',
    CommissionIsLessThanThePreviousLevel:
      '手数料の設定はローワーレベルより低いことができません',
    ManagerAPIConnFailed: 'Manager API 接続失敗',
    BadRequest: '問題ハプニング...',
    ImageDisabled: '画像削除',
    Inside: '内部',
    Outside: '外部',
    BrowserIcon: 'ブラウザアイコン',
    //Errors
    ErrorMail: '正しいメールフォーマットでご入力ください',
    ErrorPW:
      'パスワードは8キャラクター以上のアルファベットまたは数字で、特殊文字を含むことができます：+ - @ _ = * $',
    ErrorVerify: '正しい検証検証コードをご入力ください',
    ErrorName:
      '正しいメールフォーマットでご入力ください(英語の大文字、小文字、数字、スペース、特殊文字： - _ . -を含むことができます。最大64キャラクターまで)',
    ErrorCorrect: '正しいメールフォーマットでご入力ください',
    ErrorLogin: '数字のみ、最大32キャラクター',
    ErrorPhone: '数字のみ，最小7キャラクター、最大15キャラクター',
    ErrorID: 'ご提供いただいた書類と一致するID番号をご入力してください。',
    ErrorCountryCode: '国と地域を選択してください',
    ErrorEmpty: '必ずご入力ください',
    ErrorAccount:
      '正しいアカウントフォーマットでご入力ください，最小8キャラクター',
    MemberTern: '会員規約',
    PrivacyPolicy: 'プライバシー保護方針',
    TextKnowing: 'すでに知っている、受け入れています',
    TextKnowing2:
      '外国為替の差金決済取引には高いリスクが伴います 規約と条例の適用',
    VerifyPassText: '承認されました、私のプロフィールに直接更新してください。',
    SecurityBlock: '現在、取引はできません',
    GroupBlock: '現在サービスを停止しているグループ',
    ErrorReason: '失敗の理由',
    Or: 'や',
    None: 'なし',
    BackLogin: 'ログインに戻ります',
    VerifyCode: '検証コードの確認',
    Cancel: 'キャンセル',
    ReSend: '再送',
    VerifyReceived: 'メールから認証コードを受け取ってください',
    SendVerify: '検証コードを送りいたします',
    EmailAlready:
      'あなたのEメールアドレスは既に存在しています。直接ログインするか、新しいEメールアドレスを申請してください。',
    EmailNotExist:
      'あなたのEメールは存在しません。別のEメールをお試しください。。',
    Required: '必ずご入力ください',
    RequiredSwift: '正しいSWIFTコードフォーマットをご入力してください。',
    RequireIban: '正しいIBANコード形式をご入力してください',
    //Bank
    ReceviedBankInfo: '入金銀行資料',
    GBankName: '銀行名',
    GBankAddress: '銀行住所',
    GBankAccount: '銀行口座',
    GManName: '受取人のお名前',
    GManAccount: '受取人の口座',
    GManAddress: '受取人の住所',
    GBankCode: '銀行コード',
    PaymentCurrency: '支払い通貨',
    PaymentChannel: '支払いチャンネル',
    PaymenLimit: '最低入金額は1,000ドルを下回ってはなりません',
    ChoosePayment: 'チャンネルを選んでください',
    TradeCurrency: '取り引き通貨',
    Mer_no: '商戶號',
    InputMer_no: '請輸入商戶號',
    Telegraphic: '振込',
    SettlementByDay:
      '日結，毎日GMT +0 00:00にオーター同期，翌日GMT +0 01:00にオーター決算。',
    SettlementByWeek:
      '週結，每日GMT +0 00:00オーター同期，日曜日GMT +0 01:00にオーター決算。',
    SettlementByMonth:
      '月結，每日GMT +0 00:00オーター同期，次の月の第一日GMT +0 01:00オーター決算。',
    //註冊
    ErrMsg2: '後ほどお試しください',
    //Signal
    STotalInvest: '投資資本金',
    Balance: '残高',
    SEquity: '純額',
    Save: '保存',
    Show: '表示',
    Num: '個',
    AddAccountDirect: '新しいトレードアカウントを追加します',
    BindMT4Account: 'バインドMT4',
    SAvailableFunds: '使える資本金',
    WithdrawCost: '出金した金額',
    DepositCost: '入金した金額',
    WaitWithdrawCost: '未出金金額',
    ApplicationWithdraw: '出金金額の申請',
    SFPnL: '変動損益',
    SMargin: '保証金',
    MarginUsed: '使った保証金',
    MarginCanUse: '使える保証金',
    MarginRatio: '保証金比率',
    SIncome: '所得',
    SRetreat: 'リトレースメント',
    SAddPt: '少し追加',
    Leverage: 'トレードレバー',
    AccountNum: 'アカウント数',
    AgentNum: '代理数',
    Sercurity: 'トレードタイプ',
    MarginCall: '警告ライン',
    MarginStopOut: '強制フラットライン',
    TitleTrade: 'トレードタイプ / 品種',
    SCredit: 'クレジット',
    Sscore: '評価',
    Default: 'プリセット',
    SetDefault: 'プリセットグループにします',
    LeverageDefault: 'プリセットレバー',
    ChangeLeverage: 'レバー調整',
    All: '全て',
    PnLTotal: '総損益',
    ChooseGroup: 'グループを選んでください',
    ChangeGroup: 'グループの変更',
    //Order
    OnHold: '保留中のオーダー',
    OrderClose: 'クローズポジション',
    OrderOpen: 'クローズポジションになってません',
    OrderCloseProcess: '処理中',
    NoUnprocess: '処理することがありません！',
    EmptyMsg1: '現在データー',
    EmptyInfo: '現在データーなし',
    Empty: '空',
    UnitPoint: '点',
    Add: '追加',
    Edit: '変更',
    PopUpCloneDes:
      'アカウントをコピーし、このアカウントで異なる取引と資金配分を行うことができます',
    CopyPromoteLink: 'プロモーションリンクをコピーします',
    readOnlypassword: 'パスワードのみを読む',
    BalanceReport: '入出金レポート',
    UserDistribution: 'ユーザー分布',
    SelectPlaceHolderChoseAccount: 'アカウント選択',
    SelectAgent: '代理選択',
    AdvanceSearch: '高度な検索',
    FunctionSearch: '機能検索',
    FunctionSearchTrader:
      'トレードアカウントをご入力してください、複数ある場合はコンマで区切ってください。',
    FunctionSearchUser:
      'ユーザーアカウントをご入力ください、複数ある場合はコンマで区切ってください。',
    FunctionCommission: '手数料番号をご入力ください',
    Search: '検索',

    ChoisePayment: 'お支払い方法を選んでください',
    PaymentMethod: 'お支払い方法',
    PaymentSetting: 'お支払い設定',
    PaymentColegantDes:
      'Colegantでお支払う場合は支払いページに移動します、確認するには申請をクリックしてください。',
    PaymentDes1: '選択',
    PaymentDes2:
      'お支払う場合は支払いページに移動します、確認するには申請をクリックしてください。',
    LastFiveNum: 'アカウントの下5桁',
    //No Data
    // REPORT
    Subscribe: '購読',
    UnSubscribe: '購読キャンセル',
    UnSubscribeDes: '購読をキャンセルしました。',
    TradeNoAccess: 'アカウントを取り引きできません',
    UserNoAccess: '利用不可',
    TSymbol: '品種',
    TOrderNo: 'オーダー番号',
    TType: 'タイプ',
    TSummary: '概要',
    TLots: 'ロット数',
    TTrade: '取り引き数',
    TPrice: '取り引き価格',
    TSecurity: '取り引き種類',
    TOpenDate: '開倉時間',
    TOpenArea: '開倉時間或區間',
    TCloseArea: 'クローズポジションの時間や間隔',
    OpenPrice: 'テイクポジション　プライス',
    TCloseDate: 'クローズポジションの時間',
    CurrentPrice: '市場プライス',
    ClosePrice: 'クローズポジション　プライス',
    OrderSyncTime: 'ポジションの同期時間は',
    TStopLoss: 'ストップロス',
    TStopWin: 'ストップウィン',
    ProfitLoss: '損益',
    Storage: '利子',
    Fees: '手数料',
    TProfit: '利益',
    TradeLoss: '損失',
    GMTWord: '時間はこのパソコンのGMT時間',
    //Record
    BackPoint: 'バックポイント',
    SettingPC: '返點返佣統一設置バックポイントと手数料の返金を統一設定します',
    Commission: '手数料',
    Commission2: '手数料の返金',
    CommissionReport: '手数料の返金レポート',
    CommissionOrder: '手数料番号',
    MarketNone: '現在、市場はありません',
    CommissionSend: '手数料の配布',
    SettlementTime: '決済時間',
    IntervalTime: '決済時間や間隔',
    CommissionType: '手数料タイプ',
    CommissionRange: '手数料範囲',
    NormalCommission: '一般手数料',
    SameCommission: '同じレベルボーナス',
    OverCommission: 'レベルを超えるボーナス',

    //Application
    Application: '申請',
    ApplicationMan: '申請者',
    ApplicationName: '申請者のお名前',
    ApplicationAccount: '申請者のアカウント',
    ApplicationEmail: '申請者のメール',
    ApplicationOrder: '申請ナンバー',
    ApplicationType: '申請タイプ',
    ApplicationPhone: '申請者の電話',

    BuyQuty: 'バイイン量',
    SellQuty: 'セル量',
    PnL: '損益',
    PnLArea: '損益範囲',
    ProfitShare: '利益分配',
    FundOut: 'ターンアウト',
    FundIn: 'ターンイン',
    SelectMarkets: 'マーケットの選択',
    SelectLevel: 'レベルの選択',
    SetLevel: 'レベルの変更',

    SelectLevelDes:
      '同じ市場のレベル変化のみ、最初に市場をフィルタリングしてください。。',
    SelectCharacter: '機能的役割をフィルタリングしてください',
    SelectAccountStatus: 'アカウント状態をフィルタリングしてください',
    TotalCommission: '総手数料',
    CommissionAccounting: '手数料計算',
    getCommission: '受取る手数料',
    SelectAgentDes: '手数料を受取る代理会社の検索',
    CreateAccounRecord: '七日以内の口座開設記録',
    LastSettlementCommission: '前回決済手数料',
    TraderVolum7: '七日以内の出来高量',
    Day7: '七日以内',
    PnL7: '七日以内損益',
    HotSymbol: '話題の商品',
    Credit: 'クレジット',
    TotalWithdrawal: '総出金',
    Withdrawal: '出金',
    WithdrawalCost: '出金できる金額',
    Deposit: '入金',
    TotalDeposit: '総入金',
    WithdrawalMin: '出金最低金額',
    Costmin: 'を下回ってはなりません',
    DepositNum: '入金数',
    WithdrawalNum: '出金数',
    //交易組別
    AllGroup: '全グループ',
    MTGroup: 'トレードグループ',
    DefaultGroup: 'デフォルトのトレーディンググループ',
    MTGroupSetting: 'トレーディンググループの設定',
    GroupIn: 'グループに所属',
    Enabled: '有効にする',
    Disable: '無効にする',
    IsIBANSWIFT: 'SWIFT / IBAN codeをご入力しましたか',
    //資金配置紀錄
    FundArrange: '資本金配置',
    SelectCostRange: '金額範囲をフィルタリングします',
    SelectCostRangeDesMax: '最大値のみを入力し、最大値で絞り込んでいく検索',
    SelectCostRangeDesMin: '最小値のみを入力し、最小値で絞り込んでいく検索',
    CostRange: '金額範囲',
    Arragne: '配置',
    ArragneTo: 'まで',
    EnterFundCost: '配置金額をご入金ください',
    FunArrangeRecord: '資本金の変動の記録',
    FundArrangeMin: '配置金額は下回ってはなりません',
    TradeHistory: '過去の取引記録',
    ChoseFrom: 'から',
    Wallet: 'メインウォレット',
    Type: 'タイプ',
    Time: '時間',
    StartTime: '開始時間',
    EndTime: '終了時間',
    Start: '開始',
    End: '終了',
    Cost: '金額',
    AccountRecord: '入出金の金額',
    Function: '機能',
    Summary: '摘要',
    KYCStatus: 'KYCの認証',
    Action: '動作',
    Comment: '備考',
    Account: 'アカウント',

    //市場
    MarketCommission: '市場手数料',
    MarketCommissionSetting: '市場手数料の設定',
    Market: '市場',
    EditMarket: '市場の編集',
    Agent: '代理の会社',
    AgentList: '代理の会社の管理',
    IsAgent: '代理の会社でしょうか',
    NotAgent: '代理ではありません',
    MarketLevel: '市場レベル',
    MarketName: '市場名称',
    MarketCost: '市場入出金',
    Level: 'レベル',
    Detail: '詳細',
    ChannelName: 'チャンネル名称',

    //User List
    TraderName: 'アカウント名称',
    AccountStatus: 'アカウント状態',
    Register: '登録',
    RegisterEnabled: 'オープン登録',
    RegisterDisabled: '登録不可',
    RegisterDate: '登録時間',
    AuditDate: '審査送る時間',
    ApplicationDate: '申請時間',
    ApplicationDateInterval: '申請時間や間隔',
    HandleDate: '処理時間',
    HandleDateInterval: '処理時間や間隔',
    CompletedDate: '完成時間',
    ChoseGMTTime: 'GMT時間選択',
    DepositOrder: '入金申請ナンバー',
    Completed: '完成',
    WithdrawalApplication: '出金の申請',
    StopAccountDes:
      'アカウントが無効になっている場合は、ご利用のサービス会社にお問い合わせください。',
    WithdrawalOrder: '出金申請ナンバー',
    AllCharacters: '全役割',
    Characters: '役割',
    Status: '状態',
    ThisWeek: '七日間以内の取引',
    ThisMonth: '三十日間以内の取引',
    //交易數據
    TradeStatus: '最近の取引',
    TradeData: '取引データー',
    TradeEva: '取引評価',
    TDPL: '総損益',
    TDLots: '総ロット数',
    TDInterest: '総利子',
    TDHandingFee: '総手数料',
    //NOTIFY
    NewApplication: '新しい申請',
    DepositSuccess: '入金成功',
    Approved: '承認済み',
    WithdrawnSuccess: '出金成功',
    Reject: '拒否されました',
    Reject2: '拒否',
    RejectApplication: '拒否申請',
    Void: '無効',
    Check: 'チェック済み',
    Grant: '配布済み',
    RejectReason: '拒否の理由',
    Notify: 'お知らせ',
    ViewAllNotify: 'お知らせクリア',
    Clean: 'クリア',
    EnabledAgent: '代理の権限を有効します',
    ChangeParent: '上位変更',
    // ORGANIZATION
    Organization: '組織',
    People: '人数',
    OrganizationPeople: '組織人数',
    TotalUser: '総ユーザー数',
    OrganizationStructure: '組織構造',
    BrokerAgent: 'ブローカーの代理',
    BrokerUser: 'ブローカーの直接のお客様',
    AgentAgent: '代理会社の代理',
    Agentuser: '代理会社の直接のお客様',
    DirectUser: '直接のお客様',
    NormalAgent: '代理',
    OverAgent: 'レベルを超える代理',
    SameAgent: '同じレベル代理',
    Referrer: 'リファラー',
    OrganizationDes: '順位は市場レベルから、代理していない順番は一番下',
    //--
    SameBonus: '同じレベルボーナス',
    OverBonus: 'レベルを超えるボーナス',
    //UNIT
    UnitMan: '人',
    UnitStuff: '個',
    SyncConfig: 'データー同期化',
    SyncOrderhistory: '過去オーダー同期化',
    TraderLeverage: 'レバー調整',
    TraderSetGroup: 'グループ変更',
    WalletRequestDepositApprove: '入金許可',
    WalletRequestWithdrawWithdraw: '出金しました',
    WalletRequestDepositReject: '入金は許可されてません',
    WalletRequestWithdrawReject: '出金失敗',
    UserEnable: 'あなたのアカウントは有効になりました',
    UserDisable: 'あなたのアカウントは無効になりました',
    UserPasswordUpdate: 'あなたのパスワードは変更されました',
    AgentAdd: '代理になりました',
    AgentRemove: '代理権限は削除されました',

    BrokerNotFound: 'Broker not found',
    WrongManagerAPI: 'Wrong manger API',
    MT4Error: 'MT error',
    ServiceError:
      'サービスに問題がありますので、しばらく待ってから再度お試しください。',
    TraderIsNotExists: 'トレードアカウントが存在しません',
    //交易帳號相關
    TraderAccount: 'トレードアカウント',
    TraderAccountManage: 'トレードアカウントの管理',
    SyncTrader: 'トレードアカウント同期化',
    TraderEnable: 'トレードアカウント有効化',
    TraderDisable: 'トレードアカウント無効化',
    TraderAccountAdd: '新規トレードアカウント開設',

    TraderCreateFailed: '新規トレードアカウント開設に失敗',
    TraderAccountAddText:
      '新規トレードアカウント、このアカウントで取引と資本金配分を行うことができます。',
    TraderAccountNone: '現在トレードアカウントがありません',
    TraderCreate: '新規トレードアカウント開設',

    TraderBind: 'バインドトレードアカウント',
    TraderBindFailed: 'トレードアカウントのバインドに失敗',
    TraderDetail: 'トレードアカウントの詳細',
    TraderNotFound: 'トレードアカウントが存在しません',
    ModifyAccountPassword: 'このアカウントのパスワードを変更する',
    // Personal Setting
    About: 'について',
    Personal: '個人',
    PersonSetting: '個人の設定',
    CompanySetting: '会社の設定',
    BankSetting: '銀行の設定',
    WorkingDateSetting: '作業時間の設定',
    UserOpenAccountSetting: 'ユーザー新規開設の設定',
    AuditSetting: '審査の設定',
    AgentRuleSetting: '代理会社ルールの設定',
    Logout: 'ログアウト',

    // ChangeTradePW
    TraderPWModify: 'このトレードアカウントのパスワードを変更する',
    TraderPWMain: 'マスターパスワードの変更',
    TraderPWRead: 'パスワード変更のみを読み取ります',
    UserNotFound: 'ユーザーが存在しません',
    TransferFailed: '資本金の配分に失敗しました',
    Loading: 'お待ちください ...',
    WrongPassword: 'パスワードエラー',
    SomethingWrong: '問題が発生しました...',
    TryLatter: 'しばらく待ってからもう一度試してください',

    Success: '成功',
    AddDone: '追加成功',
    AddFailed: '追加失敗',
    SaveDone: '保存成功',
    SaveFailed: '保存失敗',
    Failed: '失敗',
    AsyncSend: 'リクエストを出しました',
    ProcessRequest: 'あなたの申請は提出され、担当者が手配しています。',
    //Broker audit
    AuditKyc: 'KYC審査',
    AuditWithdraw: '出金の審査',
    AuditDeposit: '入金の申請',
    AuditReject: '審査は拒否されました',
    AuditKycReject: 'KYC審査は拒否されました',
    AuditPass: '審査は承認しましたか',
    AuditRejectRes: 'ユーザーの改善と更新に役立てる理由をご記入してください。',
    AuditAllow: '審査承認',
    NextStep: '戻り',
    PrevStep: '次',
    LastUpdate: '最近更新',
    Least7Day: '最近七日',
    Allow: '合格',
    NotAllow: '不合格',
    AuditKycAllow: 'KYC審査に承認',
    Retry: 'リトライしてください',
    Reason: '理由',
    Download: 'ダウンロード',
    To: 'まで',
    // Broker-使用者管理
    ParentAccount: '上位',
    NoParent: 'なし',
    Direct: '直属',
    Direct2: '直属でしょうか',
    // Button
    Operation: '操作',
    OperationDone: '操作完成',
    //KYC return
    KYCUNSUBMITTEDTitle: '未完成KYC',
    KYCUNSUBMITTED: '審査は出されてません',
    KYCSUBMITTEDTitle: '審査中',
    KYCAllowTitle: '審査合格',

    KYCAllow: 'KYC審査は合格しました',
    KYCReject: 'KYC審査は不合格しました',
    KYCUNSUBMITTEDDes:
      'KYC認証に合格すると、アカウント管理機能を使って入出金することができます。',
    KYCSUBMITTEDDes: 'KYC認証後にトレードアカウントを追加することができます。',
    KYCFAILEDDes:
      '申請が却下されました。情報が正しくないか、明確に記入されていない可能性があります。情報申請を再送信してください。',
    KYCUNSUBMITTEDButtonText: 'KYC資料の認証',
    KYCSUBMITTEDButtonText: 'KYC審査中...',
    KYCFAILEDButtonText: 'KYC再申請',
    KycStatusSubmitted: '審査待ち',
    KycStatusPassed: '審査合格',
    KycStatusFailed: '審査不合格',

    IDCardFrontIsARequiredField: '身分証明書の表の写真が必要',
    IDCardFrontExceeds600kB:
      '身分証明書の表画像のファイルサイズは必ず600kb以下。',
    IDCardFBackIsARequiredField: '身分証明書の裏の写真が必要',
    IDCardBackExceeds600kB: '身分証明書の裏画像のファイルサイズは必ず600kb以下',
    AddressFileIsARequiredField: '住所の証明が必要です',
    AddressFileExceeds600kB: '住所証明のファイルサイズは必ず600kb以下',
    BankFrontFileIsARequiredField: '銀行の表の証明が必要です',
    BankFrontFileExceeds600kB: '銀行証明の表のファイルサイズは必ず600kb以下',
    BankBackFileIsARequiredField: '銀行の裏の証明が必要です',
    BankBackFileExceeds600kB: '銀行証明の裏のファイルサイズは必ず600kb以下ん',
    // 20201027 END

    ApplicationFailed: '申請失敗',
    ParentAgent: '所属上位',
    YesReadInfo: 'はい、データを見る',
    MarketIn: '所属市場',
    IdCardNumber: '身分証明書番号',
    ReceviedBankName: '受取る銀行名',
    ReceviedBankAddress: '受取る銀行住所',
    ChangeMarket: '市場変更',
    ChangeMarketErrorMsg1: 'レベル上下あるユーザーは市場変更できません',
    // 20201028 END
    ReadTraderAccount: 'トレードアカウントを見る',
    // 20201116 END
    UserInfo: 'ユーザー資料',
    AddrInfo: '住所資料',
    BankInfo: '銀行資料',
    MarketGroupMap: '市場とグループのマップ',
    IncludeGroup: 'グループを含めます',
    SetDefault1: 'デフォルトの設定',
    LevelName: 'レベル名',
    // 20201119 END
    OrderAdvanceSearchMsg1: 'サービス内容はサーバーによって異なります',
    // 20201123 END
    BindOtherAccount: '他のアカウントをバインドします',
    Server: 'サーバー',
    // 20201124 END
    Broker_Deposit_Applying: '新し申請',
    User_Deposit_Applying: '支払い中',
    Broker_Deposit_Reviewing: '支払いました',
    User_Deposit_Reviewing: '審査中',
    Broker_Deposit_rejected: '拒否',
    User_Deposit_rejected: '申請失敗',
    Broker_Deposit_Approved: '入金成功',
    User_Deposit_Approved: '入金成功',

    Broker_Withdraw_Applying: '新し申請',
    User_Withdraw_Applying: '審査待ち',
    Broker_Withdraw_Approved: '審査しました',
    User_Withdraw_Approved: '審査中',
    Broker_Withdraw_rejected: '削除',
    User_Withdraw_rejected: '申請失敗',
    Broker_Withdraw_Withdraw: '出金しました',
    User_Withdraw_Withdraw: '出金しました',
    // 20201127 END
    DepositIn: '純入金',
    AgentInfo: '代理情報',
    // 20201201 END
    DepositSetting: '入金設定',
    WithdrawalSetting: '出金設定',
    DepostieWallet: '電子財布',
    // 20201207 END
    ApplyAmount: '申請金額',
    PaymentDetail: '支払詳細',
    DesignationRate: '指定為替レート',
    PaymentAmount: '支払い金額',
    OrderNumber: 'オーダーナンバー',
    ApplyerEmail: '申請者のメール',
    CallApiErrorMsg: 'もう一度または後でお試してください',
    Deleted: '削除されました',
    DeleteEWallet: '電子財布を削除します',
    DeleteEWalletMsg: '電子財布を削除しますか？',
    WalletName: '財布の名称',
    EWalletEnableMsg: '少なくとも1種類のウォレットを有効にしてください',
    MinAmount: '最小金額',
    MaxAmount: '最大金額',
    Channel: 'チャンネル',
    DeleteChannelMsg: '支払いチャンネルを削除しますか？',
    CurrencyEnableMsg: '少なくとも1つの取引通貨を有効にしてください',
    DeleteChannel: '支払いチャンネルを削除します',
    Warning: 'リマインダー',
    WalletAddress: '財布の住所',
    EnterWalletAddressMsg: '財布の住所をご入力ください',
    EnterWalletNameMsg: '財布の名称をご入力ください',
    DeleteCurrencyMsg: '取引通貨を削除しますか？',
    DeleteCurrency: '取引通貨を削除する',
    EnterChannelNameMsg: 'チャンネルの名称をご入力ください',
    EnterAmountRangeMsg: '正しい金額の範囲をご入力ください',
    EnterCurrencyNameMsg:
      '正しい通貨コード（大文字3文字）をご入力してください。',
    EnterRateMsg: '小数点以下6桁まで対応',
    EnterRateMsg8: '小数点以下8桁まで対応',
    MinAmountEqualUSDMsg: '最低限の金額は米ドルに相当',
    MaxAmountEqualUSDMsg: '最高限の金額は米ドルに相当',
    EnterCurrencyMsg: '通貨をご入力してください',
    EnterRateMsg2: '為替レートをご入力してください',
    Created: '追加しました',
    Edited: '変更しました',
    EnterTokenMsg: 'Tokenをご入力してください',
    TransferInfo: '振り込むの情報',
    BankAccountName: '銀行名',
    Rate: '為替レート',
    TransferInAmount: '為替レートしたから入金します',
    MinAmountMsg: '金額を下回ることはできません',
    MaxAmountMsg: '金額を超えることはできません',
    TransferOutAmount: '為替レートしたから出金します',
    NoEnableCurrencyAmount:
      '利用可能な通貨はありませんが、ブローカーにお問い合わせください。',
    AccountInfo: 'アカウントの情報',
    ReceiveMan: 'ご依頼人',
    ApplySent: '申請を出しました',
    // 20201214 END
    WrongDataMsg: '正しい情報をご入力ください',
    EnterBankNameMsg: '銀行名ををご入力ください',
    EnterBankCodeMsg: '銀行コードをご入力ください',
    EnterBankAccountNameMsg: '銀行の名義人をご入力ください',
    EnterBankAccountMsg: '銀行の口座をご入力ください',
    EnterBankAddressMsg: '銀行の住所をご入力ください',
    EnterRateMsg3: '為替レートが0より大きいこと',
    // 20201215 END
    IDCardFrontExceeds: '身分証明書の表画像のファイルサイズは必ず10kb以下',
    IDCardBackExceeds: '身分証明書の裏画像のファイルサイズは必ず10kb以下',
    AddressFileExceeds: '住所証明のファイルサイズは必ず10kb以下',
    BankFrontFileExceeds: '銀行証明の表のファイルサイズは必ず600kb以下',
    BankBackFileExceeds: '銀行証明の裏のファイルサイズは必ず600kb以下',
    // 20201216 END
    UserIsDisabled: 'アカウントが有効になっていません',
    // 20201222 END
    YourEWalletAddr: 'あなた電子財布の住所',
    Copy: 'コピー',
    UserEWalletAddr: 'お客様電子財布の住所',
    // 20201228 END
    AllMarket: '全市場',
    // 20201229 END
    ActivePeople: 'アクティブな人の数',
    TotalPeople: '合計人数',
    PlatformFilter: 'インストールフィルタ',
    Mobile: 'モバイル機器',
    Tablet: 'タブレット',
    Desktop: 'デスクトップ',
    AccountAndName: 'アカウントとお名前',
    ShowAllDataMsg: '現在のデータ すべて表示',
    // 20201230 END
    Lots: 'ロット',
    // 20210127 END
    MemberTernDesc: `{brokerName} お客様に情報、コンテンツ、ツール、製品、サービスをご提供できることを嬉しく思います。 これらの条件には、特定の製品やサービスに関する重要な開示事項や情報も含まれています。 お客様による{brokerName}ウェブサイトのご利用は、本規約に従うものとします。 本規約は、お客様と{brokerName}との間の拘束力のある契約です。 お客様がこのウェブサイトにアクセスし、これを利用するためには、本規約およびこのウェブサイトに掲載されているその他の法律上の注意事項や通知に同意していただく必要があります。 お客様による{brokerName}ウェブサイトのご利用は、{brokerName}ウェブサイトの日付に有効な本規約のバージョンに従うものとし、

			{brokerName} 事前に通知することなくいつでも変更することができます。 このウェブサイトへの継続的なアクセスおよび使用には、これらの条件に同意していただく必要があります。{brokerName}は、変更や修正をお客様に個別に通知することはありません。。

			本規約は、お客様と{brokerName}との間で締結される、顧客契約またはアカウント契約を含むその他の契約に加えて、{brokerName}のウェブサイト上およびウェブサイトを通じて利用可能な情報、コンテンツ、ツール、製品およびサービスの利用を規定するものです。 お客様が{brokerName}との間で書面による契約を締結していない限り、{brokerName}サイトは、お客様の個人的かつ非商業的な使用のみを目的としています。
			このサイトの情報は、下記の方に提供しません：その国や地域の法律や規則に違反して配布または使用する人は、利用できません。 {brokerName}サイトに記載されているサービスや投資は、下記の方に提供しません：居住する国の現地法や規制に違反して当該サービスを提供したり投資を行ったりする者には提供されません。

			このサイトの訪問者は、自らが拘束される現地の法律または規制の条件を確認し、これを遵守する責任があります。このサイトの内容は下記の人に、商品の購入やサービスの勧誘、または販売やサービスの提供ができません：法域の法律の下で違法となる法域の人に対して、商品またはサービスの購入の勧誘、または製品やサービスの販売の勧誘。
		`,
    PrivacyPolicyDesc: `{brokerName}は、個人のプライバシーを尊重し、お客様との関係を大切にします。 {brokerName}は、個人情報にプライバシーの保護を提供し、お客様の支持と尊敬を得ています。{brokerName}が収集した全ての個人情報は、厳格なセキュリティ対策の下で保護されます。 このプライバシーポリシーでは、{brokerName}がどのように個人情報を収集し、どのように使用・開示するかを説明しています。 また、お客様の権利についても詳しく説明しています。 このポリシーは、これからAIA外国為替になろうとしているお客様、既に{brokerName}であるお客様、{brokerName}になろうとしているお客様に適用されます。

			1. 2006年《マネーロンダリング・テロ資金供与対策法》（Anti-Money Laundering and Counter-Terrorism Financing Act 2006）に基づき、{brokerName}は、顧客の身元に関連する情報を収集し、顧客の身元を確認する義務を負っています。{BrokerName}は、1993年ニュージーランドプライバシー法（Privacy Act 1993）に基づいて、顧客の識別と確認の手続きを行います。

			2. お客様は、{brokerName}の真実アカウントまたはデモアカウントを開設するために申込書を提出する際に、{brokerName}に個人情報を提供します。{brokerName} の真実アカウントまたはデモアカウントの開設を申し込むことにより、お客様は、関連するポリシーの条項を認め、これに同意し、お客様が {brokerName} に提供する同意が自発的なものであることを証明します。 個人情報とは、お客様を特定する、あるいはお客様の真の姿を確認できる、お客様に関するすべての情報です。 個人データが（お客様以外の）第三者によって収集される場合、{brokerName}は、データが収集されたことおよび収集された状況をお客様が認識できるようにするための合理的な手段を講じます。

			3. {brokerName} は、お客様のアカウントにおけるすべての取引および活動の記録を保存する、クロージング情報も含めます（ただしこれに限定されない）。{brokerName}は、利用可能な公開情報源（会社登記簿など）から顧客情報を収集しています。{brokerName}は、1993年に制定されたニュージーランド個人情報保護法の原則に則り、お客様の個人情報を厳重に管理します。 お客様は、{brokerName}が保有する顧客情報へのアクセスをいつでも要求することができます。{brokerName}は、お客様と{brokerName}のスタッフとの電話での会話を録音することがあります。 この録音された情報は、{brokerName}の法的権利の問題として、また、規制当局やその他の政府機関の要求に従って、お客様と{brokerName}との間の紛争を解決するために使用されることがあります。 また、{brokerName}は、お客様がオンラインの申込書やその他の種類のフォームに記入する際、または{brokerName}のウェブサイトを通じてお客様のアカウントや取引を操作する際に、お客様の情報を収集し、保有することがあります。

			4. {brokerName}は、お客様の機密情報を収集することがあります。{brokerName}が提供した苦情解決策がお客様の満足を得られなかった場合、お客様はプライバシーコミッショナーに苦情を申し立てることができ、プライバシーコミッショナーはお客様の苦情をさらに調査します。。

			5. お客様は、{brokerName}が保有する個人情報を電子メールで請求することができます。 {brokerName}は、通常14日以内にお客様のご要望に応じます。 お客様のご要望が複雑で、そのために時間がかかる場合には、{brokerName}は、必要な追加時間をお客様にお知らせします。 お客様ご自身に関する情報のご請求には手数料はかかりませんが、{brokerName}は、お客様のご請求に応じるためのリーズナブルな手数料をお客様に請求することができます。 特定の状況下では、{brokerName}はお客様へのアクセスを拒否することが法律で認められています。{brokerName}の機能や運営に関わる重大な違法行為や不正行為が行われている、あるいは行われている可能性があると疑う理由がある場合、正当な利益を損なう可能性がある場合には、その要求を拒否することができます。 このような場合、{brokerName}は、お客様に拒否の理由（お客様に理由を提供することが不適切または不合理であると考えられる場合を除く）、その他の規制事項を提供し、既存の苦情処理メカニズムを提供します。 実行可能な場合、{brokerName}は合理的な手段を講じ、{brokerName}とお客様のニーズに合致した方法で要求を提供することができます。

			6. {brokerName} は、お客様が{brokerName}の保有するお客様の個人情報に変更があった場合には、すぐに{brokerName}に書面で通知することを要求します。 これにより、{brokerName}が保有するお客様の個人情報が最新の状態に保たれ、{brokerName}がお客様のアカウントの継続的な状況、お客様の保証金の義務、お客様の取引活動についてお客様に通知することが可能になります。 お客様は、いつでも｛brokerName｝に対し、｛brokerName｝が保有するお客様の個人情報が不正確であると判断したものを訂正するよう求めることができます。 データの修正や訂正の依頼、または{brokerName}のプライバシーポリシーや実務に関する情報を得るための依頼に関して、{brokerName}とお客様の間でデータの正確性について意見が一致しない場合、お客様は{brokerName}に対し、お客様がデータを不正確または不完全であると考えている旨の声明をデータに添付するよう要求することができます。 {brokerName}は、個人情報の訂正を拒否する旨をお客様に書面で通知し、その理由を示し（理由を示すことが不適切であると判断される場合を除く）、当該拒否に対する苦情の仕組み、およびその他の関連事項を提供します。

			7. {brokerName} がデータが不正確、最新でない、不完全である、または関連性がない、もしくは誤解を招く恐れがあると認めた場合、{brokerName}は個人情報が正確、最新、完全、関連性があり、誤解を招かないように修正するためにあらゆる合理的な手段を講じます。 </li>

			8. {brokerName} は、お客様の個人情報を誤用、紛失、不正使用、干渉、改ざん、開示から保護するためにあらゆる合理的な手段を講じます。
		`,
    Dot: '。',
    // 20210217
    LoginDone1: 'Fully integrated transaction management system, including',
    LoginDone2: 'Multi-account visualized management',
    LoginDone3: 'Instant deposit and withdrawal',
    LoginDone4: 'Clear and concise fund record and transaction statements',
    LoginDone5:
      'Trading FX, metals, and CFDs is risky. We encourage you carefully consider your investment objectives. ',
    EWalletType: 'Types of e-Wallet',
    AcceptEWalletType: 'Types of acceptable e-wallets by the broker',
    EWalletSubmitMsg: `Please confirm the information you have filled in is correct before hitting the
			“Application” button. The broker will not be responsible for any losses associate with
			the incorrect inputs. `,
    // 20210719
    CryptocurrencyType: 'Type of cryptocurrency',
    CryptocurrencyTypeMsg: 'Cryptocurrency can only contain 3-10 characters',
    ProtocolsLabel:
      'Available Protocols (separate different protocols with semicolon ”;”)',
    ProtocolsMsg:
      'Each protocol name can only contain 3-10 characters including numbers',
    FormatMsg: 'Please confirm the format is correct',
    Decimal2Msg: 'Allow 2 decimal places',
    Protocol: 'Blockchain Protocol',
    // 20210720

    //20210817
    I18CreateUserAccountPrompt:
      'Account created successfully. Please email account information to the user.',
    I18CreateUserAccount: 'Create Account',
    // 20210819
    I18EnterEmailPleace: 'Email',
    I18AssignedAgent: 'Assigned Agent',
    I18SelectMarket: 'Select Market',
    I18SelectAgent: 'Select Agent',
    I18SelectNoAgent: 'No Agent',
    I18AgentChange: 'Modify Agent',
    I18SelectAgentTier: 'Select Tier',
    I18PromptTierCouldBeDifferent:
      'Default tier could be different with the tier in the original market.',
    I18ErrorGroupNotOpenInMarket:
      'The account {{TraderAccount.id}} of {{User.name}} was assigned to Group {{Group.name}} which is not available in the {{market.name}}.',
    I18PromptAgentChangeMarketOnlyWithLevels:
      'Agents are only allowed to change to market with multi-tiers.',
    I18NotHaveAgent: 'No agent.',
    // 20210924
    I18ClearCache: 'Clear cache',
    I18PromptClearCacheAfterUpdateManager:
      'To see your changes after updating the manager permissions on MT4/MT5, you may need to refresh by clearing your cache.',

    // 20211006
    I18FurtherInformation: 'Further information',
    I18LimitHalfwidthText: 'Limited to {limit} characters',
    I18ExceedTextLimit: 'Exceed the input limit',
    I18UpdateTimeLatest: 'Update Time',

    I18TraderAccountLimit: 'Maximum Trading Account',
    I18TraderAccountLimitExclude: 'Exclude existing accounts',
    I18TraderAccontLimitRange: 'Set a limit from {min} - {max}',

    // 20211122
    I18CustomField: 'Table on Demand',
    I18SelectAll: 'Select All',

    I18StorageNew: '利子',

    // 20211130
    I18OrderPosition: 'Position',
    I18OrderHistory: 'History',
    I18SetupPrice: 'Setup Price',
    I18OrderDoneTime: 'Done Time',
    I18OrderDoneTimeOrRange: 'Done Time',
    I18TradeTime: 'Time',
    I18FinalPrice: 'Price',
    // 20211209
    I18BankCodeEmptyCheck: 'SWIFT/IBAN code is unfilled, still save?',
    // 20211221
    I18DepositComfirmed: 'Deposit comfirmed',
    I18BrokerDepositDoubleCheckText:
      'Please confirm the deposit {currencyName} {amount} to {userName}?',
    I18BrokerDepositDepositTempText:
      'Please complete and approve the withdrawal application in the CRM system and make sure the amount first before actually transferring money to the client.',
    I18UserKycIsRequiredBeforeDeposit: 'Please complete user KYC first',
    I18WithdrawalRequestComfirmed: 'Withdrawal request comfirmed',
    I18AssetArrangementComfirmed: 'Asset arrangement completed',
    I18BrokerAssetArrangementToTraderDoubleCheckText:
      'Please confirm the asset arrangement {currencyName} {amount} to {traderId}?',
    I18BrokerWithdrawalRequestDoubleCheckText:
      'Please confirm the withdrawal {currencyName} {amount} for {userName}?',
    I18DepositByBroker: 'Deposit by broker',
    I18BrokerAssetArrangementToUserAccountDoubleCheckText:
      'Please confirm the user account {currencyName} {amount} to {accountId}?',
    I18AssetAmount: 'Amount',
    I18RequestByUserSelf: 'Request by client',
    I18RequestByBroker: 'Request by broker',
    I18TraderInformation: 'Trader Account Info.',
    I18ViewTraderInfoPermission:
      'Permission for agent to see trader account information',
    I18nUploadImageCorrupted: 'The uploaded image file is corrupted',
  },

  // 韓文
  'ko-kr': {
    Broker: '중개인',
    Provider: '제공자',
    Trader: '거래자',
    Signal: '신호',
    YES: '예',
    NO: '아니오',
    //Menu Word
    Review: '검토',
    AgentApplication: '대리인 신청',
    AgentManage: '대리인 관리',
    Client: '고객',
    FollowerManage: '팔로워 관리',
    FollowerList: '팔로워 리스트',
    Order: '주문',
    OrderHistory: '주문 기록',
    AccountDetail: '계정 상세',
    AccountHistory: '계정 기록',
    CreatAccount: '계정 개설',
    FundHistory: '자금 기록',
    ReadInfo: '정보 확인',
    // SIDE MENU
    Dashboard: '계시판',
    Setting: '설정',
    UserManagement: '사용자 관리',
    Audit: '승인 필요',
    MarketGroup: '시장 그룹',
    Management: '관리',
    Report: '보고',

    // Word
    More: '세부 정보',
    LoginSetting: '접속 설정',
    LoginDevice: '접속 기기',
    LoginDate: '접속 날짜',
    Position: '장소',
    BrokerSetting: '중개인 설정',
    SystemSetting: '시스템 설정',
    SettingDes1: '강화시키다',
    SettingDes2:
      '안전하고 강화된 거래 환경입니다, 귀하의 계좌에대한 질문이나 우려사항이 있으면 제공된 연락처 정보를 사용하여 저희에게 연락주십시오.',
    FundManage: '자금 관리',
    User: '사용자',
    UserManage: '사용자 관리',
    UserAccount: '사용자 계정',
    // Search placeholder
    PlaceholderUser: '사용자 이름, 이메일',
    PlaceholderTrader: '거래자 이름, 사용자 이름',
    PlaceholderAgent: '대리인 이름',
    PlaceholderOrderQuery: '유형',
    NotYetBindUser: '연동되지 않은 사용자',
    UserName: '사용자 이름',
    AgentName: '',
    KYC: 'KYC 신청',
    PerLot: '단위 마다',
    PerDeal: '주문 마다',
    //Login
    Login: '로그인',
    LoginMt: 'MT 로그인',
    LastLogin: '마지막 로그인',
    LoginNormal: '사용자 이름',
    LoginMtText: 'MT 계정과 비밀번호',
    TextBuildAccount: '검증 코드를 받으려면 이메일을 입력하십시오.',
    Password: '비밀번호',
    YourEmail: '귀하의 이메일',
    ForgotPassword: '비밀번호 분실',
    TextForgotPassword:
      '비밀번호를 재설정하려면 CRM 계정과 연결된 이메일 주소를 입력하십시오. 해당 이메일로 검증 코드를 보내드립니다.',
    AccountManage: '계정 관리',
    OrderQuery: '주문 확인',
    OrderStatus: '주문 상태',
    // LoginDone1:'우리는 최상위 품질의 거래 시스템과 최고의 기능을 제공합니다. ',
    // LoginDone2:'최고의 신호에 구독하고 그들의 거래를 복제합니다.',
    // LoginDone3:'언제 어디서든 가능한 인출 및 입금.',
    // LoginDone4:'다양한 신호의 포트폴리오 관리.',
    // LoginDone5:'투자에는 위험이 따릅니다. 원하는 리스크-수익 비율이 반영된 프로필에 따라 자본을 분배하십시오.',
    //Button
    Send: '보내기',
    Confirm: '승인',
    AlreadyConfirm: '승인됨',
    Done: '완료',
    Delete: '삭제',
    EditName: '수정',
    EditNickName: '닉네임 수정',
    AddMarket: '새로운 시장 생성',
    AddLevel: '새로운 레벨 생성',
    EditLevel: '등급 변경',
    DeleteLevel: '등급 삭제',
    DeleteLevelDes: '레벨을 삭제할 것인지 확인해주세요.',
    DeleteLevelDes2: '남아있는 사용자로 인해 레벨 삭제에 실패했습니다.',
    DuplicateLevel: '마지막 설정 복사',
    DeleteLoginDevice: '로그인 되어있는 기기 삭제',

    DeleteMarket: '시장 삭제',
    CommissionSetting: '커미션 설정',
    AddMarketDes: '새로운 시장 생성, 새로운 레벨 과 커미션 설정',
    DeleteMarketDes: '시장을 삭제할 것인지 확인해주세요.',
    CanNotDeleteMarket: '남아있는 사용자로 인해 시장 삭제에 실패했습니다',
    DeleteConfirm: '제거 할 것인지 확인하십시오',
    ConfirmWithdraw: '출금을 확인하십시오.',
    Upload: '업로드',
    Demo: '데모',
    //NOTIFY
    PWDone: '귀하의 비밀번호가 변경되었습니다.',
    DepositDone: '입금 요청',
    DepositOk: '입금 완료',
    WithdrawalDone: '출금 완료',
    ChangeGroupDone: '모둠 변경 완료',
    ChangeLeverageDone: '레버리지 변경 완료',
    DisableAccount: '계정 중지',
    EnableAccount: '계정 복구',
    EnableAccountDes:
      '계정을 복구 하시겠습니까? 실행하면 <br>에 액세스 할 수 있습니다.',
    DisableAccountDes:
      '계정을 중지 하시겠습니까? 실행하면 <br><br>로부터 중지됩니다.',
    ChangeParentDes: '상위 등급 조정',
    enableDemo: '데모 활성화',
    cancelIB: 'IB 비활성화',
    ChangeClientToIB: '고객을 다음 IB로 전송',
    ActionFailed: '작업 실패',
    ThrottleLimit: '너무 많이 시도하셨습니다. 나중에 다시 시도하세요.',
    CopyWordSuccess: '복사 완료',
    CopyWordFailed: '복사 실패',

    IPDes: 'IP 주소',
    //註冊
    RDone: '등록 완료',
    RText: '귀하의 권리 보호를 위해 양식을 정확하고 완전하게 작성하십시오.',
    NickName: '닉네임',
    RRealName: '본명',
    Name: '이름',
    RPersonalInfo: '개인 정보',
    RBankInfo: '은행 정보',
    RRestInfo: '거주지 정보',
    RIDCode: 'ID 번호',
    RIDDoc: '신분 증명서',
    RAddressDoc: '거주지 주소 증명서',
    RBankDoc: '은행 계정 증명서',
    RIDP: '신분증 앞면 사본',
    RIDN: '신분증 뒷면 사본',
    RBankDoc1: '은행업 세부 정보 -1',
    RBankDoc2: '은행업 세부 정보 -2',
    UserDetail: '사용자 세부 정보',
    UserInformation: '사용자 정보',

    //SETTING
    TraderAreaTimezone: '거래자 시간대',
    LanguageCode: '언어',
    Increase: '오름차순',
    Decreasing: '내림차순',
    CompanyName: '회사 이름',
    CompanyWebsite: '회사 웹사이트',
    BusinessHours: '업무 시간',
    WorkingDes: '추가 정보 필요시 ???으로 연락해주십시오. ',
    MON: '월요일',
    TUE: '화요일',
    WED: '수요일',
    THU: '목요일',
    FRI: '금요일',
    SAT: '토요일',
    OS: '운영 체제',
    RIDText: 'ID 를 입력 해주세요',
    RAddressText:
      '귀하의 이름과 주소가 포함 된 거주 증명증이 허용됩니다. 공과금 영수증, 은행 명세서 또는 임대 계약서 등을 사용 할 수 있습니다.',
    RBankText: '통장과 같은 은행 정보를 증명 할 개인 정보 문서를 제공하십시오.',
    RGender: '성별',
    RBirthday: '생년월일',
    I18GenderMale: '남자',
    I18GenderFemale: '여자',
    RGender3: '기타',
    RGender4: '밝히지 않음',
    RRegion: '국가',
    RNationCode: '국가 번호',
    RSelect: '선택',
    RCity: '도시',
    RProvince: '주',
    RArea: '구/군/동',
    RPhone: '전화번호',
    RAddress: '주소',
    Remail: '이메일',
    RPassword: '비밀번호',
    ROPassword: '기존 비밀번호',
    RNPassword: '새 비밀번호',
    RMTPassword: 'MT 계정 비밀번호',
    RMTPasswordLimited:
      '비밀번호는 15자 이내로 숫자, 영문자, 특수문자 조합으로 입력하세요.',
    I18NewRMTPasswordLimited:
      '비밀번호는8-15자 이내로 숫자, 영문자, 특수문자 조합으로 입력하세요.',
    RPassword1: '최소 8자이고 영문자와 숫자를 포함시키세요.',
    RPasswordConfirm: '비밀번호 확인됨',
    RPasswordConfirm1: '비밀번호 불일치',
    RPasswordReset: '비밀번호 재설정',
    CreateAccount: '계정 생성',
    AccountName: '거래자 계정 이름',
    AccountPW: '비밀번호',
    AccountPWA: '비밀번호 재입력',
    MTLogin: 'MT 로그인',
    OnlyNumber: '숫자만',
    OnlyNumberNoZero: '0이 아닌 숫자',
    NumberArea: '세그먼트 선호도',
    Range: '범위',
    Minnum: '최소 세그먼트보다 커야함',
    Numrange: '최소 및 최대 세그먼트 여야함',
    MinValue: '최소',
    MaxValue: '최대',
    NextAccountNum: '다음 계정 번호',
    NumberNow: '현재 번호',
    MTPW: 'MT 비밀번호',
    //後端回傳Errors
    NoLogged: '로그인 실패',
    AlreadyLogged: '로그인 됨',
    AuthServiceError: '인증 오류',
    EmailIsAlreadyUsed: '이메일이 이미 다른 계정에 사용되고 있습니다.',
    EmailDoesNotExist: '이메일이 존재하지 않습니다.',
    WrongEmailOrPassword: '잘못된 이메일 또는 비밀번호 입니다.',
    CertificationExpired: '인증 만료됨',
    ErrorFailAccount:
      '계정 생성에 실패했습니다. 계정 관리자에게 문의 하십시오.',
    'Limit exceeded': '반복 된 요청입니다. 나중에 다시 시도하십시오.',
    'broker-trader-enable': '계정 활성화됨',
    'broker-sync-config': '정보 동기화',
    'broker-sync-trader': '계정 동기화',
    'broker-trader-setgroup': '거래자 그룹',
    'broker-trader-leverage': '거래자 레버리지',
    CommissionIsGreaterThanThePreviousLevel:
      '커미션은 이전 수준을 초과할 수 없습니다.',
    CommissionIsLessThanThePreviousLevel:
      '커미션은 다음 레벨보다 작을 수 없습니다.',
    ManagerAPIConnFailed: '관리자 API 연결 실패',
    BadRequest: '요청 오류',
    ImageDisabled: '이미지 삭제',
    Inside: '내부',
    Outside: '외부',
    BrowserIcon: '브라우저',
    //Errors
    ErrorMail: '잘못된 이메일 주소',
    ErrorPW: '최소 8자로된 숫자, 영문자, 특수문자의 조합으로 입력하세요.',
    ErrorVerify: '잘못된 인증 코드',
    ErrorName:
      '잘못된 이름 (최대 64자, 영문 대/소문자, 숫자, 띄어쓰기 밎 특수문자 ：- _ . ·).',
    ErrorCorrect: '잘못된 형태',
    ErrorLogin: '32자 이내의 숫자만 허용됩니다.',
    ErrorPhone: '7자 이상 및 15자 이하의 숫자만 허용됩니다.',
    ErrorID: '아이디에 표기된 아이디 숫자를 입력하세요.',
    ErrorCountryCode: '국가를 선택하세요.',
    ErrorEmpty: '공란이거나 비어있을 수 없습니다.',
    ErrorAccount: '잘못된 계정 형태입니다. 최소 8자를 포함하여야 합니다.',
    MemberTern: '이용 약관',
    PrivacyPolicy: '개인 정보 보호 정책',
    TextKnowing: '개인 정보 보호 정책을 읽고  이해했으며 동의함을 확인합니다.',
    TextKnowing2:
      'CFD 거래는 상당한 자본 손실 위험을 수반합니다. CFD는 복잡한 상품이며 레버리지로 인해 빠르게 손실을 입을 위험이 있습니다. 이는 이용 약관에 적용됩니다.',
    VerifyPassText: '요청이 수락되었습니다. 개인 정보를 확인하십시오.',
    SecurityBlock: '거래 비활성화됨',
    GroupBlock: '그룹 정지됨',
    ErrorReason: '오류 보고',
    Or: '혹은 ',
    None: '없음',
    BackLogin: '로그인으로 돌아가기',
    VerifyCode: '코드 인증',
    Cancel: '취소',
    ReSend: '다시 제출',
    VerifyReceived: '이메일에서 검증 코드를 확인하십시오.',
    SendVerify: '이메일 인증',
    EmailAlready:
      '이메일이 이미 사용중입니다. 계정으로 로그인하거나 새 계정을 개설하십시오.',
    EmailNotExist: '이메일이 존재하지 않습니다. 다시 시도하십시오.',
    Required: '필수 사항',
    RequiredSwift: '유효하지 않은 SWIFT 코드',
    RequireIban: '유효하지 않은 IBAN 코드',
    //Bank
    ReceviedBankInfo: '수취인 은행 정보',
    GBankName: '은행명',
    GBankAddress: '은행 주소',
    GBankAccount: '은행 계좌',
    GManName: '수취인',
    GManAccount: '수취인 계좌',
    GManAddress: '수취인 주소',
    GBankCode: '은행 코드',
    PaymentCurrency: '통화',
    PaymentChannel: '지불 방법',
    PaymenLimit: '최소 $1000의 입금이 필요합니다.',
    ChoosePayment: '지불 방법 선택',
    TradeCurrency: '통화',
    Mer_no: '거래자 번호',
    InputMer_no: '거래자 번호를 입력하십시오',
    Telegraphic: '전신',
    SettlementByDay:
      '일간 정산; 매일 GMT +0 00:00에 주문 동기화; 다음날 GMT +0 01:00에 정산',
    SettlementByWeek:
      '주간 정산; 매일 GMT +0 00:00에 주문 동기화; 매 주 일요일 GMT +0 01:00에 정산',
    SettlementByMonth:
      '월간 정산; 매일 GMT +0 00:00에 주문 동기화; 다음 달 월초 GMT +0 01:00에 정산',
    //註冊
    ErrMsg2: '나중에 다시 시도하십시오.',
    //Signal
    STotalInvest: '투자 금액',
    Balance: '잔액',
    SEquity: '지분',
    Save: '저장',
    Show: '보기',
    Num: '기록',
    AddAccountDirect: '계정 추가',
    BindMT4Account: 'MT4를 연결',
    SAvailableFunds: '가용 자금',
    WithdrawCost: '출금됨',
    DepositCost: '입금됨',
    WaitWithdrawCost: '출금 대기중',
    ApplicationWithdraw: '출금',
    SFPnL: '변동 P/L',
    SMargin: '마진',
    MarginUsed: '사용 된 차액',
    MarginCanUse: '차액 사용가능',
    MarginRatio: '마진 비율',
    SIncome: '수입',
    SRetreat: '후퇴',
    SAddPt: 'Pt 추가',
    Leverage: '레버리지',
    AccountNum: '사용자 수',
    AgentNum: '대리인 번호',
    Sercurity: '주문 유형',
    MarginCall: '마진 콜',
    MarginStopOut: '정지',
    TitleTrade: '거래 유형',
    SCredit: '신용',
    Sscore: '점수',
    Default: '기본값',
    SetDefault: '기본 그룹으로 설정',
    LeverageDefault: '기본 레버리지',
    ChangeLeverage: '레버리지 변경',
    All: '모두',
    PnLTotal: '총 P/L',
    ChooseGroup: '그룹 선택',
    ChangeGroup: '그룹 변경',
    //Order
    OnHold: '보류',
    OrderClose: '닫음',
    OrderOpen: '미결제 약정',
    OrderCloseProcess: '처리중',
    NoUnprocess: '보류중인 승인 없음',
    EmptyMsg1: '가용 정보',
    EmptyInfo: '가용 정보 없음',
    Empty: '비어있음',
    UnitPoint: '단위 점',
    Add: '추가',
    Edit: '수정',
    PopUpCloneDes:
      '계정을 생성하면 여러 상품을 거래하고 자금을 관리 할 수 있습니다.',
    CopyPromoteLink: '관련 링크',
    readOnlypassword: '비밀번호만 보이기',
    BalanceReport: '잔액 보고서',
    UserDistribution: '사용자 분포',
    SelectPlaceHolderChoseAccount: '계정 선택',
    SelectAgent: '선택',
    AdvanceSearch: '고급 검색',
    FunctionSearch: '검색 도구',
    FunctionSearchTrader:
      '거래자 계정을 입력하십시오. 쉼표를 사용하여 둘 이상의 계정을 구분하십시오.',
    FunctionSearchUser:
      '사용자 계정을 입력하십시오. 쉼표를 사용하여 둘 이상의 계정을 구분하십시오.',
    FunctionCommission: '커미션 번호 입력',
    Search: '검색',

    ChoisePayment: '지불 선택',
    PaymentMethod: '지불 방법',
    PaymentSetting: '지불 설정',
    PaymentColegantDes: 'Colegant를 선택할 때 다른 페이지로 이동합니다.',
    PaymentDes1: '...를 선택할때 다른 페이지로 이동합니다.',
    PaymentDes2: '.',
    LastFiveNum: '계정의 마지막 5자리 숫자',
    //No Data
    // REPORT
    Subscribe: '구독',
    UnSubscribe: '구독 취소',
    UnSubscribeDes: '구독 취소 되었습니다.',
    TradeNoAccess: '거래를 할 수 없습니다',
    UserNoAccess: '권한이 없음',
    TSymbol: '증권 시세 표시기',
    TOrderNo: '주문 번호',
    TType: '주문 유형',
    TSummary: '요약',
    TLots: '단위',
    TTrade: '량',
    TPrice: '가격',
    TSecurity: '주문 유형',
    TOpenDate: '주문 날짜',
    TOpenArea: '포지션 열림 날짜',
    TCloseArea: '포지션 종료 날짜',
    OpenPrice: '시가',
    TCloseDate: '종료 날짜',
    CurrentPrice: '현재 가격',
    ClosePrice: '종가',
    OrderSyncTime: '마지막 주문 동기화',
    TStopLoss: '손실 중단',
    TStopWin: '이득 중단',
    ProfitLoss: '총 이익',
    Storage: '이자',
    Fees: '수수료',
    TProfit: '이익',
    TradeLoss: '손실 ',
    GMTWord: '현재 시간대',
    //Record:'',
    BackPoint: '할인',
    SettingPC: '할인 설정',
    Commission: '커미션',
    Commission2: '커미션 할인',
    CommissionReport: '커미션 보고',
    CommissionOrder: '커미션 번호',
    MarketNone: '시장 없음',
    CommissionSend: '커미션',
    SettlementTime: '정착 시간',
    IntervalTime: '시간',
    CommissionType: '커미션 종류',
    CommissionRange: '커미션',
    NormalCommission: '보통 커미션',
    SameCommission: '동일한 커미션',
    OverCommission: '우월한 커미션',

    //Application
    Application: '지원',
    ApplicationMan: '지원자',
    ApplicationName: '지원자 이름',
    ApplicationAccount: '지원자 계정',
    ApplicationEmail: '지원자 이메일',
    ApplicationOrder: '신청 번호',
    ApplicationType: '종류',
    ApplicationPhone: '지원자의 전화번호',

    BuyQuty: '사기',
    SellQuty: '팔기',
    PnL: '총 P/L',
    PnLArea: 'P/L 범위',
    ProfitShare: '이익 분배',
    FundOut: '인출',
    FundIn: '자금 추가',
    SelectMarkets: '시장 선택',
    SelectLevel: '시장 단계',
    SetLevel: '단계 변경하기',

    SelectLevelDes: '단계 변경은 동일한 시장에서만 허용됩니다.',
    SelectCharacter: '카테고리',
    SelectAccountStatus: '계정 상태',
    TotalCommission: '커미션',
    CommissionAccounting: '커미션 계산하기',
    getCommission: '커미션 받기',
    SelectAgentDes: '커미션 지불된 대리인 확인',
    CreateAccounRecord: '일주일간 새 계정',
    LastSettlementCommission: '마지막으로 지불된 커미션',
    TraderVolum7: '일주일 양',
    Day7: '일주일',
    PnL7: '일주일 P/L',
    HotSymbol: '오늘의 유행',
    Credit: '신용',
    TotalWithdrawal: '총 이체',
    Withdrawal: '승인 ',
    WithdrawalCost: '이체 가능',
    Deposit: '입금 ',
    TotalDeposit: '총 입금',
    WithdrawalMin: '최소 이체량',
    Costmin: '… 보다 적을 수 없습니다',
    DepositNum: '입금 기록',
    WithdrawalNum: '출금 기록',
    //交易組別
    AllGroup: '모든그룹',
    MTGroup: '그룹',
    DefaultGroup: '기본 그룹 ',
    MTGroupSetting: '그룹 설정',
    GroupIn: '배정된 그룹',
    Enabled: '활성화 됨',
    Disable: '비활성화 됨',
    IsIBANSWIFT: 'SWIFT/IBAN code 가 필요하십니까?',
    //資金配置紀錄
    FundArrange: '자금 설정',
    SelectCostRange: '범위',
    SelectCostRangeDesMax: '최대값을 입력한 뒤 내림차순으로 정리',
    SelectCostRangeDesMin: '최대값을 입력한 뒤 오름차순으로 정리',
    CostRange: '양',
    Arragne: '분배',
    ArragneTo: '…에 분배',
    EnterFundCost: '값 입력',
    FunArrangeRecord: '자금 기록',
    FundArrangeMin: '최소값',
    TradeHistory: '기록',
    ChoseFrom: '…에서 선택',
    Wallet: '지갑',
    Type: '방법',
    Time: '시간',
    StartTime: '시작 시간',
    EndTime: '마감 시간',
    Start: '시작',
    End: '마지막',
    Cost: '가격',
    AccountRecord: '이체 기록',
    Function: '기능',
    Summary: '요약',
    KYCStatus: 'KYC 검증',
    Action: '선택권',
    Comment: '메모',
    Account: '계정',

    //市場
    MarketCommission: '시장 커미션',
    MarketCommissionSetting: '커미션 설정',
    Market: '시장',
    EditMarket: '시장 변경',
    Agent: '대리인',
    AgentList: '대리인 관리',
    IsAgent: '대리인 정보 확인',
    NotAgent: '대리인이 아님',
    MarketLevel: '시장 단계',
    MarketName: '시장 이름',
    MarketCost: '시장 거래',
    Level: '단계',
    Detail: '상세',
    ChannelName: '채널',

    //User List
    TraderName: '거래자 이름',
    AccountStatus: '계정 상태',
    Register: '등록',
    RegisterEnabled: '활성화 됨',
    RegisterDisabled: '비활성화 됨',
    RegisterDate: '등록일',
    AuditDate: '요청일 ',
    ApplicationDate: '요청일 ',
    ApplicationDateInterval: '요청일 ',
    HandleDate: '진행일',
    HandleDateInterval: '진행일',
    CompletedDate: '완료일',
    ChoseGMTTime: '시간대 변경',
    DepositOrder: '입금 계좌 번호',
    Completed: '완료',
    WithdrawalApplication: '이체 요청',
    StopAccountDes:
      '귀하의 계정이 비활성화 되었습니다. 담당 관리자에게 연락하십시오.',
    WithdrawalOrder: '이체 계좌 번호',
    AllCharacters: '모든 카테고리',
    Characters: '카테고리',
    Status: '상태',
    ThisWeek: '지난 일주일 기록',
    ThisMonth: '지난 한달 기록',
    //交易數據
    TradeStatus: '상태',
    TradeData: '날짜',
    TradeEva: '평가',
    TDPL: '총 P/L',
    TDLots: '총 단위',
    TDInterest: '총 이자',
    TDHandingFee: '총 수수료',
    //NOTIFY
    NewApplication: '새로운 요청 ',
    DepositSuccess: '입금 성공',
    Approved: '승인되다',
    WithdrawnSuccess: '출금 성공',
    Reject: '거부됨',
    Reject2: '거부 ',
    RejectApplication: '거부된 요청',
    Void: '유효하지 않은',
    Check: '확인된',
    Grant: '분산된',
    RejectReason: '거부된 이유',
    Notify: '알림',
    ViewAllNotify: '모든 알림 삭제',
    Clean: '삭제',
    EnabledAgent: '활동중인 대리인',
    ChangeParent: '행간 조정',
    // ORGANIZATION
    Organization: '조직',
    People: '사용자',
    OrganizationPeople: '사용자',
    TotalUser: '총 사용자',
    OrganizationStructure: '구조',
    BrokerAgent: '중개인의 대리인',
    BrokerUser: '중개인 고객',
    AgentAgent: '대리인의 대리인',
    Agentuser: '대리인의 고객',
    DirectUser: '직접적 고객',
    NormalAgent: '종속적 대리인',
    OverAgent: '우월한 대리인',
    SameAgent: '동일한 대리인',
    Referrer: '리퍼러',
    OrganizationDes:
      '시장 단계별로 정렬시 대리인이 아닌 사람이 맨 아래 배치됩니다.',
    //--
    SameBonus: '동등한 보너스',
    OverBonus: '우월한 보너스',
    //UNIT
    UnitMan: '사용자',
    UnitStuff: '단위',
    SyncConfig: '동기화',
    SyncOrderhistory: '기록 동기화',
    TraderLeverage: '레버리지 수정',
    TraderSetGroup: '거래자 그룹 수정',
    WalletRequestDepositApprove: '입금된',
    WalletRequestWithdrawWithdraw: '출금된',
    WalletRequestDepositReject: '입금 실패',
    WalletRequestWithdrawReject: '출금 실패',
    UserEnable: '계좌 복구 완료',
    UserDisable: '계좌 일시 중지',
    UserPasswordUpdate: '비밀번호 업데이트 완료',
    AgentAdd: '귀하는 이제부터 대리인 입니다.',
    AgentRemove: '대리인 신분으로부터 삭제되었습니다.',

    BrokerNotFound: '중개자 찾을 수 없음',
    WrongManagerAPI: '잘못된 관리자 API',
    MT4Error: 'MT 오류',
    ServiceError: '오류가 발생했습니다. 나중에 다시 시도하십시오.',
    TraderIsNotExists: '계좌를 찾을 수 없습니다.',
    //交易帳號相關
    TraderAccount: '거래 계좌',
    TraderAccountManage: '계좌 관리',
    SyncTrader: '계좌 동기화',
    TraderEnable: '거래 계좌 복구됨',
    TraderDisable: '거래 계좌 일시 정지됨',
    TraderAccountAdd: '거래 계좌 생성 ',

    TraderCreateFailed: '거래 계좌 생성 실패',
    TraderAccountAddText:
      '계좌를 생성하면 여러 상품을 거래하고 자금을 관리 할 수 있습니다.',
    TraderAccountNone: '거래자 계좌 없음',
    TraderCreate: '거래자 계좌 생성',

    TraderBind: '거래자 계좌 연동',
    TraderBindFailed: '연동 실패',
    TraderDetail: '계좌 세부 정보',
    TraderNotFound: '계좌를 찾을 수 없습니다.',
    ModifyAccountPassword: '비밀번호 재설정',
    // Personal Setting
    About: '상세',
    Personal: '사용자',
    PersonSetting: '설정',
    CompanySetting: '회사 설정',
    BankSetting: '은행 설정',
    WorkingDateSetting: '영업 시간',
    UserOpenAccountSetting: '사용자 계정 관리',
    AuditSetting: '승인 관리',
    AgentRuleSetting: '대리인 규제',
    Logout: '로그아웃',

    // ChangeTradePW
    TraderPWModify: '현재 거래자 계정의 비밀번호를 변경하세요.',
    TraderPWMain: '기본 계정의 비밀번호를 변경하세요.',
    TraderPWRead: '비밀번호만 읽기',
    UserNotFound: '사용자를 찾을 수 없습니다',
    TransferFailed: '전송 실패',
    Loading: '로딩',
    WrongPassword: '잘못된 비밀번호',
    SomethingWrong: '무언가 잘못되었습니다!',
    TryLatter: '나중에 다시 시도하십시오.',

    Success: '성공',
    AddDone: '추가 성공',
    AddFailed: '추가 실패',
    SaveDone: '저장 완료',
    SaveFailed: '저장 할 수 없음',
    Failed: '실패',
    AsyncSend: '요청 전송됨',
    ProcessRequest: '요청 처리중',
    //Broker audit
    AuditKyc: 'KYC 검증',
    AuditWithdraw: '출금',
    AuditDeposit: '입금',
    AuditReject: '요청이 거부됨',
    AuditKycReject: 'KYC가 거부되었습니다',
    AuditPass: '요청을 승인 하겠습니까?',
    AuditRejectRes: '문제를 해결에 도움이되는 피드백을 보내주세요.',
    AuditAllow: '승인됨',
    NextStep: '다음 단계',
    PrevStep: '이전 단계',
    LastUpdate: '마지막 업데이트',
    Least7Day: '지난 7일 ',
    Allow: '확인 됨',
    NotAllow: '확인되지 않음',
    AuditKycAllow: 'KYC가 확인되었습니다. ',
    Retry: '다시 시도하십시오.',
    Reason: '이유',
    Download: '다운로드',
    To: '을',
    // Broker-使用者管理
    ParentAccount: '우월한',
    NoParent: '없음',
    Direct: '직접적 친척',
    Direct2: '직접적 친척인지',
    // Button
    Operation: '옵션',
    OperationDone: '작업 완료',
    //KYC return
    KYCUNSUBMITTEDTitle: '불완전한 KYC',
    KYCUNSUBMITTED: '제출되지 않음',
    KYCSUBMITTEDTitle: '확인중',
    KYCAllowTitle: '확인 완료',

    KYCAllow: 'KYC 승인 완료',
    KYCReject: 'KYC 거부됨',
    KYCUNSUBMITTEDDes:
      '인증을 받으면 계좌를 관리하고 자금을 입금 할 수 있습니다.',
    KYCSUBMITTEDDes: '인증을 받으면 새 계좌를 만들 수 있습니다.',
    KYCFAILEDDes:
      '요청이 거부되었습니다. 신청서를 업데이트하고 다시 제출하십시오.',
    KYCUNSUBMITTEDButtonText: 'KYC 확인',
    KYCSUBMITTEDButtonText: 'KYC 확인 중',
    KYCFAILEDButtonText: '다시 요청',
    KycStatusSubmitted: '대기중인 승인',
    KycStatusPassed: 'KYC가 확인되었습니다. ',
    KycStatusFailed: 'KYC가 거부되었습니다',

    IDCardFrontIsARequiredField: 'ID 사진 크기는',
    IDCardFrontExceeds600kB: 'ID 사진 크기는 600KB 보다 작아야 합니다.',
    IDCardFBackIsARequiredField: 'ID 사진 크기는',
    IDCardBackExceeds600kB: 'ID 사진 크기는 600KB 보다 작아야 합니다.',
    AddressFileIsARequiredField: '거주지 주소 증명 문서는',
    AddressFileExceeds600kB:
      '거주지 주소 증명 문서는 600KB 보다 작아야 합니다.',
    BankFrontFileIsARequiredField: '은행 계좌 문서는',
    BankFrontFileExceeds600kB: '은행 계좌 문서는 600KB 보다 작아야 합니다.',
    BankBackFileIsARequiredField: '은행 계좌 문서는',
    BankBackFileExceeds600kB: '은행 계좌 문서는 600KB 보다 작아야 합니다.',
    // 20201027 END

    ApplicationFailed: '요청 실패',
    ParentAgent: '우월한',
    YesReadInfo: '네, 정보를 확인하세요.',
    MarketIn: '지정된 시장',
    IdCardNumber: 'ID 번호',
    ReceviedBankName: '수취인 은행',
    ReceviedBankAddress: '수취인 은행 주소',
    ChangeMarket: '시장 조정',
    ChangeMarketErrorMsg1: '시장의 여러 단계로 인해 시장을 수정할 수 없습니다.',
    // 20201028 END
    ReadTraderAccount: '거래자 계좌',
    // 20201116 END
    UserInfo: '사용자 정보',
    AddrInfo: '주소',
    BankInfo: '은행 정보',
    MarketGroupMap: '시장 및 그룹',
    IncludeGroup: '그룹 포함 ',
    SetDefault1: '기본값으로 설정',
    LevelName: '단계 이름',
    // 20201119 END
    OrderAdvanceSearchMsg1: '서버에 따라 다른 서비스가 있습니다.',
    // 20201123 END
    BindOtherAccount: '다른 계정과 연동',
    Server: '서버',
    // 20201124 END
    Broker_Deposit_Applying: '새로운 요청 ',
    User_Deposit_Applying: '결제 처리중',
    Broker_Deposit_Reviewing: '결제 완료',
    User_Deposit_Reviewing: '처리중',
    Broker_Deposit_rejected: '거부됨',
    User_Deposit_rejected: '요청 실패',
    Broker_Deposit_Approved: '입금됨',
    User_Deposit_Approved: '입금됨',

    Broker_Withdraw_Applying: '새로운 요청 ',
    User_Withdraw_Applying: '승인 대기 중',
    Broker_Withdraw_Approved: '승인 완료',
    User_Withdraw_Approved: '승인 완료',
    Broker_Withdraw_rejected: '거부됨',
    User_Withdraw_rejected: '요청 실패',
    Broker_Withdraw_Withdraw: '출금 완료',
    User_Withdraw_Withdraw: '출금 완료',
    // 20201127 END
    DepositIn: '총 입금',
    AgentInfo: '대리인 정보',
    // 20201201 END
    DepositSetting: '입금 설정',
    WithdrawalSetting: '출금 설정',
    DepostieWallet: 'E-지갑',
    // 20201207 END
    ApplyAmount: '요청량',
    PaymentDetail: '지불 세부 사항',
    DesignationRate: '비율',
    PaymentAmount: '지불된 금액',
    OrderNumber: '주문 번호',
    ApplyerEmail: '신청자 이메일 ',
    CallApiErrorMsg: '새로 고침 하거나 나중에 다시 시도하십시오.',
    Deleted: '삭제 완료',
    DeleteEWallet: '지갑 삭제',
    DeleteEWalletMsg: '지갑을 제거 하시겠습니까?',
    WalletName: '지갑 이름',
    EWalletEnableMsg: '지갑을 하나 이상 선택하십시오.',
    MinAmount: '최소 금액',
    MaxAmount: '최대 금액',
    Channel: '수단',
    DeleteChannelMsg: '결제 수단을 제거 하시겠습니까?',
    CurrencyEnableMsg: '적어도 하나의 통화를 선택하십시오.',
    DeleteChannel: '수단 삭제',
    Warning: '경고',
    WalletAddress: '지갑 주소',
    EnterWalletAddressMsg: '귀하의 지갑 주소를 입력하세요',
    EnterWalletNameMsg: '지갑 주소 이름을 입력하세요',
    DeleteCurrencyMsg: '통화를 제거 하시겠습니까?',
    DeleteCurrency: '통화 제거',
    EnterChannelNameMsg: '채널 입력',
    EnterAmountRangeMsg: '유효한 범위를 입력하십시오.',
    EnterCurrencyNameMsg: '유효한 세자릿수의 통화 코드를 입력하십시오.',
    EnterRateMsg: '소수점 이하 6 자리 까지.',
    EnterRateMsg8: '소수점 이하 8 자리 까지.',
    MinAmountEqualUSDMsg: 'USD 최소 금액',
    MaxAmountEqualUSDMsg: 'USD 최대 금액',
    EnterCurrencyMsg: '통화 입력',
    EnterRateMsg2: '환율을 입력하십시오.',
    Created: '생성됨',
    Edited: '수정됨',
    EnterTokenMsg: '토큰 입력',
    TransferInfo: '정보 전송',
    BankAccountName: '계좌 주인 이름',
    Rate: '환율',
    TransferInAmount: '변환 된 금액',
    MinAmountMsg: '최소 금액',
    MaxAmountMsg: '최대 금액',
    TransferOutAmount: '변환 된 금액',
    NoEnableCurrencyAmount:
      '사용 가능한 통화가 없습니다. 관리자에게 문의하십시오.',
    AccountInfo: '계좌 정보',
    ReceiveMan: '수취인',
    ApplySent: '요청 전송 완료',
    // 20201214 END
    WrongDataMsg: '잘못된 필드 항목을 수정하십시오.',
    EnterBankNameMsg: '은행 이름을 입력하세요.',
    EnterBankCodeMsg: '은행 코드를 입력하세요.',
    EnterBankAccountNameMsg: '예금주 이름을 입력하세요.',
    EnterBankAccountMsg: '계좌 번호를 입력하세요.',
    EnterBankAddressMsg: '은행 주소를 입력하세요.',
    EnterRateMsg3: '환율은 0보다 커야합니다.',
    // 20201215 END
    IDCardFrontExceeds: 'ID 사진 크기는 1.2MB 보다 작아야 합니다.',
    IDCardBackExceeds: 'ID 사진 크기는 1.2MB 보다 작아야 합니다.',
    AddressFileExceeds: '거주지 주소 증명 문서는 1.2MB 보다 작아야 합니다.',
    BankFrontFileExceeds: '은행 계좌 문서는 1.2MB보다 작아야 합니다.',
    BankBackFileExceeds: '은행 계좌 문서는 1.2MB보다 작아야 합니다.',
    // 20201216 END
    UserIsDisabled: '계정이 활성화되지 않았습니다.',
    // 20201222 END
    YourEWalletAddr: '귀하의 E-지갑',
    Copy: '복사',
    UserEWalletAddr: '클라이언트 E-지갑',
    // 20201228 END
    AllMarket: '모든 시장',
    // 20201229 END
    ActivePeople: '활동중인 계정',
    TotalPeople: '총 계정',
    PlatformFilter: '기기 선택',
    Mobile: '휴대폰',
    Tablet: '태블릿',
    Desktop: 'PC',
    AccountAndName: '계정 이름, 본명',
    ShowAllDataMsg: '다 보이기',
    // 20201230 END
    Lots: '단위',
    // 20210127 END
    MemberTernDesc: `{brokerName}는 정보, 콘텐츠, 도구, 제품 및 서비스를 기꺼이 제공합니다. 이용 약관에는 특정 제품 및 서비스에 대한 중요한 공개 및 정보도 포함됩니다. 귀하의 {brokerName} 사용에는 이 이용 약관이 적용됩니다. 이 이용 약관은 귀하와 {brokerName} 간의 구속력있는 계약입니다. 이 웹 사이트에 로그인하고 사용하려면이 웹 사이트에 게시 된 약관 및 기타 법적 고지 및 진술에 동의해야합니다. 귀하의 {brokerName} 사용에는 당일 {brokerName} 웹 사이트에 적용되는 이용 약관 버전이 적용됩니다.

			{brokerName}은 사전 고지없이 언제든지 이 이용 약관을 수정할 수 있습니다. 이 웹 사이트를 계속 로그인하고 사용하려면이 이용 약관에 동의해야합니다. {brokerName}은 귀하에게 변경 또는 수정 사항을 개별적으로 알리지 않습니다.

			이 이용 약관은 귀하와 {brokerName} 간의 기타 모든 계약입니다 (고객 또는 계정 계약을 포함한 {brokerName} 웹 사이트 및 {brokerName}를 통해 제공되는 정보, 콘텐츠, 도구, 제품 및 서비스의 사용을 제한합니다). {brokerName}와 서면 계약이없는 경우, {brokerName} 웹 사이트는 귀하의 개인적 및 비상업적 용도로만 사용됩니다.
			이 웹 사이트의 정보는 해당 국가 또는 관할권의 현지 법률 또는 규정을 위반하는 사람이 배포하거나 사용할 수 없습니다. {brokerName} 웹 사이트에 설명 된 서비스 또는 투자는이 서비스를 제공하거나 거주 국가의 현지 법률 또는 규정을 위반하여 투자하는 사람에게 제공되지 않습니다.

			이 웹 사이트 방문자는 자신이 의무적으로 준수해야하는 현지 법률 또는 규정의 조건을 확인할 책임이 있습니다. {brokerName} 웹 사이트의 콘텐츠는 관할권에 따라 제안, 권유, 구매 또는 판매에 참여하는 사람에게 제품 또는 서비스를 구매하도록 권유하거나 판매하겠다는 제안으로 간주되어서는 안됩니다.
				`,
    PrivacyPolicyDesc: `{brokerName}은 개인 정보를 존중하고 고객과의 관계를 소중히 여깁니다. 그동안 {brokerName}은 고객에게 개인 데이터에 대한 개인 정보 보호를 제공하고 고객의지지와 존경을 받아 왔습니다. 엄격한 보안 조치에 따라 {brokerName}이 수집 한 모든 개인 데이터가 보호됩니다. 이 개인 정보 보호 정책은 {brokerName}이 개인 데이터를 수집하는 방법과 사용 및 공개 방법 그리고 고객의 권리에 대해 자세히 설명합니다. 이 정책은 곧 AIA Forex가되는 고객, {brokerName}이 된 고객 및 {brokerName}이 되기 위해 신청하는 고객에게 적용됩니다.

			1. 자금 세탁 방지 및 2006 테러 방지 금융법에 따라 {brokerName}은 고객 신원과 관련된 정보를 수집하고 고객의 실제 신원을 확인하는 책임이 있습니다. {brokerName}은 1993년 뉴질랜드 개인 정보 보호법에 따라 고객 식별 및 확인 절차를 수행합니다.

			2. 고객이 {brokerName} 실제 또는 모의 계정을 개설하기 위해 신청서를 제출하면 {brokerName} 회사에서 고객의 개인 정보를 제공합니다. {brokerName} 실제 또는 모의 계정 개설을 신청할 때 귀하는 관련 정책 약관을 확인하고 이에 동의하며 {brokerName}에 제공 한 동의가 자발적임을 증명합니다. 개인 정보는 고객을 식별하거나 실제 신원을 확인할 수있는 고객에 대한 모든 정보입니다. 고객 본인이 아닌 제 3자가 개인 정보를 수집하는 경우 {brokerName}은 고객이 정보가 수집되고 수집되었음을 알 수 있도록 합리적인 조치를 취할 것입니다.

			3. {brokerName}은 고객 계정의 폐쇄 정보를 포함하여 (이에 국한되지 않음) 고객 계정의 모든 거래 및 활동 기록을 보관합니다. {brokerName}은 사용 가능한 공개 출저 (예: 회사 등록부)에서 고객 정보를 수집합니다. {brokerName}은 1993 년 뉴질랜드 개인 정보 보호법의 원칙에 따라 고객의 개인 데이터를 엄격하게 보유합니다. 고객은 {brokerName}이 보유한 고객 정보에 대한 접근을 언제든지 요청할 수 있습니다. {brokerName}은 고객과 {brokerName} 직원 간의 전화 대화를 녹음 할 수 있습니다. 이 기록은 고객과 {brokerName} 간의 분쟁을 해결하는 데 사용할 수 있으며, 이는 {brokerName}의 법적 권리이며 규제 기관 및 기타 정부 기관의 요구 사항을 충족합니다. 고객이 온라인 신청서 또는 기타 유형의 양식을 작성하거나 {brokerName} 웹 사이트를 통해 고객 계정 또는 거래를 운영 할 때 {brokerName}도 고객에 대한 정보를 수집하고 보유 할 수 있습니다.

			4. {brokerName}은 고객에 대한 민감한 정보를 수집 할 수 있습니다. {brokerName}에서 제공 한 불만 해결 방법이 고객을 만족시키지 못하는 경우 고객은 불만 사항을 추가적으로 조사할 개인 정보 보호 책임자에게 불만을 제기 할 수 있습니다.

			5. 고객은 {brokerName}이 보유하고있는 개인 정보를 요청하는 이메일을 보낼 수 있습니다. {brokerName}은 일반적으로 14일 이내에 고객 요청을 처리합니다. 고객의 요청이 복잡 할 경우이 경우 시간이 더 걸릴 수 있으며 {brokerName}은 고객에게 추가 시간이 필요함을 알릴 것 입니다. 고객의 개인 정보를 요청하는 데 드는 비용은 없지만 {brokerName}은 고객의 요청을 처리하는 데 합당한 수수료를 고객에게 청구 할 수 있습니다. 경우에 따라 법적으로 {brokerName}이 고객 접근을 거부 할 수 있습니다. {brokerName}이 {brokerName}의 기능 또는 운영과 관련되어 심각한 불법 행위 또는 위법 행위가 진행 중이라고 의심 할만한 이유가 있는 경우, 또는 고객의 요청이 정당한 이익을 해칠 가능성이 있는경우 요청이 거부 될 수 있습니다. 이 경우 {brokerName}은 고객에게 거부 사유(고객에게 이유를 제공하는 것이 부적절하거나 불합리하다고 판단되지 않는 한) 와 기타 규제 문제를 제공하고 고객의 기존 불만 메커니즘을 제공합니다. 가능한 경우 {brokerName}은 합리적인 조치를 취하고 {brokerName} 및 고객의 요구를 충족하는 경우 요청을 제공 할 수 있습니다.

			6. {brokerName}은 고객이 {brokerName}이 보유하고 있는 고객 프로필에 변경 사항이 있을 시 {brokerName}에게 즉시 알릴것을 요구합니다. 이를 통해 {brokerName}이 보유한 고객 프로필을 최신 상태로 유지하고 {brokerName}이 고객 계정의 상태, 고객의 마진 책임 및 고객의 거래 활동에 대해 고객에게 알릴 수 있습니다. 고객은 {brokerName}에게 부정확하다고 생각하는 고객 개인 정보를 변경하도록 요청할 수 있습니다. 정보의 수정 또는 정정, {brokerName}의 개인 정보 취급 방침 및 실제 정보 요청에 대한 요구 사항과 관련하여 {brokerName}과 고객이 정보의 정확성에 동의하지 않는 경우 고객은 {brokerName}에게 정보가 부정확하거나 불완전하다는 진술문을 요청할 수 있습니다. {brokerName}이 개인 정보 수정을 거부할 시 거부 사유를 나열하고 (거부 사유를 제공하는 것이 부적절하다고 판단되지 않는 한) 거부에 대한 불만 처리 메커니즘과 그와 관련된 정보를 포함한 서면 통지를 보낼 것 입니다.

			7. {brokerName}이 정보가 부정확하거나, 오래되었거나, 불완전하거나, 관련성이 없거나, 오해의 소지가 있다고 판단 하는 경우, {brokerName}은 개인 정보가 정확하고, 최신이며, 완전하고, 관련성이 있으며, 오해의 소지가 없도록 모든 합리적인 조치를 취하여 개인 정보를 수정 할 것입니다.

			8. {brokerName}은 고객 개인 정보의 부적절한 사용, 손실, 무단 사용, 간섭, 변경 또는 공개를 방지하기 위해 모든 합리적인 조치를 취할 것입니다.
			`,
    Dot: '.',
    // 20210217
    LoginDone1: 'Fully integrated transaction management system, including',
    LoginDone2: 'Multi-account visualized management',
    LoginDone3: 'Instant deposit and withdrawal',
    LoginDone4: 'Clear and concise fund record and transaction statements',
    LoginDone5:
      'Trading FX, metals, and CFDs is risky. We encourage you carefully consider your investment objectives. ',
    EWalletType: 'Types of e-Wallet',
    AcceptEWalletType: 'Types of acceptable e-wallets by the broker',
    EWalletSubmitMsg: `Please confirm the information you have filled in is correct before hitting the
				“Application” button. The broker will not be responsible for any losses associate with
				the incorrect inputs. `,
    // 20210719
    CryptocurrencyType: 'Type of cryptocurrency',
    CryptocurrencyTypeMsg: 'Cryptocurrency can only contain 3-10 characters',
    ProtocolsLabel:
      'Available Protocols (separate different protocols with semicolon ”;”)',
    ProtocolsMsg:
      'Each protocol name can only contain 3-10 characters including numbers',
    FormatMsg: 'Please confirm the format is correct',
    Decimal2Msg: 'Allow 2 decimal places',
    Protocol: 'Blockchain Protocol',
    // 20210720

    //20210817
    I18CreateUserAccountPrompt:
      'Account created successfully. Please email account information to the user.',
    I18CreateUserAccount: 'Create Account',
    // 20210819
    I18EnterEmailPleace: 'Email',
    I18AssignedAgent: 'Assigned Agent',
    I18SelectMarket: 'Select Market',
    I18SelectAgent: 'Select Agent',
    I18SelectNoAgent: 'No Agent',
    I18AgentChange: 'Modify Agent',
    I18SelectAgentTier: 'Select Tier',
    I18PromptTierCouldBeDifferent:
      'Default tier could be different with the tier in the original market.',
    I18ErrorGroupNotOpenInMarket:
      'The account {{TraderAccount.id}} of {{User.name}} was assigned to Group {{Group.name}} which is not available in the {{market.name}}.',
    I18PromptAgentChangeMarketOnlyWithLevels:
      'Agents are only allowed to change to market with multi-tiers.',
    I18NotHaveAgent: 'No agent.',
    // 20210924
    I18ClearCache: 'Clear cache',
    I18PromptClearCacheAfterUpdateManager:
      'To see your changes after updating the manager permissions on MT4/MT5, you may need to refresh by clearing your cache.',

    // 20211006
    I18FurtherInformation: 'Further information',
    I18LimitHalfwidthText: 'Limited to {limit} characters',
    I18ExceedTextLimit: 'Exceed the input limit',
    I18UpdateTimeLatest: 'Update Time',

    I18TraderAccountLimit: 'Maximum Trading Account',
    I18TraderAccountLimitExclude: 'Exclude existing accounts',
    I18TraderAccontLimitRange: 'Set a limit from {min} - {max}',

    // 20211122
    I18CustomField: 'Table on Demand',
    I18SelectAll: 'Select All',

    I18StorageNew: '이자',

    // 20211130
    I18OrderPosition: 'Position',
    I18OrderHistory: 'History',
    I18SetupPrice: 'Setup Price',
    I18OrderDoneTime: 'Done Time',
    I18OrderDoneTimeOrRange: 'Done Time',
    I18TradeTime: 'Time',
    I18FinalPrice: 'Price',
    // 20211209
    I18BankCodeEmptyCheck: 'SWIFT/IBAN code is unfilled, still save?',
    // 20211221
    I18DepositComfirmed: 'Deposit comfirmed',
    I18BrokerDepositDoubleCheckText:
      'Please confirm the deposit {currencyName} {amount} to {userName}?',
    I18BrokerDepositDepositTempText:
      'Please complete and approve the withdrawal application in the CRM system and make sure the amount first before actually transferring money to the client.',
    I18UserKycIsRequiredBeforeDeposit: 'Please complete user KYC first',
    I18WithdrawalRequestComfirmed: 'Withdrawal request comfirmed',
    I18AssetArrangementComfirmed: 'Asset arrangement completed',
    I18BrokerAssetArrangementToTraderDoubleCheckText:
      'Please confirm the asset arrangement {currencyName} {amount} to {traderId}?',
    I18BrokerWithdrawalRequestDoubleCheckText:
      'Please confirm the withdrawal {currencyName} {amount} for {userName}?',
    I18DepositByBroker: 'Deposit by broker',
    I18BrokerAssetArrangementToUserAccountDoubleCheckText:
      'Please confirm the user account {currencyName} {amount} to {accountId}?',
    I18AssetAmount: 'Amount',
    I18RequestByUserSelf: 'Request by client',
    I18RequestByBroker: 'Request by broker',
    I18TraderInformation: 'Trader Account Info.',
    I18ViewTraderInfoPermission:
      'Permission for agent to see trader account information',
    I18nUploadImageCorrupted: 'The uploaded image file is corrupted',
  },

  // 法文
  'co-fr': {
    Broker: 'Broker',
    Provider: 'Fournisseur',
    Trader: 'Trader',
    Signal: 'Signal',
    YES: 'Oui',
    NO: 'Non',
    //Menu Word
    Review: 'Vue',
    AgentApplication: "Application d'agent",
    AgentManage: "gestire l'account dell'agente",
    Client: 'Client',
    FollowerManage: 'Gestion des abonnés',
    FollowerList: 'Liste des abonnés',
    Order: 'Ordres',
    OrderHistory: 'Histoire des commandes',
    AccountDetail: 'Détails du compte',
    AccountHistory: 'Histoire du compte',
    CreatAccount: 'Créer un compte',
    FundHistory: 'Histoire du financement',
    ReadInfo: 'Vérifier les informations',
    // SIDE MENU
    Dashboard: 'Tableau de bord',
    Setting: 'Préférences',
    UserManagement: 'Gestion des utilisateurs',
    Audit: 'Nécessite une approbation',
    MarketGroup: 'Groupe de marché',
    Management: 'La gestion',
    Report: 'Signaler',

    // Word
    More: 'Détail',
    LoginSetting: 'Paramètres de connexion',
    LoginDevice: 'Périphérique de connexion',
    LoginDate: 'Date de connexion',
    Position: 'Emplacement',
    BrokerSetting: 'Préférences du broker',
    SystemSetting: 'Préférences de système',
    SettingDes1: 'Renforcer',
    SettingDes2:
      'Un environnement sûr et sécurisé pour le trading, si vous avez des questions ou des préoccupations concernant votre compte, veuillez nous contacter en utilisant les coordonnées fournies.',
    FundManage: 'Gestion de fonds',
    User: 'Utilisateur',
    UserManage: 'Gestion des utilisateurs',
    UserAccount: "Compte d'utilisateur",
    // Search placeholder
    PlaceholderUser: "Nom d'utilisateur, E-mail",
    PlaceholderTrader: "Nom du trader, Nom d'utilisateur",
    PlaceholderAgent: "Nom de l'agent",
    PlaceholderOrderQuery: 'Type',
    NotYetBindUser: 'Utilisateurs non liés',
    UserName: "Nom d'utilisateur",
    AgentName: "Nom de l'agent",
    KYC: 'Application KYC',
    PerLot: 'Par lot',
    PerDeal: 'Par commande',
    //Login
    Login: 'Connexion',
    LoginMt: 'Connexion MT',
    LastLogin: 'Dernière connexion',
    LoginNormal: "Nom d'utilisateur",
    LoginMtText: 'Compte et mot de passe MT',
    TextBuildAccount:
      'Pour obtenir un code de vérification, veuillez saisir votre adresse e-mail.',
    Password: 'Mot de passe',
    YourEmail: 'Votre e-mail',
    ForgotPassword: 'Mot de passe oublié?',
    TextForgotPassword:
      "Pour réinitialiser votre mot de passe, veuillez saisir l'adresse e-mail associée à votre compte CRM, nous vous enverrons un code de vérification à cet e-mail.",
    AccountManage: 'Gestion de compte',
    OrderQuery: 'Vérifier les commandes',
    OrderStatus: 'Statut de la commande',
    // LoginDone1:'Nous offrons le système de trading de copie de la plus haute qualité et des outils puissants.',
    // LoginDone2:'Abonnez-vous aux meilleurs signaux et dupliquez leurs trades.',
    // LoginDone3:'Retrait et Dépôt à votre rythme.',
    // LoginDone4:'Gestion de dossiers de signaux multiples.',
    // LoginDone5:'Investir est risqué, vous devez allouer votre capital en fonction du profil risque-rendement souhaité.',
    //Button:,
    Send: 'Envoyer',
    Confirm: 'Confirmer',
    AlreadyConfirm: 'Confirmé',
    Done: 'Terminé',
    Delete: 'Supprimer',
    EditName: 'Éditer',
    EditNickName: 'Modifier le nom',
    AddMarket: 'Créer un nouveau marché',
    AddLevel: 'Créer un nouveau niveau',
    EditLevel: 'Changer de niveau',
    DeleteLevel: 'Supprimer le niveau',
    DeleteLevelDes: 'Veuillez confirmer que vous souhaitez supprimer le niveau',
    DeleteLevelDes2:
      'Échec de la suppression du niveau en raison des utilisateurs restants',
    DuplicateLevel: 'Copier les derniers paramètres',
    DeleteLoginDevice: 'Supprimer le périphérique de connexion',

    DeleteMarket: 'Marché supprimé',
    CommissionSetting: 'Paramètres de commission',
    AddMarketDes:
      'Créer un nouveau marché, définir un nouveau niveau et une nouvelle commission.',
    DeleteMarketDes:
      'Veuillez confirmer que vous souhaitez supprimer le marché.',
    CanNotDeleteMarket:
      'Échec de la suppression du marché en raison des utilisateurs restants.',
    DeleteConfirm: 'Veuillez confirmer que vous souhaitez le supprimer.',
    ConfirmWithdraw: 'Veuillez confirmer le retrait',
    Upload: 'Télécharger',
    Demo: 'Démo',
    //NOTIFY
    PWDone: 'Votre mot de passe a été changé.',
    DepositDone: 'Dépôt demandé',
    DepositOk: 'Dépôt réussi',
    WithdrawalDone: 'Retrait réussi',
    ChangeGroupDone: 'Changement de groupe réussi',
    ChangeLeverageDone: 'Effectuer le changement de levier avec succès',
    DisableAccount: 'Suspendre le compte',
    EnableAccount: 'Récupérer le compte',
    EnableAccountDes:
      "Êtes-vous sûr de vouloir récupérer le compte? Cela permettra d'accéder à <br>",
    DisableAccountDes:
      'Êtes-vous sûr de vouloir suspendre le compte? Cela le bloquera de <br> <br>. ',
    ChangeParentDes: 'Ajuster le niveau supérieur',
    enableDemo: 'Activer la démo',
    cancelIB: 'Diable IB',
    ChangeClientToIB: "Transférer le client à l'IB suivant",
    ActionFailed: 'Action: échoué',
    ThrottleLimit: 'Trop de tentatives, veuillez réessayer plus tard.',
    CopyWordSuccess: 'Copié',
    CopyWordFailed: 'Copie incomplète',

    IPDes: 'adresse IP',
    //註冊
    RDone: 'Inscription terminée',
    RText:
      'Pour protéger vos droits, veuillez remplir ce formulaire correctement et complètement.',
    NickName: "Nom d'utilisateur",
    RRealName: 'Nom réel',
    Name: 'Nom',
    RPersonalInfo: 'informations personnelles',
    RBankInfo: 'Informations bancaires',
    RRestInfo: 'Information résidentielle',
    RIDCode: 'ID' - 'IDENTIFIANT',
    RIDDoc: "Preuve d'identification",
    RAddressDoc: "Preuve d'adresse résidentielle",
    RBankDoc: 'Preuve de compte bancaire',
    RIDP: "Copie face de la carte d'identité",
    RIDN: "Copie derrière de la carte d'identité",
    RBankDoc1: 'Détails bancaires-1',
    RBankDoc2: 'Détails bancaires-2',
    UserDetail: "Détails de l'utilisateur",
    UserInformation: "Informations de l'utilisateur",

    //SETTING:,
    TraderAreaTimezone: 'Fuseau horaire du trader',
    LanguageCode: 'Langue',
    Increase: 'Ascendant',
    Decreasing: 'Descendant',
    CompanyName: 'Nom de la compagnie',
    CompanyWebsite: "Site Web d'entreprise",
    BusinessHours: 'Heures de travail',
    WorkingDes:
      'Veuillez nous contacter si vous avez besoin de plus amples informations, nous sommes disponibles',
    SUN: 'Dimanche',
    MON: 'Lundi',
    TUE: 'Mardi',
    WED: 'Mercredi',
    THU: 'Jeudi',
    FRI: 'Vendredi',
    SAT: 'Samedi',
    OS: 'Système opérateur',
    RIDText: "Veuillez fournir votre pièce d'identité.",
    RAddressText:
      'Nous acceptons les preuves de résidence qui incluent votre nom et votre adresse, vous pouvez utiliser des factures de services publics en cours, un relevé bancaire ou un contrat de location, etc.',
    RBankText:
      "Veuillez fournir vos documents d'informations personnelles à l'appui de vos informations bancaires, comme un livret.",
    RGender: 'Sexe',
    RBirthday: 'Date de naissance',
    I18GenderMale: 'Homme',
    I18GenderFemale: 'Femme',
    RGender3: 'Autre',
    RGender4: 'Préfère ne pas déclarer.',
    RRegion: 'Pays',
    RNationCode: 'Code postal',
    RSelect: 'Sélectionner',
    RCity: 'Ville',
    RProvince: 'État',
    RArea: 'Quartier',
    RPhone: 'numéro de téléphone',
    RAddress: 'Adresse',
    Remail: 'E-mail',
    RPassword: 'Mot de passe',
    ROPassword: 'Ancien mot de passe',
    RNPassword: 'Nouveau mot de passe',
    RMTPassword: 'Mot de passe du compte MT',
    RMTPasswordLimited:
      'Le mot de passe doit être limité à 15 caractères et contenir à la fois des chiffres et des lettres / caractères spéciaux.',
    I18NewRMTPasswordLimited:
      'Le mot de passe doit être limité à 8-15 caractères et contenir à la fois des chiffres et des lettres / caractères spéciaux.',
    RPassword1:
      'Au moins 8 caractères et contenir des lettres ou des chiffres.',
    RPasswordConfirm: 'Confirmez le mot de passe',
    RPasswordConfirm1: 'Mot de passe incorrect',
    RPasswordReset: 'Réinitialiser le mot de passe',
    CreateAccount: 'Créer un compte',
    AccountName: 'Nom du compte',
    AccountPW: 'Mot de passe',
    AccountPWA: 'Retaper le mot de passe',
    MTLogin: 'Ancien mot de passe',
    OnlyNumber: 'Chiffres uniquement',
    OnlyNumberNoZero: 'Aucun zéro chiffre',
    NumberArea: 'Préférence de segment',
    Range: 'Varier',
    Minnum: 'Doit être supérieur au segment minimum.',
    Numrange: 'Doit être compris entre segment minimum et segment maximum.',
    MinValue: 'Minimum',
    MaxValue: 'Maximum',
    NextAccountNum: 'numéro du Compte suivant',
    NumberNow: 'numéro du Compte courant',
    MTPW: 'Mot de passe MT',
    //後端回傳Errors:,
    NoLogged: 'Pas connecté',
    AlreadyLogged: 'Connecté',
    AuthServiceError: "Erreur d'authentification",
    EmailIsAlreadyUsed: 'Cet e-mail est déjà utilisé pour un autre compte.',
    EmailDoesNotExist: "Cet e-mail n'existe pas.",
    WrongEmailOrPassword: 'Adresse e-mail ou mot de passe incorrect.',
    CertificationExpired: 'Certificatoin expiré',
    ErrorFailAccount:
      'La création du compte a échoué, veuillez contacter votre responsable de compte.',
    'Limit exceeded': 'Demandes répétées, veuillez réessayer plus tard.',
    'broker-trader-enable': 'Compte activé',
    'broker-sync-config': 'Synchronisation des informations',
    'broker-sync-trader': 'Account Sync',
    'broker-trader-setgroup': 'Groupe de trader',
    'broker-trader-leverage': 'Effet de levier du trader',
    CommissionIsGreaterThanThePreviousLevel:
      'La Commission ne peut pas dépasser le niveau précédent.',
    CommissionIsLessThanThePreviousLevel:
      'La Commission ne peut pas être inférieure au niveau suivant.',
    ManagerAPIConnFailed: "La connexion à l'API Manager a échoué.",
    BadRequest: "Demande d'erreur …",
    ImageDisabled: "Supprimer l'image",
    Inside: 'Interne',
    Outside: 'Externe',
    BrowserIcon: 'Browser',
    //Errors :,
    ErrorMail: 'Adresse e-mail non valide',
    ErrorPW:
      'Au moins 8 caractères et contenir à la fois des chiffres et des lettres / caractères spéciaux ： + - @ _ = * $.',
    ErrorVerify: 'Code de vérification incorrect',
    ErrorName:
      'Nom invalide (limité à 64 caractères, autorisé en majuscules / minuscules, nombre, espace et caractères spéciaux ： - _. · ，).',
    ErrorCorrect: 'Format invalide',
    ErrorLogin: 'Seuls les nombres sont autorisés, limités à 32 caractères.',
    ErrorPhone:
      'Doit être compris entre 7 et 15 caractères et seuls les nombres doivent être autorisés.',
    ErrorID:
      "Veuillez saisir le numéro d'identification figurant sur votre pièce d'identité.",
    ErrorCountryCode: 'Veuillez sélectionner le pays',
    ErrorEmpty: 'Ne peut être nul ou vide.',
    ErrorAccount:
      'Format de compte invalide, doit contenir au moins 8 caractères.',
    MemberTern: 'Termes et conditions',
    PrivacyPolicy: 'Politique de confidentialité.',
    TextKnowing:
      'Je confirme avoir lu, compris et accepté la politique de confidentialité.',
    TextKnowing2:
      "Le trading de CFD comporte un risque considérable de perte en capital. Les CFD sont des instruments complexes et comportent un risque élevé de perdre de l'argent rapidement en raison de l'effet de levier. Des conditions générales s'appliquent.",
    VerifyPassText:
      'Demande acceptée, veuillez vérifier vos informations personnelles.',
    SecurityBlock: 'Trade désactivé',
    GroupBlock: 'Groupe suspendu',
    ErrorReason: "Rapport d'erreur",
    Or: 'ou',
    None: 'Rien',
    BackLogin: 'Retour connexion',
    VerifyCode: 'Vérifier le code',
    Cancel: 'Annuler',
    ReSend: 'Soumettre à nouveau',
    VerifyReceived:
      'Veuillez vérifier votre e-mail pour votre code de vérification.',
    SendVerify: 'Vérifier votre e-mail',
    EmailAlready:
      'Email déjà utilisé. Veuillez vous connecter avec votre compte ou créer un nouveau compte. ',
    EmailNotExist: "L'e-mail n'existe pas, veuillez en essayer un autre.",
    Required: 'Champs requis',
    RequiredSwift: 'Code SWIFT non valide',
    RequireIban: 'Code IBAN invalide',
    //Bank
    ReceviedBankInfo: 'Informations bancaires du bénéficiaire',
    GBankName: 'Nom de la banque',
    GBankAddress: 'Adresse de la banque',
    GBankAccount: 'Compte bancaire',
    GManName: 'Destinataire',
    GManAccount: 'Compte du destinataire',
    GManAddress: 'Adresse du destinataire',
    GBankCode: 'Code bancaire',
    PaymentCurrency: 'Devise',
    PaymentChannel: 'Mode de paiement',
    PaymenLimit: 'Un dépôt minimum de 1 000 USD est requis.',
    ChoosePayment: 'Choisir le paiement',
    TradeCurrency: 'Devise',
    Mer_no: 'Marchande',
    InputMer_no: 'Entrez le numéro du marchand',
    Telegraphic: 'Télégraphique',
    SettlementByDay:
      'Règlement quotidien; Synchroniser les ordres tous les jours à GMT +0 00:00; Régler à GMT +0 01:00 le jour suivant',
    SettlementByWeek:
      'Règlement hebdomadaire; Synchroniser les commandes tous les jours à GMT +0 00:00; régler à GMT +0 01:00 tous les dimanches',
    SettlementByMonth:
      'Règlement mensuel; Synchroniser les commandes tous les jours à GMT +0 00:00; Régler à GMT +0 01:00 le 1er du mois suivant',
    //註冊:,
    ErrMsg2: 'Veuillez réessayer plus tard.',
    //Signal
    STotalInvest: 'Investissement',
    Balance: 'Solde',
    SEquity: 'Équité',
    Save: 'Sauvegarder',
    Show: 'Montrer',
    Num: 'Enregistrer',
    AddAccountDirect: 'Ajouter un compte',
    BindMT4Account: 'Lien MT4',
    SAvailableFunds: 'Fonds disponibles',
    WithdrawCost: 'Retiré',
    DepositCost: 'Déposé',
    WaitWithdrawCost: 'En attente de retrait',
    ApplicationWithdraw: 'Retirer',
    SFPnL: 'P / L flottant',
    SMargin: 'Marge',
    MarginUsed: 'Marge utilisée',
    MarginCanUse: 'Marge disponible',
    MarginRatio: 'Pourcentage de marge',
    SIncome: 'Revenu',
    SRetreat: 'Retraite',
    SAddPt: 'Compte bancaire',
    Leverage: 'Effet de levier',
    AccountNum: 'Utilisateur',
    AgentNum: "Numéro d'agent",
    Sercurity: 'Types de produits',
    MarginCall: 'Marge de maintenance',
    MarginStopOut: 'Arrêter',
    TitleTrade: 'Types de produits',
    SCredit: 'Crédit',
    Sscore: 'But' - 'Marque',
    Default: 'Défaut',
    SetDefault: 'Définir comme groupe par défaut.',
    LeverageDefault: "L'effet de levier par défaut",
    ChangeLeverage: "Modifier l'effet de levier",
    All: 'Tout',
    PnLTotal: 'P / L total',
    ChooseGroup: 'Choisir un groupe',
    ChangeGroup: 'Changer de groupe',
    //Order
    OnHold: 'En attente',
    OrderClose: 'Couverture',
    OrderOpen: 'Intérêt ouvert',
    OrderCloseProcess: "En cours d'être traité",
    NoUnprocess: 'Aucune approbation en attente',
    EmptyMsg1: 'Informations disponibles',
    EmptyInfo: "Pas d'information disponible",
    Empty: 'Vide',
    UnitPoint: 'Point unitaire',
    Add: 'Ajouter',
    Edit: 'Modifier',
    PopUpCloneDes:
      'Créez un compte et vous pourrez échanger plusieurs produits et gérer vos fonds',
    CopyPromoteLink: 'Lien de référence',
    readOnlypassword: 'Afficher le mot de passe uniquement',
    BalanceReport: 'Rapport de solde',
    UserDistribution: 'Distribution des utilisateurs',
    SelectPlaceHolderChoseAccount: 'Choisir un compte',
    SelectAgent: 'Sélectionner',
    AdvanceSearch: 'Recherche Avancée',
    FunctionSearch: 'Outils de recherche',
    FunctionSearchTrader:
      'Entrez le compte du trader. Utilisez une virgule pour séparer deux ou plusieurs comptes.',
    FunctionSearchUser:
      'Entrez le compte utilisateur. Utilisez une virgule pour séparer deux ou plusieurs comptes.',
    FunctionCommission: 'Entrer le n ° de commission',
    Search: 'Rechercher',

    ChoisePayment: 'Choisir le paiement',
    PaymentMethod: 'Mode de paiement',
    PaymentSetting: 'Préférence de paiement',
    PaymentColegantDes:
      'Nous vous dirigerons vers une autre page lors du choix de Colegant.',
    LastFiveNum: 'Cinq derniers chiffres du numéro de compte',
    //No Data
    // REPORT
    Subscribe: "S'abonner",
    UnSubscribe: 'Se désabonner',
    UnSubscribeDes: 'Vous êtes maintenant désabonné.',
    TradeNoAccess: 'Aucun accès au trading',
    UserNoAccess: "Pas d'accès",
    TSymbol: 'Téléscripteur',
    TOrderNo: 'Ordre#',
    TType: 'Type de commande',
    TSummary: 'Résumé',
    TLots: 'lots',
    TTrade: 'Volume',
    TPrice: 'Prix',
    TSecurity: 'Type de commande',
    TOpenDate: "Date d'ouverture",
    TOpenArea: 'Date de position ouverte',
    TCloseArea: 'Date de clôture de la position',
    OpenPrice: 'Prix ouvert',
    TCloseDate: 'Date de clôture',
    CurrentPrice: 'Prix actuel',
    ClosePrice: 'Prix de clôture',
    OrderSyncTime: 'Synchronisation de la dernière commande',
    TStopLoss: 'Stop Loss',
    TStopWin: 'Stop Gain',
    ProfitLoss: 'Bénéfice net',
    Storage: 'Intérêt',
    Fees: 'Frais',
    TProfit: 'Profit',
    TradeLoss: 'Perte',
    GMTWord: 'Fuseau horaire actuel',
    //Record
    BackPoint: 'Remise',
    SettingPC: 'Réglage de la remise',
    Commission: 'Commission',
    Commission2: 'Rabais',
    CommissionReport: 'Rapport de la Commission',
    CommissionOrder: 'Commission#',
    MarketNone: 'Pas de marché actuel',
    SettlementTime: 'Heure réglée',
    IntervalTime: 'Temps',
    CommissionType: 'Type de commission',
    CommissionRange: 'Commission',
    NormalCommission: 'Commission normale',
    SameCommission: 'Commission égale',
    OverCommission: 'Commission supérieure',

    //Application:,
    Application: 'Application',
    ApplicationMan: 'Demandeur',
    ApplicationName: 'Nom du demandeur',
    ApplicationAccount: 'Compte du demandeur',
    ApplicationEmail: 'Courriel du demandeur',
    ApplicationOrder: 'Demande#',
    ApplicationType: 'Type',
    ApplicationPhone: 'Numéro du demandeur',

    BuyQuty: 'Acheter Qté',
    SellQuty: 'Vendre Qté',
    PnL: 'Net P / L',
    PnLArea: 'Gamme P / L',
    // 無對應字
    ProfitShare: 'Profit share',
    FundOut: 'Retirer',
    FundIn: 'Ajouter un fonds',
    SelectMarkets: 'Sélectionner un marché',
    SelectLevel: 'Sélectionner un niveau',
    SetLevel: 'Modifier le niveau',

    SelectLevelDes:
      "Le changement de niveau n'est autorisé que sur le même marché.",
    SelectCharacter: 'Catégorie',
    SelectAccountStatus: 'Statut du compte',
    TotalCommission: 'Commission',
    CommissionAccounting: 'Calculer la commission',
    getCommission: 'Recevoir la Commission',
    SelectAgentDes: 'Vérifier les agents rémunérés à la commission',
    CreateAccounRecord: 'Nouveaux comptes 1W',
    LastSettlementCommission: 'Dernière commission payée',
    TraderVolum7: 'Volume 1W',
    Day7: '1w',
    PnL7: '1w P/L',
    HotSymbol: 'Tendence actuelle',
    Credit: 'Crédit',
    TotalWithdrawal: 'Retrait total',
    Withdrawal: 'Retirer',
    WithdrawalCost: 'Disponible pour retrait',
    Deposit: 'Dépôt',
    TotalDeposit: 'Dépôt total',
    WithdrawalMin: 'Montant minimum de retrait',
    Costmin: 'Le montant ne peut pas être inférieur à',
    DepositNum: 'Nombre de transactions',
    WithdrawalNum: 'Histoire des retraits',
    //交易組別
    AllGroup: 'Tous les groupes',
    MTGroup: 'groupe',
    DefaultGroup: 'Groupe par défaut',
    MTGroupSetting: 'Paramètres du groupe',
    GroupIn: 'Groupe attribué',
    Enabled: 'Activée',
    Disable: 'Suspendu',
    IsIBANSWIFT: "Avez-vous besoin d'un code SWIFT / IBAN?",
    //資金配置紀錄
    FundArrange: 'Arrangement de fonds',
    SelectCostRange: 'Gamme',
    SelectCostRangeDesMax:
      'Entrez le montant maximum, puis triez par ordre décroissant',
    SelectCostRangeDesMin:
      'Entrez le montant minimum, puis triez par ordre croissant',
    CostRange: 'Montant',
    Arragne: 'Distribution',
    ArragneTo: 'Distribuer à',
    EnterFundCost: 'Entrer le montant',
    FunArrangeRecord: 'Historique de distribution',
    FundArrangeMin: 'Montant minimal',
    TradeHistory: 'Histoire',
    ChoseFrom: 'Choisissez parmi',
    Wallet: 'Portefeuille',
    Type: 'Méthode',
    Time: 'Temps',
    StartTime: 'Heure de début',
    EndTime: 'Heure de fin',
    Start: 'Début',
    End: 'Fin',
    Cost: 'Montant',
    AccountRecord: 'Histoire des transferts',
    Function: 'Fonctions',
    Summary: 'Résumé',
    KYCStatus: 'Vérification KYC',
    Action: 'Vérifier les détails',
    Comment: 'Remarques',
    Account: 'Compte',

    //市場
    MarketCommission: 'Commission de marché',
    MarketCommissionSetting: 'Paramètres de commission',
    Market: 'Marché',
    EditMarket: 'Modifier le marché',
    Agent: 'Agents',
    AgentList: 'Gestion des agents',
    IsAgent: 'Agent',
    NotAgent: 'Pas un agent',
    MarketLevel: 'Niveau du marché',
    MarketName: 'Nom',
    MarketCost: 'Transactions de marché',
    Level: 'Niveau',
    Detail: 'Détails',
    ChannelName: 'Canal',

    //User List:,
    TraderName: 'Nom du trader',
    AccountStatus: 'Statut du compte',
    Register: "S'inscrire",
    RegisterEnabled: 'Activée',
    RegisterDisabled: 'Désactivée',
    RegisterDate: "Date d'inscription",
    AuditDate: 'Date de la demande',
    ApplicationDate: 'Date de la demande',
    ApplicationDateInterval: 'Date de la demande',
    HandleDate: "Date de l' opération",
    HandleDateInterval: "Date de l' opération",
    CompletedDate: "Date d'achèvement",
    ChoseGMTTime: 'Changez votre fuseau horaire',
    DepositOrder: 'Compte de dépôt#',
    Completed: 'terminé',
    WithdrawalApplication: 'Demande de retrait',
    StopAccountDes:
      'Votre compte a été suspendu. Veuillez contacter votre administrateur.',
    WithdrawalOrder: 'Compte de retrait#',
    AllCharacters: 'liste complète de tous les membres',
    Characters: 'Catégorie',
    Status: 'Statut',
    ThisWeek: 'Histoire 1W',
    ThisMonth: 'Histoire 1M',
    //交易數據
    TradeStatus: 'Statut',
    TradeData: 'Données' - 'Informations',
    TradeEva: 'Évaluation',
    TDPL: 'Net P / L',
    TDLots: 'Total des lots',
    TDInterest: 'Intérêt total',
    TDHandingFee: 'Montant total',
    //NOTIFY
    NewApplication: 'Nouvelle requête',
    DepositSuccess: 'Dépôt réussi',
    Approved: 'Approuvé',
    WithdrawnSuccess: 'Retrait réussi',
    Reject: 'Refusé',
    Reject2: 'Refusé',
    RejectApplication: 'Demande refusée',
    Void: 'Invalide',
    Check: 'Vérifié',
    Grant: 'Distribué',
    BalanceStatus1: 'Retrait-Nouvelle demande',
    BalanceStatus2: 'Retiré-approuvé',
    BalanceStatus3: 'Retrait refusé',
    BalanceStatus4: 'Retrait-traité',
    BalanceStatus5: 'Dépôt-Nouvelle demande',
    BalanceStatus6: 'Dépôt refusé',
    BalanceStatus7: 'Dépôt-Succès',
    BalanceStatus8: 'Retrait-en traitement',
    BalanceStatus9: 'Retrait-échoué',
    BalanceStatus10: 'Retrait-Succès',
    BalanceStatus11: 'Dépôt échoué',
    BalanceStatus12: 'Dépôt-Succès',

    RejectReason: 'Dépôt-Succès',
    Notify: 'Notifications',
    ViewAllNotify: 'Effacer toutes les notifications',
    Clean: 'Effacer',
    EnabledAgent: "Activer l'agent",
    ChangeParent: "Ajuster l'interlignage",

    // ORGANIZATION
    Organization: 'Organisation',
    People: 'Utilisateurs',
    OrganizationPeople: 'Utilisateurs',
    TotalUser: "Nombre total d'utilisateurs",
    OrganizationStructure: 'Structure',
    BrokerAgent: 'Agents du broker',
    BrokerUser: 'Clients du broker',
    AgentAgent: "Agents de l'agent",
    Agentuser: "Clients de l'agent",
    DirectUser: 'Clients directs',
    NormalAgent: 'Agent subordonné',
    OverAgent: 'Agent supérieur',
    SameAgent: 'Agent égal',
    Referrer: 'Référent',
    OrganizationDes:
      'Trier par niveau de marché, les non-agents seront placés en bas.',
    //--
    SameBonus: 'Bonus égal',
    OverBonus: 'Bonus supérieur',
    //UNIT
    UnitMan: 'Utilisateurs',
    UnitStuff: 'Unités',
    SyncConfig: 'Sync',
    SyncOrderhistory: 'Historique de synchronisation',
    TraderLeverage: "Modifier l'effet de levier",
    TraderSetGroup: 'Modifier le groupe de trader',
    WalletRequestDepositApprove: 'Déposé',
    WalletRequestWithdrawWithdraw: 'Retiré',
    WalletRequestDepositReject: 'Échec du dépôt',
    WalletRequestWithdrawReject: 'Échec du retrait',
    UserEnable: 'Compte Récupéré',
    UserDisable: 'Compte suspendu',
    UserPasswordUpdate: 'Mot de passe mis à jour',
    AgentAdd: 'Vous êtes maintenant un agent.',
    AgentRemove: "Vous avez été retiré du statut d'agent",

    BrokerNotFound: 'Broker introuvable',
    WrongManagerAPI: 'API de gestionnaire incorrecte',
    MT4Error: 'Erreur MT',
    ServiceError: "Une erreur s'est produite. Veuillez réessayer plus tard.",
    TraderIsNotExists: 'Impossible de trouver votre compte.',
    //交易帳號相關
    TraderAccount: 'Compte',
    TraderAccountManage: 'Gestion du compte',
    SyncTrader: 'Compte de synchronisation',
    TraderEnable: 'Récupération du compte de trading',
    TraderDisable: 'Compte de trading suspendu',
    TraderAccountAdd: 'Créer un compte de trading',

    TraderCreateFailed: 'Impossible de créer un compte de trading.',
    TraderAccountAddText:
      'Créez un compte et vous pourrez échanger plusieurs produits et gérer vos fonds',
    TraderAccountNone: 'Pas de compte Trader',
    TraderCreate: 'Créer un compte de trading',

    TraderBind: 'Lier un compte de trading',
    TraderBindFailed: 'Échec de la liaison',
    TraderDetail: 'Détails du compte',
    TraderNotFound: 'Impossible de trouver votre compte.',
    ModifyAccountPassword: 'Réinitialiser le mot de passe',
    // Personal Setting
    About: 'À propos',
    Personal: 'Utilisateur',
    PersonSetting: 'Préférence',
    CompanySetting: "Paramètres de l'entreprise",
    BankSetting: 'Préférence bancaire',
    WorkingDateSetting: 'Heure de travail',
    UserOpenAccountSetting: 'Paramètres du compte utilisateur',
    AuditSetting: "Paramètres d'approbation",
    AgentRuleSetting: 'Règlement des agents',
    Logout: 'Se déconnecter',

    // ChangeTradePW
    TraderPWModify: 'Changer le mot de passe du compte trader actuel.',
    TraderPWMain: 'Changer le mot de passe du compte principal.',
    TraderPWRead: 'Lire le mot de passe uniquement',
    UserNotFound: 'Utilisateur non trouvé',
    TransferFailed: 'Échec du transfert',
    Loading: 'Chargement',
    WrongPassword: 'Mot de passe incorrect',
    SomethingWrong: "Quelque chose s'est mal passé!",
    TryLatter: 'Veuillez réessayer plus tard.',

    Success: 'Succès',
    AddDone: 'Ajouté avec succès',
    AddFailed: "Échec de l'ajout",
    SaveDone: 'Enregistré',
    SaveFailed: 'Impossible de enregister',
    Failed: 'échouée',
    AsyncSend: 'Demande envoyée',
    ProcessRequest: 'Demande en cours',
    //Broker audit
    AuditKyc: 'Vérification KYC',
    AuditWithdraw: 'Retrait',
    AuditDeposit: 'Dépôt',
    AuditReject: 'Demande refusée',
    AuditKycReject: 'KYC refusé',
    AuditPass: 'Êtes-vous sûr de vouloir approuver la demande?',
    AuditRejectRes:
      'Veuillez envoyer un commentaire pour aider à résoudre le problème.',
    AuditAllow: 'Approuvé',
    NextStep: 'Étape suivante',
    PrevStep: 'Étape précédente',
    LastUpdate: 'Dernière mise à jour',
    Least7Day: '7 derniers jours',
    Allow: 'Vérifié',
    NotAllow: 'Non vérifié',
    AuditKycAllow: 'Le KYC a été vérifié.',
    Retry: 'Veuillez réessayer',
    Reason: 'Raison',
    Download: 'Télécharger',
    To: 'À',
    // Broker-使用者管理
    ParentAccount: 'Supérieur',
    NoParent: 'Rien',
    Direct: 'Relatif direct',
    Direct2: "S'il s'agit d'un parent direct.",
    // Button
    Operation: 'Options',
    OperationDone: "L'opération est terminée",
    //KYC return
    KYCUNSUBMITTEDTitle: 'KYC incomplet',
    KYCUNSUBMITTED: 'Non soumis',
    KYCSUBMITTEDTitle: 'Vérification',
    KYCAllowTitle: 'Vérifié',

    KYCAllow: 'KYC approuvé',
    KYCReject: 'KYC refusé',
    KYCUNSUBMITTEDDes:
      'Une fois votre vérification a été effectuée, vous pourrez gérer votre compte et approvisionner votre compte.',
    KYCSUBMITTEDDes:
      'Une fois votre vérification a été effectuée, vous pourrez créer un nouveau compte de trading.',
    KYCFAILEDDes:
      'Votre demande a été refusée, veuillez mettre modifier votre demande et la renvoyer.',
    KYCUNSUBMITTEDButtonText: 'Vérification KYC',
    KYCSUBMITTEDButtonText: 'KYC en cours de vérification',
    KYCFAILEDButtonText: 'Demander à nouveau',
    KycStatusSubmitted: 'En attente de validation',
    KycStatusPassed: 'KYC a été vérifié.',
    KycStatusFailed: 'KYC a été refusé.',

    IDCardFrontIsARequiredField: "La taille de l'image d'identité doit être",
    IDCardFrontExceeds600kB:
      "La taille de l'image d'identité doit être inférieure à 600 KB",
    IDCardFBackIsARequiredField: "La taille de l'image d'identité doit être",
    IDCardBackExceeds600kB:
      "La taille de l'image d'identité doit être inférieure à 600 KB",
    AddressFileIsARequiredField: "La preuve d'adresse résidentielle doit être",
    AddressFileExceeds600kB:
      "La preuve d'adresse résidentielle doit être inférieure à 600 KB",
    BankFrontFileIsARequiredField: 'Le document du compte bancaire doit être',
    BankFrontFileExceeds600kB:
      'Le document du compte bancaire doit faire moins de 600 KB',
    BankBackFileIsARequiredField: 'Le document du compte bancaire doit être',
    BankBackFileExceeds600kB:
      'Le document du compte bancaire doit faire moins de 600 KB',
    // 20201027 END:,

    ApplicationFailed: 'Demande échoué',
    ParentAgent: 'Supérieur',
    YesReadInfo: 'Oui, veuillez vérifier les informations.',
    MarketIn: 'Marché assigné',
    IdCardNumber: 'IDENTIFIANT',
    ReceviedBankName: 'Banque bénéficiaire',
    ReceviedBankAddress: 'Adresse de la banque du destinataire',
    ChangeMarket: 'Ajuster le marché',
    ChangeMarketErrorMsg1:
      'Non autorisé à modifier le marché en raison de plusieurs niveaux sur le marché.',
    // 20201028 END
    ReadTraderAccount: 'Comptes Trader',
    // 20201116 END
    UserInfo: "Informations de l'utilisateur",
    AddrInfo: 'Adresse',
    BankInfo: 'Informations bancaires',
    MarketGroupMap: 'Marché et groupes',
    IncludeGroup: 'Groupes inclus',
    SetDefault1: 'Définir par défaut',
    LevelName: 'Nom du niveau',
    // 20201119 END
    OrderAdvanceSearchMsg1: 'Il existe différents services selon les serveurs.',
    // 20201123 END
    BindOtherAccount: 'Lien vers un autre compte',
    Server: 'Serveur',
    // 20201124 END
    Broker_Deposit_Applying: 'Nouvelle requête',
    User_Deposit_Applying: 'Traitement des paiements',
    Broker_Deposit_Reviewing: 'Payé',
    User_Deposit_Reviewing: "En cours d'être traité",
    Broker_Deposit_rejected: 'Refusé',
    User_Deposit_rejected: 'Demande échoué',
    Broker_Deposit_Approved: 'Financé',
    User_Deposit_Approved: 'Financé',

    Broker_Withdraw_Applying: 'Nouvelle requête',
    User_Withdraw_Applying: "Demande en cours d'être traité",
    Broker_Withdraw_Approved: 'Approuvé',
    User_Withdraw_Approved: 'Approuvé',
    Broker_Withdraw_rejected: 'Refusé',
    User_Withdraw_rejected: 'Demande échoué',
    Broker_Withdraw_Withdraw: 'Retiré',
    User_Withdraw_Withdraw: 'Retiré',
    // 20201127 END
    DepositIn: 'Dépôt net',
    AgentInfo: "Informations sur l'agent",
    // 20201201 END
    DepositSetting: 'Préférence de dépôt',
    WithdrawalSetting: 'Préférence de retrait',
    DepostieWallet: 'E-wallet' - 'E-portefeuille',
    // 20201207 END
    ApplyAmount: "Montant demandé'",
    PaymentDetail: 'Détails du paiement',
    DesignationRate: 'Taux',
    PaymentAmount: 'Montant payé',
    OrderNumber: 'Ordre#',
    ApplyerEmail: 'E-mail du candidat',
    CallApiErrorMsg: 'Veuillez recharger ou réessayer plus tard.',
    Deleted: 'Supprimé',
    DeleteEWallet: 'Supprimer le portefeuille',
    DeleteEWalletMsg: 'Êtes-vous sûr de vouloir supprimer le portefeuille？',
    WalletName: 'Nom du portefeuille',
    EWalletEnableMsg: 'Veuillez choisir au moins un portefeuille.',
    MinAmount: 'Montant minimal',
    MaxAmount: 'Quantité maximale',
    Channel: 'Méthodes',
    DeleteChannelMsg: 'Êtes-vous sûr de vouloir supprimer le mode de paiement?',
    CurrencyEnableMsg: 'Veuillez choisir au moins une devise.',
    DeleteChannel: 'Supprimer cette méthode',
    Warning: 'Alertes',
    WalletAddress: 'Adresse du portefeuille',
    EnterWalletAddressMsg: "Entrez l'adresse de votre portefeuille",
    EnterWalletNameMsg: 'Entrez un nom pour votre portefeuille',
    DeleteCurrencyMsg: 'Êtes-vous sûr de vouloir supprimer la devise?',
    DeleteCurrency: 'Supprimer la devise',
    EnterChannelNameMsg: 'Entrer la chaîne',
    EnterAmountRangeMsg: 'Veuillez saisir une gamme valide',
    EnterCurrencyNameMsg:
      'Veuillez saisir un code de devise valide à 3 caractères.',
    EnterRateMsg: "Jusqu'à 6 décimales.",
    EnterRateMsg8: "Jusqu'à 8 décimales.",
    MinAmountEqualUSDMsg: 'Montant minimum en USD',
    MaxAmountEqualUSDMsg: 'Montant maximum en USD',
    EnterCurrencyMsg: 'Saisir la devise',
    EnterRateMsg2: 'Saisir le taux de change',
    Created: 'Créé',
    Edited: 'Édité',
    EnterTokenMsg: 'Entrez le toekn',
    TransferInfo: 'Informations sur le transfert',
    BankAccountName: 'Nom du titulaire du compte',
    Rate: 'Taux de change',
    TransferInAmount: 'Montant converti',
    MinAmountMsg: 'Montant minimal',
    MaxAmountMsg: 'Montant maximale',
    TransferOutAmount: 'Montant converti',
    NoEnableCurrencyAmount:
      'Aucune devise disponible, veuillez contacter votre administrateur.',
    AccountInfo: 'Information sur le compte',
    ReceiveMan: 'Destinataire',
    ApplySent: 'Demande envoyée',
    // 20201214 END
    WrongDataMsg: 'Corrigez les champs non valides.',
    EnterBankNameMsg: 'Saisir le nom de la banque',
    EnterBankCodeMsg: 'Saisir le code bancaire',
    EnterBankAccountNameMsg: 'Entrez le nom du titulaire du compte',
    EnterBankAccountMsg: 'Entrez le numéro de compte',
    EnterBankAddressMsg: "Saisir l'adresse de la banque",
    EnterRateMsg3: 'Le taux de change doit être supérieur à 0.',
    // 20201215 END
    IDCardFrontExceeds:
      "La taille de l'image d'identité doit être inférieure à 1,2 MB",
    IDCardBackExceeds:
      "La taille de l'image d'identité doit être inférieure à 1,2 MB",
    AddressFileExceeds:
      "La preuve d'adresse résidentielle doit être inférieure à 1,2 MB",
    BankFrontFileExceeds:
      'Le document du compte bancaire doit être inférieur à 1,2 MB',
    BankBackFileExceeds:
      'Le document du compte bancaire doit être inférieur à 1,2 MB',
    // 20201216 END
    UserIsDisabled: 'Compte non activé',
    // 20201222 END
    YourEWalletAddr: 'Votre portefeuille électronique',
    Copy: 'Copie',
    UserEWalletAddr: 'Portefeuille électronique des clients',
    // 20201228 END
    AllMarket: 'Tous les marchés',
    // 20201229 END
    ActivePeople: 'Comptes actifs',
    TotalPeople: 'Total des comptes',
    PlatformFilter: 'Choisir un appareil',
    Mobile: 'Appareil mobile',
    Tablet: 'Tablette',
    Desktop: 'Ordinateur',
    AccountAndName: 'Compte / Nom réel',
    ShowAllDataMsg: 'Afficher tout',
    // 20201230 END
    Lots: 'Lots',
    // 20210127 END
    MemberTernDesc: `{nom du broker} est heureux de vous fournir des informations, du contenu, des outils, des produits et des services. Ces termes et conditions incluent également des divulgations et des informations importantes sur certains produits et services. Votre utilisation du site Web de {nom du broker} est soumise aux présentes conditions générales. Ces conditions générales constituent un accord contraignant entre vous et {nom du broker}. Votre connexion et votre utilisation de ce site Web nécessitent que vous acceptiez ces termes et conditions et les autres avis et déclarations juridiques publiés sur ce site Web. Votre utilisation du site Web de {nom du broker} sera régie par la version des conditions générales en vigueur sur le site Web de {nom du broker} ce jour-là.

			{nom du broker} peut modifier ces termes et conditions à tout moment sans préavis. Votre connexion continue et votre utilisation de ce site Web vous obligent à accepter ces termes et conditions. {nom du broker} ne vous informera pas de tout changement ou modification individuellement.

			Ces conditions générales sont des accords entre vous et {nom du broker} (compris tout contrat de client ou de compte et restreignent votre utilisation des informations, du contenu, des outils, des produits et des services fournis sur le site Web de {nom du broker} et via le {nom du broker} site Web Tous autres accords). Sauf si vous avez un accord écrit avec {nom du courtier}, le site Web de {nom du broker} est réservé à votre usage personnel et non commercial.
			Les informations sur ce site Web ne sont pas destinées à être distribuées ou utilisées par toute personne qui enfreint les lois ou réglementations locales de son pays ou de sa juridiction. Les services ou investissements décrits sur le site Web de {Nom du broker} ne seront pas fournis aux personnes qui fournissent ce service ou qui investissent en violation des lois ou réglementations locales de leur pays de résidence.

			Les visiteurs de ce site Web sont tenus de confirmer les termes des lois ou réglementations locales auxquelles ils sont liés et auxquels ils se conforment. Le contenu du site Web {brokerName} ne doit pas être considéré comme une invitation à acheter un produit ou un service ou une offre de vente de tout produit ou service à une personne qui participe à une telle offre, sollicitation, achat ou vente conformément à la juridiction. Les gens dans les juridictions où la loi est illégale.
				`,
    PrivacyPolicyDesc: `{nom du Broker} respecte la vie privée et valorise la relation avec les clients. {nom du broker} offre aux clients une protection de la confidentialité des données personnelles et a gagné le soutien et le respect des clients; selon des mesures de sécurité strictes, toutes les données personnelles collectées par {nom du broker} seront protégées. Cette politique de protection de la vie privée explique comment {nom du broker} collecte les données personnelles, et comment elles sont utilisées et divulguées. Et expliquez en détail les droits dont disposent les clients. Cette politique s'applique aux clients qui sont sur le point de devenir AIA Forex, aux clients qui sont devenus {nom du broker} et aux clients qui postulent pour devenir {Nom du broker}.

				1. Conformément à la loi de 2006 sur la lutte contre le blanchiment d’argent et de la lutte contre le terrorisme, {nom du broker} est chargé de collecter les informations relatives à l’identité du client et de vérifier la véritable identité du client. {nom du broker} effectuera les procédures d'identification et de vérification des clients conformément au New Zealand Privacy Act 1993.

				2. Lorsqu'un client soumet un formulaire de demande pour ouvrir un compte réel ou simulé {nom du broker}, la société {nom du broker} fournira les informations personnelles du client. Lorsque vous postulez pour ouvrir un compte réel ou simulé {nom du broker}, vous confirmez et acceptez les conditions générales pertinentes et prouvez que le consentement que vous avez fourni à {nom du broker} est volontaire. Les informations personnelles sont toutes les informations sur les clients, qui peuvent identifier les clients ou confirmer leur véritable identité. Lorsque des informations personnelles sont collectées par un tiers (et non par le client lui-même), {nom du broker} prendra des mesures raisonnables pour s'assurer que le client sait que les informations ont été collectées et collectées.

				3. {nom du broker} conserve tous les enregistrements des transactions et des activités des comptes clients, y compris (mais sans s'y limiter) les informations de clôture des comptes clients. {nom du broker} collectera des informations sur les clients à partir de sources publiques disponibles (telles que le registre de l'entreprise). {nom du broker} conservera les données personnelles des clients en stricte conformité avec les principes de la loi néo-zélandaise de 1993 sur la confidentialité. Les clients peuvent demander l'accès aux informations client détenues par {nom du broker} à tout moment. {nom du broker} peut enregistrer les conversations téléphoniques entre les clients et le personnel de {nom du broker}. Cet enregistrement peut être utilisé pour résoudre tout litige entre le client et {nom du broker}, ce qui est le droit légal de {nom du broker} et répond aux exigences des agences réglementaires et autres organisations gouvernementales. Lorsque les clients remplissent des applications en ligne ou d'autres types de formulaires, ou gèrent des comptes clients ou des transactions via le site Web {nom du broker}, {nom du broker} peut également collecter et conserver des informations sur les clients.

				4. {nom du broker} peut collecter des informations sensibles sur les clients. Si la résolution de la plainte fournie par {nom du broker} ne satisfait pas le client, le client peut se plaindre auprès du responsable de la protection de la vie privée qui enquêtera plus avant sur votre plainte.

				5. Les clients peuvent envoyer un e-mail pour demander les informations personnelles détenues par {nom du broker}. {nom du broker} traite généralement les demandes des clients dans un délai de 14 jours. Si la demande du client est complexe, dans ce cas cela peut prendre plus de temps, {nom du broker} informera le client qu'un délai supplémentaire est nécessaire. La demande des informations personnelles du client est gratuite, mais {nom du broker} peut facturer au client des frais raisonnables pour le traitement de la demande du client. Dans certains cas, la loi autorise {nom du broker} à refuser l'accès client. Si {nom du broker} a des raisons de soupçonner qu'une activité illégale grave ou une inconduite est en cours ou pourrait être engagée et implique la fonction ou le fonctionnement de {nom du broker}, si la demande est susceptible de nuire à des intérêts légitimes, la demande peut être rejetée. Dans ce cas, {nom du broker} fournira au client la raison du rejet (à moins qu'il ne soit jugé inapproprié ou déraisonnable de fournir la raison au client), d'autres questions réglementaires et fournira le mécanisme de réclamation existant. Si possible, {nom du broker} peut prendre des mesures raisonnables et fournir une demande si elle répond aux besoins de {nom du broker} et du client.

				6. {nom du broker} demande aux clients d'informer immédiatement {nom du broker} par écrit de tout changement dans le profil client détenu par {nom du broker}. Cela garantira que le profil client détenu par {nom du broker} est tenu à jour et permettra à {nom du broker} d'informer les clients de l'état actuel du compte du client, des responsabilités du client en matière de marge et des activités de trading du client. Les clients peuvent demander à {nom du broker} de modifier les informations personnelles du client détenues par {nom du broker} que le client juge inexactes. En ce qui concerne les exigences de modification ou de correction des informations ou de demande de politique de confidentialité et d'informations pratiques de {nom du broker}, si {nom du broker} et le client sont en désaccord sur l'exactitude des informations, le client peut demander à {nom du broker} de joindre une déclaration aux informations, en indiquant le client Les informations sont considérées comme inexactes ou incomplètes. {nom du broker} enverra un avis écrit au client pour refuser de corriger les informations personnelles, listera les raisons du refus (à moins qu'il ne soit jugé inapproprié de fournir la raison du refus), fournira un mécanisme de plainte pour le refus, et autres questions connexes.

				7. Si {nom du broker} accepte les informations comme étant inexactes, obsolètes, incomplètes ou non pertinentes ou trompeuses, {nom du broker} prendra toutes les mesures raisonnables pour corriger les informations personnelles afin de s'assurer qu'elles sont exactes, actuelles, complètes, pertinentes et non trompeuses.

				8. {nom du broker} prendra toutes les mesures raisonnables pour éviter toute utilisation inappropriée, perte, utilisation non autorisée, interférence, altération ou divulgation des informations personnelles du client.
			`,
    Dot: '.',
    // 20210217
    LoginDone1: 'Fully integrated transaction management system, including',
    LoginDone2: 'Multi-account visualized management',
    LoginDone3: 'Instant deposit and withdrawal',
    LoginDone4: 'Clear and concise fund record and transaction statements',
    LoginDone5:
      'Trading FX, metals, and CFDs is risky. We encourage you carefully consider your investment objectives. ',
    EWalletType: 'Types of e-Wallet',
    AcceptEWalletType: 'Types of acceptable e-wallets by the broker',
    EWalletSubmitMsg: `Please confirm the information you have filled in is correct before hitting the
				“Application” button. The broker will not be responsible for any losses associate with
				the incorrect inputs. `,
    // 20210719
    CryptocurrencyType: 'Type of cryptocurrency',
    CryptocurrencyTypeMsg: 'Cryptocurrency can only contain 3-10 characters',
    ProtocolsLabel:
      'Available Protocols (separate different protocols with semicolon ”;”)',
    ProtocolsMsg:
      'Each protocol name can only contain 3-10 characters including numbers',
    FormatMsg: 'Please confirm the format is correct',
    Decimal2Msg: 'Allow 2 decimal places',
    Protocol: 'Blockchain Protocol',
    // 20210720

    //20210817
    I18CreateUserAccountPrompt:
      'Account created successfully. Please email account information to the user.',
    I18CreateUserAccount: 'Create Account',
    // 20210819
    I18EnterEmailPleace: 'Email',
    I18AssignedAgent: 'Assigned Agent',
    I18SelectMarket: 'Select Market',
    I18SelectAgent: 'Select Agent',
    I18SelectNoAgent: 'No Agent',
    I18AgentChange: 'Modify Agent',
    I18SelectAgentTier: 'Select Tier',
    I18PromptTierCouldBeDifferent:
      'Default tier could be different with the tier in the original market.',
    I18ErrorGroupNotOpenInMarket:
      'The account {{TraderAccount.id}} of {{User.name}} was assigned to Group {{Group.name}} which is not available in the {{market.name}}.',
    I18PromptAgentChangeMarketOnlyWithLevels:
      'Agents are only allowed to change to market with multi-tiers.',
    I18NotHaveAgent: 'No agent.',
    // 20210924
    I18ClearCache: 'Clear cache',
    I18PromptClearCacheAfterUpdateManager:
      'To see your changes after updating the manager permissions on MT4/MT5, you may need to refresh by clearing your cache.',

    // 20211006
    I18FurtherInformation: 'Further information',
    I18LimitHalfwidthText: 'Limited to {limit} characters',
    I18ExceedTextLimit: 'Exceed the input limit',
    I18UpdateTimeLatest: 'Update Time',

    I18TraderAccountLimit: 'Maximum Trading Account',
    I18TraderAccountLimitExclude: 'Exclude existing accounts',
    I18TraderAccontLimitRange: 'Set a limit from {min} - {max}',

    // 20211122
    I18CustomField: 'Table on Demand',
    I18SelectAll: 'Select All',

    I18StorageNew: 'Intérêt',

    // 20211130
    I18OrderPosition: 'Position',
    I18OrderHistory: 'History',
    I18SetupPrice: 'Setup Price',
    I18OrderDoneTime: 'Done Time',
    I18OrderDoneTimeOrRange: 'Done Time',
    I18TradeTime: 'Time',
    I18FinalPrice: 'Price',
    // 20211209
    I18BankCodeEmptyCheck: 'SWIFT/IBAN code is unfilled, still save?',
    // 20211221
    I18DepositComfirmed: 'Deposit comfirmed',
    I18BrokerDepositDoubleCheckText:
      'Please confirm the deposit {currencyName} {amount} to {userName}?',
    I18BrokerDepositDepositTempText:
      'Please complete and approve the withdrawal application in the CRM system and make sure the amount first before actually transferring money to the client.',
    I18UserKycIsRequiredBeforeDeposit: 'Please complete user KYC first',
    I18WithdrawalRequestComfirmed: 'Withdrawal request comfirmed',
    I18AssetArrangementComfirmed: 'Asset arrangement completed',
    I18BrokerAssetArrangementToTraderDoubleCheckText:
      'Please confirm the asset arrangement {currencyName} {amount} to {traderId}?',
    I18BrokerWithdrawalRequestDoubleCheckText:
      'Please confirm the withdrawal {currencyName} {amount} for {userName}?',
    I18DepositByBroker: 'Deposit by broker',
    I18BrokerAssetArrangementToUserAccountDoubleCheckText:
      'Please confirm the user account {currencyName} {amount} to {accountId}?',
    I18AssetAmount: 'Amount',
    I18RequestByUserSelf: 'Request by client',
    I18RequestByBroker: 'Request by broker',
    I18TraderInformation: 'Trader Account Info.',
    I18ViewTraderInfoPermission:
      'Permission for agent to see trader account information',
    I18nUploadImageCorrupted: 'The uploaded image file is corrupted',
  },
}
