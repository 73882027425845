import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Home.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/test',
          name: 'test',
          component: () => import('./views/Test.vue'),
          meta: {
            rule: 'public',
          },
        },
        // =============================================================================
        // BROKER Routes
        // =============================================================================
        {
          path: '/broker/setting',
          name: 'broker-setting',
          component: () => import('./views/broker/setting/BrokerSettings.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'BrokerSetting',
          },
        },

        // GROUP
        {
          path: '/broker/group',
          name: 'group',
          component: () => import('./views/broker/Group.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'MTGroupSetting',
          },
        },
        {
          path: '/broker/group/:id',
          name: 'group-detail',
          component: () => import('./views/broker/GroupDetail.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'MTGroupSetting',
          },
        },
        {
          path: '/broker/trader',
          name: 'trader',
          component: () => import('./views/broker/user/Trader.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'TraderAccount',
          },
        },
        {
          path: '/broker/trader/:id',
          name: 'trader-detail-by-broker',
          component: () => import('./views/broker/user/TraderDetail.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'TraderDetail',
          },
        },
        {
          path: '/broker/user',
          name: 'user',
          component: () => import('./views/broker/user/User.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'User',
          },
        },
        {
          path: '/broker/user/:id',
          name: 'user-detail',
          component: () => import('./views/broker/user/UserDetail.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'UserDetail',
          },
        },
        //Agent
        {
          path: '/broker/agent',
          name: 'agent',
          component: () => import('./views/broker/user/AgentList.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'AgentList',
          },
        },
        {
          path: '/broker/agent/:id',
          name: 'broker-agent-detail',
          component: () => import('./views/broker/user/AgentDetail.vue'),
          meta: {
            rule: 'broker',
          },
        },
        {
          path: '/broker/agent/:id/organization',
          name: 'broker-agent-organization',
          component: () => import('./views/broker/user/Organization.vue'),
          meta: {
            rule: 'broker',
          },
        },
        // 審核
        {
          path: '/broker/audit-kyc',
          name: 'audit-kyc',
          component: () => import('./views/broker/audit/AuditKyc.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'AuditKyc',
          },
        },
        {
          path: '/broker/audit-kyc/:id',
          name: 'kyc-detail',
          component: () => import('./views/broker/audit/KycDetail.vue'),
          meta: {
            rule: 'broker',
          },
        },
        {
          path: '/broker/audit-withdraw',
          name: 'audit-withdraw',
          component: () => import('./views/broker/audit/AuditWithdraw.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'AuditWithdraw',
          },
        },
        {
          path: '/broker/audit-withdraw/:id',
          name: 'withdraw-detail',
          component: () => import('./views/broker/audit/WithdrawDetail.vue'),
          meta: {
            rule: 'broker',
          },
        },
        {
          path: '/broker/audit-deposit',
          name: 'audit-deposit',
          component: () => import('./views/broker/audit/AuditDeposit.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'AuditDeposit',
          },
        },
        //市場與反佣
        {
          path: '/broker/market',
          name: 'market',
          component: () => import('./views/broker/market/Market.vue'),
          meta: {
            rule: 'broker',
            // pageTitle: "MarketCommissionSetting",
          },
        },
        {
          path: '/broker/market/:id',
          name: 'market-level',
          component: () => import('./views/broker/market/MarketDetail.vue'),
          meta: {
            rule: 'broker',
            // pageTitle: "MarketLevel",
          },
        },
        //報表
        {
          path: '/broker/report/order',
          name: 'order-query',
          component: () => import('./views/broker/report/OrderQuery.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'OrderQuery',
          },
        },
        {
          path: '/broker/report/commission',
          name: 'commission',
          component: () => import('./views/broker/report/Commission.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'CommissionReport',
          },
        },
        {
          path: '/broker/report/balance',
          name: 'balance',
          component: () => import('./views/broker/report/Balance.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'BalanceReport',
          },
        },
        {
          path: '/broker/user-distribution',
          name: 'user-distribution',
          component: () => import('./views/broker/UserDistribution.vue'),
          meta: {
            rule: 'broker',
            pageTitle: 'UserDistribution',
          },
        },

        // =============================================================================
        // USER Routes
        // =============================================================================
        {
          path: '/about',
          name: 'broker-about',
          component: () => import('./views/broker/About.vue'),
          meta: {
            rule: 'public',
            pageTitle: 'About',
          },
        },
        {
          path: '/setting',
          name: 'setting',
          component: () => import('./views/user/Setting.vue'),
          meta: {
            rule: 'public',
            pageTitle: 'Setting',
          },
        },
        // {
        //   path: '/account',
        //   name: 'account',
        //   component: () => import('./views/user/Account.vue'),
        //   meta: {
        //     rule: 'user',
        //     pageTitle: "AccountManage",
        //   }
        // },
        {
          path: '/user/trader/:id',
          name: 'trader-detail',
          component: () => import('./views/user/TraderDetail.vue'),
          meta: {
            rule: 'user',
            pageTitle: 'TraderDetail',
          },
        },
        {
          path: '/history/:id',
          name: 'order-history',
          component: () => import('./views/user/OrderHistory.vue'),
          meta: {
            rule: 'user',
            pageTitle: 'OrderHistory',
          },
        },
        {
          path: '/kyc',
          name: 'kyc',
          component: () => import('./views/user/Kyc.vue'),
          meta: {
            rule: 'public',
            pageTitle: 'KYC',
          },
        },
        {
          path: '/order',
          name: 'user-order-query',
          component: () => import('./views/user/OrderQuery.vue'),
          meta: {
            rule: 'user',
            pageTitle: 'OrderQuery',
          },
        },
        {
          path: '/balance',
          name: 'user-balance',
          component: () => import('./views/user/UserBalance.vue'),
          meta: {
            rule: 'user',
            pageTitle: 'BalanceReport',
          },
        },
        //Agent
        // {
        //   path: '/agent',
        //   name: 'agent-detail',
        //   component: () => import('./views/dashboard/user/AgentDetail.vue'),
        //   meta: {
        //     rule: 'agent',
        //   },
        // },
        {
          path: '/agent/information',
          name: 'agent-information',
          component: () => import('./views/dashboard/user/AgentDetail.vue'),
          meta: {
            rule: 'agent',
          },
        },

        {
          path: '/agent/trader',
          name: 'trader',
          component: () => import('./views/user/agent/Trader.vue'),
          meta: {
            rule: 'agent',
            pageTitle: 'TraderAccount',
          },
        },
        {
          path: '/agent/trader/:id',
          name: 'trader-detail-by-agent',
          component: () => import('./views/user/agent/TraderDetail.vue'),
          meta: {
            rule: 'agent',
            pageTitle: 'TraderDetail',
          },
        },
        {
          path: '/agent/organization',
          name: 'agent-organization',
          component: () => import('./views/user/agent/Organization.vue'),
          meta: {
            rule: 'agent',
          },
        },
        {
          path: '/agent/commission',
          name: 'agent-commission',
          component: () => import('./views/user/agent/Commission.vue'),
          meta: {
            rule: 'agent',
            pageTitle: 'CommissionReport',
          },
        },
        {
          path: '/agent/order',
          name: 'agent-order',
          component: () => import('./views/user/agent/OrderQuery.vue'),
          meta: {
            rule: 'agent',
          },
        },
        {
          path: '/agent/balance',
          name: 'agent-balance',
          component: () => import('./views/user/agent/Balance.vue'),
          meta: {
            rule: 'agent',
            pageTitle: 'BalanceReport',
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('./layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'login',
          component: () => import('./views/Login.vue'),
          meta: {
            rule: 'public',
          },
        },
        // 註冊
        {
          path: '/register',
          name: 'register',
          component: () => import('./views/account/Register.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/register-verify',
          name: 'register-verify',
          component: () => import('./views/account/Register-verify.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/register-information',
          name: 'register-information',
          component: () => import('./views/account/Register-information.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/register-done',
          name: 'register-done',
          component: () => import('./views/account/Register-done.vue'),
          meta: {
            rule: 'public',
          },
        },
        // 忘記密碼
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: () => import('./views/account/Forgot-password.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/forgot-verify',
          name: 'forgot-verify',
          component: () => import('./views/account/Forgot-verify.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/reset-done',
          name: 'reset-done',
          component: () => import('./views/account/Reset-done.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/reset-password',
          name: 'reset-password',
          component: () => import('./views/account/Reset-password.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('./views/common/Error404.vue'),
          meta: {
            rule: 'public',
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/',
    },
  ],
})

router.afterEach(() => {
  // Remove initial loading
  // const appLoading = document.getElementById('loading-bg')
  //   if (appLoading) {
  //       appLoading.style.display = "none";
  //   }

  /// close vs-popup
  document.querySelectorAll('body > div.vs-component').forEach((el) => {
    el.remove()
  })
  document.querySelectorAll('body > div.vs-content-sidebar').forEach((el) => {
    el.remove()
  })

  /// disable Manager Server Selector
  /// TODO: 所有頁面分別設置狀態後可拿掉
  store.commit('UPDATE_ENABLE_SERVER_CHANGE', false)
})

export default router
