import mock from "@/fake-db/mock.js"
const data = {
  // agent data
  agentDetail: {
    "user": {
      "id": "479209514361421911",
      "name": "eric"
    },
    "market_id": "477904213049868322",
    "market_name": "AA市場",
    "market_level_id": "535189436959293532",
    "market_level_name": "A-1",
    "organizations_count": 5,
    "direct_count": 3,
    "direct": {
      "normal_user": 2,
      "sub_agent": 1,
      "same_agent": 0,
      "over_agent": 0,
      "disabled_user": 0
    },
    "wallet_request_total": {
      "commission": 80,
      "deposit": 522,
      "withdraw": 5897
    },
    "settle_commission": {
      "start_time": "2020-07-30T00:00:00Z",
      "end_time": "2020-07-31T00:00:00Z",
      "commission": 80,
      "same_level_reward": 0,
      "over_level_reward": 0
    },
    "popular_symbols": [
      {
        "symbol": "EURUSD",
        "lots": 3
      }
    ],
    "popular_symbols_direct": [
      {
        "symbol": "EURUSD",
        "lots": 3
      }
    ],
    "charts": {
      "account_open": [
        {
          "x": "2020-07-24T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-25T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-26T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-27T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-28T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-29T00:00:00Z",
          "y": 1
        },
        {
          "x": "2020-07-30T00:00:00Z",
          "y": 0
        }
      ],
      "account_open_direct": [
        {
          "x": "2020-07-24T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-25T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-26T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-27T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-28T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-29T00:00:00Z",
          "y": 1
        },
        {
          "x": "2020-07-30T00:00:00Z",
          "y": 0
        }
      ],
      "lots": [
        {
          "x": "2020-07-24T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-25T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-26T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-27T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-28T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-29T00:00:00Z",
          "y": 1
        },
        {
          "x": "2020-07-30T00:00:00Z",
          "y": 2
        }
      ],
      "lots_direct": [
        {
          "x": "2020-07-24T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-25T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-26T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-27T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-28T00:00:00Z",
          "y": 0
        },
        {
          "x": "2020-07-29T00:00:00Z",
          "y": 1
        },
        {
          "x": "2020-07-30T00:00:00Z",
          "y": 2
        }
      ],
      "profit": [
        {
          "x": "2020-07-24T00:00:00Z",
          "y1": 0,
          "y2": 0
        },
        {
          "x": "2020-07-25T00:00:00Z",
          "y1": 0,
          "y2": 0
        },
        {
          "x": "2020-07-26T00:00:00Z",
          "y1": 0,
          "y2": 0
        },
        {
          "x": "2020-07-27T00:00:00Z",
          "y1": 0,
          "y2": 0
        },
        {
          "x": "2020-07-28T00:00:00Z",
          "y1": 0,
          "y2": 0
        },
        {
          "x": "2020-07-29T00:00:00Z",
          "y1": 1,
          "y2": 0
        },
        {
          "x": "2020-07-30T00:00:00Z",
          "y1": 0,
          "y2": -10
        }
      ],
      "profit_direct": [
        {
          "x": "2020-07-24T00:00:00Z",
          "y1": 0,
          "y2": 0
        },
        {
          "x": "2020-07-25T00:00:00Z",
          "y1": 0,
          "y2": 0
        },
        {
          "x": "2020-07-26T00:00:00Z",
          "y1": 0,
          "y2": 0
        },
        {
          "x": "2020-07-27T00:00:00Z",
          "y1": 0,
          "y2": 0
        },
        {
          "x": "2020-07-28T00:00:00Z",
          "y1": 0,
          "y2": 0
        },
        {
          "x": "2020-07-29T00:00:00Z",
          "y1": 1,
          "y2": 0
        },
        {
          "x": "2020-07-30T00:00:00Z",
          "y1": 0,
          "y2": -10
        }
      ]
    }
  },
  line: {
    series: [
      {
        name: 'Sales',
        data: [140, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240, 295]
      }
    ]
  },
  browserAnalytics: [
      {
        id: 1,
        name: '總人數',
        ratio: 100,
        time: '66',
        comparedResult: '800'
      },
      {
        id: 3,
        name: '直屬',
        ratio: 8,
        time: '66',
        comparedResult: '-200'
      },
      {
        id: 2,
        name: 'Firefox',
        ratio: 19,
        time: '66',
        comparedResult: '100'
      },
      {
        id: 4,
        name: 'Internet Explorer',
        ratio: 27,
        time: '66',
        comparedResult: '-450'
      }
    ],
}
mock.onGet("/api/record/browser-analytics").reply(() => {
  return [200, data.browserAnalytics];
});

mock.onGet("/api/record/line").reply(() => {
    return [200, data.line];
  });
  

mock.onGet("/api/record/agentDetail").reply(() => {
    return [200, data.agentDetail];
  });
  