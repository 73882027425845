import mock from "@/fake-db/mock.js"
const data = {
  //
  funding: {
    currBalance: 22597,
    depostis: 20065,
    comparison: {
      resultPerc: 5.2,
      pastData: 956
    },
    meta: {
      earned: {
        val: 56156,
        progress: 50
      },
      duration: {
        val: '2 Year',
        progress: 50
      }
    }
  },
  //持倉查看
  group: [
    { "group": "Forex-Majors" , "show": true, "trade": false },
    { "group": "Forex" , "show": true, "trade": true },
    { "group": "Forex-Ext" , "show": true, "trade": true},
    { "group": "Gold" , "show": true, "trade": true},
    { "group": "Energy Spot" , "show": true, "trade": true},
    { "group": "INDEX" , "show": true, "trade": true},
    { "group": "Silver" , "show": true, "trade": true},
    { "group": "Crypto Currency" , "show": true, "trade": true},
    { "group": "CN300" , "show": true, "trade": true},
    { "group": "IndexA" , "show": true, "trade": true},
    { "group": "BOR" , "show": true, "trade": true},
    { "group": "Index-spot" , "show": true, "trade": false},
    { "group": "FX-SAXO" , "show": true, "trade": false},
    { "group": "METAL-SAXO" , "show": true, "trade": false},
    { "group": "INDEX-SAXO" , "show": true, "trade": false},
    { "group": "OIL-SAXO" , "show": true, "trade": true}
  ],
  symbols: [
    { "symbols": "EURUSD",
      "value": "1" },
    { "symbols": "USDJPY",
    "value": "2" },
    { "symbols": "AUDUSD",
    "value": "3" },
    { "symbols": "NZDUSD",
    "value": "4" },
    { "symbols": "USDCAD",
    "value": "5" },
    { "symbols": "USDCHF",
    "value": "6" },
    { "symbols": "GBPUSD",
    "value": "7" }
  ],
walletAllocationRecord: [
  { 
    "number": 11,
    "id": "1048910489111",
    "type": "Withdrawal",
    "target": "",
    "detailBt": false,
    "amount": 150.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Failed',
    //status 處理中 成功 失敗
  },
  { 
    "number": 10,
    "id": "1048910489110",
    "type": "Withdrawal",
    "target": "",
    "detailBt": false,
    "amount": 150.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Pending',
    //status 處理中 成功 失敗
  },
  { 
    "number": 9,
    "id": "1048910489109",
    "type": "Withdrawal",
    "target": "",
    "detailBt": false,
    "amount": 150.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Passed',
  },
  // {
  //   "number": 8,
  //   "id": "1048910489108",
  //   "type": "To",
  //   "target": "MO",
  //   "detailBt": true,
  //   "ticket": "1048910489104",
  //   "amount": 100.00,
  //   "created_at": "2020-01-17T03:48:53.368805Z",
  //   "updated_at": "2020-01-17T03:48:53.368805Z",
  //   "status": '',
  // },
  // {
  //   "number": 7,
  //   "id": "1048910489107",
  //   "type": "To",
  //   "target": "至PAUL",
  //   "detailBt": true,
  //   "ticket": "1048910489107",
  //   "amount": 100.00,
  //   "created_at": "2020-01-17T03:48:53.368805Z",
  //   "updated_at": "2020-01-17T03:48:53.368805Z",
  //   "status": '',
  // },
  { 
    "number": 6,
    "id": "1048910489106",
    "type": "Withdrawal",
    "target": "",
    "detailBt": false,
    "amount": 150.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Pending',
  },
  // { 
  //   "number": 5,
  //   "id": "1048910489105",
  //   "type": "From",
  //   "target": "MO",
  //   "detailBt": true,
  //   "amount": 200.00,
  //   "created_at": "2020-01-17T03:48:53.368805Z",
  //   "updated_at": "2020-01-17T03:48:53.368805Z",
  //   "status": '',
  // },
  // {
  //   "number": 4,
  //   "id": "1048910489104",
  //   "type": "From",
  //   "target": "PAUL",
  //   "detailBt": true,
  //   "amount": 300.00,
  //   "created_at": "2020-01-17T03:48:53.368805Z",
  //   "updated_at": "2020-01-17T03:48:53.368805Z",
  //   "status": '',
  // },
  {
    "number": 3,
    "id": "1048910489103",
    "type": "Deposit",
    "target": "",
    "detailBt": false,
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Passed',//電匯
  },
  {
    "number": 2,
    "id": "1048910489102",
    "type": "Deposit",
    "target": "",
    "detailBt": false,
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Failed',
  },
    {
    "number": 1,
    "id": "1048910489101",
    "type": "Deposit",
    "target": "",
    "detailBt": false,
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "updated_at": "2020-01-17T03:48:53.368805Z",
    "status": 'Pending',
  },
  ],
tradeAccountFundRecord: [
  {
    "no": 6,
    "id": "1048910489104",
    "type": "PnL",
    "ticket": "1048910489104",
    "amount": 100.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "大家發大財",
    "target": "BBB",
  },
  {
    "no": 5,
    "id": "1048910489105",
    "type": "Commission",
    "ticket": "1048910489105",
    "amount": 100.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "一月",
    "target": "BBB",
  },
  { 
    "no": 4,
    "id": "1048910489106",
    "type": "To",
    "amount": 150.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "至Bob",
    "target": "BBB",
  },
  { 
    "no": 3,
    "id": "1048910489107",
    "type": "From",
    "amount": 200.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "至Bob",
    "target": "BBB",
  },
  {
    "no": 2,
    "id": "1048910489108",
    "type": "ProfitShare",
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "一月",
    "target": "BBB",
  },
  {
    "no": 1,
    "id": "1048910489108",
    "type": "To",
    "amount": 300.00,
    "created_at": "2020-01-17T03:48:53.368805Z",
    "detail": "至Bob",
    "target": "BBB",
  },
],
}
mock.onGet("/api/record/symbols").reply(() => {
  return [200, data.symbols];
});
mock.onGet("/api/record/group").reply(() => {
  return [200, data.group];
});
mock.onGet("/api/record/funds").reply(() => {
  return [200, data.funds];
});

mock.onGet("/api/record/walletAllocationRecord").reply(() => {
  return [200, data.walletAllocationRecord];
});


mock.onGet("/api/record/tradeAccountFundRecord").reply(() => {
  return [200, data.tradeAccountFundRecord];
});

mock.onGet('/api/card/card-analytics/funding').reply(() => {
  return [200, data.funding]
})