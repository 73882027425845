const state = {
  // 通知更新 先拉出來
  brokerAuditNotify: false,
}

const mutations = {
  toggleBrokerAuditNotify(state) {
    state.brokerAuditNotify = !state.brokerAuditNotify
  },
  setBrokerAuditNotify(state, value) {
    state.brokerAuditNotify = value
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
