const state = {
  agentSettingOfBroker: {},
}

const getters = {
  isAgentCanViewTradderInfo: (state) => {
    return state.agentSettingOfBroker.view_trader_info
  },
}

const actions = {}

const mutations = {
  updateAgentSettingOfBroker(state, { agentSettingOfBroker }) {
    state.agentSettingOfBroker = {
      ...state.agentSettingOfBroker,
      ...agentSettingOfBroker,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
