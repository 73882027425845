/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import me from './me'
import broker from './broker'

import views from './views'
import global from './global'

import notification from './notification'
import customField from './preference/customField'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    me,
    views,
    notification,
    customField,
    broker,
    global,
  },
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== 'production',
})
