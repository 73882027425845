import mock from "@/fake-db/mock.js"
const data = {

//出入金報表
balanceReport: {
  "balances_total": {
    "total": 30,
    "deposit": 23,
    "deposit_amount": 50720,
    "withdraw": 7,
    "withdraw_amount": 5897
  },
  "balances": [
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "529706153790144632",
      "balance_type": "WITHDRAW",
      "created_at": "2020-07-06T08:34:29Z",
      "completed_at": "2020-07-06T09:01:11Z",
      "balance_status": "WITHDRAWN",
      "amount": 3333
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "529524433631576184",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-06T02:15:54Z",
      "completed_at": "2020-07-06T09:02:23Z",
      "balance_status": "APPROVED",
      "amount": 100
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "529522771470844024",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-06T02:12:26Z",
      "completed_at": "2020-07-06T09:02:30Z",
      "balance_status": "REJECTED",
      "amount": 100
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "540595754549379192",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-22T02:41:09Z",
      "completed_at": "2020-07-22T02:41:34Z",
      "balance_status": "APPROVED",
      "amount": 100
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "540595820341231736",
      "balance_type": "WITHDRAW",
      "created_at": "2020-07-22T02:41:17Z",
      "completed_at": "2020-07-22T02:41:55Z",
      "balance_status": "WITHDRAWN",
      "amount": 100
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "529510555719827483",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-06T01:46:59Z",
      "completed_at": "2020-08-05T06:52:56Z",
      "balance_status": "APPROVED",
      "amount": 100
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "529544851117572216",
      "balance_type": "WITHDRAW",
      "created_at": "2020-07-06T02:58:26Z",
      "completed_at": "2020-07-06T02:58:52Z",
      "balance_status": "WITHDRAWN",
      "amount": 1020
    },
    {
      "user_id": "545457350027247699",
      "is_agent": false,
      "id": "555288864277659667",
      "balance_type": "DEPOSIT",
      "created_at": "2020-08-12T08:51:48Z",
      "completed_at": "2020-08-12T08:52:04Z",
      "balance_status": "APPROVED",
      "amount": 10000
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "529544743986659448",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-06T02:58:12Z",
      "completed_at": "2020-07-06T02:59:24Z",
      "balance_status": "REJECTED",
      "amount": 111
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "529538068751843448",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-06T02:44:18Z",
      "completed_at": "2020-07-06T02:59:27Z",
      "balance_status": "APPROVED",
      "amount": 222
    },
    {
      "user_id": "545457350027247699",
      "is_agent": false,
      "id": "555295949841235987",
      "balance_type": "DEPOSIT",
      "created_at": "2020-08-12T09:06:33Z",
      "completed_at": "2020-08-12T09:06:51Z",
      "balance_status": "APPROVED",
      "amount": 1000
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "529620946764431480",
      "balance_type": "WITHDRAW",
      "created_at": "2020-07-06T05:36:58Z",
      "completed_at": "2020-07-06T05:37:20Z",
      "balance_status": "WITHDRAWN",
      "amount": 1111
    },
    {
      "user_id": "555762079890407507",
      "is_agent": false,
      "id": "555767313660379155",
      "balance_type": "DEPOSIT",
      "created_at": "2020-08-13T01:28:34Z",
      "completed_at": "2020-08-13T01:29:19Z",
      "balance_status": "APPROVED",
      "amount": 10000
    },
    {
      "user_id": "555762079890407507",
      "is_agent": false,
      "id": "555841495865753619",
      "balance_type": "DEPOSIT",
      "created_at": "2020-08-13T04:03:06Z",
      "completed_at": "2020-08-13T04:03:32Z",
      "balance_status": "APPROVED",
      "amount": 5000
    },
    {
      "user_id": "527545668999315533",
      "is_agent": false,
      "id": "558580423706280004",
      "balance_type": "DEPOSIT",
      "created_at": "2020-08-17T03:09:12Z",
      "completed_at": "2020-08-17T03:09:30Z",
      "balance_status": "APPROVED",
      "amount": 20000
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527659053023756367",
      "balance_type": "WITHDRAW",
      "created_at": "2020-07-03T09:29:41Z",
      "completed_at": "2020-07-06T08:33:19Z",
      "balance_status": "WITHDRAWN",
      "amount": 122
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "558602996041121860",
      "balance_type": "DEPOSIT",
      "created_at": "2020-08-17T03:56:14Z",
      "completed_at": "2020-08-17T03:56:25Z",
      "balance_status": "APPROVED",
      "amount": 1000
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "529711946434347128",
      "balance_type": "WITHDRAW",
      "created_at": "2020-07-06T08:46:33Z",
      "completed_at": "2020-07-06T08:47:28Z",
      "balance_status": "WITHDRAWN",
      "amount": 111
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527650750860361801",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-03T09:12:23Z",
      "completed_at": "2020-07-03T09:12:54Z",
      "balance_status": "APPROVED",
      "amount": 100
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527651207561347145",
      "balance_type": "WITHDRAW",
      "created_at": "2020-07-03T09:13:20Z",
      "completed_at": "2020-07-03T09:13:50Z",
      "balance_status": "WITHDRAWN",
      "amount": 100
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527652275196264521",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-03T09:15:34Z",
      "completed_at": "2020-07-03T09:15:34Z",
      "balance_status": "APPLYING",
      "amount": 100
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527657626163478607",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-03T09:26:43Z",
      "completed_at": "2020-07-03T09:26:43Z",
      "balance_status": "APPLYING",
      "amount": 222
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527659800591335503",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-03T09:31:15Z",
      "completed_at": "2020-07-03T09:31:15Z",
      "balance_status": "APPLYING",
      "amount": 122
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527674002479513627",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-03T10:00:50Z",
      "completed_at": "2020-07-03T10:00:50Z",
      "balance_status": "APPLYING",
      "amount": 222
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527674226849611803",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-03T10:01:18Z",
      "completed_at": "2020-07-03T10:01:18Z",
      "balance_status": "APPLYING",
      "amount": 333
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527680171503779867",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-03T10:13:41Z",
      "completed_at": "2020-07-03T10:13:41Z",
      "balance_status": "APPLYING",
      "amount": 444
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527680360222294043",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-03T10:14:05Z",
      "completed_at": "2020-07-03T10:14:05Z",
      "balance_status": "APPLYING",
      "amount": 111
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527686567632830491",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-03T10:27:00Z",
      "completed_at": "2020-07-03T10:27:00Z",
      "balance_status": "APPLYING",
      "amount": 1110
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "527690403592273947",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-03T10:35:00Z",
      "completed_at": "2020-07-03T10:35:00Z",
      "balance_status": "APPLYING",
      "amount": 123
    },
    {
      "user_id": "479209514361421911",
      "is_agent": true,
      "id": "529499644800008219",
      "balance_type": "DEPOSIT",
      "created_at": "2020-07-06T01:24:15Z",
      "completed_at": "2020-07-06T01:24:15Z",
      "balance_status": "APPLYING",
      "amount": 100
    }
  ]
},


//佣金報表
commissionReport: {
  "commissions_total": {
    "total": 2,
    "agent": 1,
    "amount": 190
  },
  "commissions": [
    {
      "user_id": "479209514361421911",
      "id": "546768016214327417",
      "market_id": "477904213049868322",
      "market_name": "AA市場",
      "market_level_id": "535189436959293532",
      "market_level_name": "A-1",
      "completed_at": "2020-07-31T01:00:02Z",
      "commission_type": 1,
      "amount": 80
    },
    {
      "user_id": "479209514361421911",
      "id": "559209612566331467",
      "market_id": "477904213049868322",
      "market_name": "AA市場",
      "market_level_id": "535189436959293532",
      "market_level_name": "A-1",
      "completed_at": "2020-08-18T01:00:01Z",
      "commission_type": 0,
      "amount": 110
    }
  ]
}
,


  //歷史交易
history:[
  {
    "id": "1048910489104",
    "symbol": "SWISS20",
    "ticket": "1048910489104",
    "type": "Buy",
    "lots": "1",
    "open_price": "108.80",
    "open_time": "2020-01-17T03:48:53.368805Z",
    "stop_loss": 1.20,
    "take_profit": 1.20,
    "swap": "0.00",
    "handingFee": "0.00",
    "prRatioPoint": 6,
    "close_time":"2020-01-01T03:48:53.368805Z",
    "close_price": 100.00,
    "profit": 18.00,
  },
  {
    "id": "1048910489105",
    "symbol": "AUD/USD",
    "ticket": "1048910489105",
    "type": "Buy",
    "lots": "0.01",
    "open_price": 70.00,
    "open_time": "2020-01-17T03:48:53.368805Z",
    "stop_loss": 0,
    "take_profit": 10.00,
    "swap": "-19.01",
    "handingFee": "0.15",
    "prRatioPoint": 3,
    "close_time":"2020-01-07T03:48:53.368805Z",
    "close_price": 80.00,
    "profit": -18.00,
  },
  {
    "id": "1048910489106",
    "symbol": "NAS100",
    "ticket": "1048910489106",
    "type": "sell",
    "lots": "1",
    "open_price": 70.30,
    "open_time": "2019-01-17T03:48:53.368805Z",
    "stop_loss": 0,
    "take_profit": -20.30,
    "swap": "-5.33",
    "handingFee": "0.04",
    "prRatioPoint": "-1391.30",
    "close_time":"2019-10-07T03:48:53.368805Z",
    "close_price": 50.00,
    "profit": 18.00,
  },
  {
    "id": "1048910489107",
    "symbol": "ANDNZD",
    "ticket": "1048910489107",
    "type": "Buy",
    "lots": "1",
    "open_price": "123.00",
    "open_time": "2020-01-17T03:48:53.368805Z",
    "stop_loss": 0,
    "take_profit": -187,
    "swap": "-65.33",
    "handingFee": "0.00",
    "prRatioPoint": "-1391.30",
    "close_time":"2020-02-22T03:48:53.368805Z",
    "close_price": 70.30,
    "profit": -18.00,
  },
  {
    "id": "1048910489108",
    "symbol": "USDCZK",
    "ticket": "1048910489108",
    "type": "Buy",
    "lots": "1",
    "open_price": "22.58",
    "open_time": "2020-01-17T03:48:53.368805Z",
    "stop_loss": 0,
    "take_profit": 2.1,
    "swap": 0,
    "handingFee": 0.00,
    "prRatioPoint": "-1391.30",
    "close_time":"2020-02-15T03:48:53.368805Z",
    "close_price": 70.30,
    "profit": 1829.02,
  },
],
// 訂單查詢
userlist: {
  "users": [
    {
      "id": "479209514361421911",
      "order": 1123,
      "symbol": "XAUUSD",
      "type": "buy",
      "lots": "3",
      "price": 1435.00,
      "open_at": "2020-06-24T03:50:07Z",
      "close_at": "2020-06-24T03:50:07Z",
      "stoploss": 1223,
      "takeprofit": 2442,
      "interest": 1324425,
      "handingFee": 23414,
      "profit": 1345,
    },
    {
      "id": "479209514361421912",
      "order": 1124,
      "symbol": "XAUUSD",
      "type": "sell",
      "lots": "1.22",
      "price": 1435.00,
      "open_at": "2020-06-24T03:50:07Z",
      "close_at": "2020-06-24T03:50:07Z",
      "stoploss": 422,
      "takeprofit": 4224,
      "interest": 13245,
      "handingFee": 23414,
      "profit": 1345,
    },
    {
      "id": "479209514361421912",
      "order": 1124,
      "symbol": "XAUUSD",
      "type": "sell",
      "lots": "1.22",
      "price": 1435.00,
      "open_at": "2020-06-24T03:50:07Z",
      "close_at": "2020-06-24T03:50:07Z",
      "stoploss": 422,
      "takeprofit": 4224,
      "interest": 13245,
      "handingFee": 23414,
      "profit": 1345,
    },
    {
      "id": "479209514361421912",
      "order": 1124,
      "symbol": "XAUUSD",
      "type": "sell",
      "lots": "1.22",
      "price": 1435.00,
      "open_at": "2020-06-24T03:50:07Z",
      "close_at": "2020-06-24T03:50:07Z",
      "stoploss": 422,
      "takeprofit": 4224,
      "interest": 13245,
      "handingFee": 23414,
      "profit": 1345,
    },
    {
      "id": "479209514361421912",
      "order": 1124,
      "symbol": "XAUUSD",
      "type": "sell",
      "lots": "1.22",
      "price": 1435.00,
      "open_at": "2020-06-24T03:50:07Z",
      "close_at": "2020-06-24T03:50:07Z",
      "stoploss": 422,
      "takeprofit": 4224,
      "interest": 13245,
      "handingFee": 23414,
      "profit": 1345,
    },
   
  ]
},
//訂單查詢 市場清單 代理清單
marketAndAgentList :{
  "markets": [
    {
      "id": "477904213049868322",
      "name": "AA市場"
    },
    {
      "id": "477904213049868323",
      "name": "BB市場"
    },
    {
      "id": "477904213049868324",
      "name": "C市場"
    }
  ],
  "agents": [
    {
      "user": {
        "id": "479209514361421911",
        "name": "eric"
      }
    },
    {
      "user": {
        "id": "511085083130593355",
        "name": "crux-test"
      }
    },
    {
      "user": {
        "id": "513369009022828619",
        "name": "很有钱"
      }
    }
  ],
},
// 訂單查詢 gql
orders: {
  "orders_total": {
    "total": 196941,
    "profit": 107447389.13,
    "volume": 101156288,
    "storage": -23741.95,
    "fees": 0
  },
  "orders": [
    {
      "order": 3590521,
      "login": 160086434,
      "symbol": "GBPCAD",
      "cmd": 1,
      "volume": 1,
      "open_time": "2020-08-11T04:33:07Z",
      "state": 0,
      "open_price": 1.74487,
      "sl": 0,
      "tp": 0,
      "close_time": "1970-01-01T00:00:00Z",
      "storage": 0,
      "close_price": 1.74505,
      "profit": -0.14,
      "fees": 0,
      "comment": "24544690",
      "commission": 0
    },
    {
      "order": 2900849,
      "login": 420,
      "symbol": "EURUSD",
      "cmd": 0,
      "volume": 100,
      "open_time": "2019-10-10T17:05:53Z",
      "state": 3,
      "open_price": 1.10216,
      "sl": 0,
      "tp": 0,
      "close_time": "2019-12-11T04:07:10Z",
      "storage": -591.61,
      "close_price": 1.10934,
      "profit": 718,
      "fees": 0,
      "comment": "",
      "commission": 0
    },
    {
      "order": 3023979,
      "login": 420,
      "symbol": "AUDUSD",
      "cmd": 0,
      "volume": 100,
      "open_time": "2019-12-11T04:05:58Z",
      "state": 3,
      "open_price": 0.68111,
      "sl": 0,
      "tp": 0,
      "close_time": "2019-12-11T04:07:05Z",
      "storage": 0,
      "close_price": 0.68109,
      "profit": -2,
      "fees": 0,
      "comment": "",
      "commission": 0
    },
    {
      "order": 3023980,
      "login": 420,
      "symbol": "EURUSD",
      "cmd": 0,
      "volume": 100,
      "open_time": "2019-12-11T04:06:04Z",
      "state": 3,
      "open_price": 1.1093,
      "sl": 0,
      "tp": 0,
      "close_time": "2019-12-11T04:07:06Z",
      "storage": 0,
      "close_price": 1.10934,
      "profit": 4,
      "fees": 0,
      "comment": "",
      "commission": 0
    },
    {
      "order": 3023981,
      "login": 420,
      "symbol": "EURUSD",
      "cmd": 0,
      "volume": 100,
      "open_time": "2019-12-11T04:06:34Z",
      "state": 3,
      "open_price": 1.1093,
      "sl": 0,
      "tp": 0,
      "close_time": "2019-12-11T04:07:06Z",
      "storage": 0,
      "close_price": 1.10934,
      "profit": 4,
      "fees": 0,
      "comment": "",
      "commission": 0
    },
    {
      "order": 3023982,
      "login": 420,
      "symbol": "EURUSD",
      "cmd": 0,
      "volume": 1000,
      "open_time": "2019-12-11T04:07:22Z",
      "state": 3,
      "open_price": 1.10936,
      "sl": 0,
      "tp": 0,
      "close_time": "2020-03-19T08:40:27Z",
      "storage": -9087.63,
      "close_price": 1.09328,
      "profit": -16080,
      "fees": 0,
      "comment": "",
      "commission": 0
    },
    {
      "order": 3184748,
      "login": 420,
      "symbol": "EURUSD",
      "cmd": 1,
      "volume": 50,
      "open_time": "2020-03-18T11:57:36Z",
      "state": 4,
      "open_price": 1.101,
      "sl": 0,
      "tp": 0,
      "close_time": "2020-03-18T11:59:25Z",
      "storage": 0,
      "close_price": 1.098,
      "profit": 150,
      "fees": 0,
      "comment": "to #3184761",
      "commission": 0
    },
    {
      "order": 3184761,
      "login": 420,
      "symbol": "EURUSD",
      "cmd": 1,
      "volume": 50,
      "open_time": "2020-03-18T12:57:36Z",
      "state": 3,
      "open_price": 1.10222,
      "sl": 0,
      "tp": 0,
      "close_time": "2020-03-19T08:40:27Z",
      "storage": 6.32,
      "close_price": 1.09336,
      "profit": 443,
      "fees": 0,
      "comment": "from #3184748",
      "commission": 0
    },
    {
      "order": 3184825,
      "login": 420,
      "symbol": "EURUSD",
      "cmd": 1,
      "volume": 100,
      "open_time": "2020-03-18T12:03:25Z",
      "state": 3,
      "open_price": 1.103,
      "sl": 0,
      "tp": 0,
      "close_time": "2020-03-18T12:05:27Z",
      "storage": 0,
      "close_price": 1.103,
      "profit": 0,
      "fees": 0,
      "comment": "",
      "commission": 0
    },
    {
      "order": 3184883,
      "login": 420,
      "symbol": "EURUSD",
      "cmd": 1,
      "volume": 100,
      "open_time": "2020-03-18T12:09:27Z",
      "state": 3,
      "open_price": 1.102,
      "sl": 0,
      "tp": 0,
      "close_time": "2020-03-18T12:10:21Z",
      "storage": 0,
      "close_price": 1.1,
      "profit": 200,
      "fees": 0,
      "comment": "",
      "commission": 0
    }
  ],
},

// 佣金報表
commission: {
  "users": [
    {
      "id": "479209514361421911",
      "order": 1123,
      "symbol": "XAUUSD",
      "type": "buy",
      "lots": "3",
      "price": 1435.00,
      "open_at": "2020-06-24T03:50:07Z",
      "close_at": "2020-06-24T03:50:07Z",
      "stoploss": 1223,
      "takeprofit": 2442,
      "interest": 1324425,
      "handingFee": 23414,
      "profit": 1345,
    },
    {
      "id": "479209514361421912",
      "order": 1124,
      "symbol": "XAUUSD",
      "type": "sell",
      "lots": "1.22",
      "price": 1435.00,
      "open_at": "2020-06-24T03:50:07Z",
      "close_at": "2020-06-24T03:50:07Z",
      "stoploss": 422,
      "takeprofit": 4224,
      "interest": 13245,
      "handingFee": 23414,
      "profit": 1345,
    },
    {
      "id": "479209514361421912",
      "order": 1124,
      "symbol": "XAUUSD",
      "type": "sell",
      "lots": "1.22",
      "price": 1435.00,
      "open_at": "2020-06-24T03:50:07Z",
      "close_at": "2020-06-24T03:50:07Z",
      "stoploss": 422,
      "takeprofit": 4224,
      "interest": 13245,
      "handingFee": 23414,
      "profit": 1345,
    },
    {
      "id": "479209514361421912",
      "order": 1124,
      "symbol": "XAUUSD",
      "type": "sell",
      "lots": "1.22",
      "price": 1435.00,
      "open_at": "2020-06-24T03:50:07Z",
      "close_at": "2020-06-24T03:50:07Z",
      "stoploss": 422,
      "takeprofit": 4224,
      "interest": 13245,
      "handingFee": 23414,
      "profit": 1345,
    },
    {
      "id": "479209514361421912",
      "order": 1124,
      "symbol": "XAUUSD",
      "type": "sell",
      "lots": "1.22",
      "price": 1435.00,
      "open_at": "2020-06-24T03:50:07Z",
      "close_at": "2020-06-24T03:50:07Z",
      "stoploss": 422,
      "takeprofit": 4224,
      "interest": 13245,
      "handingFee": 23414,
      "profit": 1345,
    },
   
  ]
}
}

mock.onGet("/api/record/order/history").reply(() => {
  return [200, data.history];
});
mock.onGet("/api/record/order/userlist").reply(() => {
  return [200, data.userlist];
});

mock.onGet("/api/record/order/commissioin").reply(() => {
  return [200, data.commissioin];
});

mock.onGet("/api/record/order/orders").reply(() => {
  return [200, data.orders];
});

mock.onGet("/api/record/order/market_and_agent_list").reply(() => {
  return [200, data.marketAndAgentList];
});

mock.onGet("/api/record/order/commission_report").reply(() => {
  return [200, data.commissionReport];
});
mock.onGet("/api/record/order/balance_report").reply(() => {
  return [200, data.balanceReport];
});


