import mock from "@/fake-db/mock.js"
  const data = {
    countryCode: [{
      "content": "美國 (+1)",
      "code": "US"
    }, {
      "content": "加拿大 (+1)",
      "code": "CA"
    }, {
      "content": "英國 (+44)",
      "code": "UK"
    }, {
      "content": "阿富汗 (+93)",
      "code": "AF"
    }, {
      "content": "阿根廷 (+54)",
      "code": "AR"
    }, {
      "content": "奧地利 (+43)",
      "code": "AT"
    }, {
      "content": "澳大利亞 (+61)",
      "code": "AU"
    }, {
      "content": "巴林 (+973)",
      "code": "BH"
    }, {
      "content": "孟加拉 (+880)",
      "code": "BD"
    }, {
      "content": "比利時 (+32)",
      "code": "BE"
    }, {
      "content": "不丹 (+975)",
      "code": "BT"
    }, {
      "content": "玻利維亞 (+591)",
      "code": "BO"
    }, {
      "content": "巴西 (+55)",
      "code": "BR"
    }, {
      "content": "柬埔寨 (+855)",
      "code": "KH"
    }, {
      "content": "喀麥隆 (+237)",
      "code": "CM"
    }, {
      "content": "中國 (+86)",
      "code": "CN"
    }, {
      "content": "安圭拉 (+1264)",
      "code": "AI"
    }, {
      "content": "安地瓜 (+1268)",
      "code": "AG"
    }, {
      "content": "阿魯巴 (+297)",
      "code": "AW"
    }, {
      "content": "百慕達 (+1441)",
      "code": "BM"
    }, {
      "content": "多明尼加 (+1767)",
      "code": "DO"
    }, {
      "content": "格瑞那達 (+1473)",
      "code": "GD"
    }, {
      "content": "聖盧西亞 (+1758)",
      "code": "LC"
    }, {
      "content": "哥倫比亞 (+57)",
      "code": "CO"
    }, {
      "content": "新加坡 (+65)",
      "code": "SG"
    }, {
      "content": "剛果共和國 (+243)",
      "code": "CG"
    }, {
      "content": "瑞士 (+41)",
      "code": "CH"
    }, {
      "content": "德國 (+49)",
      "code": "DE"
    }, {
      "content": "丹麥 (+45)",
      "code": "DK"
    }, {
      "content": "埃及 (+20)",
      "code": "EG"
    }, {
      "content": "西班牙 (+34)",
      "code": "ES"
    }, {
      "content": "薩爾瓦多 (+503)",
      "code": "SV"
    }, {
      "content": "芬蘭 (+358)",
      "code": "FI"
    }, {
      "content": "斐濟 (+679)",
      "code": "FJ"
    }, {
      "content": "法國 (+33)",
      "code": "FR"
    }, {
      "content": "喬治亞 (+995)",
      "code": "GE"
    }, {
      "content": "迦納 (+233)",
      "code": "GH"
    }, {
      "content": "希臘 (+30)",
      "code": "GR"
    }, {
      "content": "瓜地馬拉 (+502)",
      "code": "GT"
    }, {
      "content": "蓋亞那 (+967)",
      "code": "GY"
    }, {
      "content": "海地 (+509)",
      "code": "HT"
    }, {
      "content": "宏都拉斯 (+504)",
      "code": "HN"
    }, {
      "content": "香港 (+852)",
      "code": "HK"
    }, {
      "content": "印度 (+91)",
      "code": "IN"
    }, {
      "content": "冰島 (+354)",
      "code": "IS"
    }, {
      "content": "印尼 (+62)",
      "code": "ID"
    }, {
      "content": "伊拉克 (+964)",
      "code": "IQ"
    }, {
      "content": "愛爾蘭 (+353)",
      "code": "IE"
    }, {
      "content": "義大利 (+39)",
      "code": "IT"
    }, {
      "content": "牙買加 (+1876)",
      "code": "JM"
    }, {
      "content": "約旦 (+962)",
      "code": "JO"
    }, {
      "content": "哈薩克 (+7)",
      "code": "KZ"
    }, {
      "content": "肯亞 (+254)",
      "code": "KE"
    }, {
      "content": "日本 (+81)",
      "code": "JP"
    }, {
      "content": "韓國 (+82)",
      "code": "KP"
    }, {
      "content": "科威特 (+965)",
      "code": "KW"
    }, {
      "content": "盧森堡 (+352)",
      "code": "LU"
    }, {
      "content": "澳門 (+853)",
      "code": "MO"
    }, {
      "content": "馬其頓 (+389)",
      "code": "MK"
    }, {
      "content": "馬達加斯加 (+261)",
      "code": "MG"
    }, {
      "content": "馬來西亞 (+60)",
      "code": "ML"
    }, {
      "content": "馬爾地夫 (+960)",
      "code": "MV"
    }, {
      "content": "墨西哥 (+52)",
      "code": "MX"
    }, {
      "content": "摩洛哥 (+212)",
      "code": "MA"
    }, {
      "content": "挪威 (+47)",
      "code": "NO"
    }, {
      "content": "諾魯 (+674)",
      "code": "NR"
    }, {
      "content": "紐西蘭 (+64)",
      "code": "NZ"
    }, {
      "content": "尼加拉瓜 (+505)",
      "code": "NI"
    }, {
      "content": "奈及利亞 (+234)",
      "code": "NG"
    }, {
      "content": "巴基斯坦 (+92)",
      "code": "PK"
    }, {
      "content": "巴拿馬 (+507)",
      "code": "PA"
    }, {
      "content": "巴布亞紐幾內亞 (+675)",
      "code": "PG"
    }, {
      "content": "葡萄牙 (+351)",
      "code": "PT"
    }, {
      "content": "巴拉圭 (+595)",
      "code": "PY"
    }, {
      "content": "羅馬尼亞 (+40)",
      "code": "RO"
    }, {
      "content": "俄羅斯 (+7)",
      "code": "RU"
    }, {
      "content": "盧旺達 (+250)",
      "code": "RW"
    }, {
      "content": "沙烏地阿拉伯 (+966)",
      "code": "SA"
    }, {
      "content": "敘利亞 (+381)",
      "code": "SY"
    }, {
      "content": "塞席爾 (+248)",
      "code": "SC"
    }, {
      "content": "斯里蘭卡 (+94)",
      "code": "LK"
    }, {
      "content": "蘇丹 (+249)",
      "code": "SD"
    }, {
      "content": "瑞典 (+46)",
      "code": "SE"
    }, {
      "content": "泰國 (+66)",
      "code": "TL"
    }, {
      "content": "中國台灣 (+886)",
      "code": "TW"
    }, {
      "content": "湯加群島 (+676)",
      "code": "TO"
    }, {
      "content": "土耳其 (+90)",
      "code": "TR"
    }, {
      "content": "烏干達 (+256)",
      "code": "UG"
    }, {
      "content": "烏克蘭 (+380)",
      "code": "UA"
    }, {
      "content": "阿拉伯聯合大公國 (+971)",
      "code": "AE"
    }, {
      "content": "烏拉圭 (+598)",
      "code": "UY"
    }, {
      "content": "烏茲別克 (+998)",
      "code": "UZ"
    }, {
      "content": "委內瑞拉 (+58)",
      "code": "VE"
    }, {
      "content": "葉門 (+967)",
      "code": "YE"
    }]
  }

mock.onGet("/api/data/countryCode").reply(() => {
  return [200, data.countryCode];
});