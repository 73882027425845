import mock from './mock'


import "./data/countryCode.js"
//ACCOUNT
import "./data/user/index.js"
import "./data/user/user-list.js"
import "./data/record/index.js"
import "./data/record/order.js"
import "./data/record/group.js"
import "./data/record/analytics.js"
import "./data/setting.js"

mock.onAny().passThrough(); // forwards the matched request over network
